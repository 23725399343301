import React, { useReducer, useEffect } from "react";
import CreatableSelect from "react-select/creatable";

const reducer = (state, action) => {
  switch (action.type) {
    case "fieldChanged": {
      return {
        ...state,
        [action.field]: action.value,
      };
    }

    default:
      break;
  }
  return state;
};

const EmailInput = (props) => {
  let { parentDispatch } = props;

  const initialState = {
    email: [],
    emailInputValue: "",
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const emailComponents = {
    DropdownIndicator: null,
  };

  const createOption = (label) => ({
    label,
    value: label,
  });

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailKeyDown = (event) => {
    const { emailInputValue, email } = state;
    if (!emailInputValue || validateEmail(emailInputValue)) return;

    switch (event.key) {
      case "Enter":
      case "Tab":
        let newValue = [];

        if (state.email) {
          newValue = [...state.email, createOption(emailInputValue)];
        } else {
          newValue = [createOption(emailInputValue)];
        }

        let newValueArray = newValue.map((item) => item.value);

        dispatch({
          type: "fieldChanged",
          field: "email",
          value: newValue,
        });

        parentDispatch({
          type: props.dispatchType,
          field: props.dispatchField,
          value: newValueArray,
        });

        dispatch({
          type: "fieldChanged",
          field: "emailInputValue",
          value: "",
        });
        event.preventDefault();
    }
  };

  const handleEmailInputChange = (inputValue, action) => {
    let type = props.dispatchType;
    let field = props.dispatchField;

    dispatch({
      type: "fieldChanged",
      field: "emailInputValue",
      value: inputValue,
    });

    if (
      action.action === "menu-close" &&
      validateEmail(state.emailInputValue)
    ) {
      let newValue = [...state.email, createOption(state.emailInputValue)];

      let newValueArray = newValue.map((item) => item.value);

      dispatch({
        type: "fieldChanged",
        field: "email",
        value: newValue || [],
      });

      parentDispatch({
        type,
        field,
        value: newValueArray || [],
      });

      dispatch({
        type: "fieldChanged",
        field: "emailInputValue",
        value: "",
      });
    }
  };

  const handleEmailChange = (newValue, actionMeta) => {
    let type = props.dispatchType;
    let field = props.dispatchField;

    if (type && field) {
      console.log("PARENT DISPATCH", type, field, newValue);
      parentDispatch({
        type,
        field,
        value: newValue || [],
      });
      dispatch({
        type: "fieldChanged",
        field: "email",
        value: newValue || [],
      });
    }
  };

  return (
    <div className="form-group mt20">
      <label htmlFor={props.name}>{props.label}</label>
      <CreatableSelect
        className="email-select"
        classNamePrefix="select"
        placeholder={props.placeholder || props.label}
        components={emailComponents}
        isClearable
        isMulti
        menuIsOpen={false}
        onChange={handleEmailChange}
        onInputChange={handleEmailInputChange}
        onKeyDown={handleEmailKeyDown}
        inputValue={state.emailInputValue}
        value={state.email}
        name={props.name}
        inputId={props.inputId || props.name}
      ></CreatableSelect>
    </div>
  );
};

export default EmailInput;
