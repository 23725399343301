import React, { Fragment, useReducer, useEffect } from "react";
import DatePicker from "react-date-picker";
import { useAuth0 } from "@auth0/auth0-react";

import ai from "../../helpers/axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import * as Sentry from "@sentry/react";
import download from "js-file-download";

const reducer = (state, action) => {
  switch (action.type) {
    case "fieldChanged": {
      let value =
        action.fieldType === "number" ? parseInt(action.value) : action.value;

      return {
        ...state,
        [action.field]: value,
      };
    }

    default:
      break;
  }
  return state;
};

const initialState = {
  startDate: "",
  endDate: "",
};

const ExportContent = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();

  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  useEffect(() => {
    document.title = "Export Tool | Flight Deck by evoJets";
  }, []);

  const handleExport = async (e) => {
    e.preventDefault();
    let exportType = null;
    if (e.target.id === "export-leads") {
      exportType = "leads";
    }

    if (e.target.id === "export-estimates") {
      exportType = "estimates";
    }

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let response = await ai
      .auth(token)
      .get(
        `/api/export/${exportType}?startDate=${state.startDate}&endDate=${state.endDate}`,
        {
          responseType: "blob",
        }
      )
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
      });

    if (response && response.data) {
      toast.success("Export Created Successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      let fileName = response.headers["content-disposition"]
        .split("filename=")[1]
        .split(";")[0]
        .slice(1)
        .slice(0, -1);

      download(response.data, fileName);

      console.log(response.data, response.headers, fileName);
    }
  };

  return (
    <Fragment>
      <div className="text-center hero">
        <h1 className="mb-4">Export Records</h1>
      </div>

      <div className="form-group no-form">
        <label htmlFor="startDate">Start Date</label>

        <DatePicker
          name="startDate"
          onChange={(date) =>
            dispatch({
              type: "fieldChanged",
              field: "startDate",
              value: date,
            })
          }
          value={state.startDate}
        ></DatePicker>
      </div>

      <div className="form-group no-form">
        <label htmlFor="returnDate">End Date</label>

        <DatePicker
          name="endDate"
          onChange={(date) =>
            dispatch({
              type: "fieldChanged",
              field: "endDate",
              value: date,
            })
          }
          value={state.endDate}
        ></DatePicker>
      </div>

      <button
        type="button"
        className="base-button evo-blue mr-30"
        id="export-estimates"
        onClick={handleExport}
      >
        Export Estimates
      </button>
      <button
        type="button"
        className="base-button evo-blue"
        id="export-leads"
        onClick={handleExport}
      >
        Export Leads
      </button>
    </Fragment>
  );
};

export default ExportContent;
