import React, { Fragment, useReducer, useEffect } from "react";
import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import ai from "../../helpers/axios";

import { useHistory, useParams } from "react-router-dom";

const reducer = (state, action) => {
  switch (action.type) {
    case "search": {
      return {
        ...state,
        query: action.query,
      };
    }
    case "fieldChanged": {
      return {
        ...state,
        aircraft: {
          ...state.aircraft,
          [action.field]: action.value,
        },
      };
    }
    case "dataBeganDownloading": {
      return {
        ...state,
        loading: true,
      };
    }
    case "dataDownloaded": {
      return {
        ...state,
        aircraft: action.payload.data,
      };
    }
    default:
      break;
  }
  return state;
};

const SingleAircraftContent = (props) => {
  const { id } = useParams();
  const history = useHistory();

  const initialState = {
    aircraft: {
      active: false,
      baggageCapacity: "",
      cabinHeightInches: "",
      cabinLengthInches: "",
      cabinVolume: "",
      cabinWidthInches: "",
      imageTitle: "",
      aircraftTypeId: "",
      evoUrl: "",
      cruiseSpeed: "",
      deleted: false,
      exteriorImagePath: "",
      oldId: "",
      interiorImagePath: "",
      manufacturer: "",
      name: "",
      price: "",
      range: "",
      seats: "",
      yom: "",
    },
    loading: true,
  };

  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const [state, dispatch] = useReducer(reducer, initialState);

  const getData = async () => {
    dispatch({ type: "dataBeganDownloading" });

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let { data } = await ai.auth(token).get(`/api/aircrafts/${id}`);

    dispatch({
      type: "dataDownloaded",
      payload: {
        data,
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    console.log("currentState", state.aircraft);

    let { data } = await ai
      .auth(token)
      .put(`/api/aircrafts/${id}`, state.aircraft);
    console.log("resp", data);

    history.push({ pathname: "/aircraft/" });

    setTimeout(() => {
      toast.success("Aircraft Updated Successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }, 50);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Fragment>
      <h2 id="addAirportHeading">Edit Aircraft</h2>

      <div className="checkboxContainer">
        <label htmlFor="name">Active</label>
        <input
          type="checkbox"
          name="active"
          checked={state.aircraft.active}
          onChange={(e) =>
            dispatch({
              type: "fieldChanged",
              field: e.target.name,
              value: e.target.checked,
            })
          }
        ></input>
      </div>

      <form
        id="addAircraft"
        className="updateDocumentForm mt-20"
        onSubmit={handleSubmit}
      >
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            name="name"
            value={state.aircraft.name}
            placeholder="Name"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="baggageCapacity">Baggage Capacity</label>
          <input
            type="number"
            name="baggageCapacity"
            value={state.aircraft.baggageCapacity}
            placeholder="Baggage Capacity"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: parseFloat(e.target.value) || "",
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="cabinHeightInches">Cabin Height (Inches)</label>
          <input
            type="number"
            name="cabinHeightInches"
            value={state.aircraft.cabinHeightInches}
            placeholder="Cabin Height"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: parseFloat(e.target.value) || "",
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="cabinLengthInches">Cabin Length (Inches)</label>

          <input
            type="number"
            name="cabinLengthInches"
            value={state.aircraft.cabinLengthInches}
            placeholder="Cabin Length (Inches)"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: parseFloat(e.target.value) || "",
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="cabinWidthInches">Cabin Width (Inches)</label>
          <input
            type="number"
            name="cabinWidthInches"
            value={state.aircraft.cabinWidthInches}
            placeholder="Cabin Width (Inches)"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: parseFloat(e.target.value) || "",
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="cabinVolume">Cabin Volume</label>
          <input
            type="number"
            name="cabinVolume"
            value={state.aircraft.cabinVolume}
            placeholder="Cabin Volume"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: parseFloat(e.target.value) || "",
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="imageTitle">Image Title</label>
          <input
            type="text"
            name="imageTitle"
            value={state.aircraft.imageTitle}
            placeholder="Image Title"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="interiorImagePath">Interior Image Path</label>
          <input
            type="text"
            name="interiorImagePath"
            value={state.aircraft.interiorImagePath}
            placeholder="Interior Image Path"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="exteriorImagePath">Exterior Image Path</label>
          <input
            type="text"
            name="exteriorImagePath"
            value={state.aircraft.exteriorImagePath}
            placeholder="Exterior Image Path"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="manufacturer">Manufacturer</label>
          <input
            type="text"
            name="manufacturer"
            value={state.aircraft.manufacturer}
            placeholder="Manufacturer"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="evoUrl">Evo Aircraft URL</label>
          <input
            type="text"
            name="evoUrl"
            value={state.aircraft.evoUrl}
            placeholder="Evo Aircraft URL"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="cruiseSpeed">Cruise Speed</label>
          <input
            type="number"
            name="cruiseSpeed"
            value={state.aircraft.cruiseSpeed}
            placeholder="Cruise Speed"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: parseFloat(e.target.value) || "",
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="price">Price</label>
          <input
            type="text"
            name="price"
            value={state.aircraft.price}
            placeholder="Price"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>
        <div className="form-group">
          <label htmlFor="price">Seats</label>
          <input
            type="number"
            name="seats"
            value={state.aircraft.seats}
            placeholder="Seats"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: parseInt(e.target.value) || "",
              })
            }
          ></input>
        </div>
        <div className="form-group">
          <label htmlFor="price">YOM</label>
          <input
            type="text"
            name="yom"
            value={state.aircraft.yom}
            placeholder="YOM"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <input type="submit" value="Update Aircraft"></input>
      </form>

      {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
    </Fragment>
  );
};

export default SingleAircraftContent;
