import { useReducer } from "react";
import React, { useEffect } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { useParams, Link } from "react-router-dom";

import ai from "../../helpers/axios";

import StateDisplay from "../reusable-modules/state-display";

import Loader from "../loader";

const reducer = (state, action) => {
  switch (action.type) {
    case "fieldChanged": {
      return {
        ...state,
        [action.field]: action.value,
      };
    }

    case "beginDataDownloading": {
      return {
        ...state,
        loading: true,
      };
    }

    case "setHTML": {
      return {
        ...state,
        loading: false,
        invoiceHtml: action.payload.html,
      };
    }

    case "beginPDFDownloading": {
      return {
        ...state,
        loadingPDF: true,
      };
    }

    case "endPDFDownloading": {
      return {
        ...state,
        loadingPDF: false,
      };
    }

    default:
      break;
  }
  return state;
};

const InvoicePreviewContent = (props) => {
  const { getAccessTokenSilently, user, loginWithRedirect } = useAuth0();
  const { tripId, invoiceId } = useParams();

  let initialState = {
    loading: true,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const getData = async () => {
    dispatch({ type: "beginDataDownloading" });

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    const html = await ai
      .auth(token)
      .get(`/api/invoices/preview/${invoiceId}`)
      .catch((error) => {
        console.error(error);
      });

    if (html) {
      dispatch({
        type: "setHTML",
        payload: {
          html: html.data.html,
        },
      });
    }
  };

  const handlePDF = async () => {
    dispatch({ type: "beginPDFDownloading" });

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    const pdf = await ai
      .auth(token)
      .get(`/api/invoices/preview/${invoiceId}/pdf`, {
        responseType: "arraybuffer",
      })
      .catch((error) => {
        console.error(error);
      });

    if (pdf) {
      const saveFile = async () => {
        try {
          const options = {
            suggestedName: `${state.fileName ? state.fileName : 'evo-invoice.pdf'}`,
            types: [{
              description: 'PDF file',
              accept: { 'application/pdf': ['.pdf'] }
            }]
          };

          const handle = await window.showSaveFilePicker(options);
          const writable = await handle.createWritable();
          await writable.write(new Blob([pdf.data], { type: "application/pdf" }));
          await writable.close();
        } catch (err) {
          console.error('Error saving file:', err);
        }
      };

      // Call the saveFile function
      saveFile();
    }


    dispatch({ type: "endPDFDownloading" });
  };

  useEffect(() => {
    getData();
    document.title = "Invoice Preview | Flight Deck by evoJets";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <h2 id="addAirportHeading">Preview Invoice</h2>

      {state.loading && <Loader></Loader>}

      {!state.loading && (
        <React.Fragment>
          <h4>Shortcuts</h4>

          <div className="d-flex flex-wrap flex-align-center column-gap-20">
            <Link to={`/trips/${tripId}`} className={`base-button evo-blue`}>
              Return to Trip
            </Link>

            <Link
              to={`/trips/${tripId}/invoices/${invoiceId}`}
              className={`base-button evo-blue`}>
              Edit Invoice
            </Link>

            <button
              className="evo-blue base-button "
              onClick={handlePDF}
              disabled={state.loadingPDF}>
              {state.loadingPDF ? "Generating PDF..." : "Save as PDF"}
            </button>
          </div>

          <hr />
        </React.Fragment>
      )}

      <iframe
        title="Invoice Preview"
        className="contract-preview-container"
        id="contractPreviewIframe"
        sandbox="allow-same-origin"
        srcDoc={state.invoiceHtml}></iframe>

      <StateDisplay data={state} user={user}></StateDisplay>
    </React.Fragment>
  );
};

export default InvoicePreviewContent;
