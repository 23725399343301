const formatAircraftSelection = (aircraft) => {
  switch (aircraft) {
    case "heavy_jet":
      return "Heavy Jet";
    case "executive_turboprop":
      return "Turboprop";
    case "compact_light_jet":
      return "VLJ";
    case "light_jet":
      return "Light Jet";
    case "midsize_jet":
      return "Midsize Jet";
    case "super_midsize_jet":
      return "Super Midsize Jet";
    default:
      return aircraft;
  }
};

export default formatAircraftSelection;
