import React, { Fragment } from "react";

import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CheckBoxOutlineBlank from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import moment from "moment-timezone";

import formatAircraftSelection from "../../helpers/format-aircraft-selection";
import formatStage from "../../helpers/format-stage";
import currencyFormatter from "../../helpers/currencyFormatter";

import { Link } from "react-router-dom";

const getAircraftSelection = (data) => {
  if (data?.aircraftSelection?.length) {
    return data?.aircraftSelection
      .map((aircraft) => formatAircraftSelection(aircraft))
      .join(", ");
  }
  return "N/A";
};

const capitalizeFirstLetter = (string) => {
  if (!string) {
    return "";
  }
  return string[0].toUpperCase() + string.substring(1);
};

function Row(props) {
  const { row, superAdminOrAd } = props;
  const [open, setOpen] = React.useState(false);
  const { dispatch, selectedRows } = props;

  let rowSelected = selectedRows.filter(
    (selectedRow) => row._id === selectedRow._id
  );
  rowSelected = rowSelected?.length ? true : false;

  // const [selected, setSelected] = React.useState(rowSelected);

  console.log("ROW", row);

  let hasMatches =
    row.flightLegData.length &&
    row.flightLegData.filter((leg) => leg.flightMatches?.length)?.length;

  row.itineraryDetails = row.estimateData[0];

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        onClick={() => setOpen(!open)}
        className="fm-l2-parent-table-row"
        key={JSON.stringify(selectedRows)}
      >
        <TableCell className="centered-important">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              dispatch({
                type: "rowSelectionEvent",
                rowId: row._id,
                selected: !rowSelected,
              });
              // setSelected(!selected);
            }}
          >
            {rowSelected ? <CheckBoxIcon /> : <CheckBoxOutlineBlank />}
          </IconButton>
          {/* <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton> */}
        </TableCell>
        <TableCell className="mobile-lead-details-initial-cell">
          <span className="material-icons lead-info-icon">person</span>
          {capitalizeFirstLetter(row.firstName)}{" "}
          {capitalizeFirstLetter(row.lastName)}
          <br />
          <span className="material-icons lead-info-icon">flight</span>
          {row.itineraryDetails.departureAirportCode} -{" "}
          {row.itineraryDetails.arrivalAirportCode}
          <br />
          <span className="material-icons lead-info-icon">date_range</span>
          {row.itineraryDetails.departureDate}
          {row.tripType === 2
            ? ` - ${row.itineraryDetails.returnDate}`
            : ""}{" "}
          <br />
          {/* <span className="material-icons lead-info-icon">schedule</span>
          {formatStage(row.stage)} */}
          <span className="material-icons lead-info-icon">groups</span>
          {row.itineraryDetails.passengerCount} Pax
          {/* ads and up if unassigned it shows owner  */}
          {/* if lead is assigned to them, shows pax count */}
          {/* <span className="material-icons lead-info-icon">schedule</span>
          {row.itineraryDetails.passengerCount} */}
          {row.assigneeId && superAdminOrAd && (
            <span className="material-icons lead-assignee-indicator lead-assigned-indicator">
              task_alt
            </span>
          )}
          {!row.assigneeId && superAdminOrAd && (
            <span className="material-icons lead-assignee-indicator lead-unassigned-indicator">
              radio_button_unchecked
            </span>
          )}
          {hasMatches ? (
            <span
              className={`material-icons lead-assignee-indicator lead-has-matches-indicator ${
                superAdminOrAd ? "superAdminOrAd" : ""
              }`}
            >
              sync_alt
            </span>
          ) : (
            ""
          )}
        </TableCell>
        {/* 
        <TableCell>{formatStage(row.stage)}</TableCell>

        <TableCell>{row.assigneeName || "-"}</TableCell>
        <TableCell>
          {row.firstName} {row.lastName}
        </TableCell>
        <TableCell>{row.phone}</TableCell>
        <TableCell>{row.email}</TableCell>

        {row.companyName !== "" && <TableCell>{row.companyName}</TableCell>} */}

        {/* 
        <TableCell>{row.assigneeName}</TableCell>
        <TableCell>{row.assigneeName}</TableCell>
        <TableCell>{row.assigneeName}</TableCell>
 */}
        {/* <TableCell scope="row">
          {row.isCurrentLeg ? "Current Leg" : `Leg ${row.indexText}`}
        </TableCell>
        <TableCell>{row.departureDate}</TableCell>
        <TableCell>{row.departureAirport.airportName}</TableCell>
        <TableCell>{row.arrivalAirport.airportName}</TableCell>
        <TableCell>{row.passengerCount}</TableCell> */}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }} className="fm-l2-child-table-container">
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                className="fm-l2-child-table-heading mb-20 mt-20"
              >
                Lead Metadata
              </Typography>

              <p key={`createdAt-${row.createdAt}`}>
                <strong>Created At: </strong>
                {moment(row.createdAt).format("MMM Do YYYY h:mm a")}
              </p>

              <p key={`stage-${row.stage}`}>
                <strong>Stage: </strong>
                {formatStage(row.stage)}
              </p>

              <p key={`assignee-${row.assigneeName}`}>
                <strong>Assignee: </strong>
                {row.assigneeName || "-"}
              </p>

              <p key={`stage-${row.organizationName}`}>
                <strong>Organization: </strong>
                {row.organizationName}
              </p>

              <p key={`id-${row._id}`}>
                <strong>Lead ID: </strong>
                {row._id}
              </p>

              <hr></hr>

              <Typography
                variant="h5"
                gutterBottom
                component="div"
                className="fm-l2-child-table-heading mb-20 mt-20"
              >
                Contact Info
              </Typography>

              <p key={`contact-name`}>
                <strong>Name: </strong>
                {`${row.firstName} ${row.lastName}`}
              </p>

              {row.phone && row.phone !== "" && (
                <p key={`contact-phone`}>
                  <strong>Phone: </strong>
                  <a href={`tel:${row.phone}`}>{row.phone}</a>
                </p>
              )}

              <p key={`contact-email`}>
                <strong>Email: </strong>
                {`${row.email}`}
              </p>

              <p key={`contact-company`}>
                <strong>Company: </strong>
                {`${row.companyName ? row.companyName : "-"}`}
              </p>

              <hr></hr>

              <Typography
                variant="h5"
                gutterBottom
                component="div"
                className="fm-l2-child-table-heading mb-20 mt-20"
              >
                Lead Actions
              </Typography>

              <p className="mb-25">
                <Link to={`/leads/${row._id}`} className="evo-blue base-button">
                  View Lead
                </Link>
              </p>

              {row.evoProspectId && superAdminOrAd ? (
                <p className="mt-20">
                  <Link
                    to={`/prospects/${row.evoProspectId}`}
                    className="evo-blue base-button"
                  >
                    View Prospect
                  </Link>
                </p>
              ) : (
                <p className="mt-20">Assign Lead to Send a Quote.</p>
              )}

              {row.assigneeId ? (
                <p className="mt-20">
                  <Link
                    to={`/leads/${row._id}/quotes/new`}
                    className="evo-blue base-button"
                  >
                    New Quote
                  </Link>
                </p>
              ) : (
                <p className="mt-20">Assign Lead to Send a Quote.</p>
              )}

              {superAdminOrAd && row.assigneeId && (
                <p className="mt-20">
                  <Link
                    to={{ pathname: `/sourcing/new`, state: { lead: row } }}
                    className="evo-blue base-button"
                  >
                    New Sourcing Record
                  </Link>
                </p>
              )}

              {row.assigneeId && (
                <p className="mt-20">
                  <Link
                    className="evo-blue base-button"
                    to={{ pathname: `/contracts/new`, state: { lead: row } }}
                  >
                    New Contract
                  </Link>
                </p>
              )}

              <hr></hr>

              <Typography
                variant="h5"
                gutterBottom
                component="div"
                className="fm-l2-child-table-heading mb-20 mt-20"
              >
                Flight Matches
              </Typography>

              {row.flightLegData.length > 0 &&
                row.flightLegData.map((leg, index) => {
                  return (
                    <Fragment key={`flight-leg-${leg._id}`}>
                      <p key={`flight-leg-p-${leg._id}`}>
                        <Link
                          className="linked-heading"
                          to={`/flight-matches/${leg._id}`}
                        >
                          Leg {index + 1}
                        </Link>
                      </p>

                      {leg.flightMatches?.length === 0 && <p>No matches.</p>}

                      {leg.flightMatches?.length > 0 && (
                        <ul className="pl-0">
                          {leg.flightMatches.map((match, index) => {
                            return (
                              <li
                                className="d-block mb-15"
                                key={`match-${index}`}
                              >
                                <Link to={`/flight-matches/${match?.legId}`}>
                                  View Match {index + 1}
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </Fragment>
                  );
                })}

              <hr></hr>

              <Typography
                variant="h5"
                gutterBottom
                component="div"
                className="fm-l2-child-table-heading mb-20 mt-20"
              >
                Trip Details
              </Typography>

              <p key={`trip-type-${row.tripType}`}>
                <strong>Trip Type: </strong>
                {row.tripType === 1 ? "One Way" : "Round Trip"}
              </p>

              <p key={`depart-${row.itineraryDetails.departureDate}`}>
                <strong>Departure Date: </strong>
                {row.itineraryDetails.departureDate}
              </p>

              {row.tripType === 2 && (
                <p key={`return-${row.itineraryDetails.returnDate}`}>
                  <strong>Return Date: </strong>
                  {row.itineraryDetails.returnDate || "N/A"}
                </p>
              )}

              <p
                key={`depart-airport-${row.itineraryDetails.departureAirportName}`}
              >
                <strong>Departure Airport: </strong>
                {row.itineraryDetails.departureAirportName}
              </p>

              <p
                key={`arrival-airport-${row.itineraryDetails.arrivalAirportName}`}
              >
                <strong>Arrival Airport: </strong>
                {row.itineraryDetails.arrivalAirportName}
              </p>

              <p key="departureTime">
                <strong>Departure Time: </strong>
                {row.itineraryDetails.departureTime
                  ? row.itineraryDetails.departureTime
                  : "N/A"}
              </p>

              {row.tripType === 2 && (
                <p key="returnTime">
                  <strong>Return Time: </strong>
                  {row.itineraryDetails.returnTime ? row.returnTime : "N/A"}
                </p>
              )}

              <hr></hr>

              <Typography
                variant="h5"
                gutterBottom
                component="div"
                className="fm-l2-child-table-heading mb-20 mt-20"
              >
                Additional Details
              </Typography>

              <p key={`pax-${row.itineraryDetails.passengerCount}`}>
                <strong>Passengers: </strong>
                {row.itineraryDetails.passengerCount}
              </p>

              <p key="aircraftSelection">
                <strong>Aircraft Selection: </strong>
                {getAircraftSelection()}
              </p>
              <p key="baggage">
                <strong>Baggage: </strong>
                {row.baggage.length ? row.baggage.join(", ") : "N/A"}
              </p>
              <p key="pets">
                <strong>Pets: </strong>
                {row.pets ? "Yes" : "No"}
              </p>
              <p key="infants">
                <strong>Infants: </strong>
                {row.infants ? "Yes" : "No"}
              </p>

              <p key="comments">
                <strong>Comments: </strong>
                {row.comments}
              </p>
              <hr></hr>

              <Typography
                variant="h5"
                gutterBottom
                component="div"
                className="fm-l2-child-table-heading mb-20 mt-20"
              >
                Quote Details
              </Typography>

              {row.quotes.length > 0 &&
                row.quotes.map((quote) => {
                  return (
                    <p key={`quote-cont-${quote}`}>
                      <Link key={`quote-${quote}`} to={`/quotes/${quote}`}>
                        View Quote: {quote}
                      </Link>
                    </p>
                  );
                })}

              {!row.quotes.length && (
                <p key="quotesNotSent">
                  <strong>No Quotes Sent.</strong>
                </p>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable(props) {
  const { dispatch, selectedRows, superAdminOrAd } = props;
  // console.log({ selectedRows });

  return (
    <TableContainer
      component={Paper}
      className="mt-20 accordion-table-with-details"
    >
      <Table aria-label="collapsible table">
        <TableHead className="fm-l2-parent-table-head">
          <TableRow className="fm-l2-parent-table-head-row">
            <TableCell />
            <TableCell>LEAD INFO</TableCell>
            {/* <TableCell>STAGE</TableCell>
            <TableCell>ASSIGNEE</TableCell>
            <TableCell>LEAD NAME</TableCell>
            <TableCell>PHONE NUMBER</TableCell>
            <TableCell>EMAIL</TableCell>

            {props.rows[0].companyName !== "" && <TableCell>COMPANY</TableCell>} */}
          </TableRow>
        </TableHead>
        <TableBody className="fm-l2-parent-table-body">
          {props.rows.map((row) => (
            <Row
              key={row._id}
              dispatch={dispatch}
              row={row}
              selectedRows={selectedRows}
              superAdminOrAd={superAdminOrAd}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
