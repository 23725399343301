import { Link } from "react-router-dom";

const columns = [
  {
    name: "Edit",
    sortable: false,
    cell: (row) => {
      return <Link to={`/aircraft/${row._id}`}>Edit</Link>;
    },
    width: "70px",
  },
  {
    name: "Aircraft Name",
    cell: (row) => {
      return row.name;
    },
    sortable: true,
    width: "200px",
  },
  {
    name: "Active",
    sortable: true,
    cell: (row) => {
      return row.active ? "Active" : "Inactive";
    },
    width: "200px",
  },
  {
    name: "Manufacturer",
    selector: "manufacturer",
    sortable: true,
    width: "200px",
  },
  {
    name: "Class",
    selector: "aircraftTypeData[0].name",
    sortable: true,
    width: "200px",
  },

  // {
  //   name: "Active",
  //   sortable: true,
  //   cell: (row) => {
  //     return JSON.stringify(row.aircraftTypeData);
  //   },
  //   width: "200px",
  // },
];

export default columns;
