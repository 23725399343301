import React, { Fragment } from "react";

import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import moment from "moment-timezone";

import { Link } from "react-router-dom";

const formatStage = (stage) => {
  let formattedStage = "N/A";

  if (stage === "newLead") {
    formattedStage = "New Lead";
  } else if (stage === "inProgress") {
    formattedStage = "In Progress";
  } else if (stage === "archived") {
    formattedStage = "Archived";
  } else if (stage === "warmProspect") {
    formattedStage = "Warm Prospect";
  } else if (stage === "unqualified") {
    formattedStage = "Unqualified";
  } else if (stage === "evoEmptyLeg") {
    formattedStage = "Evo Empty Leg";
  } else if (stage === "repeatClientRequest") {
    formattedStage = "Repeat Client Request";
  } else if (stage === "newClientRequest") {
    formattedStage = "New Client Request";
  } else if (stage === "signedContract") {
    formattedStage = "Signed Contract - LTC";
  } else if (stage === "inProgressTemp") {
    formattedStage = "Soft-Deleted";
  } else if (stage === "noReply") {
    formattedStage = "No Reply";
  } else if (stage === "hotProspect") {
    formattedStage = "Hot Prospect";
  } else if (stage === "signedContractQC") {
    formattedStage = "Signed Contract - QC";
  }

  return formattedStage;
};

function Row(props) {
  const { row, superAdminOrAd } = props;
  const [open, setOpen] = React.useState(row.isCurrentLeg ? true : false);

  console.log("ROW", row);

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        onClick={() => setOpen(!open)}
        className="fm-l2-parent-table-row"
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell scope="row">
          {row.isCurrentLeg ? "Current Leg" : `Leg ${row.indexText}`}
        </TableCell>
        <TableCell>{row.departureDate}</TableCell>
        <TableCell>{row.departureAirport.airportName}</TableCell>
        <TableCell>{row.arrivalAirport.airportName}</TableCell>
        <TableCell>{row.passengerCount}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }} className="fm-l2-child-table-container">
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                className="fm-l2-child-table-heading mb-20 mt-20"
              >
                Leg Metadata
              </Typography>
              {/* 
              <Typography
                variant="h6"
                gutterBottom
                component="div"
                className="fm-l2-child-table-heading mb-20"
              >
                METADATA
              </Typography> */}
              <p>
                <strong>Owner: </strong>
                {row?.originData?.assigneeName || "Not Yet Assigned"}
              </p>

              <p>
                <strong>Leg Origin: </strong>
                {row.isFromATC === true ? (
                  <Link to={`/atc/${row.atcId}`}>View ATC Record</Link>
                ) : (
                  <Link to={`/leads/${row.leadId}`}>View Lead</Link>
                )}
              </p>

              <p>
                <strong>
                  {row.isFromATC
                    ? `ATC Record Created At:`
                    : `Lead Created At: `}
                </strong>
                {moment(row?.originData?.createdAt)
                  .utc()
                  .format("ddd, MMM Do YYYY")}
              </p>

              <p>
                <strong>Stage: </strong>
                {formatStage(row.stage)}
              </p>

              {superAdminOrAd && row._id !== props.currentLegId && (
                <Link
                  className="base-button evo-blue mt-20 d-block w-200 centered"
                  to={`/flight-matches/${row._id}`}
                >
                  View All Leg Data
                </Link>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable(props) {
  const { superAdminOrAd, currentLegId } = props;
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead className="fm-l2-parent-table-head">
          <TableRow className="fm-l2-parent-table-head-row">
            <TableCell />
            <TableCell>LEG TYPE</TableCell>
            <TableCell>DEPARTURE DATE</TableCell>
            <TableCell>DEPARTURE AIRPORT</TableCell>
            <TableCell>ARRIVAL AIRPORT</TableCell>
            <TableCell>PASSENGERS</TableCell>
            {/* <TableCell>ORIGIN CREATED AT</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody className="fm-l2-parent-table-body">
          {props.rows.map((row) => (
            <Row
              key={row._id}
              row={row}
              currentLegId={currentLegId}
              superAdminOrAd={superAdminOrAd}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
