import React, { Fragment, useReducer, useEffect } from "react";
import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import ai from "../../helpers/axios";

import { useHistory, useParams } from "react-router-dom";

import TextInput from "../text-input";
import countryCodes from "../../helpers/country-codes";
import Select from "react-select";

const reducer = (state, action) => {
  switch (action.type) {
    case "search": {
      return {
        ...state,
        query: action.query,
      };
    }
    case "fieldChanged": {
      return {
        ...state,
        countrySetting: {
          ...state.countrySetting,
          [action.field]: action.value,
        },
      };
    }

    case "rootFieldChanged": {
      return {
        ...state,
        [action.field]: action.value,
      };
    }
    default:
      break;
  }
  return state;
};

const NewCountrySettingContent = (props) => {
  const history = useHistory();

  const initialState = {
    loading: true,
    isoCountries: countryCodes,
    countrySetting: {
      active: true,
      baseCostAdjustment: "",
      countryISOCode: "",
    },
    countryCodeSelect: null,
  };

  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const [state, dispatch] = useReducer(reducer, initialState);

  //   const getData = async () => {
  //     dispatch({ type: "dataBeganDownloading" });

  //     const token = await getAccessTokenSilently().catch((e) => {
  //   console.error(e);
  //   loginWithRedirect();
  // });

  //     let { data } = await ai.auth(token).get(`/api/exclusionRules/ISOCountries`);

  //     dispatch({
  //       type: "dataDownloaded",
  //       payload: {
  //         data,
  //       },
  //     });
  //   };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let formData = { ...state.countrySetting };

    let { data } = await ai
      .auth(token)
      .post(`/api/countrySettings/create`, formData);

    console.log("resp", data);

    if (data) {
      setTimeout(() => {
        toast.success("Country Setting Created Successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }, 50);

      dispatch({
        type: "rootFieldChanged",
        field: "countryCodeSelect",
        value: null,
      });

      dispatch({
        type: "fieldChanged",
        field: "baseCostAdjustment",
        value: "",
      });
    }
    // history.push({ pathname: "/country-settings" });
  };

  useEffect(() => {
    // getData();
    document.title = "Add Country Setting | Flight Deck by evoJets";
  }, []);

  const handleBaseCostAdjustmentChange = (e) => {
    let value = parseInt(e.target.value);
    if (
      e.target.value === null ||
      typeof e.target.value === undefined ||
      e.target.value === ""
    ) {
      value = "";
    }

    console.log(e.target.value);

    dispatch({
      type: "fieldChanged",
      field: e.target.name,
      value: value,
    });
  };

  const handleCountryCodeChange = (countryCode) => {
    dispatch({
      type: "fieldChanged",
      field: "countryISOCode",
      value: countryCode.value,
    });

    dispatch({
      type: "rootFieldChanged",
      field: "countryCodeSelect",
      value: countryCode,
    });

    return countryCode;
  };

  return (
    <Fragment>
      <h2 id="addAirportHeading">Add Country Setting</h2>

      <form id="addAirport" onSubmit={handleSubmit}>
        <div className="full-width-row">
          <input
            name="active"
            type="checkbox"
            value="active"
            id="active"
            checked={state.countrySetting.active}
            onChange={(e) => {
              dispatch({
                type: "fieldChanged",
                field: "active",
                value: e.target.checked ? true : false,
              });
            }}
          ></input>
          <label className="base-label ml-10 b-2 p-relative" htmlFor="active">
            Active
          </label>
        </div>

        <div className="form-group ml-0">
          <label>Country Code</label>
          <Select
            className="countryCode-select"
            classNamePrefix="select"
            options={state.isoCountries}
            placeholder="Country Codes"
            onChange={handleCountryCodeChange}
            value={state.countryCodeSelect}
            key={`key-${state.countryCodeSelect}`}
          ></Select>
        </div>

        <TextInput
          type="number"
          name="baseCostAdjustment"
          value={state.countrySetting.baseCostAdjustment}
          label="Base Cost Adjustment"
          handleChange={handleBaseCostAdjustmentChange}
          containerClasses="mr-0 ml-4p"
        ></TextInput>

        <input type="submit" value="Add Country Setting"></input>
      </form>

      {/* <pre>{JSON.stringify(state.countrySetting, null, 2)}</pre> */}
    </Fragment>
  );
};

export default NewCountrySettingContent;
