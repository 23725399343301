import React, { Fragment, useEffect, useReducer } from "react";
import { ToastContainer, toast, Slide } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";

import ai from "../../helpers/axios";
import columns from "./quotes-columns";
import TableBulkActions from "../table-bulk-actions";
import Search from "../search";
import DataTableServerPagination from "../data-table-server-pagination";

import PageHeaderRowSearch from "../shared/page-header-row-search";

const reducer = (state, action) => {
  switch (action.type) {
    case "search": {
      return {
        ...state,
        query: action.query,
      };
    }
    case "dataBeganDownloading": {
      return {
        ...state,
        loading: action.payload.loadingStatus,
      };
    }
    case "rowSelection": {
      return {
        ...state,
        selectedRows: action.payload.selectedRows,
        allSelected: action.payload.allSelected,
      };
    }

    case "fieldChanged": {
      return {
        ...state,
        [action.field]: action.value,
      };
    }

    case "dataDownloaded": {
      return {
        ...state,
        quotes: {
          data: action.payload.data.quotes,
        },
        loading: false,
        totalLength: action.payload.data.countTotal,
      };
    }
    case "pageChanged": {
      return {
        ...state,
        currentPage: action.payload.page,
      };
    }
    case "tableSort": {
      return {
        ...state,
        sortBy: action.payload.sortBy,
        sortDirection: action.payload.sortDirection,
      };
    }
    case "stageFilterChanged": {
      return {
        ...state,
        stageFilters: action.payload.values,
      };
    }
    case "rowsPerPageChanged": {
      return {
        ...state,
        countPerPage: action.payload.currentRowsPerPage,
      };
    }

    default:
      break;
  }
  return state;
};

const QuotesContent = () => {
  const initialState = {
    quotes: {
      data: [],
    },
    loading: true,
    countPerPage: 25,
    currentPage: 1,
    selectedRows: [],
    allSelected: false,
    tagFilters: [],
    totalLength: null,
    clearSelectedRows: false,
    ownerInputField: "",
    selectedOwner: null,
    query: "",
    // stageFilters: ["active"],
    sortDirection: "desc",
    sortBy: "createdAt",
    draftOnly: true,
  };

  const { getAccessTokenSilently, user, loginWithRedirect } = useAuth0();

  const userRoles = user && user["https://app.evojets.com/roles"];

  const superAdmin = userRoles.some(function (role) {
    return "Super Admin" === role;
  });

  const [state, dispatch] = useReducer(reducer, initialState);

  console.log(state);

  const getData = async (
    dbQuery,
    loadSilently,
    stageFilters,
    sortBy,
    sortDirection,
    draftOnly
  ) => {
    let loadingStatus = true;
    if (loadSilently) {
      loadingStatus = false;
    }

    dispatch({ type: "dataBeganDownloading", payload: { loadingStatus } });

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let { data } = await ai.auth(token).get(`/api/quotes/list`, {
      params: {
        query: dbQuery,
        page: state.currentPage,
        countPerPage: state.countPerPage,
        sortBy,
        sortDirection,
        stageFilters,
        draftOnly,
      },
    });

    console.log(data);

    dispatch({
      type: "dataDownloaded",
      payload: {
        data,
      },
    });
  };

  useEffect(() => {
    getData(
      state.query,
      false,
      state.stageFilters,
      state.sortBy,
      state.sortDirection,
      state.draftOnly
    );

    document.title = "Quote Management | Flight Deck";
  }, [state.currentPage, state.countPerPage]);

  const handlePageChange = (page, totalRows) => {
    console.log(page, totalRows);

    dispatch({
      type: "pageChanged",
      payload: {
        page,
        totalRows,
      },
    });
  };

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    dispatch({
      type: "rowsPerPageChanged",
      payload: {
        currentRowsPerPage,
        currentPage,
      },
    });
  };

  const handleSelection = function (rowData) {
    dispatch({
      type: "rowSelection",
      payload: {
        selectedRows: rowData.selectedRows,
        allSelected: rowData.allSelected,
      },
    });
  };

  const handleSearch = (e) => {
    let query = e.target.value.toLowerCase();

    dispatch({ type: "search", query });
    getData(
      query,
      true,
      state.stageFilters,
      state.sortBy,
      state.sortDirection,
      state.draftOnly
    );
  };

  const draftToggle = (e) => {
    e.preventDefault();

    getData(
      state.query,
      false,
      state.stageFilters,
      state.sortBy,
      state.sortDirection,
      !state.draftOnly
    );

    dispatch({
      type: "fieldChanged",
      field: "draftOnly",
      value: !state.draftOnly,
    });
  };

  //   const disableAssignmentButtons = () => {
  //     let disabled = "disabled";

  //     if (state.selectedRows.length) {
  //       let someRowsAreNotAssigned = state.selectedRows.some(
  //         (row) => row.assigneeId === undefined
  //       );
  //       if (!someRowsAreNotAssigned) {
  //         disabled = "";
  //       }
  //     }

  //     return disabled;
  //   };

  //   const stageFilterOptions = [
  //     { label: "Active", value: "active" },
  //     { label: "In Progress", value: "inProgress" },
  //     { label: "Warm Prospect", value: "warmProspect" },
  //     { label: "Unqualified", value: "unqualified" },
  //     { label: "Archived", value: "archived" },
  //   ];

  //   const handleStageFilterChange = (selectedValues, action) => {
  //     console.log(selectedValues, action);
  //     let values = [];
  //     if (selectedValues) {
  //       values = selectedValues.map(({ value }) => value);
  //     }

  //     dispatch({ type: "stageFilterChanged", payload: { values } });

  //     getData(state.query, true, values, state.sortBy, state.sortDirection, state.draftOnly);
  //   };

  const handleSort = (column, sortDirection) => {
    console.log("sorted", column.selector, sortDirection);

    dispatch({
      type: "tableSort",
      payload: {
        sortBy: column.selector,
        sortDirection,
      },
    });

    getData(
      state.query,
      true,
      state.stageFilters,
      column.selector,
      sortDirection,
      state.draftOnly
    );

    //keyField is required for server sort
  };

  return (
    <Fragment>
      {/* <ToastContainer transition={Slide} /> */}

      <PageHeaderRowSearch
        h1={`Quote Management`}
        pretitle="Business"
        breadcrumbs={[{ link: "/leads", label: "Leads" }]}
        rightColumn={
          <div id="quote-actions-row">
            <Search
              onSearch={handleSearch}
              placeholder="Search Quotes"
              query={state.query}
              className="quote-search"
            ></Search>

            <button
              type="button"
              className="base-button evo-blue"
              onClick={draftToggle}
            >
              {state.draftOnly ? "Show Sent Quotes" : "Show Draft Quotes"}
            </button>
          </div>
        }
      ></PageHeaderRowSearch>

      {/* 
      <LeadBulkActions
        handleOwnerSelection={handleOwnerSelection}
        handleOwnerInputChange={handleOwnerInputChange}
        loadOwnerOptions={loadOwnerOptions}
        state={state}
        handleLeadStageUpdate={handleLeadStageUpdate}
        disableAssignmentButtons={disableAssignmentButtons}
        stageFilterOptions={stageFilterOptions}
        handleStageFilterChange={handleStageFilterChange}
      ></LeadBulkActions> */}

      <DataTableServerPagination
        onSelection={handleSelection}
        columns={columns}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
        state={state}
        data={state.quotes.data}
        defaultSortField={"createdAt"}
        defaultSortAsc={false}
        handleSort={handleSort}
        sortServer={true}
      ></DataTableServerPagination>

      {/* <pre>{JSON.stringify(state.totalLength, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(state.quotes, null, 2)}</pre> */}

      {/* {superAdmin && <pre>{JSON.stringify(state.draftOnly, null, 2)}</pre>} */}
    </Fragment>
  );
};

export default QuotesContent;
