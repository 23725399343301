import React, { Fragment, useReducer, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import ai from "../../helpers/axios";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import Select from "react-select";

import ReactModal from "react-modal";
import { useHistory, useLocation } from "react-router-dom";

import TextInput from "../text-input";
import PhoneInput from "react-phone-number-input";

import "react-json-pretty/themes/monikai.css";
import JSONPretty from "react-json-pretty";

import * as Sentry from "@sentry/react";

const reducer = (state, action) => {
  switch (action.type) {
    case "fieldChanged": {
      let value =
        action.fieldType === "number" ? parseInt(action.value) : action.value;

      return {
        ...state,
        [action.field]: value,
      };
    }

    case "resetState": {
      return {
        longName: "",
        telephone: "",
        website: "",
        address1: "",
        address2: "",
        city: "",
        icao: "",
        iata: "",
      };
    }

    default:
      break;
  }
  return state;
};

const CreateFBOModal = (props) => {
  let initialState = {
    longName: "",
    telephone: "",
    website: "",
    address1: "",
    address2: "",
    state: "",
    country: "",
    city: "",
    icao: "",
    iata: "",

    //replace the fields above with dummy data
    // longName: "Test FBO",
    // telephone: "1234567890",
    // website: "www.testfbo.com",
    // address1: "123 Test St",
    // address2: "Suite 123",
    // city: "Test City",
    // state: "New Jersey",
    // postcode: "07608",
    // icao: "KTEB",
    // iata: "TEB",
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();

  const { getAccessTokenSilently, user, loginWithRedirect } = useAuth0();

  useEffect(() => {}, []);

  const handlePhoneNumberChange = (value) => {
    dispatch({
      type: "fieldChanged",
      field: "prospectPhone",
      value: value,
    });
  };

  const handlePhoneCountryChange = (countryCode) => {
    console.log(countryCode);
    dispatch({
      type: "fieldChanged",
      field: "prospectCountryCode",
      value: countryCode,
    });
  };

  const handleTextInputChange = (e) => {
    dispatch({
      type: "fieldChanged",
      field: e.currentTarget.name,
      value: e.currentTarget.value,
    });
  };

  const handleProspectOwnerChange = (selectedOwner) => {
    dispatch({
      type: "fieldChanged",
      field: "selectedProspectOwner",
      value: selectedOwner,
    });
    return selectedOwner;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    let fboData = {
      longName: state.longName,
      telephone: state.telephone,
      website: state.website,
      address1: state.address1,
      address2: state.address2,
      city: state.city,
      state: state.state,
      postcode: state.postcode,
      icao: state.icao,
      iata: state.iata,
    };

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    console.log("SUBMISSION DATA", fboData);

    let response = await ai
      .auth(token)
      .post(`/api/trips/modalCreateFBO`, {
        ...fboData,
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });

    if (response) {
      console.log(response.data);

      if (response?.data?.details?.length) {
        let toastErrorMessage =
          "Please fill in all fields and ensure they are valid.";

        props.toast.error(toastErrorMessage, props.toastOptions);
        return;
      }

      props.parentDispatch({
        type: "fieldChanged",
        field: "fboCreationResponse",
        value: response.data,
      });

      //   props.parentDispatch({
      //     type: "fboAutoSelected",
      //     value: {
      //       value: response.data?.prospectData._id,
      //       label,
      //       emails: response?.data?.prospectData?.emails,
      //       email: response.data?.prospectData?.emails[0],
      //       phone,
      //       countryCode,
      //       orProspectId: i.orProspectId,
      //       companyName: i.companyName,
      //       phoneNumbers: i.phoneNumbers,
      //       firstName: i.firstName,
      //       lastName: i.lastName,
      //     },
      //   });

      props.parentDispatch({
        type: "fieldChanged",
        field: "createFBOModalIsOpen",
        value: false,
      });

      props.toast.success(
        `FBO was successfully created. You can now select it in the builder.`,
        props.toastOptions
      );

      return;
    }
  };

  return (
    <ReactModal
      isOpen={props.modalIsOpen}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() =>
        props.parentDispatch({
          type: "fieldChanged",
          field: "createFBOModalIsOpen",
          value: false,
        })
      }
      style={{
        overlay: {},
        content: {
          width: "50%",
          // height: "auto",
          maxWidth: "700px",
          maxHeight: "70vh",
          overflow: "scroll",
        },
      }}
      appElement={document.getElementById("app")}
    >
      <form
        className="base-form"
        id="createFBOModalForm"
        onSubmit={handleSubmit}
      >
        <h3>Create FBO</h3>

        {
          //create fields for each fbo field in initialState
        }

        <TextInput
          name="longName"
          label="FBO Name"
          handleChange={handleTextInputChange}
          value={state.longName}
          required
        ></TextInput>

        <TextInput
          name="telephone"
          label="Telephone"
          handleChange={handleTextInputChange}
          value={state.telephone}
        ></TextInput>

        <TextInput
          name="website"
          label="Website"
          handleChange={handleTextInputChange}
          value={state.website}
        ></TextInput>

        <TextInput
          name="address1"
          label="Address 1"
          handleChange={handleTextInputChange}
          value={state.address1}
        ></TextInput>

        <TextInput
          name="address2"
          label="Address 2"
          handleChange={handleTextInputChange}
          value={state.address2}
        ></TextInput>

        <TextInput
          name="city"
          label="City"
          handleChange={handleTextInputChange}
          value={state.city}
        ></TextInput>

        <TextInput
          name="state"
          label="State"
          handleChange={handleTextInputChange}
          value={state.state}
        ></TextInput>

        <TextInput
          name="postcode"
          label="Post Code"
          handleChange={handleTextInputChange}
          value={state.postcode}
        ></TextInput>

        <TextInput
          name="icao"
          label="ICAO"
          handleChange={handleTextInputChange}
          value={state.icao}
          required
        ></TextInput>

        <TextInput
          name="iata"
          label="IATA"
          handleChange={handleTextInputChange}
          value={state.iata}
        ></TextInput>

        {/*     
        <div className="form-group">
          <label className="phoneInputLabel" htmlFor="phone">
            Phone Number
          </label>
          <PhoneInput
            placeholder="Phone Number"
            value={state.prospectPhone}
            name="prospectPhone"
            onChange={handlePhoneNumberChange}
            onCountryChange={handlePhoneCountryChange}
            defaultCountry="US"
          />
        </div> */}
        <input type="submit" value="Submit"></input>
      </form>
    </ReactModal>
  );
};

export default CreateFBOModal;
