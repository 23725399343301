import React from "react";
import LeadsContent from "../components/leads/leads-content";

const InProgressLeads = () => {
  return (
    <LeadsContent
      prefilters={["inProgress"]}
      defaultStageFilters={{ label: "In Progress", value: "inProgress" }}
      pageTitle="In Progress Leads"
    />
  );
};

export default InProgressLeads;
