import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const Hero = () => {
  const { user, isAuthenticated } = useAuth0();

  if (isAuthenticated) {
    const { name } = user;

    return (
      <div className="text-center hero">
        <h1 className="mb-4">Flight Deck</h1>
        <p className="lead">Welcome, {name}.</p>
      </div>
    );
  } else {
    return (
      <div className="text-center hero">
        <h1 className="mb-4">Flight Deck</h1>
        <p className="lead">Please log in to get started.</p>
      </div>
    );
  }
};

export default Hero;
