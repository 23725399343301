import React, { Fragment, useReducer, useEffect } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import ai from "../../helpers/axios";

import { io } from "socket.io-client";

const reducer = (state, action) => {
  switch (action.type) {
    case "fieldChanged":
      return { ...state, [action.field]: action.value };
    default:
      throw new Error();
  }
};

const initialState = {
  messages: [],
};

const getInitials = (name) => {
  const parts = name.split(" ");
  if (parts.length === 1) {
    return parts[0][0];
  } else if (parts.length > 1) {
    return `${parts[0][0]}${parts[parts.length - 1][0]}`;
  }
  return "";
};

const MessageList = ({
  threadId,
  latestMessage,
  parentDispatch,
  recordType,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { getAccessTokenSilently, user, loginWithRedirect } = useAuth0();

  useEffect(() => {
    const fetchMessages = async () => {
      const token = await getAccessTokenSilently().catch((e) => {
        console.error(e);
        return loginWithRedirect();
      });

      try {
        const response = await ai
          .auth(token)
          .get(`/api/messages/list/${threadId}?recordType=${recordType}`)
          .catch((e) => console.error(e));

        dispatch({
          type: "fieldChanged",
          field: "messages",
          value: response.data,
        });

        // scroll smoothly to the bottom of the message list
        const messageList = document.querySelector(".message-list");

        if (messageList) {
          messageList.scrollTo({
            top: messageList.scrollHeight,
            behavior: "smooth",
          });
        }
      } catch (error) {
        console.error("Error fetching messages", error);
      }
    };

    fetchMessages();
  }, [threadId, latestMessage]);

  useEffect(() => {
    console.log("PROCESS", process.env);

    const connectToSocket = async () => {
      const token = await getAccessTokenSilently().catch((e) => {
        console.error(e);
        return loginWithRedirect();
      });

      console.log(`${process.env.REACT_APP_SOCKET_URL}`);
      const socket = io(`${process.env.REACT_APP_SOCKET_URL}`, {
        extraHeaders: { Authorization: `Bearer ${token}` },
      });

      socket.onAny((eventName, ...args) => {
        // ...
        console.log(eventName, args);

        if (eventName === "newMessage") {
          parentDispatch({
            type: "fieldChanged",
            field: "latestMessage",
            value: Date.now(),
          });
        }
      });

      // socket.on("newMessage", (...args) => {
      //   // ...
      //   console.log("NEW MESSAGE CAME IN", args);
      // });
    };

    connectToSocket();
  }, []);

  return (
    <>
      {state?.messages?.length > 0 && (
        <ul className="message-list">
          {state.messages.map((message) => (
            <li
              key={message._id}
              className={message.sender?.id === user?.sub ? "me" : ""}
            >
              {/* <div className="avatar">{getInitials(message.sender.name)}</div> */}
              <div className="message">
                <p>
                  <strong>{message.sender.name}:</strong> {message.contents}
                </p>
                <p className="timestamp">
                  {new Date(message.timestamp).toLocaleString()}
                </p>
              </div>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default MessageList;
