import React from "react";

const SwitchInput = (props) => {
  const { checked, onChange, rightLabel, leftLabel } = props;
  return (
    <div class="switch-container">
      <label class="left-label">{leftLabel}</label>
      <label className="switch">
        <input type="checkbox" checked={checked} onChange={onChange} />
        <span className="slider round"></span>
      </label>
      <label class="right-label">{rightLabel}</label>
    </div>
  );
};

export default SwitchInput;
