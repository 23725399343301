import React from "react";
import CalculatorContent from "../components/calculator/calculator-content";
import { useAuth0 } from "@auth0/auth0-react";
import { Fragment } from "react";
import OutsideOrgCalculatorContent from "../components/calculator/outside-org-calculator-content";

const Calculator = () => {
  const { isAuthenticated, user } = useAuth0();
  const outsideOrg = user["https://app.evojets.com/outsideOrganization"];

  return (
    <Fragment>
      {isAuthenticated && !outsideOrg && <CalculatorContent />}
      {isAuthenticated && outsideOrg && <OutsideOrgCalculatorContent />}
    </Fragment>
  );
};

export default Calculator;
