import React, { Fragment, useReducer, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ai from "../../helpers/axios";

import { useParams, Link, useHistory } from "react-router-dom";
import PageHeaderRow from "../shared/page-header-row";
import moment from "moment";
import formatStage from "../../helpers/format-stage";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";

import TripRequestDetailsDesktop from "./trip-request-details-desktop";
import TripRequestDetailsMobile from "./trip-request-details-mobile";

const reducer = (state, action) => {
  switch (action.type) {
    // case "search": {
    //   return {
    //     ...state,
    //     query: action.query,
    //   };
    // }
    // case "fieldChanged": {
    //   return {
    //     ...state,
    //     airport: {
    //       ...state.airport,
    //       [action.field]: action.value,
    //     },
    //   };
    // }
    case "dataBeganDownloading": {
      return {
        ...state,
        loading: true,
      };
    }
    case "dataDownloaded": {
      return {
        ...state,
        lead: action.payload.data,
        loading: false,
      };
    }
    default:
      break;
  }
  return state;
};

const SingleTripRequestContent = (props) => {
  const { id } = useParams();
  const history = useHistory();

  const initialState = {
    lead: {
      itineraryDetails: {
        departureAirportName: "",
        arrivalAirportName: "",
        departureDate: "",
        departureTime: "",
        returnDate: "",
        returnTime: "",
        passengerCount: "",
        companyName: "",
      },
      aircraftSelection: [],
      baggage: [],
      quotes: [],

      tripOptions: [],
    },
    loading: true,
  };

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const { getAccessTokenSilently, user, loginWithRedirect } = useAuth0();

  const userRoles = user && user["https://app.evojets.com/roles"];

  const superAdmin = userRoles.some(function (role) {
    return "Super Admin" === role;
  });

  const [state, dispatch] = useReducer(reducer, initialState);

  const formatCurrency = (x) => {
    return "$" + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const getAircraftSelection = () => {
    if (state?.lead?.aircraftSelection?.length) {
      return state?.lead?.aircraftSelection
        .map((aircraft) => formatAircraftSelection(aircraft))
        .join(", ");
    }
    return "N/A";
  };

  const formatAircraftSelection = (aircraft) => {
    switch (aircraft) {
      case "heavy_jet":
        return "Heavy Jet";
      case "executive_turboprop":
        return "Turboprop";
      case "compact_light_jet":
        return "VLJ";
      case "light_jet":
        return "Light Jet";
      case "midsize_jet":
        return "Midsize Jet";
      case "super_midsize_jet":
        return "Super Midsize Jet";
      default:
        return aircraft;
    }
  };

  const getData = async () => {
    dispatch({ type: "dataBeganDownloading" });

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let response = await ai
      .auth(token)
      .get(`/api/leads/trip-request/${id}?lean=true`)
      .catch((error) => {
        history.push({ pathname: "/trip-requests" });
        setTimeout(() => {
          toast.error("Unauthorized!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }, 50);

        return console.error(error.response);
      });

    if (response) {
      dispatch({
        type: "dataDownloaded",
        payload: {
          data: response.data,
        },
      });
    }
  };
  useEffect(() => {
    getData();
    document.title = "Trip Request | Flight Deck by evoJets";
  }, []);

  return (
    <Fragment>
      {!state.loading && (
        <PageHeaderRow
          h1={`Trip Request #${id}`}
          pretitle="Business"
          breadcrumbs={[{ link: "/trip-requests", label: "Trip Requests" }]}
          mobileCurrentPageLabel={`${state.lead.firstName} ${state.lead.lastName}`}
          isMobile={true}
        ></PageHeaderRow>
      )}

      {!state.loading && !isMobile && (
        <TripRequestDetailsDesktop
          // superAdminOrAd={superAdminOrAd}
          rows={[state.lead]}
        ></TripRequestDetailsDesktop>
      )}

      {!state.loading && isMobile && (
        <TripRequestDetailsMobile
          // superAdminOrAd={superAdminOrAd}
          rows={[state.lead]}
        ></TripRequestDetailsMobile>
      )}

      {/* <div className="content-block"> */}
      {/* <h3>Request Details</h3> */}
      {/* <table className="evo-table">
          <thead>
            <tr>
              <th>Lead ID</th>
              <th>Date Requested</th>
              <th>Stage</th>
              <th>Organization</th>
              <th>Assignee</th>

              <th>Lead Name</th>
              <th>Phone Number</th>
              <th>Email</th>
              <th>Company</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{id}</td>
              <td>
                {moment(state.lead.createdAt).format("MMM Do YYYY h:mm a")}
              </td>
              <td>{formatStage(state.lead.stage)}</td>

              <td>{state.lead.organizationName}</td>
              <td>{state.lead.assigneeName}</td>

              <td>
                {state.lead.firstName} {state.lead.lastName}
              </td>
              <td>{state.lead.phone}</td>
              <td>
                <a href={state.lead.email}>{state.lead.email}</a>
              </td>
              <td>{state.lead.companyName}</td>
            </tr>
          </tbody>
        </table> */}

      {/* {state.lead.quotes.length > 0 && <h3 className="mt-40">Quotes</h3>} */}

      {/* {state.lead.quotes.length > 0 &&
          state.lead.quotes.map((quote) => {
            return (
              <p>
                <Link key={quote} to={`/quotes/${quote}`}>
                  View Quote: {quote}
                </Link>
              </p>
            );
          })} */}
      {/* </div> */}
      {/* 
      <div className="content-block">
        <h3>Trip Details</h3>
        <table className="evo-table">
          <thead>
            <tr>
              <th>Trip Type</th>
              <th>Departure Airport</th>
              <th>Departure Date</th>
              <th>Departure Time</th>
              <th>Arrival Airport</th>
              <th>Return Date</th>
              <th>Return Time</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{state.lead.tripType === 1 ? "One Way" : "Round Trip"}</td>
              <td>{state.lead.itineraryDetails.departureAirportName}</td>
              <td>{state.lead.itineraryDetails.departureDate}</td>
              <td>{state.lead.departureTime || "N/A"}</td>
              <td>{state.lead.itineraryDetails.arrivalAirportName}</td>
              <td>{state.lead.itineraryDetails.returnDate || "N/A"}</td>
              <td>{state.lead.returnTime ? state.lead.returnTime : "N/A"}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="content-block">
        <h3>Additional Details</h3>
        <table className="evo-table">
          <thead>
            <tr>
              <th>Passengers</th>
              <th>Selection</th>
              <th>Baggage</th>
              <th>Pets</th>
              <th>Infants</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{state.lead.itineraryDetails.passengerCount}</td>
              <td>{getAircraftSelection()}</td>
              <td>
                {state.lead.baggage.length
                  ? state.lead.baggage.join(", ")
                  : "N/A"}
              </td>
              <td>{state.lead.pets ? "Yes" : "No"}</td>
              <td>{state.lead.infants ? "Yes" : "No"}</td>
            </tr>
          </tbody>
        </table>
        <h3 className="mt-30">Comments</h3>
        <p>{state.lead.comments}</p>
      </div>

      <div className="content-block">
        <h3>Estimate Info</h3>
        <table className="evo-table">
          <thead>
            <tr>
              <th>Aircraft Type</th>
              <th>Low Spread</th>
              <th>High Spread</th>
            </tr>
          </thead>
          <tbody>
            {state.lead.tripOptions.map(
              ({ aircraftName, lowSpread, highSpread }) => {
                return (
                  <tr key={aircraftName + lowSpread}>
                    <td key={aircraftName}>{aircraftName}</td>
                    <td key={lowSpread}>{formatCurrency(lowSpread)}</td>
                    <td key={highSpread}>{formatCurrency(highSpread)}</td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </div> */}

      {/* {superAdmin && <pre>{JSON.stringify(state, null, 2)}</pre>} */}
    </Fragment>
  );
};

export default SingleTripRequestContent;
