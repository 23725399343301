import React, { Fragment, useReducer, useEffect } from "react";
import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import ai from "../../helpers/axios";
import * as Sentry from "@sentry/react";

import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";

const reducer = (state, action) => {
  switch (action.type) {
    case "search": {
      return {
        ...state,
        query: action.query,
      };
    }

    case "fieldChanged": {
      return {
        ...state,
        [action.field]: action.value,
      };
    }

    default:
      break;
  }
  return state;
};

const NewUserContent = (props) => {
  const history = useHistory();

  const initialState = {
    organizations: [],
    availableRoles: [],
    availableAccountDirectors: [],
    availableOutreachUsers: [],
    selectedOrganization: {},
    selectedOutreachUser: {},
    phone: "",
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    selectedRole: { id: "", value: "", label: "" },
  };

  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    document.title = "New User | Flight Deck by evoJets";
    loadOrganizations();
  }, []);

  const loadOrganizations = async (inputValue) => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });
    let organizations = await ai
      .auth(token)
      .get(`/api/organizations/list`)
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
      });

    let roles = await ai
      .auth(token)
      .get(`/api/users/availableRoles`)
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
      });

    let accountDirectors = await ai
      .auth(token)
      .get(`/api/users/roles/accountDirectors`)
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
      });

    let outreachUsers = await ai
      .auth(token)
      .get(`/api/users/outreachUsers`)
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
      });

    if (outreachUsers?.data?.data?.length) {
      //account directors loaded
      outreachUsers = outreachUsers.data.data.map((user) => {
        return {
          label: user?.attributes?.name,
          value: user.id,
        };
      });

      dispatch({
        type: "fieldChanged",
        value: outreachUsers,
        field: "availableOutreachUsers",
      });
    }

    if (accountDirectors?.data?.users?.length) {
      //account directors loaded
      accountDirectors = accountDirectors.data.users.map((ad) => {
        return {
          ...ad,
          label: ad?.name,
          value: ad?.user_id,
        };
      });

      dispatch({
        type: "fieldChanged",
        value: accountDirectors,
        field: "availableAccountDirectors",
      });
    }

    if (
      organizations &&
      organizations.data &&
      organizations.data.organizations &&
      organizations.data.organizations.length > 0
    ) {
      console.log(organizations.data);

      let organizationsFormatted = organizations.data.organizations.map(
        (organization) => {
          return {
            ...organization,
            label: organization.name,
            value: organization._id,
          };
        }
      );

      dispatch({
        type: "fieldChanged",
        field: "organizations",
        value: organizationsFormatted,
      });
    }

    if (
      roles &&
      roles.data &&
      roles.data.roles &&
      roles.data.roles.length > 0
    ) {
      dispatch({
        type: "fieldChanged",
        field: "availableRoles",
        value: roles.data.roles.map((role) => {
          return { ...role, label: role.name, value: role.id };
        }),
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let formData = {
      firstName: state.firstName,
      lastName: state.lastName,
      name: `${state.firstName} ${state.lastName}`,
      phone: state.phone,
      email: state.email,
      organizationId: state.selectedOrganization._id,
      password: state.password,
    };

    if (formData?.organizationId === "600c8d371f10c61723e64402") {
      formData.outreachUserId = state?.selectedOutreachUser?.value;

      if (!state?.selectedOutreachUser?.value) {
        toast.error("Please select an Outreach User.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        return;
      }
    }

    if (
      formData?.organizationId === "600c8d371f10c61723e64402" &&
      state.selectedRole?.id !== "rol_pOQalM41JyTqKsF4" &&
      state.selectedRole?.id !== "rol_rvDqDue8G72tSinZ"
    ) {
      //not an AD, but an evo user
      formData.accountDirectorId = state?.selectedAccountDirector?.value;

      if (!state?.selectedAccountDirector?.value) {
        toast.error("Please select an Account Director.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        return;
      }
    }

    if (
      formData?.organizationId === "600c8d371f10c61723e64402" &&
      state.selectedRole?.id === "rol_rvDqDue8G72tSinZ"
    ) {
      //dynamic sales assistant

      if (state?.parentUsers?.length) {
        formData.parentUserIds = state.parentUsers.map((item) => {
          if (item.user_id) {
            return item.user_id;
          }
        });
      }

      if (!formData?.parentUserIds?.length) {
        toast.error("Please select at least one Parent User.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        return;
      }
    }

    console.log("NEW USER FORM DATA", formData);

    if (state.selectedRole.id) {
      formData.roles = [state.selectedRole.id];
    }

    let response = await ai
      .auth(token)
      .post(`/api/users/create`, formData)
      .catch((error) => {
        Sentry.captureException(error);
        return error;
      });

    if (response) {
      // history.push({ pathname: "/organizations/" });
      console.log(response);

      if (response?.data?.statusCode === 400) {
        //error

        return toast.error(`Error: ${response?.data?.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }

      setTimeout(() => {
        toast.success("User Created Successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }, 50);
    }
  };

  return (
    <Fragment>
      <h2 id="addAirportHeading">New User</h2>
      <form id="" className="base-form two-column" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="firstName">First Name</label>
          <input
            type="text"
            name="firstName"
            value={state.firstName}
            placeholder="First Name"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="lastName">Last Name</label>
          <input
            type="text"
            name="lastName"
            value={state.lastName}
            placeholder="Last Name"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="phone">Phone Number</label>
          <input
            type="text"
            name="phone"
            value={state.phone}
            placeholder="Phone Number"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="phone">Email Address</label>
          <input
            type="email"
            name="email"
            value={state.email}
            placeholder="Email Address"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="notificationEmails">Organization</label>

          <Select
            key={`organization-options-key-${JSON.stringify(
              state.organizations
            )}`}
            className="organization-select"
            classNamePrefix="select"
            options={state.organizations}
            // defaultValue={props.initialTags}
            placeholder="Organization"
            onChange={(selectedOrganization) => {
              dispatch({
                type: "fieldChanged",
                field: "selectedOrganization",
                value: selectedOrganization,
              });
            }}
          ></Select>
        </div>

        <div className="form-group">
          <label htmlFor="phone">Password</label>
          <input
            type="text"
            name="password"
            value={state.password}
            placeholder="Password"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="userRoles">User Role</label>

          <Select
            key={`role-options-key-${JSON.stringify(state.availableRoles)}`}
            name="userRoles"
            className="organization-select"
            classNamePrefix="select"
            options={state.availableRoles}
            // defaultValue={state.userRoles}
            placeholder="User Role"
            onChange={(selectedRole) => {
              dispatch({
                type: "fieldChanged",
                field: "selectedRole",
                value: selectedRole,
              });
            }}
          ></Select>
        </div>

        {state.selectedOrganization?._id === "600c8d371f10c61723e64402" &&
          state.selectedRole?.id !== "" &&
          state.selectedRole?.id !== "rol_pOQalM41JyTqKsF4" &&
          state.selectedRole?.id !== "rol_rvDqDue8G72tSinZ" && (
            <div className="form-group">
              <label htmlFor="accountDirector">Account Director</label>

              <Select
                key={`account-director-options-key-${JSON.stringify(
                  state.availableAccountDirectors
                )}`}
                name="accountDirector"
                className="base-select"
                classNamePrefix="select"
                options={state.availableAccountDirectors}
                placeholder="Account Director"
                onChange={(selectedAD) => {
                  dispatch({
                    type: "fieldChanged",
                    field: "selectedAccountDirector",
                    value: selectedAD,
                  });
                }}
              ></Select>
            </div>
          )}

        {state.selectedOrganization?._id === "600c8d371f10c61723e64402" &&
          state.selectedRole?.id === "rol_rvDqDue8G72tSinZ" && (
            <div className="form-group">
              <label htmlFor="parentUsers">Parent Users</label>

              <Select
                key={`account-director-options-key-${JSON.stringify(
                  state.availableAccountDirectors
                )}`}
                name="parentUsers"
                className="base-select"
                classNamePrefix="select"
                isMulti
                options={state.availableAccountDirectors}
                placeholder="Account Director"
                onChange={(selectedAD) => {
                  dispatch({
                    type: "fieldChanged",
                    field: "parentUsers",
                    value: selectedAD,
                  });
                }}
              ></Select>
            </div>
          )}

        {state.selectedOrganization?._id === "600c8d371f10c61723e64402" && (
          <div className="form-group">
            <label htmlFor="outreachUserId">Outreach User</label>

            <Select
              key={`account-director-options-key-${JSON.stringify(
                state.availableOutreachUsers
              )}`}
              name="outreachUserId"
              className="base-select"
              classNamePrefix="select"
              options={state.availableOutreachUsers}
              placeholder="Outreach User"
              onChange={(selectedOutreachUser) => {
                dispatch({
                  type: "fieldChanged",
                  field: "selectedOutreachUser",
                  value: selectedOutreachUser,
                });
              }}
            ></Select>
          </div>
        )}

        <input type="submit" value="Save New User"></input>
      </form>

      {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
    </Fragment>
  );
};

export default NewUserContent;
