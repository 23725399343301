import React, { Fragment, useEffect, useReducer } from "react";
import { ToastContainer, Slide } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";

import ai from "../../helpers/axios";

import columns from "./opi-columns";
import nonAdminColumns from "./opi-columns-non-admin";
// import TableBulkActions from "../table-bulk-actions";

// import DataTableServerPagination from "../data-table-server-pagination";
import PageHeaderRowSearch from "../shared/page-header-row-search";
import DataTable from "react-data-table-component";
import Search from "../search";

import { Link } from "react-router-dom";

const reducer = (state, action) => {
  switch (action.type) {
    case "search": {
      return {
        ...state,
        query: action.query,
      };
    }
    case "dataBeganDownloading": {
      return {
        ...state,
        loading: action.payload.loadingStatus,
      };
    }
    // case "rowsDeleted": {
    //   return {
    //     ...state,
    //     clearSelectedRows: !state.clearSelectedRows,
    //     selectedRows: [],
    //   };
    // }
    // case "rowSelection": {
    //   return {
    //     ...state,
    //     selectedRows: action.payload.selectedRows,
    //     allSelected: action.payload.allSelected,
    //   };
    // }
    case "dataDownloaded": {
      console.log(action.payload);
      return {
        ...state,
        operators: {
          data: action.payload.data.operators,
        },
        opiWeights: action.payload.data.opiWeights,
        loading: false,
        totalLength: action.payload.data.countTotal,
      };
    }
    // case "pageChanged": {
    //   return {
    //     ...state,
    //     currentPage: action.payload.page,
    //   };
    // }
    // case "rowsPerPageChanged": {
    //   return {
    //     ...state,
    //     countPerPage: action.payload.currentRowsPerPage,
    //   };
    // }
    default:
      break;
  }
  return state;
};

const OPIContent = () => {
  const initialState = {
    operators: {
      data: [],
    },
    loading: true,
    countPerPage: 25,
    currentPage: 1,
    query: "",
    selectedRows: [],
    allSelected: false,
    tagFilters: [],
    totalLength: null,
    clearSelectedRows: false,
  };

  const { getAccessTokenSilently, user, loginWithRedirect } = useAuth0();

  const userRoles = user && user["https://app.evojets.com/roles"];

  const superAdminOrAD = userRoles.some(function (role) {
    return "Super Admin" === role || "Account Director" === role;
  });

  const superAdmin = userRoles.some(function (role) {
    return "Super Admin" === role;
  });

  const superAdminOrSales = userRoles.some(function (role) {
    return (
      "Super Admin" === role ||
      "Account Director" === role ||
      "Sales Assistant" === role ||
      "Dynamic Sales Assistant" === role ||
      "Account Executive" === role
    );
  });

  const [state, dispatch] = useReducer(reducer, initialState);

  const getData = async (query, loadSilently) => {
    let loadingStatus = true;
    if (loadSilently) {
      loadingStatus = false;
    }

    dispatch({ type: "dataBeganDownloading", payload: { loadingStatus } });

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let { data } = await ai.auth(token).get(`/api/opi/list`, {
      params: {
        query: query,
      },
    });

    dispatch({
      type: "dataDownloaded",
      payload: {
        data,
      },
    });
  };

  useEffect(() => {
    getData("");
    document.title = "Operators | Flight Deck by evoJets";
  }, []);

  //   const handlePageChange = (page, totalRows) => {
  //     dispatch({
  //       type: "pageChanged",
  //       payload: {
  //         page,
  //         totalRows,
  //       },
  //     });
  //   };

  //   const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
  //     dispatch({
  //       type: "rowsPerPageChanged",
  //       payload: {
  //         currentRowsPerPage,
  //         currentPage,
  //       },
  //     });
  //   };

  //   const handleSelection = function (rowData) {
  //     dispatch({
  //       type: "rowSelection",
  //       payload: {
  //         selectedRows: rowData.selectedRows,
  //         allSelected: rowData.allSelected,
  //       },
  //     });
  //   };

  const handleSearch = (e) => {
    let query = e.target.value.toLowerCase();
    dispatch({ type: "search", query });

    getData(query, true);
  };

  //   const handleDelete = async (e) => {
  //     e.preventDefault();

  //     const token = await getAccessTokenSilently().catch((e) => {
  //   console.error(e);
  //   loginWithRedirect();
  // });

  //     let rowIds = state.selectedRows.map((item) => item._id);
  //     let { data } = await ai
  //       .auth(token)
  //       .delete("/api/distanceParams/deleteMany", {
  //         data: { ids: rowIds },
  //       });

  //     if (data.deletedCount) {
  //       getData("", true);

  //       toast.success("Distance Params Deleted Successfully", {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //       });
  //     } else {
  //       console.log("No Rows Deleted");
  //     }

  //     // state changes happen regardless if rows successfully deleted or not
  //     dispatch({
  //       type: "rowsDeleted",
  //     });
  //   };

  return (
    <Fragment>
      {/* <ToastContainer transition={Slide} /> */}

      <PageHeaderRowSearch
        h1={`Operator Performance Index`}
        pretitle="Settings"
        breadcrumbs={[{ link: "/opi", label: "Operator Performance Index" }]}
        rightColumn={
          <div
            id="operator-actions-row"
            className="header-actions-row base-header-actions-row has-delete-btn opi-header"
          >
            <Search
              placeholder="Search Operators"
              onSearch={handleSearch}
              query={state.query}
              className="opi-search"
            ></Search>
            {/* <TableBulkActions
              state={state}
              handleDelete={handleDelete}
              hideTags
              deleteBtnAlignment={"align-right"}
            /> */}
            {superAdminOrAD && (
              <Link to="/opi/new" className="evo-blue base-button">
                Add Operator
              </Link>
            )}
          </div>
        }
      ></PageHeaderRowSearch>

      <div
        className={`flight-table-container standard-data-table-container opi-table ${
          !superAdminOrAD && `opi-auto-width`
        }`}
      >
        <DataTable
          data={state.operators.data}
          columns={superAdminOrAD ? columns : nonAdminColumns}
          noHeader
          highlightOnHover
          pagination={true}
          paginationPerPage={50}
          paginationRowsPerPageOptions={[50, 100, 150, 200, 250]}
          defaultSortField="composedScore"
          defaultSortAsc={false}
          responsive={true}
        ></DataTable>
      </div>

      {/* 
      <DataTableServerPagination
        onSelection={handleSelection}
        columns={columns}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
        state={state}
        data={state.distanceParams.data}
        defaultSortField={"aircraftTypeData[0].name"}
      ></DataTableServerPagination> */}

      {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
    </Fragment>
  );
};

export default OPIContent;
