import { Link } from "react-router-dom";
import moment from "moment";

const columns = [
  {
    name: "Edit",
    sortable: false,
    cell: (row) => {
      return <Link to={`/opi/${row._id}`}>Edit</Link>;
    },
    width: "70px",
  },

  {
    name: "Operator Name",
    selector: "operatorName",
    sortable: true,
    width: "200px",
  },
  {
    name: "Score",
    selector: "composedScore",
    cell: (row) => {
      if (row.composedScore) {
        return row.composedScore;
      }

      return "-";
    },
    sortable: true,
    width: "120px",
  },
  // {
  //   name: "Type",
  //   selector: "type",
  //   sortable: true,
  //   width: "150px",
  // },
  {
    name: "Country",
    selector: "country",
    sortable: true,
    width: "150px",
  },
  {
    name: "Cities",
    selector: "cities",
    sortable: true,
    cell: (row) => {
      if (row?.cities?.length) {
        return row.cities.join(", ");
      }
      return "-";
    },
    width: "450px",
  },
  {
    name: "Restrictions",
    selector: "restrictions",
    sortable: true,
    width: "200px",
    cell: (row) => {
      let restrictions = [];
      if (row?.restrictions?.length) {
        restrictions = row.restrictions.flatMap((item) => {
          let label = [];
          if (item === "caution") {
            label = "Use With Caution";
          }

          if (item === "blacklist") {
            label = "Blacklist";
          }

          if (item === "recovery") {
            label = "Recovery Only";
          }

          return label;
        });

        return restrictions.join(", ");
      }
    },
  },
  {
    name: "Operational Integrity",
    selector: "operationalIntegrity",
    sortable: true,
    width: "150px",
  },
  {
    name: "Communication",
    selector: "communication",
    sortable: true,
    width: "150px",
  },
  {
    name: "Aircraft in Operator Fleet",
    selector: "aircraftInFleet",
    sortable: true,
    width: "200px",
  },
  {
    name: "Average Condition of Aircraft",
    selector: "aircraftCondition",
    sortable: true,
    width: "200px",
  },
  {
    name: "Service Record",
    selector: "serviceRecord",
    sortable: true,
    width: "200px",
  },
  {
    name: "On-Demand Friendly",
    selector: "onDemandFriendly",
    sortable: true,
    width: "200px",
  },
  {
    name: "Years With Part 135",
    selector: "yearsWithPart135",
    sortable: true,
    width: "200px",
  },
  {
    name: "Average Age of Fleet",
    selector: "fleetAge",
    sortable: true,
    width: "200px",
  },
  {
    name: "Safety Rating",
    selector: "safetyRating",
    sortable: true,
    width: "200px",
  },
  {
    name: "Discretionary",
    selector: "discretionary",
    sortable: true,
    width: "200px",
    cell: (row) => (row.discretionary ? row.discretionary : 0),
  },
  {
    name: "Updated At",
    selector: "updatedAt",
    sortable: true,
    width: "200px",
    cell: (row) => {
      return moment(row.createdAt).format("llll");
    },
  },
];

export default columns;
