import React, { Fragment, useReducer, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import ai from "../../helpers/axios";

import { toast } from "react-toastify";
import { useHistory, useLocation, useParams } from "react-router-dom";

// import Select from "react-select";
import TextInput from "../text-input";
// import PhoneInput from "react-phone-number-input";

import "react-json-pretty/themes/monikai.css";
import JSONPretty from "react-json-pretty";

import "react-phone-number-input/style.css";

import * as Sentry from "@sentry/react";
import Loader from "../../components/loader";

import moment from "moment-timezone";
import OperatorAutocomplete from "../opi/operator-autocomplete";
import AircraftAutocomplete from "../aircrafts/aircraft-autocomplete";
import TextAreaInput from "../textarea-input";

import Select from "react-select";

// mui imports
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import NumberFormat from "react-number-format";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import currencyFormatter from "../../helpers/currencyFormatter";

import OperatorCancellationPolicies from "../trips/financials/operator-cancellation-policies";

let toastOptions = {
  position: "top-right",
  autoClose: 6000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  icon: false,
};

let initialState = {
  lead: null,
  isRedirecting: false,

  selectedOperator: null,
  operatorOptions: [],

  selectedAircraft: null,
  aircraftOptions: [],

  interiorRefurbYearIsRange: false,
  yomIsRange: false,

  selectedYOM: null,
  selectedInteriorRefurbYear: null,
  selectedYOMEnd: null,
  selectedInteriorRefurbYearEnd: null,

  operatorPrice: null,
  grossCommission: null,
  netCommission: null,
  commissionPercentage: null,

  showCustomFETField: false,

  selectedFET: {
    label: "US Domestic",
    value: "domestic",
  },

  FET: 0,
  showCustomSegmentFeesField: false,

  selectedSegmentFees: {
    label: "US Domestic",
    value: "domestic",
  },

  segmentFees: 31.5,

  referralFees: null,

  totalClientPayment: null,

  amenities: [],
  safetyStandards: [],

  ownerApproval: "no",
  paymentType: null,

  serviceFee: 0,
  ccFee: 0,

  tailNumber: "",
  notes: "",
  includeNotesOnQuote: false,
  includeOPIOnQuote: false,
  loading: true,
  title: "New Sourcing Record",
  aircraftSeats: "",
  whichLeg: "",
  discount: null,
  operatorCancellationPolicies: [
    {
      type: 1,
      value: 0,
      dueDate: "",
    },
  ],
  dusCancellationPolicy: {
    type: 1,
    value: 0,
  },

  crew: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "fieldChanged": {
      let value = action.value;

      if (action?.fieldType === "number") {
        value = parseInt(action.value);
        if (isNaN(value)) {
          value = null;
        }
        console.log("SEAT CHANGE", action);
      }

      if (action?.fieldType === "float") {
        value = parseFloat(action.value);
        if (isNaN(value)) {
          value = null;
        }
      }

      return {
        ...state,
        [action.field]: value,
      };
    }

    case "resetState": {
      return {
        ...initialState,
      };
    }

    case "dataDownloaded": {
      let data = action?.payload?.data?.sourcingRecord;
      console.log("DATA DOWNLOADED", data);
      if (!data) {
        return state;
      }

      let selectedYOM,
        selectedSegmentFees,
        selectedInteriorRefurbYear,
        selectedFET,
        selectedYOMEnd,
        selectedInteriorRefurbYearEnd = null;

      if (data.yom) {
        selectedYOM = {
          label: data.yom,
          value: data.yom,
        };
      }

      if (data.interiorRefurbYear) {
        selectedInteriorRefurbYear = {
          label: data.interiorRefurbYear,
          value: data.interiorRefurbYear,
        };
      }

      if (data?.segmentFees?.value && data?.segmentFees?.label) {
        selectedSegmentFees = {
          value: data?.segmentFees?.value,
          label: data?.segmentFees?.label,
        };
      }

      if (data?.fet?.value && data?.fet?.label) {
        selectedFET = {
          value: data?.fet?.value,
          label: data?.fet?.label,
        };
      }

      if (data?.interiorRefurbYearIsRange && data.interiorRefurbYearEnd) {
        selectedInteriorRefurbYearEnd = {
          label: data.interiorRefurbYearEnd,
          value: data.interiorRefurbYearEnd,
        };
      }

      if (data?.yomIsRange && data.yomIsRange) {
        selectedYOMEnd = {
          label: data.yomEnd,
          value: data.yomEnd,
        };
      }

      let ownerApproval = false;

      if (data?.ownerApproval === true) {
        ownerApproval = "yes";
      } else if (data?.ownerApproval === false) {
        ownerApproval = "no";
      }

      let firstTwoCities = "";
      let showIndicator = false;
      let allCities = "";

      if (data?.operatorData?.cities?.length) {
        firstTwoCities = data?.operatorData?.cities.slice(0, 2);
        firstTwoCities = firstTwoCities.join(", ");

        if (data?.operatorData?.cities.length > 2) {
          showIndicator = true;
        }

        allCities = data?.operatorData?.cities.join(", ");
      }

      let labelString = `<span ${showIndicator ? `title="${allCities}"` : ""}>${
        data?.operator?.name
      } ${firstTwoCities ? `| ${firstTwoCities} |` : "| "} OPI ${
        data?.operatorData?.composedScore || "Unrated"
      }${
        showIndicator
          ? '<span class="material-icons select-label-indicator">info</span>'
          : ""
      }</span>`;

      let selectedOperator = {
        value: data?.operator?.name || "",
        label: data?.operator?.name || "",
        _id: data?.operator?.id,
        operatorName: data?.operator?.name,
        composedScore: data?.operatorData?.composedScore,
        specs: {
          showIndicator,
          firstTwoCities,
          allCities,
          composedScore: data?.operatorData?.composedScore,
        },
      };

      let lead = {
        _id: data?.leadId,
      };

      if (state?.lead) {
        lead = state.lead;
      }

      return {
        ...state,
        title: "Edit Sourcing Record",
        loading: false,
        selectedAircraft: {
          value: data?.aircraft?.id,
          label: data?.aircraft?.name,
          aircraftType: {
            _id: data?.aircraft?.aircraftType?.id,
            name: data?.aircraft?.aircraftType?.name,
          },
          seats: data?.aircraft?.seats || null,
        },

        aircraftSeats: data?.aircraft?.seats || "",
        selectedOperator,
        selectedFET,
        FET: data?.fet?.amount,

        selectedSegmentFees,
        segmentFees: data?.segmentFees?.amount,
        referralFees: data?.referralFees,
        discount: data?.discount,
        paymentType: data?.paymentType,

        amenities: data?.amenities || [],
        safetyStandards: data?.safetyStandards || [],

        grossCommission: data.grossCommission,

        operatorPrice: data.operatorPrice,

        totalCostOfGoods: data.totalCostOfGoods,
        totalTaxesAndFees: data.totalTaxesAndFees,
        totalClientPayment: data.totalClientPayment,

        netCommission: data.netCommission,
        commissionPercentage: data.commissionPercentage,

        notes: data.notes,
        includeNotesOnQuote: data.includeNotesOnQuote,
        includeOPIOnQuote: data.includeOPIOnQuote,
        tailNumber: data.tailNumber,

        selectedYOM,
        yomIsRange: data.yomIsRange,
        ownerApproval,

        selectedInteriorRefurbYear,
        interiorRefurbYearIsRange: data.interiorRefurbYearIsRange,
        selectedYOMEnd,
        selectedInteriorRefurbYearEnd,
        sourcingRecord: data,

        lead,

        crew: data?.crew || "",
        whichLeg: data?.whichLeg || "",
        operatorCancellationPolicies:
          data?.operatorCancellationPolicies?.length >= 1
            ? data?.operatorCancellationPolicies
            : [
                {
                  type: 1,
                  value: "",
                  dueDate: "",
                },
              ],
        dusCancellationPolicy: data?.dusCancellationPolicy || {
          type: 1,
          value: 0,
        },
      };
    }

    default:
      break;
  }
  return state;
};

const SourcingModuleContent = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();
  const location = useLocation();

  const { id } = useParams();

  const { getAccessTokenSilently, user, loginWithRedirect } = useAuth0();

  const userRoles = user && user["https://app.evojets.com/roles"];

  const superAdmin =
    user &&
    userRoles.some(function (role) {
      return "Super Admin" === role || "Account Director" === role;
    });

  const salesAssistant =
    user &&
    userRoles.some(function (role) {
      return "Sales Assistant" === role;
    });

  const getData = async () => {
    if (!id) {
      console.log("NEW RECORD, NO DATA TO DOWNLOAD");
      dispatch({ type: "fieldChanged", field: "loading", value: false });
    }

    if (id) {
      console.log("Data downloading...");

      dispatch({ type: "dataBeganDownloading" });

      const token = await getAccessTokenSilently().catch((e) => {
        console.error(e);
        return loginWithRedirect();
      });

      let response = await ai
        .auth(token)
        .get(`/api/sourcing/${id}`)
        .catch((error) => {
          // history.push({ pathname: "/sourcing" });

          setTimeout(() => {
            toast.error("Unauthorized!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }, 50);

          return console.error(error.response);
        });

      if (response && response.data) {
        console.log(response.data);
        dispatch({
          type: "dataDownloaded",
          payload: {
            data: response.data,
          },
        });
      }
    }
  };

  useEffect(() => {
    document.title = `${state.title} | Flight Deck`;

    const lead = location?.state?.lead;
    if (lead) {
      dispatch({ type: "fieldChanged", field: "lead", value: lead });

      console.log("LEAD PASSED", lead);
    }

    getData();
  }, []);

  useEffect(() => {
    if (document) {
      document.getElementById("page-wrap").scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [state.loading]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // console.log(e, e.target);

    // return;

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let sourcingData = {
      leadId: state.lead?._id,
      operator: {
        id: state.selectedOperator?._id,
        name: state.selectedOperator?.label,
        // opi: state.selectedOperator?.composedScore,
      },
      aircraft: {
        id: state.selectedAircraft?.value,
        name: state.selectedAircraft?.label,
        aircraftType: {
          id: state.selectedAircraft?.aircraftType?._id,
          name: state.selectedAircraft?.aircraftType?.name,
        },
        seats: parseInt(state.aircraftSeats),
      },
      tailNumber: state.tailNumber,
      notes: state.notes,
      includeNotesOnQuote: state.includeNotesOnQuote,
      includeOPIOnQuote: state.includeOPIOnQuote,

      yom: state?.selectedYOM?.value,
      yomIsRange: state.yomIsRange,
      interiorRefurbYear: state.selectedInteriorRefurbYear?.value,
      interiorRefurbYearIsRange: state.interiorRefurbYearIsRange,
      operatorPrice: state.operatorPrice,
      grossCommission: state.grossCommission,
      netCommission: state.netCommission,
      fet: {
        amount: state.FET,
        label: state?.selectedFET?.label,
        value: state?.selectedFET?.value,
      },
      segmentFees: {
        amount: state.segmentFees,
        label: state?.selectedSegmentFees?.label,
        value: state?.selectedSegmentFees?.value,
      },
      referralFees: state.referralFees,
      discount: state.discount,
      paymentType: state.paymentType,
      ccFee: state.ccFee,
      amenities: state.amenities,
      safetyStandards: state.safetyStandards,
      totalClientPayment: state.totalClientPayment,
      totalTaxesAndFees: state.totalTaxesAndFees,
      totalCostOfGoods: state.totalCostOfGoods,
      ownerApproval:
        state.ownerApproval === "no"
          ? false
          : state.ownerApproval === "yes"
          ? true
          : false,
      whichLeg:
        state?.whichLeg && !isNaN(state?.whichLeg)
          ? parseInt(state?.whichLeg)
          : null,

      crew: state.crew,
    };

    if (state.yomIsRange && state?.selectedYOMEnd?.value) {
      sourcingData.yomEnd = state?.selectedYOMEnd?.value;
    }

    if (state?.operatorCancellationPolicies?.length) {
      sourcingData.operatorCancellationPolicies =
        state.operatorCancellationPolicies.flatMap((policy) => {
          if (policy.date !== "" && policy.value) {
            return {
              type: policy.type,
              value: policy.value,
              dueDate: policy.dueDate,
            };
          }

          return [];
        });
    }

    if (state?.dusCancellationPolicy) {
      sourcingData.dusCancellationPolicy = {
        type: state?.dusCancellationPolicy?.type,
        value: state?.dusCancellationPolicy?.value,
      };
    }

    if (
      state.interiorRefurbYearIsRange &&
      state?.selectedInteriorRefurbYearEnd?.value
    ) {
      sourcingData.interiorRefurbYearEnd =
        state?.selectedInteriorRefurbYearEnd?.value;
    }

    console.log("SOURCING DATA SUBMISSION", sourcingData);

    let response = false;

    let successVerb = "Created";

    if (!id) {
      // only post if creating new

      response = await ai
        .auth(token)
        .post(`/api/sourcing/create`, sourcingData)
        .catch((err) => {
          console.error(err.response.data);
          toast.error(err.response.data, toastOptions);

          return;
        });
    }

    if (id) {
      response = await ai
        .auth(token)
        .put(`/api/sourcing/${id}`, sourcingData)
        .catch((err) => {
          console.error(err.response.data);
          toast.error(err.response.data, toastOptions);

          return;
        });
      successVerb = "Updated";
    }

    if (!response || !response?.data) {
      return;
    }

    let data = response?.data;

    setTimeout(() => {
      document
        .getElementById("nav-container")
        .scrollIntoView({ behavior: "smooth" });

      toast.success(
        `Sourcing Record ${successVerb} Successfully!`,
        toastOptions
      );
    }, 5);

    if (e?.target?.name === "addAnother") {
      //clear form and scroll to top, return

      setTimeout(() => {
        history.push({
          pathname: `/sourcing/new`,
          state: { lead: state?.lead },
        });
      }, 50);

      // dispatch({
      //   type: "resetState",
      // });
    }

    if (e?.target?.name === "viewLead") {
      //redirect to Lead
      setTimeout(() => {
        history.push({ pathname: `/leads/${state?.lead?._id}` });
      }, 20);
    }

    // if (!id) {
    //   setTimeout(() => {
    //     history.push({ pathname: `/sourcing/${data._id}/` });
    //   }, 5);
    // }

    // dispatch({ type: "fieldChanged", field: "isRedirecting", value: true });
  };

  const resetSelection = (fieldName) => {
    setTimeout(() => {
      dispatch({
        type: "fieldChanged",
        field: fieldName,
        value: null,
      });
    }, 0);
  };

  const handleOperatorChange = (selectedOperator) => {
    console.log(selectedOperator);

    if (selectedOperator) {
      if (selectedOperator?.restrictions?.length) {
        let confirm = window.confirm(
          `This operator has a status of: ${selectedOperator?.restrictions.join(
            ", "
          )}. Do you wish to proceed?`
        );

        if (!confirm) {
          return resetSelection("selectedOperator");
        }
      }

      dispatch({
        type: "fieldChanged",
        field: "selectedOperator",
        value: selectedOperator,
      });
    } else {
      resetSelection("selectedOperator");
    }
  };

  const handleAircraftChange = (selectedAircraft) => {
    if (selectedAircraft) {
      dispatch({
        type: "fieldChanged",
        field: "selectedAircraft",
        value: selectedAircraft,
      });

      dispatch({
        type: "fieldChanged",
        field: "aircraftSeats",
        value: selectedAircraft?.seats,
      });

      console.log("SELECTED AIRCRAFT", selectedAircraft);
    } else {
      resetSelection("selectedAircraft");
      dispatch({ type: "fieldChanged", field: "aircraftSeats", value: "" });
    }
  };

  const handleProspectChange = (selectedProspect) => {
    if (!superAdmin && !salesAssistant) {
      //account executive
      if (selectedProspect?.ownerId !== user.sub && selectedProspect?.ownerId) {
        console.log("ACCOUNT EXECUTIVE FAIL", user);

        toast.error(
          `This prospect is owned by ${selectedProspect.ownerName}. Contact your AD to update ownership.`,
          toastOptions
        );
        return resetSelection("selectedProspect");
      }
    }

    if (salesAssistant) {
      let accountDirectorId =
        user["https://app.evojets.com/appMetadata"]?.accountDirectorId;

      if (
        selectedProspect?.ownerId !== user.sub &&
        selectedProspect.ownerId !== accountDirectorId
      ) {
        toast.error(
          `This prospect is owned by ${selectedProspect.ownerName}. Contact your AD to update ownership.`,
          toastOptions
        );
        return resetSelection("selectedProspect");
      }
    }

    if (!superAdmin && !selectedProspect?.ownerId) {
      toast.error(
        "This prospect has no owner. Contact your AD to update ownership.",
        toastOptions
      );
      return resetSelection("selectedProspect");
    }

    if (selectedProspect) {
      dispatch({
        type: "fieldChanged",
        field: "selectedProspect",
        value: selectedProspect,
      });

      dispatch({
        type: "fieldChanged",
        field: "outreachProspectId",
        value: selectedProspect.orProspectId,
      });
    } else {
      resetSelection("selectedProspect");
    }
  };

  function convertUTCToLocalDate(date) {
    if (!date) {
      return date;
    }
    date = new Date(date);
    date = new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate()
    );
    return date;
  }

  function convertLocalToUTCDate(date) {
    if (!date) {
      return date;
    }
    date = new Date(date);
    date = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
    return date;
  }

  const handleTextFieldChange = (e) => {
    console.log("EVENT", e.target.name);

    // if (e.target.type === "number") {
    //   return dispatch({
    //     type: "fieldChanged",
    //     field: e.target.name,
    //     value: parseInt(e.target.value),
    //     fieldType: e.target.type,
    //   });
    // }

    dispatch({
      type: "fieldChanged",
      field: e.target.name,
      value: e.target.value,
      fieldType: e.target.type,
    });
  };
  const handleCheckboxChange = (e) => {
    console.log("EVENT", e.target.name);

    dispatch({
      type: "fieldChanged",
      field: e.target.name,
      value: e.target.checked,
    });
  };

  const generateRange = (start, end, length = end - start + 1) =>
    Array.from({ length }, (_, i) => start + i);

  const generateRangeOptions = (start, end) => {
    let range = generateRange(start, end);

    range.sort((a, b) => b - a);

    range = range.map((item) => {
      return { value: item, label: `${item}` };
    });

    range.unshift({ value: 0, label: "TBA" });

    return range;
  };

  const handlePriceFieldChange = (values, sourceInfo) => {
    const { formattedValue, value } = values;
    // Event is a Synthetic Event wrapper which holds target and other information. Source tells whether the reason for this function being triggered was an 'event' or due to a 'prop' change
    const { event, source } = sourceInfo;

    console.log("event", sourceInfo);

    if (!event?.target) {
      return;
    }

    dispatch({
      type: "fieldChanged",
      field: event?.target?.name,
      value: value,
      fieldType: "float",
    });
  };

  const handleSelectChange = (selectedValue, action) => {
    dispatch({
      type: "fieldChanged",
      field: action.name,
      value: selectedValue,
    });
  };

  useEffect(() => {
    recalculateFET(state.selectedFET);
  }, [state.selectedFET, state.operatorPrice]);

  useEffect(() => {
    recalculateSegmentFees(state.selectedSegmentFees);
  }, [state.selectedSegmentFees]);

  useEffect(() => {
    determineServiceFee(state.operatorPrice);
  }, [state.operatorPrice, state.selectedFET]);

  const calculateTotalClientPayment = (
    segmentFees,
    operatorPrice,
    FET,
    grossCommission,
    referralFees,
    paymentType,
    serviceFee,
    discount
  ) => {
    console.log("FINAL PRICE CALCULATION RUNNING");
    if (segmentFees === "" || segmentFees === null) {
      segmentFees = 0;
    }

    if (operatorPrice === "" || operatorPrice === null) {
      operatorPrice = 0;
    }

    if (FET === "" || FET === null) {
      FET = 0;
    }

    if (grossCommission === "" || grossCommission === null) {
      grossCommission = 0;
    }

    if (referralFees === "" || referralFees === null) {
      referralFees = 0;
    }

    let totalWithTaxesAndFees =
      segmentFees + operatorPrice + FET + grossCommission + referralFees;
    let ccFee = 0;

    if (serviceFee === "" || serviceFee === null) {
      serviceFee = 0;
    }

    if (discount === "" || discount === null) {
      discount = 0;
    }

    totalWithTaxesAndFees = totalWithTaxesAndFees - discount;

    if (paymentType === "cc") {
      ccFee = totalWithTaxesAndFees * 0.04;
    }
    totalWithTaxesAndFees += ccFee;

    //round up
    totalWithTaxesAndFees = Math.ceil(totalWithTaxesAndFees / 5) * 5;

    let totalTaxesAndFees = FET + segmentFees;

    dispatch({
      type: "fieldChanged",
      field: "totalTaxesAndFees",
      value: totalTaxesAndFees,
    });

    let totalCostOfGoods = operatorPrice + referralFees;

    dispatch({
      type: "fieldChanged",
      field: "totalCostOfGoods",
      value: totalCostOfGoods,
    });

    console.log({
      segmentFees,
      operatorPrice,
      FET,
      grossCommission,
      referralFees,
      ccFee,
    });

    recalculateCommission(grossCommission, operatorPrice, serviceFee, discount);

    dispatch({
      type: "fieldChanged",
      field: "ccFee",
      value: ccFee,
    });

    dispatch({
      type: "fieldChanged",
      field: "totalClientPayment",
      value: totalWithTaxesAndFees,
    });
  };

  useEffect(() => {
    calculateTotalClientPayment(
      state.segmentFees,
      state.operatorPrice,
      state.FET,
      state.grossCommission,
      state.referralFees,
      state.paymentType,
      state.serviceFee,
      state.discount
    );
  }, [
    state.segmentFees,
    state.operatorPrice,
    state.FET,
    state.grossCommission,
    state.referralFees,
    state.paymentType,
    state.serviceFee,
    state.discount,
  ]);

  const recalculateFET = (fetSelection) => {
    console.log("RECALCULATE FET", fetSelection);

    //match Seg Fee
    dispatch({
      type: "fieldChanged",
      field: "selectedSegmentFees",
      value: fetSelection,
    });

    switch (fetSelection?.value) {
      case "domestic": {
        dispatch({
          type: "fieldChanged",
          field: "FET",
          value: state.operatorPrice * 0.075,
        });
        dispatch({
          type: "fieldChanged",
          field: "showCustomFETField",
          value: false,
        });

        break;
      }

      case "225zone": {
        dispatch({
          type: "fieldChanged",
          field: "FET",
          value: state.operatorPrice * 0.075,
        });
        dispatch({
          type: "fieldChanged",
          field: "showCustomFETField",
          value: false,
        });

        break;
      }

      case "usInternational": {
        dispatch({ type: "fieldChanged", field: "FET", value: 0 });
        dispatch({
          type: "fieldChanged",
          field: "showCustomFETField",
          value: false,
        });
        break;
      }

      case "allInternational": {
        dispatch({ type: "fieldChanged", field: "FET", value: 0 });
        dispatch({
          type: "fieldChanged",
          field: "showCustomFETField",
          value: false,
        });
        break;
      }

      case "custom": {
        // dispatch({ type: "fieldChanged", field: "FET", value: "" });
        dispatch({
          type: "fieldChanged",
          field: "showCustomFETField",
          value: true,
        });
        break;
      }

      case "operatorCollected": {
        dispatch({ type: "fieldChanged", field: "FET", value: 0 });
        dispatch({
          type: "fieldChanged",
          field: "showCustomFETField",
          value: false,
        });
        break;
      }

      case "passThrough": {
        dispatch({ type: "fieldChanged", field: "FET", value: 0 });
        dispatch({
          type: "fieldChanged",
          field: "showCustomFETField",
          value: false,
        });
        break;
      }

      default: {
        dispatch({
          type: "fieldChanged",
          field: "FET",
          value: 0,
        });
        dispatch({
          type: "fieldChanged",
          field: "showCustomFETField",
          value: false,
        });
        break;
      }
    }
  };

  const handleSegmentFeesSelectChange = (selectedValue, action) => {
    dispatch({
      type: "fieldChanged",
      field: action.name,
      value: selectedValue,
    });

    recalculateSegmentFees(selectedValue);
  };

  const determineServiceFee = (operatorPrice) => {
    let serviceFee = 0;

    if (operatorPrice < 9000) {
      serviceFee = 100;
    }

    if (operatorPrice >= 9000 && operatorPrice <= 19999) {
      serviceFee = 275;
    }

    if (operatorPrice >= 20000 && operatorPrice <= 39999) {
      serviceFee = 400;
    }

    if (operatorPrice >= 40000 && operatorPrice <= 59999) {
      serviceFee = 475;
    }

    if (operatorPrice >= 60000 && operatorPrice <= 99999) {
      serviceFee = 575;
    }

    if (operatorPrice >= 100000 && operatorPrice <= 199999) {
      serviceFee = 750;
    }

    if (operatorPrice >= 200000) {
      serviceFee = 1000;
    }

    //no fees on passThrough FET type
    if (state?.selectedFET?.value === "passThrough") {
      serviceFee = 0;
    }

    return dispatch({
      type: "fieldChanged",
      field: "serviceFee",
      value: serviceFee,
    });
  };

  const recalculateCommission = (
    grossCommission,
    operatorPrice,
    serviceFee,
    discount
  ) => {
    let percentage = null;
    if (grossCommission && operatorPrice) {
      percentage = (grossCommission / operatorPrice) * 100;
      percentage = percentage.toFixed(2);
    }

    if (!discount) {
      discount = 0;
    }

    let netCommission = grossCommission - discount - serviceFee;

    dispatch({
      type: "fieldChanged",
      field: "netCommission",
      value: netCommission,
    });

    dispatch({
      type: "fieldChanged",
      field: "commissionPercentage",
      value: percentage,
    });
  };

  const recalculateSegmentFees = (segmentFeesSelection) => {
    console.log("RECALCULATE SEGMENT FEES", segmentFeesSelection);
    switch (segmentFeesSelection?.value) {
      case "domestic": {
        dispatch({
          type: "fieldChanged",
          field: "segmentFees",
          value: 31.5,
        });
        dispatch({
          type: "fieldChanged",
          field: "showCustomSegmentFeesField",
          value: false,
        });

        break;
      }

      case "225zone": {
        dispatch({
          type: "fieldChanged",
          field: "segmentFees",
          value: 139.5,
        });
        dispatch({
          type: "fieldChanged",
          field: "showCustomSegmentFeesField",
          value: false,
        });

        break;
      }

      case "usInternational": {
        dispatch({ type: "fieldChanged", field: "segmentFees", value: 139.5 });
        dispatch({
          type: "fieldChanged",
          field: "showCustomSegmentFeesField",
          value: false,
        });
        break;
      }

      case "allInternational": {
        dispatch({ type: "fieldChanged", field: "segmentFees", value: 0 });
        dispatch({
          type: "fieldChanged",
          field: "showCustomSegmentFeesField",
          value: false,
        });
        break;
      }

      case "custom": {
        // dispatch({
        //   type: "fieldChanged",
        //   field: "segmentFees",
        //   value: "",
        // });
        dispatch({
          type: "fieldChanged",
          field: "showCustomSegmentFeesField",
          value: true,
        });
        break;
      }

      case "operatorCollected": {
        dispatch({ type: "fieldChanged", field: "segmentFees", value: 0 });
        dispatch({
          type: "fieldChanged",
          field: "showCustomSegmentFeesField",
          value: false,
        });
        break;
      }

      case "passThrough": {
        dispatch({ type: "fieldChanged", field: "segmentFees", value: 0 });
        dispatch({
          type: "fieldChanged",
          field: "showCustomSegmentFeesField",
          value: false,
        });
        break;
      }

      default: {
        dispatch({
          type: "fieldChanged",
          field: "segmentFees",
          value: 0,
        });
        dispatch({
          type: "fieldChanged",
          field: "showCustomSegmentFeesField",
          value: false,
        });
        break;
      }
    }
  };

  const handleFETSelectChange = (selectedValue, action) => {
    dispatch({
      type: "fieldChanged",
      field: action.name,
      value: selectedValue,
    });

    recalculateFET(selectedValue);
  };

  const handleAmenitiesChange = (event, newSelection) => {
    dispatch({
      type: "fieldChanged",
      field: "amenities",
      value: newSelection,
    });
  };

  const handleSafetyStandardsChange = (event, newSelection) => {
    dispatch({
      type: "fieldChanged",
      field: "safetyStandards",
      value: newSelection,
    });
  };

  const amenitiesList = [
    "WiFi Free",
    "WiFi Billed",
    "Lav - Enclosed",
    "Lav - Seat",
    "Flight Attendant",
    "Catering Included",
    "No Lavatory",
    "Single Pilot",
    "Full Galley",
    "Pets Allowed",
    "Pet Cleaning Fee",
    "No Pets",
    "No Red Wine",
    "Smoking",
    "Standard Catering",
  ];

  const safetyStandardsList = [
    "ARG/US Gold",
    "ARG/US Gold+",
    "ARG/US Platinum",
    "Wyvern Registered",
    "Wyvern Wingman",
  ];

  return (
    <Fragment>
      {(state.isRedirecting || state.loading) && (
        <Fragment>
          <Loader additionalClasses="mt-100"></Loader>
        </Fragment>
      )}

      {!state.isRedirecting && !state.loading && (
        <Fragment>
          <div className="text-center hero">
            <h1 className="mb-4" id="page-title">
              {state.title}
            </h1>
          </div>

          <div id="sourcing-container">
            <form
              className="base-form mb-20"
              id="sourcingModuleForm"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <OperatorAutocomplete
                handleChange={handleOperatorChange}
                defaultOperatorOptions={state.operatorOptions}
                parentDispatch={dispatch}
                selectedOperator={state.selectedOperator}
              ></OperatorAutocomplete>

              {state?.selectedOperator &&
                state?.selectedOperator?.composedScore > 0 && (
                  <div className="form-group">
                    <label>
                      {state?.selectedOperator?.operatorName}'s OPI:{" "}
                      <span className="text-evo-blue">
                        {state.selectedOperator?.composedScore}
                      </span>
                      . Include On Quote?
                    </label>
                    <Checkbox
                      checked={state.includeOPIOnQuote}
                      onChange={handleCheckboxChange}
                      name="includeOPIOnQuote"
                    />
                  </div>
                )}

              <AircraftAutocomplete
                handleChange={handleAircraftChange}
                defaultAircraftOptions={state.aircraftOptions}
                parentDispatch={dispatch}
                selectedAircraft={state.selectedAircraft}
              ></AircraftAutocomplete>

              {(state?.sourcingRecord?.leadData?.tripType === 2 ||
                state?.lead?.tripType === 2) && (
                <FormControl className="form-group base-radio-group">
                  <label id="which-leg-label">
                    Which Leg Does This Aircraft Correspond To?
                  </label>

                  <RadioGroup
                    aria-labelledby="which-leg-label"
                    // defaultValue=""
                    name="whichLeg"
                    value={state.whichLeg}
                    onChange={handleTextFieldChange}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Depart Leg Only"
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio />}
                      label="Return Leg Only"
                    />
                    <FormControlLabel
                      value="-1"
                      control={<Radio />}
                      label="Both Legs"
                    />
                  </RadioGroup>
                </FormControl>
              )}
              <TextInput
                name="aircraftSeats"
                label="Seats"
                handleChange={handleTextFieldChange}
                value={state.aircraftSeats}
                type="number"
                className="base-input"
              ></TextInput>

              <TextInput
                name="tailNumber"
                label="Tail #"
                handleChange={handleTextFieldChange}
                value={state.tailNumber}
                type="text"
              ></TextInput>

              <TextAreaInput
                name="notes"
                value={state.notes}
                handleChange={handleTextFieldChange}
                label="Notes"
                containerClasses="mb-0"
              ></TextAreaInput>

              <div className="form-group">
                <label>Include Notes on Quote</label>
                <Checkbox
                  checked={state.includeNotesOnQuote}
                  onChange={handleCheckboxChange}
                  name="includeNotesOnQuote"
                />
              </div>

              <div className="form-group">
                <label>YOM</label>

                <Select
                  className="base-select yom-select"
                  defaultValue={state.selectedYOM}
                  isClearable
                  // key={state.selectedYOM}
                  value={state.selectedYOM}
                  isSearchable
                  placeholder={"Select a Year"}
                  name="selectedYOM"
                  options={generateRangeOptions(1975, 2024)}
                  onChange={handleSelectChange}
                ></Select>
              </div>

              {state.yomIsRange && (
                <div className="form-group">
                  <label>YOM End</label>

                  <Select
                    className="base-select yom-select"
                    defaultValue={state.selectedYOMEnd}
                    isClearable
                    // key={state.selectedYOM}
                    value={state.selectedYOMEnd}
                    isSearchable
                    placeholder={"Select a Year"}
                    name="selectedYOMEnd"
                    options={generateRangeOptions(1975, 2023)}
                    onChange={handleSelectChange}
                  ></Select>
                </div>
              )}

              <div className="form-group">
                <label>Display YOM as a range?</label>
                <Checkbox
                  checked={state.yomIsRange}
                  onChange={handleCheckboxChange}
                  name="yomIsRange"
                />
              </div>

              <div className="form-group">
                <label>Interior Refurb</label>

                <Select
                  className="base-select inter-refurb-select"
                  defaultValue={state.selectedInteriorRefurbYear}
                  isClearable
                  value={state.selectedInteriorRefurbYear}
                  isSearchable
                  placeholder={"Select a Year"}
                  name="selectedInteriorRefurbYear"
                  options={generateRangeOptions(1990, 2024)}
                  onChange={handleSelectChange}
                ></Select>
              </div>

              {state.interiorRefurbYearIsRange && (
                <div className="form-group">
                  <label>Interior Refurb End</label>
                  <Select
                    className="base-select inter-refurb-select"
                    defaultValue={state.selectedInteriorRefurbYearEnd}
                    isClearable
                    value={state.selectedInteriorRefurbYearEnd}
                    isSearchable
                    placeholder={"Select a Year"}
                    name="selectedInteriorRefurbYearEnd"
                    options={generateRangeOptions(1990, 2024)}
                    onChange={handleSelectChange}
                  ></Select>
                </div>
              )}

              <div className="form-group">
                <label>Display Interior Refurb as a range?</label>
                <Checkbox
                  checked={state.interiorRefurbYearIsRange}
                  onChange={handleCheckboxChange}
                  name="interiorRefurbYearIsRange"
                />
              </div>

              <div className="form-group">
                <FormControl className="form-group base-radio-group">
                  <label id="yes-no-radio-group-label">Crew</label>

                  <RadioGroup
                    aria-labelledby="yes-no-radio-group-label"
                    defaultValue=""
                    name="crew"
                    value={state.crew}
                    onChange={handleTextFieldChange}
                  >
                    <FormControlLabel
                      value="Two Pilots"
                      control={<Radio />}
                      label="Two Pilots"
                    />
                    <FormControlLabel
                      value="Single-Pilot"
                      control={<Radio />}
                      label="Single-Pilot"
                    />
                    <FormControlLabel
                      value="Three Pilots"
                      control={<Radio />}
                      label="Three Pilots"
                    />
                  </RadioGroup>
                </FormControl>
              </div>

              <FormControl className="form-group base-radio-group">
                <label id="yes-no-radio-group-label">Owner Approval</label>

                <RadioGroup
                  aria-labelledby="yes-no-radio-group-label"
                  defaultValue="no"
                  name="ownerApproval"
                  value={state.ownerApproval}
                  onChange={handleTextFieldChange}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>

              <h4>PRICING</h4>

              <div className="form-group">
                <label>Operator Price</label>

                <NumberFormat
                  value={state.operatorPrice}
                  thousandSeparator={true}
                  prefix={"$"}
                  onValueChange={handlePriceFieldChange}
                  placeholder="Operator Price"
                  className="semibold-text"
                  decimalScale={2}
                  name="operatorPrice"
                />
              </div>

              <div className="form-group">
                <label>Gross Commission</label>

                <NumberFormat
                  value={state.grossCommission}
                  thousandSeparator={true}
                  prefix={"$"}
                  onValueChange={handlePriceFieldChange}
                  placeholder="Gross Commission"
                  className="semibold-text"
                  decimalScale={2}
                  name="grossCommission"
                />
              </div>

              <div className="form-group">
                <label>FET</label>

                <Select
                  className="base-select yom-select"
                  defaultValue={state.selectedFET}
                  isClearable
                  isSearchable
                  value={state.selectedFET}
                  placeholder={"Select an FET Option"}
                  name="selectedFET"
                  onChange={handleFETSelectChange}
                  options={[
                    {
                      label: "US Domestic",
                      value: "domestic",
                    },
                    {
                      label: "225mi. Zone",
                      value: "225zone",
                    },
                    {
                      label: "US International",
                      value: "usInternational",
                    },
                    {
                      label: "All International",
                      value: "allInternational",
                    },
                    {
                      label: "Custom",
                      value: "custom",
                    },
                    {
                      label: "Operator Collected",
                      value: "operatorCollected",
                    },
                    {
                      label: "Pass-through",
                      value: "passThrough",
                    },
                  ]}
                ></Select>
              </div>

              {state.showCustomFETField && (
                <div className="form-group">
                  <label>Custom FET</label>

                  <NumberFormat
                    value={state.FET}
                    thousandSeparator={true}
                    prefix={"$"}
                    onValueChange={handlePriceFieldChange}
                    placeholder="Custom FET"
                    className="semibold-text"
                    decimalScale={2}
                    name="FET"
                  />
                </div>
              )}

              <div className="form-group">
                <label>Segment Fees</label>

                <Select
                  className="base-select yom-select"
                  defaultValue={state.selectedSegmentFees}
                  isClearable
                  isSearchable
                  value={state.selectedSegmentFees}
                  placeholder={"Select a Segment Fee Option"}
                  name="selectedSegmentFees"
                  onChange={handleSegmentFeesSelectChange}
                  options={[
                    {
                      label: "US Domestic",
                      value: "domestic",
                    },
                    {
                      label: "225mi. Zone",
                      value: "225zone",
                    },
                    {
                      label: "US International",
                      value: "usInternational",
                    },
                    {
                      label: "All International",
                      value: "allInternational",
                    },
                    {
                      label: "Custom",
                      value: "custom",
                    },
                    {
                      label: "Operator Collected",
                      value: "operatorCollected",
                    },
                    {
                      label: "Pass-through",
                      value: "passThrough",
                    },
                  ]}
                ></Select>
              </div>

              {state.showCustomSegmentFeesField && (
                <div className="form-group">
                  <label>Custom Segment Fees</label>

                  <NumberFormat
                    value={state.segmentFees}
                    thousandSeparator={true}
                    prefix={"$"}
                    onValueChange={handlePriceFieldChange}
                    placeholder="Custom Segment Fees"
                    className="semibold-text"
                    decimalScale={2}
                    name="segmentFees"
                  />
                </div>
              )}

              <div className="form-group">
                <label>Referral Fees</label>

                <NumberFormat
                  value={state.referralFees}
                  thousandSeparator={true}
                  prefix={"$"}
                  onValueChange={handlePriceFieldChange}
                  placeholder="Referral Fees"
                  className="semibold-text"
                  decimalScale={2}
                  name="referralFees"
                />
              </div>

              <div className="form-group">
                <label>Discount</label>

                <NumberFormat
                  value={state.discount}
                  thousandSeparator={true}
                  prefix={"$"}
                  onValueChange={handlePriceFieldChange}
                  placeholder="Discount"
                  className="semibold-text"
                  decimalScale={2}
                  name="discount"
                />
              </div>

              <FormControl className="form-group base-radio-group">
                <label id="payment-type-label">Payment Type</label>

                <RadioGroup
                  aria-labelledby="payment-type-label"
                  name="paymentType"
                  value={state.paymentType}
                  onChange={handleTextFieldChange}
                >
                  <FormControlLabel
                    value="wire"
                    control={<Radio />}
                    label="Wire"
                  />
                  <FormControlLabel
                    value="cc"
                    control={<Radio />}
                    label="Credit Card"
                  />
                </RadioGroup>
              </FormControl>

              <OperatorCancellationPolicies
                operatorCancellationPolicies={
                  state.operatorCancellationPolicies
                }
                parentDispatch={dispatch}
                dusCancellationPolicy={state.dusCancellationPolicy}
              ></OperatorCancellationPolicies>

              <h4>DETAILS</h4>

              <div className="form-group">
                <label>Amenities</label>

                <ToggleButtonGroup
                  value={state.amenities}
                  onChange={handleAmenitiesChange}
                  aria-label="Amenities"
                  className={"amenities-toggle-list"}
                >
                  {amenitiesList.map((item) => {
                    return (
                      <ToggleButton key={item} value={item} aria-label={item}>
                        {item}
                      </ToggleButton>
                    );
                  })}
                </ToggleButtonGroup>
              </div>

              <div className="form-group">
                <label>Safety Standards</label>

                <ToggleButtonGroup
                  value={state.safetyStandards}
                  onChange={handleSafetyStandardsChange}
                  aria-label="Safety Standards"
                  className={"safety-standards-toggle-list base-toggle-list"}
                >
                  {safetyStandardsList.map((item) => {
                    return (
                      <ToggleButton key={item} value={item} aria-label={item}>
                        {item}
                      </ToggleButton>
                    );
                  })}
                </ToggleButtonGroup>
              </div>

              <div
                id="sourcing-submit-button-container"
                className="display-flex flex-row flex-wrap gap-20"
              >
                <button
                  className="mt-10 base-button evo-blue fg-1 p-12"
                  type="submit"
                  name="addAnother"
                  id="addAnother"
                  onClick={handleSubmit}
                >
                  Submit &amp; Add Another
                </button>
                <button
                  className="mt-10 base-button evo-gunmetal fg-1 p-12"
                  id="submitAndViewLead"
                  type="submit"
                  name="viewLead"
                  onClick={handleSubmit}
                >
                  Submit &amp; View Lead
                </button>
              </div>
            </form>

            <div id="breakdown">
              <div id="breakdown-child">
                <TableContainer
                  component={Paper}
                  className="mb-30"
                  sx={
                    {
                      // maxWidth: 500,
                      // marginLeft: "auto",
                      // marginRight: "auto",
                    }
                  }
                >
                  <Table aria-label="simple table" className="breakdown-table">
                    <TableHead>
                      <TableRow key={"cogs-header"}>
                        <TableCell component="th" colSpan={3} scope="row">
                          <h5>Cost of Goods</h5>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key={"operator-price"}>
                        <TableCell
                          component="th"
                          scope="row"
                          className="semibold-text"
                        >
                          Operator Price
                        </TableCell>
                        <TableCell align="right">
                          {state.operatorPrice
                            ? currencyFormatter.format(state.operatorPrice)
                            : "-"}
                        </TableCell>
                      </TableRow>

                      <TableRow key={"referral-fees"}>
                        <TableCell
                          component="th"
                          scope="row"
                          className="semibold-text black-bottom-border"
                        >
                          Referral Fees
                        </TableCell>
                        <TableCell
                          align="right"
                          className="black-bottom-border"
                        >
                          {state.referralFees
                            ? currencyFormatter.format(state.referralFees)
                            : "-"}
                        </TableCell>
                      </TableRow>

                      <TableRow key={"total"}>
                        <TableCell
                          component="th"
                          scope="row"
                          className="semibold-text total-cell-larger-text gunmetal-text"
                        >
                          TOTAL
                        </TableCell>
                        <TableCell
                          align="right"
                          className="semibold-text total-cell-larger-text gunmetal-text"
                        >
                          {state.totalCostOfGoods
                            ? currencyFormatter.format(state.totalCostOfGoods)
                            : "-"}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>

                <TableContainer
                  component={Paper}
                  className="mb-30"
                  sx={
                    {
                      // maxWidth: 500,
                      // marginLeft: "auto",
                      // marginRight: "auto",
                    }
                  }
                >
                  <Table aria-label="simple table" className="breakdown-table">
                    <TableHead>
                      <TableRow key={"cogs-header"}>
                        <TableCell component="th" colSpan={3} scope="row">
                          <h5>TAXES &amp; FEES</h5>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key={"FET"}>
                        <TableCell
                          component="th"
                          scope="row"
                          className="semibold-text"
                        >
                          FET{" "}
                          {state.selectedFET?.label
                            ? `(${state.selectedFET?.label})`
                            : ""}
                        </TableCell>
                        <TableCell align="right">
                          {state.FET
                            ? currencyFormatter.format(state.FET)
                            : "-"}
                        </TableCell>
                      </TableRow>

                      <TableRow key={"seg-fees"}>
                        <TableCell
                          component="th"
                          scope="row"
                          className="semibold-text black-bottom-border"
                        >
                          Seg. Fees{" "}
                          {state.selectedSegmentFees
                            ? `(${state.selectedSegmentFees?.label})`
                            : ""}
                        </TableCell>
                        <TableCell
                          align="right"
                          className="black-bottom-border"
                        >
                          {state.segmentFees
                            ? currencyFormatter.format(state.segmentFees)
                            : "-"}
                        </TableCell>
                      </TableRow>

                      <TableRow key={"total-taxes-fees"}>
                        <TableCell
                          component="th"
                          scope="row"
                          className="semibold-text total-cell-larger-text gunmetal-text"
                        >
                          TOTAL
                        </TableCell>
                        <TableCell
                          align="right"
                          className="semibold-text total-cell-larger-text gunmetal-text"
                        >
                          {state.totalTaxesAndFees
                            ? currencyFormatter.format(state.totalTaxesAndFees)
                            : "-"}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>

                <TableContainer
                  component={Paper}
                  className="mb-30"
                  sx={
                    {
                      // maxWidth: 500,
                      // marginLeft: "auto",
                      // marginRight: "auto",
                    }
                  }
                >
                  <Table aria-label="simple table" className="breakdown-table">
                    <TableHead>
                      <TableRow key={"cogs-header"}>
                        <TableCell component="th" colSpan={3} scope="row">
                          <h5>COMMISSION DETAILS</h5>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key={"gross-commission"}>
                        <TableCell
                          component="th"
                          scope="row"
                          className=" semibold-text"
                        >
                          Gross Commission
                        </TableCell>
                        <TableCell align="right" className="semibold-text">
                          {state.grossCommission
                            ? currencyFormatter.format(state.grossCommission)
                            : "-"}
                        </TableCell>
                      </TableRow>

                      {!!state.discount && (
                        <TableRow key={"discount"}>
                          <TableCell
                            component="th"
                            scope="row"
                            className="semibold-text"
                          >
                            Discount
                          </TableCell>
                          <TableCell align="right">
                            {state.discount
                              ? currencyFormatter.format(state.discount)
                              : "-"}
                          </TableCell>
                        </TableRow>
                      )}

                      <TableRow key={"service-fee"}>
                        <TableCell
                          component="th"
                          scope="row"
                          className="semibold-text"
                        >
                          Service Fee
                        </TableCell>
                        <TableCell align="right">
                          {state.serviceFee
                            ? currencyFormatter.format(state.serviceFee)
                            : "-"}
                        </TableCell>
                      </TableRow>

                      <TableRow key={"net-commission"}>
                        <TableCell
                          component="th"
                          scope="row"
                          className="semibold-text"
                        >
                          Net Commission
                        </TableCell>
                        <TableCell align="right" className="semibold-text">
                          {state.netCommission
                            ? currencyFormatter.format(state.netCommission)
                            : "-"}
                        </TableCell>
                      </TableRow>

                      <TableRow key={"commission-percentage"}>
                        <TableCell
                          component="th"
                          scope="row"
                          className="semibold-text"
                        >
                          Commission %
                        </TableCell>
                        <TableCell align="right" className="">
                          {state.commissionPercentage
                            ? `${state.commissionPercentage}%`
                            : "-"}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>

                <TableContainer
                  component={Paper}
                  className="mb-30"
                  sx={
                    {
                      // maxWidth: 500,
                      // marginLeft: "auto",
                      // marginRight: "auto",
                    }
                  }
                >
                  <Table aria-label="simple table" className="breakdown-table">
                    <TableHead>
                      <TableRow key={"payment-details-header"}>
                        <TableCell component="th" colSpan={3} scope="row">
                          <h5>Payment Details</h5>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key={"payment-type"}>
                        <TableCell
                          component="th"
                          scope="row"
                          className={`semibold-text ${
                            state.paymentType !== "cc" &&
                            !state.ccFee &&
                            "black-bottom-border"
                          }`}
                        >
                          Payment Type
                        </TableCell>

                        <TableCell
                          align="right"
                          className={`semibold-text ${
                            state.paymentType !== "cc" &&
                            !state.ccFee &&
                            "black-bottom-border"
                          }`}
                        >
                          {state.paymentType === "wire"
                            ? "Wire"
                            : state.paymentType === "cc"
                            ? "Credit Card"
                            : "-"}
                        </TableCell>
                      </TableRow>

                      <TableRow
                        key={"pre-cc-total"}
                        className={`${
                          state.paymentType === "cc" && state.ccFee
                            ? ""
                            : "hidden"
                        }`}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className="semibold-text "
                        >
                          Pre-Credit Card Fee Total
                        </TableCell>
                        <TableCell align="right" className="">
                          {currencyFormatter.format(
                            state.totalClientPayment - state.ccFee
                          )}
                        </TableCell>
                      </TableRow>

                      <TableRow
                        key={"ccFee"}
                        className={`${
                          state.paymentType === "cc" && state.ccFee
                            ? ""
                            : "hidden"
                        }`}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className="semibold-text black-bottom-border"
                        >
                          Credit Card Fee
                        </TableCell>
                        <TableCell
                          align="right"
                          className="black-bottom-border"
                        >
                          {currencyFormatter.format(state.ccFee)}
                        </TableCell>
                      </TableRow>

                      {/* {state.paymentType === "cc" && state.ccFee && (
                        <TableRow key={"ccFee"}>
                          <TableCell
                            component="th"
                            scope="row"
                            className="semibold-text black-bottom-border"
                          >Credit Card Fee
                          </TableCell>
                          <TableCell
                            align="right"
                            className="black-bottom-border"
                          >
                            {currencyFormatter.format(state.ccFee)}
                          </TableCell>
                        </TableRow>
                      )} */}

                      <TableRow key={"total-client-payment"}>
                        <TableCell
                          component="th"
                          scope="row"
                          className="semibold-text total-cell-larger-text gunmetal-text"
                        >
                          TOTAL CLIENT PAYMENT
                        </TableCell>
                        <TableCell
                          align="right"
                          className="semibold-text total-cell-larger-text gunmetal-text"
                        >
                          {state.totalClientPayment
                            ? currencyFormatter.format(state.totalClientPayment)
                            : ""}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>

                <TableContainer
                  component={Paper}
                  className="mb-30"
                  sx={
                    {
                      // maxWidth: 500,
                      // marginLeft: "auto",
                      // marginRight: "auto",
                    }
                  }
                >
                  <Table aria-label="simple table" className="breakdown-table">
                    <TableHead>
                      <TableRow key={"payment-details-header"}>
                        <TableCell component="th" colSpan={3} scope="row">
                          <h5>Contract Details</h5>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key={"payment-type"}>
                        <TableCell
                          component="th"
                          scope="row"
                          className={`semibold-text`}
                        >
                          Flight Charges
                        </TableCell>

                        <TableCell align="right" className={`semibold-text`}>
                          {currencyFormatter.format(
                            state.operatorPrice +
                              state.grossCommission +
                              state.referralFees
                          )}
                        </TableCell>
                      </TableRow>

                      {state.paymentType === "cc" && state.ccFee && (
                        <TableRow key={"ccFee"}>
                          <TableCell
                            component="th"
                            scope="row"
                            className="semibold-text "
                          >
                            Credit Card Fee
                          </TableCell>
                          <TableCell align="right" className="">
                            {currencyFormatter.format(state.ccFee)}
                          </TableCell>
                        </TableRow>
                      )}

                      {!!state.discount && (
                        <TableRow key={"discount"}>
                          <TableCell
                            component="th"
                            scope="row"
                            className="semibold-text"
                          >
                            Discount
                          </TableCell>
                          <TableCell align="right">
                            {state.discount
                              ? currencyFormatter.format(state.discount)
                              : "-"}
                          </TableCell>
                        </TableRow>
                      )}

                      <TableRow key={"FET"}>
                        <TableCell
                          component="th"
                          scope="row"
                          className="semibold-text"
                        >
                          Federal Excise Tax
                        </TableCell>
                        <TableCell align="right">
                          {state.FET
                            ? currencyFormatter.format(state.FET)
                            : "-"}
                        </TableCell>
                      </TableRow>

                      <TableRow key={"seg-fees"}>
                        <TableCell
                          component="th"
                          scope="row"
                          className="semibold-text black-bottom-border"
                        >
                          Segment Fees
                        </TableCell>
                        <TableCell
                          align="right"
                          className="black-bottom-border"
                        >
                          {state.segmentFees
                            ? currencyFormatter.format(state.segmentFees)
                            : "-"}
                        </TableCell>
                      </TableRow>

                      <TableRow key={"total-client-payment"}>
                        <TableCell
                          component="th"
                          scope="row"
                          className="semibold-text total-cell-larger-text gunmetal-text"
                        >
                          TOTAL CLIENT PAYMENT
                        </TableCell>
                        <TableCell
                          align="right"
                          className="semibold-text total-cell-larger-text gunmetal-text"
                        >
                          {state.totalClientPayment
                            ? currencyFormatter.format(state.totalClientPayment)
                            : ""}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
        </Fragment>
      )}

      {user &&
        user["https://app.evojets.com/roles"].includes("Super Admin") &&
        user?.sub === "auth0|5fe3c41e81637b00685cb5ff" && (
          <>
            {/* <JSONPretty id="json-pretty" data={user}></JSONPretty> */}
            <JSONPretty id="json-pretty" data={user}></JSONPretty>
          </>
        )}
    </Fragment>
  );
};

export default SourcingModuleContent;
