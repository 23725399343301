import React, { Fragment, useEffect, useReducer } from "react";
import { Link } from "react-router-dom";

import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";

import ai from "../../helpers/axios";
import * as Sentry from "@sentry/react";

import columns from "./flightLeg-columns";
import salesTeamColumns from "./flightLeg-columns-ae-sa";
import Search from "../search";
import DataTableServerPagination from "../data-table-server-pagination";

import PageHeaderRowSearch from "../shared/page-header-row-search";

const reducer = (state, action) => {
  switch (action.type) {
    case "search": {
      return {
        ...state,
        query: action.query,
      };
    }
    case "dataBeganDownloading": {
      return {
        ...state,
        loading: action.payload.loadingStatus,
      };
    }
    case "rowsDeleted": {
      return {
        ...state,
        clearSelectedRows: !state.clearSelectedRows,
        selectedRows: [],
      };
    }
    case "rowSelection": {
      return {
        ...state,
        selectedRows: action.payload.selectedRows,
        allSelected: action.payload.allSelected,
      };
    }
    case "dataDownloaded": {
      //   console.log("Data Downloaded", action.payload.data.data);
      return {
        ...state,
        flightLegs: action.payload.data.data,
        loading: false,
        totalLength: action.payload.data.count,
      };
    }
    case "pageChanged": {
      return {
        ...state,
        currentPage: action.payload.page,
      };
    }
    case "tableSort": {
      return {
        ...state,
        sortBy: action.payload.sortBy,
        sortDirection: action.payload.sortDirection,
      };
    }
    case "stageFilterChanged": {
      return {
        ...state,
        stageFilters: action.payload.values,
      };
    }
    case "rowsPerPageChanged": {
      return {
        ...state,
        countPerPage: action.payload.currentRowsPerPage,
      };
    }

    case "fieldChanged": {
      return {
        ...state,
        [action.field]: action.value,
      };
    }

    case "tableDataChanged": {
      return {
        ...state,
        flightLegs: action.value,
      };
    }

    default:
      break;
  }
  return state;
};

const FlightLegsContent = (props) => {
  const initialState = {
    flightLegs: {
      data: [],
    },
    loading: true,
    countPerPage: 25,
    currentPage: 1,
    selectedRows: [],
    allSelected: false,
    tagFilters: [],
    totalLength: null,
    clearSelectedRows: false,
    ownerInputField: "",
    selectedOwner: null,
    query: "",
    stageFilters: ["newLead", "inProgress"],
    sortDirection: "desc",
    sortBy: "originCreatedAt",
    ownerOptions: [],
    teamMembers: [],
    defaultStageFilters: { label: "New Lead", value: "newLead" },
  };

  if (props.prefilters && props.prefilters.length) {
    initialState.stageFilters = props.prefilters;
  }

  if (props.defaultStageFilters) {
    initialState.defaultStageFilters = props.defaultStageFilters;
  }

  if (props.qfsOnly) {
    initialState.defaultStageFilters = [];
    initialState.stageFilters = [];
  }

  const { user } = useAuth0();

  const userRoles = user && user["https://app.evojets.com/roles"];

  const superAdminOrAd = userRoles.some(function (role) {
    return "Super Admin" === role || "Account Director" === role;
  });

  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const [state, dispatch] = useReducer(reducer, initialState);

  console.log(state);

  const getData = async (
    dbQuery,
    loadSilently,
    stageFilters,
    sortBy,
    sortDirection
  ) => {
    let loadingStatus = true;
    if (loadSilently) {
      loadingStatus = false;
    }

    // console.log("props.showOnlyMine", props.showOnlyMine);

    dispatch({ type: "dataBeganDownloading", payload: { loadingStatus } });

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let response = await ai
      .auth(token)
      .get(`/api/flightLegs/list`, {
        params: {
          query: dbQuery,
          page: state.currentPage,
          countPerPage: state.countPerPage,
          stageFilters,

          sortBy,
          sortDirection,
          //   myLeads: props.showOnlyMine,
          //   qfsOnly: props.qfsOnly,
          //   myTeamLeads: props.showMyTeamLeads,
        },
      })
      .catch((error) => {
        console.error(error.response.data);
        Sentry.captureException(error);
      });

    if (response) {
      console.log("LEGS DOWNLOADED", response.data);
      dispatch({
        type: "dataDownloaded",
        payload: {
          data: response.data,
        },
      });
    }
  };

  useEffect(() => {
    getData(
      state.query,
      false,
      state.stageFilters,
      state.sortBy,
      state.sortDirection
    );
    // loadOwnerOptions();
    // getTeamMembers();
    document.title = `${
      props.pageTitle ? props.pageTitle : "Flight Matches"
    } | Flight Deck by evoJets`;
  }, [state.currentPage, state.countPerPage]);

  const handlePageChange = (page, totalRows) => {
    console.log(page, totalRows);

    dispatch({
      type: "pageChanged",
      payload: {
        page,
        totalRows,
      },
    });
  };

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    dispatch({
      type: "rowsPerPageChanged",
      payload: {
        currentRowsPerPage,
        currentPage,
      },
    });
  };

  const handleSelection = function (rowData) {
    dispatch({
      type: "rowSelection",
      payload: {
        selectedRows: rowData.selectedRows,
        allSelected: rowData.allSelected,
      },
    });
  };

  const handleDelete = async (e) => {
    e.preventDefault();

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let rowIds = state.selectedRows.map((item) => item._id);
    let { data } = await ai.auth(token).delete("/api/airports/deleteMany", {
      data: { ids: rowIds },
    });

    if (data.deletedCount) {
      getData("", true);

      toast.success("Lead Deleted Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      console.log("No Rows Deleted");
    }

    // state changes happen regardless if rows successfully deleted or not
    dispatch({
      type: "rowsDeleted",
    });
  };

  const handleSearch = (e) => {
    let query = e.target.value.toLowerCase();

    dispatch({ type: "search", query });
    getData(query, true, state.stageFilters, state.sortBy, state.sortDirection);
  };

  const loadOwnerOptions = async () => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });
    console.log("Leads Content");
    let { data } = await ai.auth(token).get(`/api/users/roles/sales`, {});

    if (data.users.length >= 1) {
      console.log(data.users);
      let results = data.users.map((user) => {
        return { value: user.user_id, label: user.name, email: user.email };
      });

      dispatch({ type: "fieldChanged", field: "ownerOptions", value: results });

      return results;
    } else {
      return [];
    }
  };

  const handleOwnerInputChange = (newValue) => {
    dispatch({
      type: "fieldChanged",
      field: "ownerInputField",
      value: newValue,
    });
    return newValue;
  };

  //   const handleOwnerSelection = async (selectedOwner) => {
  //     dispatch({
  //       type: "fieldChanged",
  //       field: "selectedOwner",
  //       value: selectedOwner,
  //     });

  //     console.log(selectedOwner);

  //     const token = await getAccessTokenSilently().catch((e) => {
  //       console.error(e);
  //       loginWithRedirect();
  //     });

  //     // hit api endpoint to update assignment -leads

  //     let leadIds = state.selectedRows.map((row) => row._id);

  //     let { data } = await ai
  //       .auth(token)
  //       .put(`/api/leads/bulkAssign`, {
  //         leadIds: leadIds,
  //         assigneeId: selectedOwner.value,
  //       })
  //       .catch((e) => console.error(e));

  //     if (data) {
  //       console.log(data);

  //       getData(
  //         state.query,
  //         true,
  //         state.stageFilters,
  //         state.sortBy,
  //         state.sortDirection
  //       );

  //       dispatch({
  //         type: "fieldChanged",
  //         field: "selectedRows",
  //         value: [],
  //       });

  //       dispatch({
  //         type: "fieldChanged",
  //         field: "clearSelectedRows",
  //         value: !state.clearSelectedRows,
  //       });

  //       dispatch({
  //         type: "fieldChanged",
  //         field: "selectedOwner",
  //         value: null,
  //       });

  //       return data;
  //     }

  //     return selectedOwner;
  //   };

  //   const preSelectCriteria = (row) => {
  //     let ids = state.selectedRows.map((item) => item._id);
  //     return ids.includes(row._id);
  //   };

  //   const handleLeadStageUpdate = async (e) => {
  //     e.preventDefault();

  //     let buttonId = e.currentTarget.id;
  //     let leadIds = state.selectedRows.map((row) => row._id);
  //     let stage = "newLead";

  //     if (buttonId === "in-progress-button") {
  //       stage = "inProgress";
  //     } else if (buttonId === "archive-button") {
  //       stage = "archived";
  //     } else if (buttonId === "warm-prospect-button") {
  //       stage = "warmProspect";
  //     } else if (buttonId === "unqualified-button") {
  //       stage = "unqualified";
  //     }

  //     console.log(buttonId, leadIds);

  //     const token = await getAccessTokenSilently().catch((e) => {
  //       console.error(e);
  //       loginWithRedirect();
  //     });

  //     let { data } = await ai
  //       .auth(token)
  //       .put(`/api/leads/bulkUpdateStages`, {
  //         leadIds,
  //         stage,
  //       })
  //       .catch((e) => console.error(e));

  //     if (data) {
  //       console.log(data);

  //       getData(
  //         state.query,
  //         true,
  //         state.stageFilters,
  //         state.sortBy,
  //         state.sortDirection
  //       );

  //       dispatch({
  //         type: "fieldChanged",
  //         field: "selectedRows",
  //         value: [],
  //       });

  //       dispatch({
  //         type: "fieldChanged",
  //         field: "clearSelectedRows",
  //         value: !state.clearSelectedRows,
  //       });

  //       dispatch({
  //         type: "fieldChanged",
  //         field: "selectedOwner",
  //         value: null,
  //       });

  //       return data;
  //     }
  //   };

  //   const disableAssignmentButtons = () => {
  //     let disabled = "disabled";

  //     if (state.selectedRows.length) {
  //       let someRowsAreNotAssigned = state.selectedRows.some(
  //         (row) => row.assigneeId === undefined
  //       );
  //       if (!someRowsAreNotAssigned) {
  //         disabled = "";
  //       }
  //     }

  //     return disabled;
  //   };

  //   const disableAssignmentSelect = () => {
  //     let disabled = true;

  //     if (state.selectedRows.length) {
  //       disabled = false;
  //     }

  //     return disabled;
  //   };

  //   const stageFilterOptions = [
  //     { label: "New Lead", value: "newLead" },
  //     { label: "In Progress", value: "inProgress" },
  //     { label: "Warm Prospect", value: "warmProspect" },
  //     { label: "Unqualified", value: "unqualified" },
  //     { label: "Archived", value: "archived" },
  //   ];

  //   const getTeamMembers = async () => {
  //     const token = await getAccessTokenSilently().catch((e) => {
  //       console.error(e);
  //       loginWithRedirect();
  //     });

  //     let { data } = await ai.auth(token).get(`/api/users/getTeamMembers`);

  //     if (data.length) {
  //       data = data.map((member) => member.user_id);
  //     }

  //     dispatch({
  //       type: "fieldChanged",
  //       field: "teamMembers",
  //       value: data,
  //     });
  //   };

  //   const handleStageFilterChange = (selectedValues, action) => {
  //     console.log(selectedValues, action);
  //     let values = [];
  //     if (selectedValues) {
  //       values = selectedValues.map(({ value }) => value);
  //     }

  //     dispatch({ type: "stageFilterChanged", payload: { values } });

  //     getData(state.query, true, values, state.sortBy, state.sortDirection);
  //   };

  const handleSort = (column, sortDirection) => {
    console.log("sorted", column.selector, sortDirection);

    dispatch({
      type: "tableSort",
      payload: {
        sortBy: column.selector,
        sortDirection,
      },
    });

    getData(
      state.query,
      true,
      state.stageFilters,
      column.selector,
      sortDirection
    );

    //keyField is required for server sort
  };

  const onRowClicked = (row, event) => {
    // check if row id is inside selectedRows array
    let arr = [...state.selectedRows];
    arr = arr.filter((selectedRow) => selectedRow._id === row._id);

    if (arr.length) {
      // row is already in there...uncheck it?

      let newSelectedRowsArr = [...state.selectedRows];

      newSelectedRowsArr = newSelectedRowsArr.filter(
        (selectedRow) => selectedRow._id !== row._id
      );

      // console.log("NEW SELECTED ROWS ARRAY, ITEM EXISTS", newSelectedRowsArr);

      dispatch({
        type: "fieldChanged",
        field: "selectedRows",
        value: newSelectedRowsArr,
      });

      let newData = [...state.flightLegs];

      dispatch({ type: "tableDataChanged", value: newData });

      return;
    } else {
      //push row into selectedRows
      let currentSelectedRowsArray = [...state.selectedRows];
      currentSelectedRowsArray.push(row);

      // console.log(
      //   "NEW SELECTED ROWS ARRAY, ITEM DOES NOT EXIST",
      //   currentSelectedRowsArray
      // );

      dispatch({
        type: "fieldChanged",
        field: "selectedRows",
        value: currentSelectedRowsArray,
      });

      let newData = [...state.flightLegs];
      dispatch({ type: "tableDataChanged", value: newData });

      // console.log("TABLE DATA", newData);

      return;
    }
  };

  const preSelectCriteria = (row) => {
    let selectedRows = [...state.selectedRows];

    selectedRows = selectedRows.filter(
      (selectedRow) => selectedRow._id === row._id
    );

    console.log("SELECTED ROWS", selectedRows.length);

    // let value = { ...state.leads };

    // dispatch({ type: "fieldChanged", field: "leads", value: value });

    return selectedRows.length;
  };

  return (
    <Fragment>
      {/* <pre>{JSON.stringify(state.query, null, 2)}</pre> */}

      {/* <pre>{JSON.stringify(state.flightLegs, null, 2)}</pre> */}

      <PageHeaderRowSearch
        h1={`${props.pageTitle ? props.pageTitle : "Flight Matches"}`}
        pretitle="Business"
        breadcrumbs={[{ link: "/flight-matches", label: "Flight Matches" }]}
        rightColumn={
          <div id="quote-actions-row">
            <Search
              onSearch={handleSearch}
              placeholder="Search Flight Matches"
              query={state.query}
              className="lead-search"
            ></Search>
          </div>
        }
      ></PageHeaderRowSearch>

      <DataTableServerPagination
        onSelection={handleSelection}
        key={JSON.stringify(columns)}
        columns={superAdminOrAd ? columns : salesTeamColumns}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
        state={state}
        data={state.flightLegs}
        defaultSortField={"auditTrail"}
        defaultSortAsc={false}
        selectableRowSelected={preSelectCriteria}
        onRowClicked={onRowClicked}
        handleSort={handleSort}
        disableSelection={true}
        sortServer={true}
        persistTableHead={true}
      ></DataTableServerPagination>

      {/* <pre>{JSON.stringify(state.flightLegs.data, null, 2)}</pre> */}
    </Fragment>
  );
};

export default FlightLegsContent;
