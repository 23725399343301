import React, { Fragment } from "react";
import NewAirportAutocomplete from "./new-airport-autocomplete";
import DatePicker from "react-date-picker";
import Select from "react-select";
import AsyncSelect from "react-select/async";

const AddFlightForm = (props) => {
  return (
    <Fragment>
      <form id="addFlight" onSubmit={props.handleSubmit}>
        <h2 id="addFlightHeading" className="centered">
          Add Flight
        </h2>
        <div className="form-group">
          <label>Departure Airport</label>

          <AsyncSelect
            className="departure-airport-select"
            classNamePrefix="select"
            cacheOptions
            loadOptions={props.loadAirportOptions}
            // defaultValue={props.state.selectedAircraft}
            placeholder="Search Airports"
            // onInputChange={props.handleDepartureInputChange}
            onChange={props.handleDepartureChange}
          ></AsyncSelect>
        </div>

        <div className="form-group">
          <label>Arrival Airport</label>

          <AsyncSelect
            className="arrival-airport-select"
            classNamePrefix="select"
            cacheOptions
            loadOptions={props.loadAirportOptions}
            // defaultValue={props.state.selectedAircraft}
            placeholder="Search Airports"
            // onInputChange={props.handleDepartureInputChange}
            onChange={props.handleArrivalChange}
          ></AsyncSelect>
        </div>

        {/* <div className="form-group">
          <label>Departure Airport</label>

          <NewAirportAutocomplete
            id="departure-airport"
            placeholder="Departure Airport"
            handleChange={props.handleDepartureChange}
            defaultSelected={""}
          ></NewAirportAutocomplete>
        </div>

        <div className="form-group">
          <label>Arrival Airport</label>

          <NewAirportAutocomplete
            id="arrival-airport"
            placeholder="Arrival Airport"
            handleChange={props.handleArrivalChange}
            defaultSelected={""}
          ></NewAirportAutocomplete>
        </div> */}

        <div className="form-group">
          <label>Trip Type</label>

          <select
            id="tripType"
            value={props.state.tripType}
            onChange={(e) =>
              props.dispatch({
                type: "tripTypeChanged",
                payload: {
                  tripType: parseInt(e.currentTarget.value),
                },
              })
            }
            name="tripType"
            required
          >
            <option key="placeholder" value={0} hidden>
              Trip Type
            </option>
            <option key="oneWay" value={1}>
              One-Way
            </option>
            <option key="roundTrip" value={2}>
              Round Trip
            </option>
          </select>
        </div>

        <div className="form-group">
          <label>Departure Date</label>

          <DatePicker
            name="departureDate"
            onChange={(date) =>
              props.dispatch({
                type: "fieldChanged",
                field: "departureDate",
                value: date,
              })
            }
            value={props.state.departureDate}
          ></DatePicker>
        </div>

        {props.state.tripType === 2 && (
          <div className="form-group">
            <label>Return Date</label>

            <DatePicker
              name="returnDate"
              onChange={(date) =>
                props.dispatch({
                  type: "fieldChanged",
                  field: "returnDate",
                  value: date,
                })
              }
              value={props.state.returnDate}
            ></DatePicker>
          </div>
        )}

        <div className="form-group">
          <label>Aircraft</label>

          <AsyncSelect
            className="aircraft-select"
            classNamePrefix="select"
            cacheOptions
            isMulti
            loadOptions={props.loadAircraftOptions}
            defaultOptions
            placeholder="Search Aircraft"
            onInputChange={props.handleAircraftInputChange}
            onChange={props.handleAircraftSelection}
          ></AsyncSelect>
        </div>

        <div className="form-group containsNumberInput">
          <label htmlFor="passengers">Passengers</label>
          <input
            type="number"
            name="passengers"
            placeholder="Passengers"
            // onChange={(e) =>
            //   dispatch({
            //     type: "fieldChanged",
            //     field: e.target.name,
            //     value: e.target.value,
            //     fieldType: e.target.type,
            //   })
            // }
            onChange={(e) =>
              props.dispatch({
                type: "fieldChanged",
                field: "passengers",
                value: parseInt(e.target.value),
              })
            }
            value={props.state.passengers}
          ></input>
        </div>

        <div className="form-group">
          <label>Owner</label>

          <Select
            key={`owner-options-key-${props.ownerOptions}`}
            className="owner-select"
            classNamePrefix="select"
            options={props.ownerOptions}
            defaultValue={props.initialTags}
            placeholder="Assign Flight"
            onChange={props.handleOwnerSelection}
          ></Select>
        </div>

        {/* <Select
          className="basic-multi-select"
          classNamePrefix="select"
          isMulti
          options={props.tagOptions}
          defaultValue={props.initialTags}
          placeholder="Flight Tags"
          onChange={props.handleTagChange}
        ></Select> */}

        <div className="form-group">
          <label>Priority</label>

          <select
            id="flightPriority"
            value={props.state.flightPriority}
            onChange={(e) =>
              props.dispatch({
                type: "fieldChanged",
                field: "flightPriority",
                value: e.currentTarget.value,
              })
            }
            name="flightPriority"
            required
          >
            <option key="placeholder" value={0} hidden>
              Flight Priority
            </option>
            <option key="evoEmptyLeg" value={1}>
              Evo Empty Leg
            </option>
            <option key="signedContract" value={2}>
              Signed Contract
            </option>
            <option key="repeatClient" value={3}>
              Repeat Client Request
            </option>
            <option key="newClient" value={4}>
              New Client Request
            </option>
          </select>
        </div>

        <div className="form-group">
          <label>Notes</label>

          <textarea
            value={props.state.flightNotes}
            id="flightNotes"
            placeholder="Notes"
            onChange={(e) =>
              props.dispatch({
                type: "fieldChanged",
                field: "flightNotes",
                value: e.currentTarget.value,
              })
            }
          ></textarea>
        </div>
        <input type="submit" value="Add Flight"></input>
      </form>
    </Fragment>
  );
};

export default AddFlightForm;
