import React, { Fragment, useReducer, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import ai from "../../helpers/axios";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";

// import Select from "react-select";

import { useHistory, useLocation } from "react-router-dom";

import TextInput from "../text-input";

import "react-json-pretty/themes/monikai.css";
import JSONPretty from "react-json-pretty";

import * as Sentry from "@sentry/react";

let initialState = {
  defaultOptions: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "fieldChanged": {
      let value =
        action.fieldType === "number" ? parseInt(action.value) : action.value;

      return {
        ...state,
        [action.field]: value,
      };
    }

    case "resetState": {
      return {
        ...initialState,
      };
    }

    default:
      break;
  }
  return state;
};

const FBOAutocomplete = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();

  const { getAccessTokenSilently, user, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (props.airports?.length === 1) {
      let codes = props.airports?.map((airport) => airport.code);
      if (codes?.length) {
        loadInitialOptions(codes[0]);
      }
    }
  }, []);

  const loadFBOOptions = async (inputValue) => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });
    let { data } = await ai.auth(token).get(`/api/trips/fbos/search`, {
      params: {
        q: inputValue,
        airports: props.airports,
      },
    });

    console.log("FBO DATA", data);

    if (data?.length > 0) {
      let options = data.map((fbo) => {
        return {
          value: fbo._id,
          label: `${fbo.longName} - ${fbo.icao}`,
          fboData: fbo,
        };
      });

      return options;
    } else {
      return [];
    }
  };

  const loadInitialOptions = async (inputValue) => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });
    let { data } = await ai.auth(token).get(`/api/trips/fbos/search`, {
      params: {
        q: inputValue,
        airports: props.airports,
      },
    });

    if (data?.length > 0) {
      let options = data.map((fbo) => {
        return {
          value: fbo._id,
          label: `${fbo.longName} - ${fbo.icao}`,
          fboData: fbo,
        };
      });

      dispatch({
        type: "fieldChanged",
        field: "defaultOptions",
        value: options,
      });
    } else {
      return [];
    }
  };

  // console.log(props.label, props.selectedFBO);

  return (
    <div className="form-group">
      <label>{props.label || "FBO"}</label>

      {props.allowCreate && (
        <AsyncCreatableSelect
          className="fbo-select"
          classNamePrefix="select"
          loadOptions={loadFBOOptions}
          placeholder={props.placeholder || "Select an FBO"}
          onChange={props.handleChange}
          isClearable={true}
          defaultOptions={state?.defaultOptions}
          value={props.selectedFBO}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          key={`selected-fbo-${props.selectedFBO?.value}`}
          onCreateOption={props.handleCreateOption}
        ></AsyncCreatableSelect>
      )}

      {!props.allowCreate && (
        <AsyncSelect
          className="fbo-select"
          classNamePrefix="select"
          loadOptions={loadFBOOptions}
          placeholder={props.placeholder || "Select an FBO"}
          onChange={props.handleChange}
          isClearable={true}
          defaultOptions={state?.defaultOptions}
          value={props.selectedFBO}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          key={`selected-fbo-${props.selectedFBO?.value}`}
        ></AsyncSelect>
      )}
    </div>
  );
};

export default FBOAutocomplete;
