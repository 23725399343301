import React from "react";
import DeleteButton from "./delete-button";
import Select from "react-select";
import { useLocation } from "react-router-dom";

const TableBulkActions = (props) => {
  const selectedRows = props.state.selectedRows;
  let deleteButtonText = "Archive";
  let deleteButtonStatus = "disabled";
  if (selectedRows.length) {
    if (selectedRows.length === 1) {
      deleteButtonText = `Archive ${selectedRows.length} Row`;
    } else {
      deleteButtonText = `Archive ${selectedRows.length} Rows`;
    }
    deleteButtonStatus = "";
  }

  // const { pathname } = useLocation();

  return (
    <div
      id="table-bulk-actions"
      // className={pathname === "/" ? "homepage" : pathname.slice(1)}
    >
      {!props.hideTags && (
        <Select
          className="basic-multi-select"
          classNamePrefix="select"
          isMulti
          options={props.tagOptions}
          placeholder="Filter By Priority"
          onChange={props.handleTagFilterChange}
        ></Select>
      )}

      <DeleteButton
        buttonText={deleteButtonText}
        buttonStatus={deleteButtonStatus}
        selectedRows={selectedRows}
        handleDelete={props.handleDelete}
        alignment={props.deleteBtnAlignment}
      ></DeleteButton>
    </div>
  );
};

export default TableBulkActions;
