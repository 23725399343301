import { Link } from "react-router-dom";
import moment from "moment";

const columns = [
  {
    name: "View Quote",
    sortable: false,
    cell: (row) => {
      return <Link to={`/quotes/${row._id}`}>View</Link>;
    },
    width: "70px",
  },
  {
    name: "Edit",
    sortable: false,
    cell: (row) => {
      return <Link to={`/quotes/${row._id}/edit`}>Edit</Link>;
    },
    width: "70px",
  },
  {
    name: "Lead Details",
    sortable: false,
    cell: (row) => {
      return <Link to={`/leads/${row.leadId}`}>View Details</Link>;
    },
    width: "130px",
  },
  {
    name: "Created At",
    selector: "createdAt",
    sortable: true,
    width: "250px",
    cell: (row) => {
      return moment(row.createdAt).format("llll");
    },
  },
  {
    name: "Assigned To",
    selector: "leadData.assigneeName",
    // cell: (row) => row.status.charAt(0).toUpperCase() + row.status.slice(1),
    sortable: true,
    width: "150px",
  },
  {
    name: "Sent Status",
    selector: "liveQuoteLog",
    cell: (row) =>
      row.liveQuoteLog.length
        ? moment(
            new Date(row.liveQuoteLog[row.liveQuoteLog.length - 1].timeStamp)
          ).format("llll")
        : "Not Sent",
    sortable: true,
    width: "200px",
  },

  {
    name: "First Name",
    selector: "firstName",
    sortable: true,
    width: "150px",
  },
  {
    name: "Last Name",
    selector: "lastName",
    sortable: true,
    width: "150px",
  },
  {
    name: "Email",
    selector: "email",
    sortable: true,
    width: "150px",
  },
  {
    name: "Departure",
    selector: "departure",
    sortable: true,
    cell: (row) => {
      return row.legs[0].departureAirport.city;
    },
    width: "190px",
  },
  {
    name: "Arrival",
    selector: "arrival",
    sortable: true,
    cell: (row) => {
      return row.legs[0].arrivalAirport.city;
    },
    width: "140px",
  },

  {
    name: "Legs",
    selector: "legs",
    sortable: true,
    width: "80px",
    cell: (row) => row.legs.length,
  },
];

export default columns;
