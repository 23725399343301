import React, { Fragment, useReducer, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import ai from "../../helpers/axios";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";

// import Select from "react-select";

import { useHistory, useLocation } from "react-router-dom";

import TextInput from "../text-input";

import "react-json-pretty/themes/monikai.css";
import JSONPretty from "react-json-pretty";

import * as Sentry from "@sentry/react";

let initialState = {
  // prospectOptions: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "fieldChanged": {
      let value =
        action.fieldType === "number" ? parseInt(action.value) : action.value;

      return {
        ...state,
        [action.field]: value,
      };
    }

    case "resetState": {
      return {
        ...initialState,
      };
    }

    default:
      break;
  }
  return state;
};

const ProspectsAutocomplete = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();

  const { getAccessTokenSilently, user, loginWithRedirect } = useAuth0();

  useEffect(() => {}, []);

  const loadEvoProspects = async (inputValue) => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });
    let { data } = await ai
      .auth(token)
      .get(`/api/prospects/search?q=${inputValue}`);

    let options = [];
    if (data && data?.prospects?.length) {
      options = data.prospects.flatMap((i) => {
        if (!i.firstAndLastName && !i.lastName && !i.firstName) {
          return [];
        }

        let label = `${i.firstAndLastName} - ${i.emails[0]}`;

        if (!i.firstAndLastName) {
          if (!i.firstName) {
            i.firstName = "";
          }

          if (!i.lastName) {
            i.lastName = "";
          }

          label = `${i.firstName} ${i.lastName} - ${i.emails[0]}`;
        }

        if (i.companyName) {
          label = `${label} - ${i.companyName}`;
        }

        if (i.assigneeName && i.assigneeId) {
          label = `${label} - ${i.assigneeName}`;
        }

        // console.log(i.firstAndLastName, i);

        let phone = "";

        if (i?.phoneNumbers?.length) {
          phone = i.phoneNumbers[0]?.number;
        }

        let countryCode = "";

        if (i?.phoneNumbers?.length) {
          countryCode = i.phoneNumbers[0]?.countryCode;
        }

        return {
          value: i._id,
          label,
          emails: i.emails,
          email: i.emails[0],
          phone,
          orProspectId: i.orProspectId,
          companyName: i.companyName,
          phoneNumbers: i.phoneNumbers,
          countryCode,
          firstName: i.firstName,
          lastName: i.lastName,
          ownerName: i.assigneeName,
          ownerId: i.assigneeId,
        };
      });
    }

    props.parentDispatch({
      type: "fieldChanged",
      field: "prospectOptions",
      value: options,
    });

    return options;
  };

  return (
    <div className="form-group">
      <label>
        {props.label || "Prospect"}{" "}
        {props.required ? <span className="required">*</span> : ``}
      </label>

      {/* <AsyncSelect
        className="outreach-prospect-select"
        classNamePrefix="select"
        loadOptions={loadEvoProspects}
        placeholder="Select a Prospect"
        onChange={props.handleProspectChange}
        isClearable={true}
        cacheOptions
      ></AsyncSelect> */}

      <AsyncCreatableSelect
        className="outreach-prospect-select"
        classNamePrefix="select"
        loadOptions={loadEvoProspects}
        placeholder={props.placeholder || "Select a Prospect"}
        onChange={props.handleProspectChange}
        isClearable={true}
        cacheOptions
        defaultOptions={props.prospectOptions}
        onCreateOption={props.onCreateOption}
        allowCreateWhileLoading={false}
        value={props.selectedProspect}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      ></AsyncCreatableSelect>
    </div>
  );
};

export default ProspectsAutocomplete;
