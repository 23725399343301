import React from "react";

const TextAreaInput = (props) => {
  return (
    <div
      className={`form-group ${
        props.containerClasses ? props.containerClasses : ""
      }`}
    >
      {!props.hideLabel && <label htmlFor={props.name}>{props.label}</label>}
      <textarea
        // key={props.value}
        type={props.type || "text"}
        name={props.name}
        value={props.value}
        placeholder={props.placeholder || props.label}
        onChange={props.handleChange}
        className={`${props.className} standardTextArea`}
      ></textarea>
    </div>
  );
};

export default TextAreaInput;
