import React, { Fragment, useEffect, useReducer } from "react";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams, useHistory } from "react-router-dom";

import ai from "../helpers/axios";
import { flightTagOptions as tagOptions } from "../helpers/tag-options";

import EditFlightForm from "./edit-flight-form";

const reducer = (state, action) => {
  switch (action.type) {
    case "dataDownloaded": {
      return {
        ...state,
        currentFlight: {
          ...state.currentFlight,
          data: action.payload.data,
        },
        flightNotes: action.payload.data.flightNotes,
        passengers: parseInt(action.payload.data.passengers),
        tripType: action.payload.data.tripType,
        flightPriority: action.payload.data.flightPriority,
        departureDate: new Date(action.payload.data.departureDate),
        returnDate: action.payload.data.returnDate
          ? new Date(action.payload.data.returnDate)
          : null,
        selectedAircraft:
          action.payload.data.aircraft.length >= 1
            ? action.payload.data.aircraft.map((aircraft) => {
                return {
                  label: aircraft.aircraftName,
                  value: aircraft.aircraftId,
                };
              })
            : [],
        selectedOwner: {
          value: action.payload.data.owner.ownerId,
          label: action.payload.data.owner.ownerName,
          email: action.payload.data.owner.ownerEmail,
        },
        userOptions: action.payload.userOptions,
        departureAirportInput: {
          label: action.payload.data.departureAirport.airportName,
          value: action.payload.data.departureAirport.id,
          code: action.payload.data.departureAirport.code,
        },
        arrivalAirportInput: {
          label: action.payload.data.arrivalAirport.airportName,
          value: action.payload.data.arrivalAirport.id,
          code: action.payload.data.arrivalAirport.code,
        },
        departureAirport: {
          airportName: action.payload.data.departureAirport.airportName,
          id: action.payload.data.departureAirport.id,
          code: action.payload.data.departureAirport.code,
        },
        arrivalAirport: {
          airportName: action.payload.data.arrivalAirport.airportName,
          id: action.payload.data.arrivalAirport.id,
          code: action.payload.data.arrivalAirport.code,
        },
        loading: false,
      };
    }
    case "fieldChanged": {
      return {
        ...state,
        [action.field]: action.value,
      };
    }
    case "tripTypeChanged": {
      return {
        ...state,
        tripType: action.payload.tripType,
        returnDate: action.payload.tripType === 1 ? null : state.returnDate,
      };
    }
    case "airportChange": {
      return {
        ...state,
        [action.airportType]: action.payload,
      };
    }
    default:
      break;
  }
  return state;
};

const initialState = {
  currentFlight: {
    data: [],
  },
  loading: true,
  flightNotes: "",
  tripType: 1,
  flightPriority: 0,
  departureDate: "",
  returnDate: "",
  aircraftInputField: "",
  ownerInputField: "",
  selectedAircraft: [],
  selectedOwner: "",
  userOptions: [],
  passengers: "",
  departureAirport: {
    airportName: "",
    id: "",
    code: "",
  },
  arrivalAirport: {
    airportName: "",
    id: "",
    code: "",
  },
  departureAirportInput: {
    label: "",
    value: "",
    code: "",
  },
  arrivalAirportInput: {
    label: "",
    value: "",
    code: "",
  },
};

const EditFlightContent = () => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  let { id } = useParams();
  const history = useHistory();

  let ip =
    Math.floor(Math.random() * 255) +
    1 +
    "." +
    (Math.floor(Math.random() * 255) + 0) +
    "." +
    (Math.floor(Math.random() * 255) + 0) +
    "." +
    (Math.floor(Math.random() * 255) + 0);

  const [state, dispatch] = useReducer(reducer, initialState);

  const getData = async () => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });
    let { data: flightData } = await ai.auth(token).get(`/api/flights/${id}`);
    let { data: userOptionsData } = await ai
      .auth(token)
      .get(`/api/users/roles/sales`);
    let userOptions = [];
    if (userOptionsData.users.length >= 1) {
      userOptions = userOptionsData.users.map((user) => {
        return { value: user.user_id, label: user.name, email: user.email };
      });
    }

    dispatch({
      type: "dataDownloaded",
      payload: {
        data: flightData,
        userOptions: userOptions,
      },
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleDepartureChange = (selectedAirport) => {
    dispatch({
      type: "airportChange",
      airportType: "departureAirport",
      payload: {
        airportName: selectedAirport?.label || "",
        id: selectedAirport?.value || "",
        code: selectedAirport?.code || "",
      },
    });
  };

  const handleArrivalChange = (selectedAirport) => {
    dispatch({
      type: "airportChange",
      airportType: "arrivalAirport",
      payload: {
        airportName: selectedAirport?.label || "",
        id: selectedAirport?.value || "",
        code: selectedAirport?.code || "",
      },
    });
  };

  const loadAirportOptions = async (inputValue) => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });
    let { data } = await ai
      .auth(token)
      .get(`/api/airports/search?q=${inputValue}&ip_address=${ip}`);

    let options = [];
    if (data.length >= 1) {
      options = data.map((i) => ({
        value: i._id,
        label: i.airportName,
        code: i.code,
      }));
    }

    return options;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let fieldsFilled =
      state.departureAirport.airportName &&
      state.departureAirport.code &&
      state.departureAirport.id &&
      state.arrivalAirport.airportName &&
      state.arrivalAirport.code &&
      state.arrivalAirport.id &&
      state.departureDate &&
      state.flightPriority &&
      state.tripType &&
      state.selectedAircraft.length >= 1 &&
      state.selectedOwner.value &&
      state.selectedOwner.label &&
      state.selectedOwner.email;

    if (!fieldsFilled) {
      alert("Please fill in all fields.");
      return;
    }

    if (state.tripType === 2) {
      if (!state.returnDate) {
        alert("Please set a return date.");
        return;
      }
    }

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let formattedAircraft = state.selectedAircraft.map((aircraft) => {
      return {
        aircraftName: aircraft.label,
        aircraftId: aircraft.value,
      };
    });

    let formData = {
      departureAirport: state.departureAirport,
      arrivalAirport: state.arrivalAirport,
      flightNotes: state.flightNotes,
      tripType: state.tripType,
      flightPriority: state.flightPriority,
      departureDate: state.departureDate,
      aircraft: formattedAircraft,
      owner: {
        ownerId: state.selectedOwner.value,
        ownerName: state.selectedOwner.label,
        ownerEmail: state.selectedOwner.email,
      },
      passengers: parseInt(state.passengers),
    };

    console.log("form data", formData);

    if (state.tripType === 2) {
      formData.returnDate = state.returnDate;
    }

    try {
      let { data } = await ai.auth(token).put(`/api/flights/${id}`, formData);
      console.log(data);

      history.push({ pathname: "/" });

      setTimeout(() => {
        toast.success("Flight Updated Successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }, 50);
    } catch (error) {
      toast.error("There was an error adding your flight.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      console.log(error);
    }
  };

  const handleAircraftInputChange = (newValue) => {
    const inputValue = newValue;
    dispatch({
      type: "fieldChanged",
      field: "aircraftInputField",
      value: inputValue,
    });
    return inputValue;
  };

  const handleAircraftSelection = (selectedAircraft, actionData) => {
    console.log(selectedAircraft);
    dispatch({
      type: "fieldChanged",
      field: "selectedAircraft",
      value: selectedAircraft,
    });
    return selectedAircraft;
  };

  const loadAircraftOptions = async (inputValue) => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });
    let { data } = await ai.auth(token).get(`/api/aircrafts/list`, {
      params: {
        query: inputValue,
        page: 1,
        countPerPage: 50,
      },
    });

    if (data.aircraft.length >= 1) {
      console.log(data.aircraft);
      return data.aircraft.map((aircraft) => {
        return { value: aircraft._id, label: aircraft.name };
      });
    } else {
      return [];
    }
  };

  const handleOwnerInputChange = (newValue) => {
    dispatch({
      type: "fieldChanged",
      field: "ownerInputField",
      value: newValue,
    });
    return newValue;
  };

  const handleOwnerSelection = (selectedOwner) => {
    console.log(selectedOwner);
    dispatch({
      type: "fieldChanged",
      field: "selectedOwner",
      value: selectedOwner,
    });
    return selectedOwner;
  };

  return (
    <Fragment>
      {/* <ToastContainer transition={Slide} /> */}

      <EditFlightForm
        dispatch={dispatch}
        state={state}
        handleSubmit={handleSubmit}
        handleDepartureChange={handleDepartureChange}
        handleArrivalChange={handleArrivalChange}
        tagOptions={tagOptions}
        handleAircraftInputChange={handleAircraftInputChange}
        loadAircraftOptions={loadAircraftOptions}
        handleAircraftSelection={handleAircraftSelection}
        handleOwnerSelection={handleOwnerSelection}
        handleOwnerInputChange={handleOwnerInputChange}
        loadAirportOptions={loadAirportOptions}
      ></EditFlightForm>

      {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
    </Fragment>
  );
};

export default EditFlightContent;
