import { Link } from "react-router-dom";
import countryCodes from "../../helpers/country-codes";
import moment from "moment";

const columns = [
  {
    name: "Edit",
    sortable: false,
    cell: (row) => {
      return <Link to={`/exclusion-rules/${row._id}`}>Edit</Link>;
    },
    width: "70px",
  },

  {
    name: "Trip Category",
    sortable: true,
    selector: "tripCategory",
    width: "180px",
    cell: (row) => {
      let formatted = "";
      if (row.tripCategory === "international") {
        formatted = "International";
      } else if (row.tripCategory === "allInternational") {
        formatted = "All International";
      }

      return formatted;
    },
  },
  {
    name: "Origin ISO Code",
    sortable: true,
    selector: "originCountryISOCode",
    width: "180px",
  },
  {
    name: "Destination ISO Code",
    sortable: true,
    selector: "destinationCountryISOCode",
    width: "220px",
  },

  {
    name: "Origin Country Name",
    sortable: true,
    selector: "originCountryISOCode",
    cell: (row) => {
      let code = countryCodes.find(
        (item) => item.value === row?.originCountryISOCode
      );

      if (code) {
        return code?.label || "-";
      } else {
        return "--";
      }
    },
    width: "250px",
  },
  {
    name: "Destination Country Name",
    sortable: true,
    selector: "destinationCountryISOCode",
    cell: (row) => {
      if (row.destinationCountryISOCode) {
        let code = countryCodes.find(
          (item) => item.value === row?.destinationCountryISOCode
        );

        if (code) {
          return code?.label || "-";
        } else {
          return "--";
        }
      }
    },
    width: "250px",
  },

  {
    name: "Active",
    sortable: true,
    selector: "active",
    cell: (row) => (row.active ? "Yes" : "No"),
    width: "140px",
  },
  {
    name: "Created At",
    sortable: true,
    selector: "createdAt",
    cell: (row) => {
      return moment(row.createdAt).format("llll");
    },
    width: "180px",
  },
  {
    name: "Updated At",
    sortable: true,
    selector: "updatedAt",
    cell: (row) => {
      return moment(row.createdAt).format("llll");
    },
    width: "180px",
  },
];

export default columns;
