import React, {
  Fragment,
  useEffect,
  useReducer,
  useRef,
  useCallback,
  useState,
  useLayoutEffect,
} from "react";

import DataTable from "react-data-table-component";

import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";

import { useMediaQuery } from "react-responsive";
import useLocalStorage from "../../helpers/useLocalStorage";

import ai from "../../helpers/axios";
import * as Sentry from "@sentry/react";
import moment from "moment-timezone";

import columns from "./trips-list-columns";
// import salesTeamColumns from "./flightLeg-columns-ae-sa";
import Search from "../search";
import DataTableServerPagination from "../data-table-server-pagination";
import Select from "react-select";

import { Eventcalendar, Popup, Button } from "@mobiscroll/react";

import PageHeaderRowSearch from "../shared/page-header-row-search";
import SwitchInput from "../reusable-modules/switch-input";
import StateDisplay from "../reusable-modules/state-display";

import TripBulkActions from "./trip-bulk-actions";
import Loader from "../loader";

const stageFilterOptions = [
  { label: "Active", value: "newTrip" },
  { label: "Ended", value: "ended" },
  { label: "Canceled", value: "canceled" },
  { label: "Archived", value: "archived" },
];

let toastOptions = {
  position: "top-right",
  autoClose: 10000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  icon: false,
};

const conditionalRowStyles = [
  {
    when: (row) => row.displayStage === "canceled",
    style: {
      backgroundColor: "#ffcdd2", // darker red
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#ef9a9a", // slightly lighter on hover
      },
    },
  },
  {
    when: (row) => row.displayStage === "archived",
    style: {
      backgroundColor: "#ffebee", // lighter red
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#ffcdd2", // slightly darker on hover
      },
    },
  },
];

const showToastMessage = (id, message, options) => {
  if (id) {
    toast.update(id, {
      render: message,
      ...toastOptions,
      ...options,
    });
  } else {
    return toast(message, {
      ...toastOptions,
      ...options,
    });
  }
};

const colors = [
  "#0f9ed5",
  "#f1a983",
  "#4da72e",
  "#ffff00",
  "#d86dcd",
  "#ff0000",
  "#2196F3", // Blue
  "#FF5722", // Deep Orange
  "#4CAF50", // Green
  "#9C27B0", // Purple
  "#FFC107", // Amber
  "#E91E63", // Pink
];

const assignColorsToEvents = (events) => {
  const colorMap = new Map();
  const dayEvents = new Map(); // Track events by day

  // Group events by day
  events.forEach((event) => {
    const date = new Date(event.start).toDateString();
    if (!dayEvents.has(date)) {
      dayEvents.set(date, []);
    }
    dayEvents.get(date).push(event);
  });

  // Process each day's events
  dayEvents.forEach((dailyEvents, date) => {
    const usedColorsForDay = new Set();

    // Sort events by start time
    dailyEvents.sort((a, b) => new Date(a.start) - new Date(b.start));

    dailyEvents.forEach((event) => {
      // Check colors used by previous and next day
      const eventDate = new Date(event.start);
      const prevDate = new Date(eventDate);
      const nextDate = new Date(eventDate);
      prevDate.setDate(eventDate.getDate() - 1);
      nextDate.setDate(eventDate.getDate() + 1);

      const adjacentDayEvents = [
        ...(dayEvents.get(prevDate.toDateString()) || []),
        ...(dayEvents.get(nextDate.toDateString()) || []),
      ];

      const unavailableColors = new Set([
        ...Array.from(usedColorsForDay),
        ...adjacentDayEvents.map((e) => colorMap.get(e.id)).filter(Boolean),
      ]);

      // Find first available color
      const availableColor = colors.find(
        (color) => !unavailableColors.has(color)
      );

      if (availableColor) {
        colorMap.set(event.id, availableColor);
        event.color = availableColor;
        usedColorsForDay.add(availableColor);
      } else {
        // Fallback: use least used color for this day
        const colorUsageCounts = colors.reduce((acc, color) => {
          acc[color] = 0;
          return acc;
        }, {});

        Array.from(usedColorsForDay).forEach((color) => {
          colorUsageCounts[color]++;
        });

        const leastUsedColor = Object.entries(colorUsageCounts).sort(
          ([, a], [, b]) => a - b
        )[0][0];

        colorMap.set(event.id, leastUsedColor);
        event.color = leastUsedColor;
        usedColorsForDay.add(leastUsedColor);
      }
    });
  });

  return events;
};

const reducer = (state, action) => {
  switch (action.type) {
    case "search": {
      console.log("Search reducer:", action.query);
      return {
        ...state,
        query: action.query,
      };
    }
    case "dataBeganDownloading": {
      return {
        ...state,
        loading: action.payload.loadingStatus,
        // Reset trips when starting a new load
        trips: action.payload.loadingStatus ? [] : state.tableData,
      };
    }
    case "rowsDeleted": {
      return {
        ...state,
        clearSelectedRows: !state.clearSelectedRows,
        selectedRows: [],
      };
    }
    case "rowSelection": {
      return {
        ...state,
        selectedRows: action.payload.selectedRows,
        allSelected: action.payload.allSelected,
      };
    }

    case "dataDownloaded": {
      return {
        ...state,
        tableData: action?.payload?.data?.trips || [],
        loading: false,
        totalLength: action?.payload?.data?.countTotal,
        tableLoading: false, // Ensure loading is set to false
      };
    }

    case "pageChanged": {
      return {
        ...state,
        currentPage: action.payload.page,
      };
    }

    case "tableSort": {
      console.log("Reducer updating sort:", action.payload);
      return {
        ...state,
        sortBy: action.payload.sortBy,
        sortDirection: action.payload.sortDirection,
        currentPage: 1, // Reset to first page on sort change
      };
    }

    case "stageFilterChanged": {
      return {
        ...state,
        stageFilters: action.payload.values,
        currentPage: 1, // Reset to first page when filter changes
      };
    }

    case "rowsPerPageChanged": {
      return {
        ...state,
        countPerPage: action.payload.currentRowsPerPage,
      };
    }

    case "fieldChanged": {
      return {
        ...state,
        [action.field]: action.value,
      };
    }

    case "tableDataChanged": {
      return {
        ...state,
        trips: action.value,
      };
    }

    case "setPopupTripInfo": {
      return {
        ...state,
        popupTripInfo: action.payload,
      };
    }

    case "setTableLoading": {
      return {
        ...state,
        tableLoading: action.payload,
      };
    }

    case "setCalendarLoading": {
      return {
        ...state,
        calendarLoading: action.payload,
      };
    }

    case "setCalendarEvents": {
      return {
        ...state,
        calendarData: action.payload, // Store in calendarData instead of trips
        calendarLoading: false,
      };
    }

    case "setCalendarDateRange": {
      console.log("SETTING CALENDAR DATE RANGE", action.payload);
      return {
        ...state,
        calendarDateRange: action.payload,
      };
    }

    case "setInitialLoadComplete": {
      return {
        ...state,
        initialLoadComplete: true,
      };
    }

    case "ownerFilterChanged": {
      return {
        ...state,
        ownerFilters: action.payload.values,
        currentPage: 1, // Reset to first page when filter changes
      };
    }

    default:
      break;
  }
  return state;
};

const formatUSDAmount = (amount) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
};

const TripsListContent = (props) => {
  const [viewType, setViewType] = useLocalStorage("viewType", "table");

  const initialState = {
    tableData: [],
    calendarData: [],
    trips: {
      data: [],
    },
    loading: true,
    countPerPage: 25,
    currentPage: 1,
    selectedRows: [],
    allSelected: false,
    tagFilters: [],
    totalLength: null,
    clearSelectedRows: false,

    ownerFilters: props?.defaultOwnerFilters?.length
      ? [...props.defaultOwnerFilters.map((filter) => filter.value)]
      : [],
    defaultOwnerFilters: props?.defaultOwnerFilters?.length
      ? props.defaultOwnerFilters
      : [],
    ownerFilterOptions: [],
    ownerFiltersInput: props?.defaultOwnerFilters?.length
      ? props.defaultOwnerFilters
      : [],

    selectedOwner: null,
    query: "",
    stageFilters: viewType === "calendar" ? ["newTrip", "ended"] : [],
    sortDirection: "desc",
    sortBy: "createdAt",
    ownerOptions: [],
    teamMembers: [],
    popupTripInfo: {},
    tableLoading: true,
    calendarLoading: true,
    calendarDateRange: null,
    initialLoadComplete: false,
  };

  const { user } = useAuth0();

  const userRoles = user && user["https://app.evojets.com/roles"];

  const superAdminOrAd = userRoles.some(function (role) {
    return "Super Admin" === role || "Account Director" === role;
  });

  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const [state, dispatch] = useReducer(reducer, initialState);

  const timerRef = useRef(null);
  const initialLoadRef = useRef(false);
  const initialCalendarLoadRef = useRef(false);

  const myView = { calendar: { labels: true } };

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  const navigateToTrip = (id) => {
    openInNewTab(`/trips/${id}`);
  };

  const handleEventHoverIn = useCallback(
    (args) => {
      const selectedTrip = args.event;
      const tripDetails = state.calendarData.find((trip) => {
        return trip._id === selectedTrip.tripId;
      });

      console.log("EVENT HOVER", { selectedTrip, tripDetails });

      // Only proceed if we found matching trip details
      if (!tripDetails) return;

      // Format legs array before adding to payload
      const formattedLegs = tripDetails.itineraryData[0].legs.map((leg) => {
        const etdTime = new Date(leg.etd).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });
        // Return a string instead of an object
        return `${new Date(
          leg.departureDate
        ).toLocaleDateString()} ${etdTime} | ${leg.departureAirport.code} - ${
          leg.arrivalAirport.code
        }`;
      });

      dispatch({
        type: "setPopupTripInfo",
        payload: {
          legId: selectedTrip.legId,
          id: tripDetails._id,
          owner: tripDetails.owner ? tripDetails.owner.name : "No Owner",
          title: selectedTrip.title,
          createdAt: tripDetails.createdAt,
          clientName: tripDetails.clientName || "No Client Name",
          legs: formattedLegs, // Now an array of strings instead of objects
          payments: tripDetails.clientPaymentHistory.map(
            (payment) =>
              `${formatUSDAmount(payment.amount)} - ${
                payment.paymentType || ""
              } - ${new Date(payment.paymentDate).toLocaleDateString()}`
          ),
          tripTag: tripDetails.tripTag ? "#" + tripDetails.tripTag : "",
          anchor: args.domEvent.target,
          isOpen: true,
          activeIndex: selectedTrip.legIndex,
        },
      });

      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    },
    [state.calendarData]
  );

  const handleEventHoverOut = useCallback(() => {
    timerRef.current = setTimeout(() => {
      dispatch({
        type: "setPopupTripInfo",
        payload: {
          isOpen: false,
        },
      });
    }, 200);
  }, []);

  const handleEventClick = useCallback(
    (args) => {
      handleEventHoverIn(args);
    },
    [handleEventHoverIn]
  );

  const onMouseEnter = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  }, []);

  const onMouseLeave = useCallback(() => {
    timerRef.current = setTimeout(() => {
      dispatch({
        type: "setPopupTripInfo",
        payload: {
          isOpen: false,
        },
      });
    }, 200);
  }, []);

  const getData = async (
    dbQuery,
    loadSilently,
    stageFilters,
    sortBy,
    sortDirection,
    ownerFilters
  ) => {
    if (viewType === "calendar") return;
    dispatch({ type: "setTableLoading", payload: true });

    try {
      dispatch({ type: "setTableLoading", payload: true });

      const token = await getAccessTokenSilently();
      const response = await ai.auth(token).get(`/api/trips/list`, {
        params: {
          query: dbQuery,
          page: state.currentPage,
          countPerPage: state.countPerPage,
          stageFilters: JSON.stringify(stageFilters),
          sortBy,
          sortDirection,
          ownerFilters: JSON.stringify(ownerFilters),
        },
      });

      if (response?.data) {
        // Only update data if we're still in table view
        if (viewType === "table") {
          dispatch({
            type: "dataDownloaded",
            payload: {
              data: response.data,
            },
          });
        }
      }
    } catch (error) {
      console.error("Error fetching table data:", error);
      toast.error("Failed to load trips");
    } finally {
      dispatch({ type: "setTableLoading", payload: false });
    }
  };

  // funcs for calendar view

  const fetchCalendarData = useCallback(
    async (start, end, context, query) => {
      console.log("Fetching calendar data:", { start, end, context });
      if (!start || !end) return;

      const toastId = showToastMessage(null, "Events are loading...", {
        icon: "📆",
        id: "eventToast",
        theme: "dark",
      });

      try {
        const token = await getAccessTokenSilently();
        const params = new URLSearchParams({
          dateRangeStart: start,
          dateRangeEnd: end,
          stageFilters: JSON.stringify(state.stageFilters),
          ownerFilters: JSON.stringify(state.ownerFilters),
        });

        // if (state.query?.trim() && !query) {
        //   params.append("query", state.query.trim());
        // }

        if (query) {
          params.append("query", query.trim());
        }

        const response = await ai
          .auth(token)
          .get("/api/trips/calendarViewList", { params });

        if (response?.data?.trips) {
          dispatch({
            type: "setCalendarEvents",
            payload: response.data.trips,
          });
          showToastMessage(toastId, "Events loaded.", { type: "success" });
        }
      } catch (error) {
        console.error("Error fetching calendar data:", error);
        showToastMessage(toastId, "Failed to load events", "error");
      }
    },
    [
      getAccessTokenSilently,
      state.stageFilters,
      state.ownerFilters,
      state.query,
    ]
  );
  const handleCalendarNavigate = useCallback(
    (args) => {
      if (viewType !== "calendar") return;

      const month = args.month.getMonth();
      const year = args.month.getFullYear();
      const { firstDay, lastDay } = args;

      const start = moment(firstDay).format("YYYY-MM-DD");
      const end = moment(lastDay).format("YYYY-MM-DD");

      // Update ref immediately
      calendarDateRangeRef.current = {
        start: moment(firstDay).format("YYYY-MM-DD"),
        end: moment(lastDay).format("YYYY-MM-DD"),
      };

      fetchCalendarData(start, end, "HANDLE CALENDAR NAVIGATE");
    },
    [viewType, fetchCalendarData]
  );

  const handleChangeViewType = useCallback(
    (newViewType) => {
      if (newViewType === viewType) return;

      setViewType(newViewType);
      if (newViewType === "calendar") {
        const currentDate = moment();
        const start = currentDate.clone().startOf("month").format("YYYY-MM-DD");
        const end = currentDate.clone().endOf("month").format("YYYY-MM-DD");

        // Set default filters for calendar view
        dispatch({
          type: "stageFilterChanged",
          payload: { values: ["newTrip", "ended"] },
        });
      } else {
        dispatch({
          type: "stageFilterChanged",
          payload: { values: [] },
        });
        dispatch({ type: "setTableLoading", payload: true });
        dispatch({ type: "setCalendarEvents", payload: [] });

        getData(
          state.query,
          true,
          state.stageFilters,
          state.sortBy,
          state.sortDirection,
          state.ownerFilters
        );
      }
    },
    [
      viewType,
      getData,
      state.query,
      state.stageFilters,
      state.sortBy,
      state.sortDirection,
      state.ownerFilters,
      fetchCalendarData,
    ]
  );

  // end funcs for calendar view

  // Simplify handleSort to directly use the provided direction
  const handleSort = (column, sortDirection) => {
    // Set loading immediately
    dispatch({ type: "setTableLoading", payload: true });

    const newDirection =
      column.selector === state.sortBy
        ? state.sortDirection === "asc"
          ? "desc"
          : "asc"
        : "desc";

    // Update sort state first
    dispatch({
      type: "tableSort",
      payload: {
        sortBy: column.selector,
        sortDirection: newDirection,
      },
    });

    // Fetch new data
    getData(
      state.query,
      true,
      state.stageFilters,
      column.selector,
      newDirection,
      state.ownerFilters
    );
  };

  const calendarDateRangeRef = useRef(null);

  useEffect(() => {
    if (viewType === "table") {
      getData(
        state.query,
        false,
        state.stageFilters,
        state.sortBy,
        state.sortDirection,
        state.ownerFilters
      );
    }
  }, [state.currentPage, state.countPerPage, viewType]);

  const handlePageChange = (page, totalRows) => {
    console.log(page, totalRows);

    dispatch({
      type: "pageChanged",
      payload: {
        page,
        totalRows,
      },
    });
  };

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    dispatch({
      type: "rowsPerPageChanged",
      payload: {
        currentRowsPerPage,
        currentPage,
      },
    });
  };

  const loadOwnerOptions = async () => {
    try {
      const token = await getAccessTokenSilently();
      const { data } = await ai.auth(token).get(`/api/users/roles/sales`);

      if (data.users?.length) {
        const results = data.users.map((user) => ({
          value: user.user_id,
          label: user.name,
          email: user.email,
        }));

        dispatch({
          type: "fieldChanged",
          field: "ownerOptions",
          value: results,
        });

        const ownerFilterOptions = [...results];
        ownerFilterOptions.unshift({ label: "Unassigned", value: "null" }); // Use "null" string to match backend expectation

        dispatch({
          type: "fieldChanged",
          field: "ownerFilterOptions",
          value: ownerFilterOptions,
        });

        return results;
      }
      return [];
    } catch (error) {
      console.error("Error loading owner options:", error);
      toast.error("Failed to load owner options");
      return [];
    }
  };

  const handleOwnerFilterChange = (selectedValues) => {
    const values = selectedValues?.length
      ? selectedValues.map(({ value }) => value)
      : [];

    dispatch({
      type: "ownerFilterChanged",
      payload: { values },
    });

    dispatch({
      type: "fieldChanged",
      field: "ownerFiltersInput",
      value: selectedValues,
    });

    getData(
      state.query,
      true,
      state.stageFilters,
      state.sortBy,
      state.sortDirection,
      values
    );
  };

  const handleOwnerInputChange = (newValue) => {
    dispatch({
      type: "fieldChanged",
      field: "ownerInputField",
      value: newValue,
    });
    return newValue;
  };

  const handleOwnerSelection = async (selectedOwner) => {
    dispatch({
      type: "fieldChanged",
      field: "selectedOwner",
      value: selectedOwner,
    });
  };

  const handleStageFilterChange = (selectedOptions) => {
    console.log("Stage filter changed:", selectedOptions);

    const values = selectedOptions?.length
      ? selectedOptions.map((option) => option.value)
      : [];

    dispatch({
      type: "stageFilterChanged",
      payload: { values },
    });

    // Handle data fetching based on current view
    if (viewType === "table") {
      getData(
        state.query,
        false,
        values,
        state.sortBy,
        state.sortDirection,
        state.ownerFilters
      );
    }
  };

  useEffect(() => {
    if (viewType === "table" && !initialLoadRef.current) {
      initialLoadRef.current = true;
      dispatch({ type: "setTableLoading", payload: true });
      getData(
        state.query,
        false,
        state.stageFilters,
        state.sortBy,
        state.sortDirection
      );
    }
  }, [viewType, getData]);

  useEffect(() => {
    document.title = `${
      props.pageTitle ? props.pageTitle : "Trips"
    } | Flight Deck by evoJets`;
    loadOwnerOptions(); // Load owner options when component mounts
  }, []);

  useEffect(() => {
    if (calendarDateRangeRef.current) {
      dispatch({
        type: "setCalendarDateRange",
        payload: calendarDateRangeRef.current,
      });
      calendarDateRangeRef.current = null;
    }
  }, []);

  useEffect(() => {
    if (viewType === "calendar" && state.calendarDateRange) {
      console.log("Calendar effect triggered:", {
        viewType,
        query: state.query,
        calendarDateRange: state.calendarDateRange,
        stageFilters: state.stageFilters,
        ownerFilters: state.ownerFilters,
      });

      if (!initialCalendarLoadRef.current) {
        initialCalendarLoadRef.current = true;
        return;
      }

      fetchCalendarData(
        state.calendarDateRange.start,
        state.calendarDateRange.end,
        "USE EFFECT FOR FILTERS AND SEARCH"
      );
    }
  }, [
    viewType,
    state.stageFilters,
    state.ownerFilters,
    state.calendarDateRange,
  ]);

  // const handleSearch = (e) => {
  //   const query = e.target.value.toLowerCase();
  //   console.log("Search triggered:", { query, viewType });

  //   dispatch({ type: "search", query });
  //   dispatch({ type: "setTableLoading", payload: true });

  //   if (viewType === "table") {
  //     debouncedTableSearch(
  //       getData,
  //       query,
  //       state.stageFilters,
  //       state.sortBy,
  //       state.sortDirection,
  //       state.ownerFilters
  //     );
  //   } else if (viewType === "calendar" && state.calendarDateRange) {
  //     debouncedCalendarSearch(
  //       fetchCalendarData,
  //       query,
  //       state.calendarDateRange.start,
  //       state.calendarDateRange.end
  //     );
  //   }
  // };

  const searchInputRef = useRef("");

  // Helper function to handle both table and calendar searches
  const executeSearch = (query) => {
    dispatch({ type: "search", query });
    dispatch({ type: "setTableLoading", payload: true });

    console.log("CALENDAR DATE RANGE", state.calendarDateRange, query);

    if (viewType === "table") {
      getData(
        query,
        false,
        state.stageFilters,
        state.sortBy,
        state.sortDirection,
        state.ownerFilters
      );
    } else if (viewType === "calendar" && state.calendarDateRange) {
      fetchCalendarData(
        state.calendarDateRange.start,
        state.calendarDateRange.end,
        "SEARCH FUNCTION",
        query
      );
    }
  };
  // New handler for tracking input value without triggering search
  const handleInputChange = (e) => {
    searchInputRef.current = e.target.value.toLowerCase();
  };

  // Modified to handle Enter key instead of Tab
  const handleKeyDown = (e) => {
    // Check if Enter key was pressed
    if (e.keyCode === 13) {
      const query = searchInputRef.current;
      console.log("Search triggered:", { query, viewType });
      executeSearch(query);
    }
  };

  useEffect(() => {
    // Cleanup function
    return () => {
      // Clear any existing toasts when component unmounts
      toast.dismiss();
      // Clear any pending calendar date range updates
      calendarDateRangeRef.current = null;
    };
  }, []);

  const handleBlur = () => {
    const query = searchInputRef.current;
    console.log("Search triggered on blur:", { query, viewType });
    executeSearch(query);
  };

  return (
    <Fragment>
      <PageHeaderRowSearch
        h1={`${props.pageTitle ? props.pageTitle : "Trips"}`}
        pretitle="Business"
        breadcrumbs={[{ link: "/trips", label: "Trips" }]}
        extraClasses="page-header-trips-l1"
        rightColumn={
          <div id="trip-actions-row">
            {/* <Search
              onSearch={handleSearch}
              placeholder="Search Trips"
              query={state.query}
              className="lead-search"
            ></Search> */}

            <input
              type="text"
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              onBlur={handleBlur}
              autoComplete={"off"}
              className="lead-search"
              placeholder={"Search Trips"}
              id="searchFlights"
            />

            <Select
              options={stageFilterOptions}
              isMulti
              className="stage-filter"
              placeholder="Filter by Stage"
              value={stageFilterOptions.filter((option) =>
                state.stageFilters.includes(option.value)
              )}
              onChange={handleStageFilterChange}
              // Add these props for better UX
              isClearable={true}
              closeMenuOnSelect={false}
              defaultValue={[...state.stageFilters]}
            />
            {superAdminOrAd && (
              <Select
                options={state.ownerFilterOptions}
                isMulti
                className="owner-filter"
                placeholder="Filter by Owner"
                value={state.ownerFiltersInput}
                onChange={handleOwnerFilterChange}
                isClearable={true}
                closeMenuOnSelect={false}
              />
            )}

            <SwitchInput
              leftLabel="Table"
              rightLabel="Calendar"
              checked={viewType === "calendar"}
              onChange={(e) =>
                handleChangeViewType(e.target.checked ? "calendar" : "table")
              }
            ></SwitchInput>
          </div>
        }
      />

      {viewType === "table" && (
        <div
          className={`view-transition ${
            viewType === "table" ? "view-visible" : ""
          }`}
        >
          <div className="server-pagination-table-container trips-list-table-container">
            <DataTable
              columns={columns}
              data={state.tableData}
              pagination
              paginationServer
              paginationTotalRows={state.totalLength}
              paginationPerPage={state.countPerPage}
              paginationDefaultPage={state.currentPage}
              paginationRowsPerPageOptions={[25, 50, 100, 150, 200, 250, 500]}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsPerPageChange}
              sortServer
              onSort={handleSort}
              // sortField={state.sortBy}
              defaultSortFieldId={state.sortBy}
              sortDirection={state.sortDirection}
              progressPending={state.tableLoading}
              progressComponent={<Loader />}
              persistTableHead
              highlightOnHover
              responsive
              className="server-pagination-table"
              fixedHeader
              fixedHeaderScrollHeight="60vh"
              conditionalRowStyles={conditionalRowStyles}
            />
          </div>
        </div>
      )}

      {viewType === "calendar" && (
        <div
          className={`view-transition ${
            viewType === "calendar" ? "view-visible" : ""
          }`}
        >
          {false ? (
            <div className="calendar-loader-container">
              <Loader text="Loading calendar..." />
            </div>
          ) : (
            <Eventcalendar
              clickToCreate={false}
              dragToCreate={false}
              dragToMove={false}
              refreshControl={false}
              dragToResize={false}
              eventDelete={false}
              showEventTooltip={false}
              onEventHoverIn={handleEventHoverIn}
              onEventHoverOut={handleEventHoverOut}
              onPageLoading={handleCalendarNavigate}
              data={(() => {
                const legEvents = state.calendarData
                  .flatMap((trip) => {
                    if (!trip.itineraryData?.[0]?.legs?.length) return [];

                    const totalLegs = trip.itineraryData[0].legs.length;

                    return trip.itineraryData[0].legs.map((leg, index) => {
                      // Get the departure date in local timezone
                      const localStart = moment(leg.departureDate)
                        .startOf("day")
                        .add({
                          hours: moment(leg.etd).hour(),
                          minutes: moment(leg.etd).minute(),
                        })
                        .toDate();

                      // Calculate end time - if it crosses midnight, cap it at end of departure day
                      const localEnd = moment(localStart).set({
                        hour: moment(leg.eta).hour(),
                        minute: moment(leg.eta).minute(),
                      });

                      // If end time is before start time, it means it crossed midnight
                      // In this case, set end time to 23:59:59 of start date
                      if (localEnd.isBefore(moment(localStart))) {
                        localEnd.endOf("day");
                      }

                      return {
                        start: localStart,
                        end: localEnd.toDate(),
                        title: `(Leg ${index + 1} of ${totalLegs}) ${
                          trip.clientName || "No Client"
                        } | ${leg.departureAirport.code}-${
                          leg.arrivalAirport.code
                        }`,
                        id: leg._id,
                        legId: leg._id,
                        tripId: trip._id,
                        legIndex: index,
                      };
                    });
                  })
                  .filter(Boolean);

                return assignColorsToEvents(legEvents);
              })()}
              view={myView}
              onEventClick={handleEventClick}
              theme="ios"
              themeVariant="light"
              fuseeffect
              // Add these props
              dataTimezone="UTC"
              displayTimezone="local"
              // Force monthly view
              renderMode="month"
              calendarScroll="month"
              calendarType="month"
            />
          )}

          <Popup
            display="anchored"
            isOpen={state.popupTripInfo.isOpen}
            anchor={state.popupTripInfo.anchor}
            touchUi={false}
            showOverlay={false}
            contentPadding={false}
            closeOnOverlayClick={false}
            width={400}
            cssClass="md-tooltip"
          >
            <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
              <div className="md-tooltip-header">
                <span>{state.popupTripInfo.title}</span>
                <Button
                  variant="outline"
                  color="primary"
                  className="md-tooltip-view-button"
                  onClick={() => navigateToTrip(state.popupTripInfo.id)}
                >
                  View
                </Button>
              </div>

              <div className="md-tooltip-info">
                <div className="md-tooltip-title">
                  Owner:{" "}
                  <span className="md-tooltip-text">
                    {state.popupTripInfo.owner}
                  </span>
                </div>
                <div className="md-tooltip-title">
                  Created at:{" "}
                  <span className="md-tooltip-text">
                    {new Date(state.popupTripInfo.createdAt).toLocaleString()}
                  </span>
                </div>
                <div className="md-tooltip-title">
                  Client:{" "}
                  <span className="md-tooltip-text">
                    {state.popupTripInfo.clientName}
                  </span>
                </div>
                <div className="md-tooltip-title">
                  Trip:{" "}
                  <span className="md-tooltip-text">
                    {state.popupTripInfo.tripTag}
                  </span>
                </div>
                <div className="md-tooltip-title">Legs:</div>
                <ul>
                  {state.popupTripInfo &&
                    state.popupTripInfo.legs &&
                    state.popupTripInfo.legs.map((leg, i) => (
                      <li
                        className="md-tooltip-text"
                        key={`${state.popupTripInfo.id}-leg-${i}`}
                        style={{
                          color:
                            i === state.popupTripInfo.activeIndex
                              ? "var(--evoblue)"
                              : "black",
                          fontWeight:
                            i === state.popupTripInfo.activeIndex
                              ? "bold"
                              : "normal",
                        }}
                      >
                        {leg}
                      </li>
                    ))}
                </ul>
                {state.popupTripInfo.payments &&
                  state.popupTripInfo.payments.length && (
                    <>
                      <div className="md-tooltip-title">Payments:</div>
                      <ul>
                        {state.popupTripInfo.payments.map((pay, i) => (
                          <li className="md-tooltip-text" key={i}>
                            {pay}
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
              </div>
            </div>
          </Popup>
        </div>
      )}
    </Fragment>
  );
};

export default TripsListContent;
