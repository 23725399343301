import React, { useReducer, useEffect } from "react";

import { useAuth0 } from "@auth0/auth0-react";

import { EditorState, ContentState, convertToRaw } from "draft-js";

import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import ai from "../../helpers/axios";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { toast } from "react-toastify";

import TextInput from "../../components/text-input";

const reducer = (state, action) => {
  switch (action.type) {
    case "dataBeganDownloading": {
      return {
        ...state,
        loading: true,
      };
    }

    case "fieldChanged": {
      return {
        ...state,
        [action.field]: action.value,
      };
    }

    default:
      break;
  }
  return state;
};

const MyAccountContent = () => {
  const { user, getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const { name, picture, email } = user;

  const signatureHTML = htmlToDraft(
    user["https://app.evojets.com/userMetadata"].signature
  );

  const signatureState = ContentState.createFromBlockArray(
    signatureHTML.contentBlocks,
    signatureHTML.entityMap
  );

  let initialState = {
    signatureEditor: EditorState.createWithContent(signatureState),
    phoneNumber: user["https://app.evojets.com/userMetadata"].phoneNumber,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let rawContentState = convertToRaw(
      state.signatureEditor.getCurrentContent()
    );

    let signatureContent = draftToHtml(rawContentState);
    console.log(signatureContent);

    let { data } = await ai
      .auth(token)
      .post(`/api/users/updateMyProfile`, {
        signature: signatureContent,
        phoneNumber: state.phoneNumber,
      })
      .catch((error) => console.error(error.response.data));

    if (data) {
      console.log(data);

      toast.success("Signature Updated Successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }

    return;
  };

  return (
    <div>
      <div className="row align-items-center profile-header">
        <div className="col-md-2 mb-3">
          <img
            src={picture}
            alt="Profile"
            className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
          />
        </div>
        <div className="col-md text-center text-md-left">
          <h2>{name}</h2>
          <p className="lead text-muted">{email}</p>
        </div>
      </div>

      <form id="signatureForm" className="base-form" onSubmit={handleSubmit}>
        <h3>Edit Profile</h3>
        <Editor
          editorState={state.signatureEditor}
          wrapperClassName="signature-editor-wrapper"
          editorClassName="signature-editor"
          onEditorStateChange={(editorState) =>
            dispatch({
              type: "fieldChanged",
              field: "signatureEditor",
              value: editorState,
            })
          }
        />

        <TextInput
          name="phoneNumber"
          type="tel"
          value={state.phoneNumber}
          label="Phone Number"
          handleChange={(e) => {
            dispatch({
              type: "fieldChanged",
              field: "phoneNumber",
              value: e.target.value,
            });
          }}
        ></TextInput>

        <input type="submit" value="Save Changes"></input>
      </form>

      {/* <div className="row"> */}
      {/* <pre className="col-12 text-light bg-dark p-4">
          {JSON.stringify(user, null, 2)}
        </pre> */}

      {/* <pre className="col-12 text-light bg-dark p-4">
          {JSON.stringify(state, null, 2)}
        </pre> */}
      {/* </div> */}
    </div>
  );
};

export default MyAccountContent;
