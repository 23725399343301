import { Link } from "react-router-dom";
import moment from "moment-timezone";
import { Fragment } from "react";

import currencyFormatter from "../../helpers/currencyFormatter";

const columns = [
  {
    name: "Quote Sent",
    sortable: false,
    cell: (row) => {
      if (
        row?.hasMatchingQuote &&
        row?.latestQuoteTimeStamp &&
        row?.latestQuoteId
      ) {
        return (
          <Link to={`/quotes/${row?.latestQuoteId}`}>
            {moment(row?.latestQuoteTimeStamp).format("lll")}
          </Link>
        );
      }

      if (row?.hasMatchingQuote) {
        return <Link to={`/quotes/${row?.latestQuoteId}`}>View Draft</Link>;
      }

      return "-";
      // return <Link to={`/sourcing/${row._id}`}>View / Edit</Link>;
    },
    width: "180px",
    // grow: 0,
  },

  {
    name: "Record",
    sortable: false,
    cell: (row) => {
      return <Link to={`/sourcing/${row._id}`}>View / Edit</Link>;
    },
    width: "100px",
    // grow: 0,
  },

  {
    name: "Lead",
    sortable: false,
    cell: (row) => {
      if (row?.leadData?._id) {
        return <Link to={`/leads/${row.leadData._id}`}>View</Link>;
      }
    },
    width: "100px",
    // grow: 0,
  },

  {
    name: "Client",
    sortable: true,
    selector: "prospectData.lastName",
    cell: (row) => {
      if (row?.prospectData?.firstName && row?.prospectData?.lastName) {
        return `${row?.prospectData?.firstName} ${row?.prospectData?.lastName}`;
      }

      return "-";
    },
    // right: "true",
    width: "200px",
    // grow: 1,
  },

  {
    name: "Dates",
    selector: "leadData.estimateData.0.departureDateString",
    cell: (row) => {
      if (
        row?.leadData?.estimateData[0]?.departureDateString &&
        row?.leadData?.estimateData[0]?.returnDateString
      ) {
        return `${moment
          .utc(row?.leadData.estimateData[0]?.departureDateString)
          .format("MMM D")} - ${moment
          .utc(row.leadData.estimateData[0]?.returnDateString)
          .format("MMM D")}`;
      }

      if (row?.leadData?.estimateData[0]?.departureDateString) {
        return `${moment
          .utc(row?.leadData.estimateData[0]?.departureDateString)
          .format("MMM D")}`;
      }

      return "-";
    },
    sortable: true,
    width: "140px",
  },
  {
    name: "Initial Departure",
    // sortable: true,
    selector: "row.leadData.estimateData.0.departureAirportCode",
    cell: (row) => {
      if (row?.leadData?.estimateData?.length) {
        let estimate = row?.leadData?.estimateData[0];

        return estimate.departureAirportCode || "-";
      }

      return "-";
    },
    // right: "true",
    width: "180px",
    // grow: 0,
  },

  {
    name: "Operator",
    sortable: true,
    selector: "operator.name",
    cell: (row) => {
      return row?.operator?.name || "-";
    },
    // right: "true",
    width: "180px",
    // grow: 0,
  },

  {
    name: "Aircraft",
    sortable: true,
    selector: "aircraft.name",
    cell: (row) => {
      return row?.aircraft?.name || "-";
    },
    // right: "true",
    width: "150px",
    // grow: 1,
  },

  {
    name: "Operator Price",
    sortable: true,
    selector: "operatorPrice",
    cell: (row) => {
      if (row?.operatorPrice) {
        return currencyFormatter.format(row.operatorPrice);
      }

      return "-";
    },
    right: "true",
    width: "180px",
    // grow: 1,
  },

  {
    name: "Total Price",
    sortable: true,
    selector: "totalClientPayment",
    cell: (row) => {
      if (row?.totalClientPayment) {
        return currencyFormatter.format(row.totalClientPayment);
      }

      return "-";
    },
    right: "true",
    width: "140px",
    // grow: 1,
  },

  {
    name: "YOM",
    sortable: true,
    selector: "yom",
    cell: (row) => {
      return row.yom || "-";
    },
    right: "true",
    width: "80px",
    // grow: 0,
  },

  {
    name: "Class",
    sortable: true,
    selector: "aircraft.aircraftType.name",
    cell: (row) => {
      return row?.aircraft?.aircraftType?.name || "-";
    },
    // right: "true",
    width: "170px",
    // grow: 1,
  },

  {
    name: "Stage",
    selector: "leadData.stage",
    sortable: true,
    width: "130px",
    cell: (row) => {
      // console.log(row);

      let stage = row?.leadData?.stage;

      if (!stage) {
        return "-";
      }

      if (stage === "newLead") {
        return "New Lead";
      } else if (stage === "inProgress") {
        return "In Progress";
      } else if (stage === "archived") {
        return "Archived";
      } else if (stage === "warmProspect") {
        return "Warm Prospect";
      } else if (stage === "unqualified") {
        return "Unqualified";
      } else if (stage === "inLimbo") {
        return "In Limbo";
      } else if (stage === "signedContract") {
        return "Signed Contract - LTC";
      } else if (stage === "tripComplete") {
        return "Trip Complete";
      } else if (stage === "closedNoReply") {
        return "Closed - No Reply";
      } else if (stage === "closedGhost") {
        return "Closed - Ghost";
      } else if (stage === "repeatClient") {
        return "Closed - Repeat Client";
      } else if (stage === "closedCompetitor") {
        return "Closed - Lost to Competitor";
      } else if (stage === "inProgressTemp") {
        return "Soft-Deleted";
      } else if (stage === "signedContractQC") {
        return "Signed Contract - QC";
      }
    },
  },

  {
    name: "Created At",
    selector: "createdAt",
    sortable: true,
    width: "130px",
    right: "true",

    cell: (row) => {
      return moment(row.createdAt).format("M/D/YY - HHmm");
    },
  },

  {
    name: "Owner",
    sortable: true,
    selector: "leadData.assigneeName",
    cell: (row) => {
      if (row?.leadData?.assigneeName) {
        return `${row.leadData.assigneeName}`;
      }

      return "-";
    },
    // right: "true",
    width: "150px",
    // grow: 1,
  },

  // {
  //   name: "Create Contract",
  //   sortable: false,
  //   cell: (row) => {
  //     let newContract = (
  //       <Link to={{ pathname: `/trips/new`, state: { lead: row } }}>
  //         New Contract
  //       </Link>
  //     );

  //     if (row.assigneeId) {
  //       return newContract;
  //     }

  //     return "Assign Lead First";
  //   },
  //   width: "130px",
  // },

  // {
  //   name: "Sourcing",
  //   sortable: false,
  //   cell: (row) => {
  //     let newSourcing = (
  //       <Link to={{ pathname: `/sourcing/new`, state: { lead: row } }}>
  //         Create
  //       </Link>
  //     );

  //     if (row.assigneeId) {
  //       return newSourcing;
  //     }

  //     return "Assign Lead First";
  //   },
  //   width: "130px",
  // },

  // {
  //   name: "Owner",
  //   selector: "assigneeName",
  //   sortable: true,
  //   cell: (row) => {
  //     if (row.assigneeName) {
  //       let assigneeArray = row.assigneeName.split(" ");
  //       if (assigneeArray[0] && assigneeArray[1] && assigneeArray[1][0]) {
  //         return `${assigneeArray[0]} ${assigneeArray[1][0]}.`;
  //       }
  //       if (assigneeArray[0]) {
  //         return `${assigneeArray[0]}`;
  //       }
  //     }

  //     return "-";
  //   },

  //   width: "110px",
  // },

  // {
  //   name: "Notes",
  //   selector: "notes",
  //   sortable: true,
  //   width: "350px",
  // },
  // {
  //   name: "Lead Origin",
  //   selector: "leadOrigin",
  //   sortable: true,
  //   width: "150px",
  //   cell: (row) => {
  //     let formatted = "";
  //     if (row.leadOrigin === "qfs") {
  //       formatted = "Flight Deck - QFS";
  //     } else if (row.leadOrigin === "liveAPI") {
  //       formatted = "Live API";
  //     } else if (row.leadOrigin === "phoneCall") {
  //       formatted = "Phone Call";
  //     } else if (row.leadOrigin === "flightDeckInternal") {
  //       formatted = "Flight Deck - Internal";
  //     } else if (row.leadOrigin === "flightDeckExternal") {
  //       formatted = "Flight Deck - External";
  //     } else if (row.leadOrigin === "apiV3") {
  //       formatted = "Outside Org API";
  //     } else {
  //       formatted = "Unset";
  //     }
  //     return formatted;
  //   },
  // },
];

export default columns;
