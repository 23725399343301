import React, { Fragment, useReducer, useEffect } from "react";
import DatePicker from "react-date-picker";
import { useAuth0 } from "@auth0/auth0-react";

import ai from "../../helpers/axios";
import DataTable from "react-data-table-component";
import AsyncSelect from "react-select/async";
import RFPForm from "../leads/rfp-form";

import oneWayColumns from "./one-way-result-columns";
import roundTripColumns from "./round-trip-result-columns";

import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import "react-json-pretty/themes/monikai.css";
import JSONPretty from "react-json-pretty";
import * as Sentry from "@sentry/react";

const reducer = (state, action) => {
  switch (action.type) {
    case "fieldChanged": {
      let value =
        action.fieldType === "number" ? parseInt(action.value) : action.value;

      return {
        ...state,
        [action.field]: value,
      };
    }

    case "leadFieldChanged": {
      let value =
        action.fieldType === "number" ? parseInt(action.value) : action.value;

      return {
        ...state,
        leadData: {
          ...state.leadData,
          [action.field]: value,
        },
      };
    }
    case "airportChange": {
      return {
        ...state,
        [action.airportType]: action.payload,
      };
    }

    case "owChange": {
      let field =
        action.field === "departureOW" ? "departureAirport" : "arrivalAirport";

      return {
        ...state,
        [field]: {
          airportName: state[field].airportName,
          id: state[field].id,
          code: state[field].code,
          ow: action.value,
        },
      };
    }

    case "tripTypeChanged": {
      return {
        ...state,
        tripType: action.payload.tripType,
        returnDate: action.payload.tripType === 1 ? null : state.returnDate,
      };
    }

    case "estimateReturned": {
      return {
        ...state,
        tableData: action.payload.results.tripOptions,
        data: action.payload,
      };
    }

    default:
      break;
  }
  return state;
};

const initialState = {
  departureAirport: {
    airportName: null,
    id: null,
    code: null,
    ow: "",
  },
  arrivalAirport: {
    airportName: null,
    id: null,
    code: null,
    ow: "",
  },
  passengers: 0,
  departureDate: null,
  tripType: 1,
  returnDate: null,
  data: {},
  composedOW: "",
  tableData: [],
  departureAirportInput: "",
  arrivalAirportInput: "",
  leadData: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    companyName: "",
    comments: "",
  },
};

const CalculatorContent = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();

  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });
    let estimateData = {
      departureAirport: state.departureAirport,
      arrivalAirport: state.arrivalAirport,
      passengers: state.passengers,
      departureDate: state.departureDate,
      tripType: state.tripType,
      returnDate: state.returnDate,
      origin: "flightDeckInternal",
      owRatio: state.composedOW,
    };

    console.log("estimateData", estimateData);

    let { data } = await ai
      .auth(token)
      .post(`/api/estimates/create`, estimateData)
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
      });

    dispatch({
      type: "estimateReturned",
      payload: data,
    });

    console.log(data);
  };

  const loadAirportOptions = async (inputValue) => {
    let ip =
      Math.floor(Math.random() * 255) +
      1 +
      "." +
      (Math.floor(Math.random() * 255) + 0) +
      "." +
      (Math.floor(Math.random() * 255) + 0) +
      "." +
      (Math.floor(Math.random() * 255) + 0);
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });
    let { data } = await ai
      .auth(token)
      .get(`/api/airports/search?q=${inputValue}&ip_address=${ip}`);

    console.log(data);

    let options = [];
    if (data.length >= 1) {
      options = data.map((i) => ({
        value: i._id,
        label: i.airportName,
        code: i.code,
        ow: i.ow,
      }));
    }

    return options;
  };

  const getOWRatio = async () => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    if (state.departureAirport.id && state.arrivalAirport.id) {
      let { data } = await ai
        .auth(token)
        .get(
          `/api/airports/getOWRatio?departureAirportId=${state.departureAirport.id}&arrivalAirportId=${state.arrivalAirport.id}`
        )
        .catch((error) => console.error(error.response.data));

      if (data) {
        console.log("Composed OW", data);

        dispatch({
          type: "fieldChanged",
          field: "composedOW",
          value: parseFloat(data.ow) || "",
        });
      }

      return;
    }
  };

  useEffect(() => {
    getOWRatio();
    document.title = "Calculator | Flight Deck by evoJets";
  }, [state.departureAirport.id, state.arrivalAirport.id]);

  const handleDepartureChange = (selectedAirport) => {
    dispatch({
      type: "airportChange",
      airportType: "departureAirport",
      payload: {
        airportName: selectedAirport?.label || "",
        id: selectedAirport?.value || "",
        code: selectedAirport?.code || "",
        ow: parseFloat(selectedAirport?.ow) || "",
      },
    });
  };

  const handleArrivalChange = (selectedAirport) => {
    dispatch({
      type: "airportChange",
      airportType: "arrivalAirport",
      payload: {
        airportName: selectedAirport?.label || "",
        id: selectedAirport?.value || "",
        code: selectedAirport?.code || "",
        ow: parseFloat(selectedAirport?.ow) || "",
      },
    });
  };

  const handleRFPSubmit = async (e) => {
    e.preventDefault();

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });
    let leadData = {
      firstName: state.leadData.firstName,
      lastName: state.leadData.lastName,
      email: state.leadData.email,
      phone: state.leadData.phone,
      companyName: state.leadData.companyName,
      comments: state.leadData.comments,
      estimateId: state.data.estimateDoc._id,
      tripType: state.data.estimateDoc.tripType,
      leadOrigin: "flightDeckInternal",
    };
    let { data } = await ai.auth(token).post(`/api/leads/create`, leadData);

    // dispatch({
    //   type: "leadReturned",
    //   payload: data,
    // });

    setTimeout(() => {
      toast.success("Lead Created Successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }, 50);

    history.push({ pathname: "/leads/" });

    console.log(data);
  };

  const handleAirportChange = (e) => {
    dispatch({
      type: "owChange",
      field: e.target.name,
      value: parseFloat(e.target.value) || "",
      fieldType: "float",
    });
  };

  return (
    <Fragment>
      <div className="text-center hero">
        <h1 className="mb-4">Flight Deck Calculator</h1>
      </div>

      <form className="calculatorForm" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="tripType">Trip Type</label>
          <select
            id="tripType"
            value={state.tripType}
            onChange={(e) =>
              dispatch({
                type: "tripTypeChanged",
                payload: {
                  tripType: parseInt(e.currentTarget.value),
                },
              })
            }
            name="tripType"
            required
          >
            <option key="placeholder" value={0} hidden>
              Trip Type
            </option>

            <option key="oneWay" value={1}>
              One-Way
            </option>
            <option key="roundTrip" value={2}>
              Round Trip
            </option>
          </select>
        </div>

        <div className="form-group">
          <label>Departure Airport</label>

          <AsyncSelect
            className="departure-airport-select"
            classNamePrefix="select"
            cacheOptions
            loadOptions={loadAirportOptions}
            // defaultValue={props.state.selectedAircraft}
            placeholder="Search Airports"
            // onInputChange={props.handleDepartureInputChange}
            onChange={handleDepartureChange}
          ></AsyncSelect>
        </div>

        <div className="form-group">
          <label>Arrival Airport</label>

          <AsyncSelect
            className="arrival-airport-select"
            classNamePrefix="select"
            cacheOptions
            loadOptions={loadAirportOptions}
            // defaultValue={props.state.selectedAircraft}
            placeholder="Search Airports"
            // onInputChange={props.handleDepartureInputChange}
            onChange={handleArrivalChange}
          ></AsyncSelect>
        </div>

        <div className="form-group">
          <label htmlFor="passengers">Passengers</label>
          <input
            type="number"
            name="passengers"
            placeholder="Passengers"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
                fieldType: e.target.type,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="departureDate">Departure Date</label>

          <DatePicker
            name="departureDate"
            onChange={(date) =>
              dispatch({
                type: "fieldChanged",
                field: "departureDate",
                value: date,
              })
            }
            value={state.departureDate}
          ></DatePicker>
        </div>

        {state.tripType === 2 && (
          <div className="form-group">
            <label htmlFor="returnDate">Return Date</label>

            <DatePicker
              name="returnDate"
              onChange={(date) =>
                dispatch({
                  type: "fieldChanged",
                  field: "returnDate",
                  value: date,
                })
              }
              value={state.returnDate}
            ></DatePicker>
          </div>
        )}

        <div className="form-group">
          <label htmlFor="departureOW">Departure OW Ratio</label>
          <input
            type="number"
            name="departureOW"
            placeholder="Departure OW Ratio"
            onChange={handleAirportChange}
            value={state.departureAirport.ow}
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="arrivalOW">Arrival OW Ratio</label>
          <input
            type="number"
            name="arrivalOW"
            placeholder="Arrival OW Ratio"
            onChange={handleAirportChange}
            value={state.arrivalAirport.ow}
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="composedOW">Dynamic OW Ratio</label>
          <input
            type="number"
            name="composedOW"
            placeholder="Dynamic OW Ratio"
            onChange={(e) => {
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: parseFloat(e.target.value) || "",
                fieldType: "float",
              });
            }}
            value={state.composedOW}
          ></input>
        </div>

        <input type="submit" value="Submit"></input>
      </form>

      {state.tableData.length ? (
        <h4 className="centered">
          Distance: {Math.round(state.data.results.distance)} mi.
        </h4>
      ) : (
        ""
      )}
      <div id="results">
        {state.tableData.length && state.data.results.tripType === 1 ? (
          <DataTable
            data={state.tableData}
            columns={oneWayColumns}
            noHeader
            highlightOnHover
            pagination={false}
            responsive={true}
            defaultSortField={"lowSpread"}
          ></DataTable>
        ) : (
          ""
        )}

        {state.tableData.length && state.data.results.tripType === 2 ? (
          <DataTable
            data={state.tableData}
            columns={roundTripColumns}
            noHeader
            highlightOnHover
            pagination={false}
            responsive={true}
            defaultSortField={"lowSpread"}
          ></DataTable>
        ) : (
          ""
        )}

        {/* {state.data.estimateDoc && (
          <RFPForm
            handleRFPSubmit={handleRFPSubmit}
            dispatch={dispatch}
            state={state}
          ></RFPForm>
        )} */}

        {/* <JSONPretty id="json-pretty" data={state.data.results}></JSONPretty> */}

        {/* <JSONPretty id="json-pretty" data={state.departureAirport}></JSONPretty>

        // <JSONPretty id="json-pretty" data={state.leadData}></JSONPretty>
        <JSONPretty id="json-pretty" data={state.departureAirport}></JSONPretty> */}
      </div>
    </Fragment>
  );
};

export default CalculatorContent;
