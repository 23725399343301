import React from "react";

import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import CheckBoxOutlineBlank from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import currencyFormatter from "../../helpers/currencyFormatter";

import formatStage from "../../helpers/format-stage";

import moment from "moment-timezone";

function Row(props) {
  const {
    row,
    superAdminOrAd,
    context,
    parentDispatch,
    selectedRows,
    handleActionButton,
  } = props;
  const [open, setOpen] = React.useState(false);
  let rowSelected = null;

  if (selectedRows) {
    rowSelected = selectedRows?.filter(
      (selectedRow) => row?._id === selectedRow?._id
    );

    rowSelected = rowSelected?.length > 0 ? true : false;
  }

  return (
    <React.Fragment key={`fragment-${props.tableName}-${row._id}`}>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        onClick={() => setOpen(!open)}
        className="fm-l2-parent-table-row"
        key={`table-row-${props.tableName}-${row._id}`}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        {props.columns.map((column, index) => {
          if (column.field) {
            const fieldPath = column.field.split(".");
            let value = row;

            for (let i = 0; i < fieldPath.length; i++) {
              value = value[fieldPath[i]];
            }

            if (column.format === "date") {
              value = moment(value).format("MMM Do YYYY h:mm a");
            }
            if (column.format === "currency") {
              value = currencyFormatter.format(value);
            }

            if (column.format === "stage") {
              value = formatStage(value);
            }

            return (
              <TableCell key={`basic-row-data-${index}`}>
                {value ? value : "-"}
              </TableCell>
            );
          }

          if (column.render) {
            let value = column.render(row, context);
            if (column.format === "date") {
              value = moment(value).format("MMM Do YYYY h:mm a");
            }
            if (column.format === "currency") {
              value = currencyFormatter.format(value);
            }

            if (column.format === "stage") {
              value = formatStage(value);
            }

            return (
              <TableCell key={`render-row-data-${index}`}>
                {value ? value : "-"}
              </TableCell>
            );
          }

          if (column.type === "selection") {
            return (
              <TableCell className="centered-important">
                <IconButton
                  aria-label="expand row"
                  size="small"
                  disabled={row?.hidden}
                  className="sourcing-record-checkbox"
                  onClick={(e) => {
                    console.log("EVENT", {
                      type: "rowSelectionEvent",
                      rowId: row._id,
                      selected: !rowSelected,
                      selectionKey: props.selectionKey,
                      recordKey: props.recordKey,
                    });
                    e.stopPropagation();
                    parentDispatch({
                      type: "rowSelectionEvent",
                      rowId: row._id,
                      selected: !rowSelected,
                      selectionKey: props.selectionKey,
                      recordKey: props.recordKey,
                    });
                  }}
                >
                  {rowSelected ? <CheckBoxIcon /> : <CheckBoxOutlineBlank />}
                </IconButton>
              </TableCell>
            );
          }
        })}
      </TableRow>
      <TableRow key={`accordion-row-${row._id}`}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }} className="fm-l2-child-table-container">
              {props.accordionChildren?.map((column, index) => {
                // console.log("RETURN ACCORDION", column.render(row));
                return column.render(row, context, handleActionButton);
              })}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function SortableTableHead(props) {
  let showSortIcon = true;
  let sortUpText = "arrow_drop_up";
  let sortDownText = "arrow_drop_down";

  let isSorted = props.sortField === props.id ? true : false;

  console.log("DIRECTION", props.sortDirection);

  return (
    <TableCell
      onClick={(e) => {
        props.onClick(props.id);
      }}
      id={props.id}
      className="sortable-evo-header"
    >
      {props.label}{" "}
      {isSorted && (
        <span
          className={`material-icons sort-icon ${
            showSortIcon ? "sort-icon-visible" : "sort-icon-hidden"
          }`}
        >
          {props.sortDirection === 1 ? sortUpText : sortDownText}
        </span>
      )}
    </TableCell>
  );
}

export default function CollapsibleTable(props) {
  const { superAdmin } = props;
  return (
    <React.Fragment>
      <TableContainer
        component={Paper}
        className={
          props.tableClassName
            ? props.tableClassName
            : "mt-30 accordion-table-with-details"
        }
        key={props?.tableName}
      >
        <Table aria-label="collapsible table">
          <TableHead className="fm-l2-parent-table-head dynamic-accordion-table-header">
            <TableRow className="fm-l2-parent-table-head-row">
              <TableCell />
              {props.columns.map((header, index) => {
                if (header.placement === "accordion") {
                  return null;
                } else {
                  return (
                    <TableCell key={`header-${index}`}>
                      {header.label}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody className="fm-l2-parent-table-body">
            {props.rows?.length > 0 &&
              props.rows.map((row, i) => (
                <Row
                  key={`${props.tableName}-row-${row._id}-${i}`}
                  tableName={props.tableName}
                  row={row}
                  columns={props.columns}
                  context={props.context}
                  accordionChildren={props.accordionChildren}
                  parentDispatch={props.parentDispatch}
                  selectedRows={props.selectedRows}
                  selectionKey={props.selectionKey}
                  recordKey={props.recordKey}
                  handleActionButton={props.handleActionButton}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {props.tableFooter && (
        <div className="mt-30">
          {props.tableFooter.map((children) => children.render(props.context))}
        </div>
      )}
    </React.Fragment>
  );
}
