import React, { Fragment, useReducer, useEffect } from "react";
import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import ai from "../../helpers/axios";

import { useHistory, useParams } from "react-router-dom";

const reducer = (state, action) => {
  switch (action.type) {
    case "search": {
      return {
        ...state,
        query: action.query,
      };
    }
    case "fieldChanged": {
      return {
        ...state,
        spread: {
          ...state.spread,
          [action.field]: action.value,
        },
      };
    }

    case "rootFieldChanged": {
      return {
        ...state,
        [action.field]: action.value,
      };
    }
    case "dataBeganDownloading": {
      return {
        ...state,
        loading: true,
      };
    }
    case "dataDownloaded": {
      let lowPercentage = action.payload.data?.lowPercentage;
      let highPercentage = action.payload.data?.highPercentage;
      lowPercentage = parseFloat(lowPercentage * 100).toFixed(2);
      highPercentage = parseFloat(highPercentage * 100).toFixed(2);

      return {
        ...state,
        spread: { ...action.payload.data, lowPercentage, highPercentage },
      };
    }
    default:
      break;
  }
  return state;
};

const SingleSpreadContent = (props) => {
  const history = useHistory();
  const { id } = useParams();

  const initialState = {
    loading: true,
    spread: {
      bracketStart: 0,
      bracketEnd: 0,
      lowPercentage: 0,
      highPercentage: 0,
    },
  };

  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const [state, dispatch] = useReducer(reducer, initialState);

  const getData = async () => {
    dispatch({ type: "dataBeganDownloading" });

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let { data } = await ai.auth(token).get(`/api/spreads/${id}`);

    console.log(data);
    dispatch({
      type: "dataDownloaded",
      payload: {
        data,
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let formData = { ...state.spread };

    delete formData._id;

    formData.lowPercentage = parseFloat(state.spread.lowPercentage) / 100;
    formData.highPercentage = parseFloat(state.spread.highPercentage) / 100;

    let { data } = await ai.auth(token).put(`/api/spreads/${id}`, formData);

    console.log("resp", data);

    history.push({ pathname: "/spreads" });

    setTimeout(() => {
      toast.success("Spread Updated Successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }, 50);
  };

  useEffect(() => {
    getData();
    document.title = "Edit Spread | Flight Deck by evoJets";
  }, []);

  //   const handleTextInputChange = (e) => {
  //     let value = e.target.value;

  //     if (e.target.type === "number") {
  //       value = parseInt(e.target.value);

  //       if (
  //         e.target.value === null ||
  //         typeof e.target.value === undefined ||
  //         e.target.value === ""
  //       ) {
  //         value = "";
  //       }
  //     }

  //     console.log(e.target.value);

  //     dispatch({
  //       type: "fieldChanged",
  //       field: e.target.name,
  //       value: value,
  //     });
  //   };

  return (
    <Fragment>
      <h2 id="editSpreadHeading" class="base-heading">
        Edit Spread
      </h2>

      <form
        id="editSpread"
        class="base-form two-column"
        onSubmit={handleSubmit}
      >
        <div className="form-group">
          <label htmlFor="bracketStart">Bracket Start</label>
          <input
            type="number"
            name="bracketStart"
            value={state.spread.bracketStart}
            placeholder="Bracket Start"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: parseInt(e.target.value),
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="bracketEnd">Bracket End</label>
          <input
            type="number"
            name="bracketEnd"
            value={state.spread.bracketEnd}
            placeholder="Bracket End"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: parseInt(e.target.value),
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="lowPercentage">Low Percentage</label>
          <input
            type="number"
            name="lowPercentage"
            value={state.spread.lowPercentage}
            placeholder="Bracket End"
            step="0.5"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: parseFloat(e.target.value),
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="highPercentage">High Percentage</label>
          <input
            type="number"
            name="highPercentage"
            value={state.spread.highPercentage}
            placeholder="Bracket End"
            step="0.5"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: parseFloat(e.target.value),
              })
            }
          ></input>
        </div>

        <input type="submit" value="Update Spread"></input>
      </form>

      {/* <pre>{JSON.stringify(state.spread, null, 2)}</pre> */}
    </Fragment>
  );
};

export default SingleSpreadContent;
