import React, { Fragment } from "react";

import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import CheckBoxOutlineBlank from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import moment from "moment-timezone";

import formatAircraftSelection from "../../helpers/format-aircraft-selection";
import formatStage from "../../helpers/lead-stage-formatter";
import currencyFormatter from "../../helpers/currencyFormatter";
import timeFormatter from "../../helpers/timeFormatter";

import { Link } from "react-router-dom";

const getAircraftSelection = (data) => {
  if (data?.aircraftSelection?.length) {
    return data?.aircraftSelection
      .map((aircraft) => formatAircraftSelection(aircraft))
      .join(", ");
  }
  return "N/A";
};

function Row(props) {
  const { row, superAdminOrAd } = props;
  const [open, setOpen] = React.useState(false);

  // console.log("ROW", row);

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        onClick={() => setOpen(!open)}
        className="fm-l2-parent-table-row"
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          {moment(row.createdAt).format("MMM Do YYYY h:mm a")}
        </TableCell>

        <TableCell>{formatStage(row.stage)}</TableCell>

        <TableCell>{row.assigneeName || "-"}</TableCell>
        <TableCell>
          {row.firstName} {row.lastName}
        </TableCell>
        <TableCell>{row.phone}</TableCell>
        <TableCell>{row.email}</TableCell>

        {row.companyName !== "" && <TableCell>{row.companyName}</TableCell>}

        {/* 
        <TableCell>{row.assigneeName}</TableCell>
        <TableCell>{row.assigneeName}</TableCell>
        <TableCell>{row.assigneeName}</TableCell>
 */}
        {/* <TableCell scope="row">
          {row.isCurrentLeg ? "Current Leg" : `Leg ${row.indexText}`}
        </TableCell>
        <TableCell>{row.departureDate}</TableCell>
        <TableCell>{row.departureAirport.airportName}</TableCell>
        <TableCell>{row.arrivalAirport.airportName}</TableCell>
        <TableCell>{row.passengerCount}</TableCell> */}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }} className="fm-l2-child-table-container">
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                className="fm-l2-child-table-heading mb-20 mt-20"
              >
                Trip Details
              </Typography>

              <p key="tripType">
                <strong>Trip Type: </strong>
                {row.tripType === 1 ? "One Way" : "Round Trip"}
              </p>

              <p key="departureDate">
                <strong>Departure Date: </strong>
                {row?.itineraryDetails?.departureDate || "-"}
              </p>

              {row.tripType === 2 && (
                <p key="returnDate">
                  <strong>Return Date: </strong>
                  {row?.itineraryDetails?.returnDate || "N/A"}
                </p>
              )}
              <p key="departureAirport">
                <strong>Departure Airport: </strong>
                {row?.itineraryDetails?.departureAirportName}
              </p>

              <p key="arrivalAirport">
                <strong>Arrival Airport: </strong>
                {row?.itineraryDetails?.arrivalAirportName}
              </p>

              <p key="departureTime">
                <strong>Departure Time: </strong>
                {row?.departureTime ? row?.departureTime : "N/A"}
              </p>

              {row.tripType === 2 && (
                <p>
                  <strong>Return Time: </strong>
                  {row?.returnTime ? row?.returnTime : "N/A"}
                </p>
              )}

              <hr></hr>

              <Typography
                variant="h5"
                gutterBottom
                component="div"
                className="fm-l2-child-table-heading mb-20 mt-20"
              >
                Quote Details
              </Typography>

              {row.quotes.length > 0 &&
                row.quotes.map((quote) => {
                  return (
                    <p key={`${quote}-p`}>
                      <Link key={quote} to={`/quotes/${quote}`}>
                        View Quote: {quote}
                      </Link>
                    </p>
                  );
                })}

              {!row.quotes.length && (
                <p>
                  <strong>No Quotes Sent.</strong>
                </p>
              )}

              <hr></hr>

              <Typography
                variant="h5"
                gutterBottom
                component="div"
                className="fm-l2-child-table-heading mb-20 mt-20"
              >
                Additional Details
              </Typography>

              <p>
                <strong>Passengers: </strong>
                {row?.itineraryDetails?.passengerCount || "-"}
              </p>

              <p>
                <strong>Aircraft Selection: </strong>
                {getAircraftSelection()}
              </p>
              <p>
                <strong>Baggage: </strong>
                {row?.baggage?.length ? row.baggage.join(", ") : "N/A"}
              </p>
              <p>
                <strong>Pets: </strong>
                {row.pets ? "Yes" : "No"}
              </p>
              <p>
                <strong>Infants: </strong>
                {row.infants ? "Yes" : "No"}
              </p>

              <p>
                <strong>Comments: </strong>
                {row.comments}
              </p>

              <hr></hr>

              <Typography
                variant="h5"
                gutterBottom
                component="div"
                className="fm-l2-child-table-heading mb-20 mt-20"
              >
                Estimate Details
              </Typography>

              {row?.tripOptions?.length > 0 &&
                row?.tripOptions.map(
                  ({ aircraftName, lowSpread, highSpread }) => {
                    return (
                      <p key={aircraftName}>
                        <strong>{aircraftName}: </strong>
                        {currencyFormatter.format(lowSpread)} -{" "}
                        {currencyFormatter.format(highSpread)}
                      </p>
                    );
                  }
                )}

              <hr></hr>
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                className="fm-l2-child-table-heading mb-20 mt-20"
              >
                Lead Metadata
              </Typography>

              <p>
                <strong>Lead ID: </strong>
                {row._id}
              </p>

              <p>
                <strong>Organization: </strong>
                {row.organizationName}
              </p>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function SourcingRow(props) {
  const { superAdminOrAd } = props;
  let { row, dispatch, selectedRows, handleDelete, handleHide } = props;
  const [open, setOpen] = React.useState(false);

  // row = row[0];

  if (!row) return null;

  let rowSelected = selectedRows.filter(
    (selectedRow) => row._id === selectedRow._id
  );

  rowSelected = rowSelected?.length ? true : false;

  let matchingOperator = null;

  if (props?.leadData?.operatorData?.length) {
    matchingOperator = props?.leadData?.operatorData.filter(
      (item) => item._id === row.operator.id
    );

    if (matchingOperator?.length) {
      matchingOperator = matchingOperator[0];
    } else {
      matchingOperator = null;
    }
  }

  // let rowSelected = false;

  // console.log("SOURCING ROW", row);

  return (
    <>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        onClick={() => setOpen(!open)}
        className={`fm-l2-parent-table-row ${
          row?.hidden ? "sourcing-record-hidden" : "sourcing-record-active"
        } `}
        key={row._id}
      >
        <TableCell key="toggleOpenClose">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell key="editLink">
          <Link
            onClick={(e) => {
              if (row?.hidden) {
                e.preventDefault();
              }
              return;
            }}
            to={{
              pathname: `/sourcing/${row?._id}`,
              state: {
                lead: props.leadData,
              },
            }}
            className={row?.hidden ? "disabled-link" : ""}
          >
            Edit
          </Link>
        </TableCell>

        <TableCell key="operatorName">{row?.operator?.name}</TableCell>

        <TableCell key="operatorPrice">
          {row.operatorPrice
            ? currencyFormatter.format(row?.operatorPrice)
            : "-"}
        </TableCell>

        <TableCell key="totalClientPayment">
          {row.totalClientPayment
            ? currencyFormatter.format(row.totalClientPayment)
            : "-"}
        </TableCell>

        <TableCell key="aircraftName">{row?.aircraft?.name || "-"}</TableCell>

        <TableCell key="createdAt">
          {moment(row.createdAt).format("MMM Do YYYY h:mm a")}
        </TableCell>

        <TableCell key="quoteStatus">
          {row?.latestQuoteId && row?.latestQuoteTimeStamp ? (
            <Link to={`/quotes/${row?.latestQuoteId}`}>
              {moment(row?.latestQuoteTimeStamp).format("lll")}
            </Link>
          ) : row?.latestQuoteId ? (
            <Link to={`/quotes/${row?.latestQuoteId}`}>View Draft</Link>
          ) : (
            "-"
          )}
        </TableCell>

        <TableCell key="record-actions">
          {!row?.hidden && (
            <button
              className={`base-button btn-danger mr-20`}
              key={"handle-delete"}
              onClick={(e) => {
                e.preventDefault();
                handleDelete(row._id);
              }}
            >
              Delete
            </button>
          )}
          <button
            className={`base-button evo-gunmetal`}
            key={"handle-hide"}
            onClick={(e) => {
              e.preventDefault();
              handleHide(row._id);
            }}
          >
            {row?.hidden ? "Unhide" : "Hide"}
          </button>
        </TableCell>

        <TableCell className="centered-important">
          <IconButton
            aria-label="expand row"
            size="small"
            disabled={row?.hidden}
            className="sourcing-record-checkbox"
            onClick={(e) => {
              e.stopPropagation();
              dispatch({
                type: "rowSelectionEvent",
                rowId: row._id,
                selected: !rowSelected,
              });
            }}
          >
            {rowSelected ? <CheckBoxIcon /> : <CheckBoxOutlineBlank />}
          </IconButton>
        </TableCell>

        {/* <TableCell>{row.assigneeName || "-"}</TableCell>
        <TableCell>
          {row.firstName} {row.lastName}
        </TableCell> */}
        {/* <TableCell>{row.phone}</TableCell>
        <TableCell>{row.email}</TableCell> */}

        {/* {row.companyName !== "" && <TableCell>{row.companyName}</TableCell>} */}

        {/* 
        <TableCell>{row.assigneeName}</TableCell>
        <TableCell>{row.assigneeName}</TableCell>
        <TableCell>{row.assigneeName}</TableCell>
 */}
        {/* <TableCell scope="row">
          {row.isCurrentLeg ? "Current Leg" : `Leg ${row.indexText}`}
        </TableCell>
        <TableCell>{row.departureDate}</TableCell>
        <TableCell>{row.departureAirport.airportName}</TableCell>
        <TableCell>{row.arrivalAirport.airportName}</TableCell>
        <TableCell>{row.passengerCount}</TableCell> */}
      </TableRow>
      <TableRow
        className={`${
          row?.hidden ? "sourcing-record-hidden" : "sourcing-record-active"
        }`}
      >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }} className="fm-l2-child-table-container">
              <div className="three-columns">
                <div className="column">
                  <Typography
                    variant="h5"
                    gutterBottom
                    component="div"
                    className="fm-l2-child-table-heading mb-20 mt-20"
                  >
                    Aircraft Details
                  </Typography>

                  <p>
                    <strong>Tail #: </strong>
                    {row.tailNumber || "-"}
                  </p>

                  <p key={`seats`}>
                    <strong>Seats: </strong>
                    {row?.aircraft?.seats ? row?.aircraft?.seats : "-"}
                  </p>

                  <p key={`class`}>
                    <strong>Class: </strong>
                    {row?.aircraft?.aircraftType?.name
                      ? row?.aircraft?.aircraftType?.name
                      : "-"}
                  </p>
                  <p key={`yom`}>
                    <strong>YOM: </strong>
                    {row?.yomIsRange && row?.yom && row?.yomEnd
                      ? `${row.yom} - ${row.yomEnd}`
                      : row?.yom
                      ? row.yom
                      : row?.fullAircraftData?.yom
                      ? row?.fullAircraftData?.yom
                      : "-"}
                  </p>

                  <p key={`interiorRefurb`}>
                    <strong>Interior Refurb: </strong>
                    {row?.interiorRefurbYearIsRange &&
                    row?.interiorRefurbYear &&
                    row?.interiorRefurbYearEnd
                      ? `${row.interiorRefurbYear} - ${row.interiorRefurbYearEnd}`
                      : row?.interiorRefurbYear
                      ? row?.interiorRefurbYear
                      : "-"}
                  </p>

                  <p key={`ownerApproval`}>
                    <strong>Owner Approval Required: </strong>
                    {row.ownerApproval ? "Yes" : "No"}
                  </p>

                  <p key={`amenities`}>
                    <strong>Amenities: </strong>
                    {row?.amenities?.length ? row?.amenities?.join(", ") : "-"}
                  </p>

                  <p key={`safetyStandards`}>
                    <strong>Safety Standards: </strong>
                    {row?.safetyStandards?.length
                      ? row?.safetyStandards?.join(", ")
                      : "-"}
                  </p>
                </div>

                <div className="column">
                  <Typography
                    variant="h5"
                    gutterBottom
                    component="div"
                    className="fm-l2-child-table-heading mb-20 mt-20"
                  >
                    Payment Details
                  </Typography>

                  <p key={`totalClientPayment`}>
                    <strong>Total Client Payment: </strong>
                    {row?.totalClientPayment
                      ? currencyFormatter.format(row?.totalClientPayment)
                      : "-"}
                  </p>

                  <p key={`operatorPrice`}>
                    <strong>Operator Price: </strong>
                    {row?.operatorPrice
                      ? currencyFormatter.format(row.operatorPrice)
                      : "-"}
                  </p>

                  <p key={`fet`}>
                    <strong>FET: </strong>
                    {row?.fet?.amount
                      ? currencyFormatter.format(row?.fet?.amount)
                      : "-"}
                  </p>
                  <p key={`seg-fees`}>
                    <strong>Segment Fees: </strong>
                    {row?.segmentFees?.amount
                      ? currencyFormatter.format(row?.segmentFees?.amount)
                      : "-"}
                  </p>

                  <p key={`referral-fees`}>
                    <strong>Referral Fees: </strong>
                    {row?.referralFees
                      ? currencyFormatter.format(row?.referralFees)
                      : "-"}
                  </p>

                  <p key={`ccFees`}>
                    <strong>CC Fee: </strong>
                    {row?.paymentType === "cc" && row?.ccFee
                      ? currencyFormatter.format(row?.ccFee)
                      : "-"}
                  </p>

                  <p key={`net-commission`}>
                    <strong>Net Commission: </strong>
                    {row?.netCommission
                      ? currencyFormatter.format(row.netCommission)
                      : "-"}
                  </p>

                  <p key={`gross-commission`}>
                    <strong>Gross Commission: </strong>
                    {row?.grossCommission
                      ? currencyFormatter.format(row.grossCommission)
                      : "-"}
                  </p>
                  {/* 
                  <p key={`payment-type`}>
                    <strong>Payment Type: </strong>
                    {row?.paymentType === "cc"
                      ? "Credit Card"
                      : row?.paymentType === "wire"
                      ? "Wire"
                      : "-"}
                  </p> */}
                </div>

                <div className="column">
                  <Typography
                    variant="h5"
                    gutterBottom
                    component="div"
                    className="fm-l2-child-table-heading mb-20 mt-20"
                  >
                    Metadata
                  </Typography>

                  <p key={`flightTime`}>
                    <strong>Flight Time: </strong>
                    {!row?.isRoundTrip && row?.flightTime
                      ? timeFormatter(row.flightTime)
                      : row?.isRoundTrip &&
                        row?.originLegFlightTime &&
                        row?.returnLegFlightTime
                      ? `${timeFormatter(
                          row.originLegFlightTime
                        )}, ${timeFormatter(row.returnLegFlightTime)}`
                      : "-"}
                  </p>

                  <p key={`costPerFlightHour`}>
                    <strong>Operator Hourly: </strong>
                    {row?.costPerFlightHour
                      ? currencyFormatter.format(row.costPerFlightHour)
                      : "-"}
                  </p>

                  <p key={`evoCostPerFlightHour`}>
                    <strong>Evo Hourly: </strong>
                    {row?.evoCostPerFlightHour
                      ? currencyFormatter.format(row.evoCostPerFlightHour)
                      : "-"}
                  </p>

                  <p key={`notes`}>
                    <strong>Notes: </strong>
                    {row?.notes || "-"}
                  </p>

                  <p key={`includeNotesOnQuote`}>
                    <strong>Include Notes On Quote: </strong>
                    {row?.includeNotesOnQuote ? "Yes" : "No"}
                  </p>

                  <p key={`includeOPIOnQuote`}>
                    <strong>Include OPI On Quote: </strong>
                    {row?.includeOPIOnQuote ? "Yes" : "No"}
                  </p>

                  <p key={`opi-score`}>
                    <strong>OPI: </strong>
                    {matchingOperator?.composedScore
                      ? matchingOperator.composedScore
                      : "-"}
                  </p>
                </div>
              </div>

              {/* <pre>{JSON.stringify(row, null, 2)}</pre> */}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function OperatorCallLogRow(props) {
  const { superAdminOrAd } = props;
  let { row, dispatch, handleDelete } = props;
  const [open, setOpen] = React.useState(false);

  // row = row[0];

  if (!row) return null;

  // let rowSelected = selectedRows.filter(
  //   (selectedRow) => row._id === selectedRow._id
  // );

  // rowSelected = rowSelected?.length ? true : false;

  // let matchingOperator = null;

  // if (props?.leadData?.operatorData?.length) {
  //   matchingOperator = props?.leadData?.operatorData.filter(
  //     (item) => item._id === row.operator.id
  //   );

  //   if (matchingOperator?.length) {
  //     matchingOperator = matchingOperator[0];
  //   } else {
  //     matchingOperator = null;
  //   }
  // }

  // let rowSelected = false;

  // console.log("SOURCING ROW", row);

  return (
    <>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        onClick={() => setOpen(!open)}
        className={`fm-l2-parent-table-row operator-call-log-row `}
        key={row._id}
      >
        {/* <TableCell key="toggleOpenClose">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell> */}

        {/* <TableCell key="editLink">
          <Link
            onClick={(e) => {
              if (row?.hidden) {
                e.preventDefault();
              }
              return;
            }}
            to={`/sourcing/${row?._id}`}
            className={row?.hidden ? "disabled-link" : ""}
          >
            Edit
          </Link>
        </TableCell> */}

        <TableCell key="operatorName">{row?.operator?.name}</TableCell>

        <TableCell key="timestamp">
          {moment(row.timestamp).format("MMM Do YYYY h:mm a")}
        </TableCell>

        {/* <TableCell key="status">{row?.status || ""}</TableCell> */}
        <TableCell key="notes">{row?.notes || "-"}</TableCell>

        <TableCell key="record-actions">
          <button
            className={`base-button btn-danger mr-20`}
            key={"handle-delete"}
            onClick={(e) => {
              e.preventDefault();
              handleDelete(row._id);
            }}
          >
            Delete
          </button>
        </TableCell>
      </TableRow>
    </>
  );
}

function SortableTableHead(props) {
  let showSortIcon = true;
  let sortUpText = "arrow_drop_up";
  let sortDownText = "arrow_drop_down";

  let isSorted = props.sortField === props.id ? true : false;

  console.log("DIRECTION", props.sortDirection);

  return (
    <TableCell
      onClick={(e) => {
        props.onClick(props.id);
      }}
      id={props.id}
      className="sortable-evo-header"
    >
      {props.label}{" "}
      {isSorted && (
        <span
          className={`material-icons sort-icon ${
            showSortIcon ? "sort-icon-visible" : "sort-icon-hidden"
          }`}
        >
          {props.sortDirection === 1 ? sortUpText : sortDownText}
        </span>
      )}
    </TableCell>
  );
}

export default function CollapsibleTable(props) {
  const { superAdmin } = props;
  // console.log("SOURCING ROWS", props.sourcingRows);
  return (
    <>
      <TableContainer
        component={Paper}
        className="mt-30 accordion-table-with-details"
        key="leadDataTable"
      >
        <Table aria-label="collapsible table">
          <TableHead className="fm-l2-parent-table-head">
            <TableRow className="fm-l2-parent-table-head-row">
              <TableCell />
              <TableCell>DATE REQUESTED</TableCell>
              <TableCell>STAGE</TableCell>
              <TableCell>ASSIGNEE</TableCell>
              <TableCell>LEAD NAME</TableCell>
              <TableCell>PHONE NUMBER</TableCell>
              <TableCell>EMAIL</TableCell>

              {props.rows[0].companyName !== "" && (
                <TableCell>COMPANY</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody className="fm-l2-parent-table-body">
            {props.rows.map((row) => (
              <Row key={row._id} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {props?.sourcingRows?.length > 0 && (
        <>
          <h2 className="mt-30 mb-0 text-center">SOURCING</h2>

          <TableContainer
            component={Paper}
            className="mt-30 accordion-table-with-details sourcing-table"
            key="sourcingTable"
          >
            <Table aria-label="collapsible table">
              <TableHead className="fm-l2-parent-table-head">
                <TableRow className="fm-l2-parent-table-head-row">
                  <TableCell />
                  <TableCell>EDIT</TableCell>

                  <SortableTableHead
                    onClick={props.handleSourcingSort}
                    id="operatorName"
                    label="OPERATOR"
                    sortDirection={props.sourcingSortDirection}
                    sortField={props.sourcingSortField}
                  />

                  <SortableTableHead
                    onClick={props.handleSourcingSort}
                    id="operatorPrice"
                    label="OPERATOR PRICE"
                    sortDirection={props.sourcingSortDirection}
                    sortField={props.sourcingSortField}
                  />

                  <SortableTableHead
                    onClick={props.handleSourcingSort}
                    id="totalClientPayment"
                    label="TOTAL PRICE"
                    sortDirection={props.sourcingSortDirection}
                    sortField={props.sourcingSortField}
                  />

                  <SortableTableHead
                    onClick={props.handleSourcingSort}
                    id="aircraftName"
                    label="AIRCRAFT"
                    sortDirection={props.sourcingSortDirection}
                    sortField={props.sourcingSortField}
                  />

                  <SortableTableHead
                    onClick={props.handleSourcingSort}
                    id="createdAt"
                    label="CREATED AT"
                    sortDirection={props.sourcingSortDirection}
                    sortField={props.sourcingSortField}
                  />

                  <SortableTableHead
                    onClick={props.handleSourcingSort}
                    id="quoteStatus"
                    label="QUOTE STATUS"
                    sortDirection={props.sourcingSortDirection}
                    sortField={props.sourcingSortField}
                    // key={`quoteStatus-${props.sortDirection}-${props.sortField}`}
                  />

                  <TableCell>ACTIONS</TableCell>
                  <TableCell>SELECT</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="fm-l2-parent-table-body">
                {props?.sourcingRows?.length > 0 &&
                  props?.sourcingRows.map((row) => (
                    <SourcingRow
                      key={row?._id}
                      row={row}
                      leadData={props.lead}
                      handleDelete={props.handleSourcingRecordDelete}
                      handleHide={props.handleSourcingRecordHide}
                      dispatch={props.parentDispatch}
                      selectedRows={props.selectedRows}
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          <div className="text-right mt-20">
            <Link
              key="Add Record"
              className="base-button evo-blue"
              to={{ pathname: `/sourcing/new`, state: { lead: props.lead } }}
            >
              Add Record
            </Link>

            {superAdmin && props?.selectedRows?.length === 1 && true && (
              <Link
                to={{
                  pathname: `/contracts/new`,
                  state: {
                    lead: props.lead,
                    sourcingRecords: props.selectedRows,
                  },
                }}
                className="base-button evo-gunmetal ml-20"
              >
                New Contract
              </Link>
            )}

            {props?.selectedRows?.length > 0 && (
              <Link
                key="Create Quote"
                className="base-button evo-gunmetal ml-20"
                to={{
                  pathname: `/leads/${props?.lead?._id}/quotes/new`,
                  state: {
                    sourcingRecords: props.selectedRows,
                    leadL2Data: props.lead,
                  },
                }}
              >
                Create Quote
              </Link>
            )}
          </div>
        </>
      )}

      <h2 className="mt-30 mb-0 text-center">UNAVAILABLE</h2>

      {props?.operatorCallLog?.length > 0 && (
        <TableContainer
          component={Paper}
          className="mt-30 accordion-table-with-details unavailable-operator-table"
          key="operatorCallLogTable"
        >
          <Table aria-label="collapsible table">
            <TableHead className="fm-l2-parent-table-head">
              <TableRow className="fm-l2-parent-table-head-row">
                <SortableTableHead
                  onClick={props.handleUnavailableOperatorSort}
                  id="operatorName"
                  label="OPERATOR"
                  sortDirection={props.unavailableOperatorSortDirection}
                  sortField={props.unavailableOperatorSortField}
                />

                <SortableTableHead
                  onClick={props.handleUnavailableOperatorSort}
                  id="timestamp"
                  label="TIMESTAMP"
                  sortDirection={props.unavailableOperatorSortDirection}
                  sortField={props.unavailableOperatorSortField}
                />

                {/* <TableCell>STATUS</TableCell> */}
                <TableCell>NOTES</TableCell>
                <TableCell>ACTIONS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="fm-l2-parent-table-body">
              {props?.operatorCallLog?.length > 0 &&
                props?.operatorCallLog.map((row) => (
                  <OperatorCallLogRow
                    key={row?._id}
                    row={row}
                    leadData={props.lead}
                    handleDelete={props.handleOperatorCallLogDelete}
                    dispatch={props.parentDispatch}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <div className="text-center mt-20">
        <button
          key="Add Operator"
          className="base-button evo-blue"
          onClick={props.handleAddOperatorButton}
        >
          Add Operator
        </button>
      </div>
    </>
  );
}
