import React, { Fragment } from "react";

import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import moment from "moment-timezone";
import formatStage from "../../helpers/format-stage";
import currencyFormatter from "../../helpers/currencyFormatter";

import { Link } from "react-router-dom";

function Row(props) {
  const { row, superAdminOrAd } = props;
  const [open, setOpen] = React.useState(false);
  const lead = row;
  // console.log("ROW", row);

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        onClick={() => setOpen(!open)}
        className="fm-l2-parent-table-row"
      >
        <TableCell className="table-cell-centered">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell scope="row" className="table-cell-centered">
          <Link to={`/leads/${lead._id}`}>
            {moment(lead.createdAt).format("MMM Do YYYY h:mm a")}
          </Link>
        </TableCell>
        <TableCell className="table-cell-centered">
          {lead.estimateData[0].departureAirportCode} -{" "}
          {lead.estimateData[0].arrivalAirportCode}
        </TableCell>

        <TableCell className="table-cell-centered">
          {lead.estimateData[0].departureDate}{" "}
          {lead.tripType === 2 ? ` - ${lead.estimateData[0].returnDate}` : ""}
        </TableCell>
        <TableCell className="table-cell-centered">
          {lead.firstName} {lead.lastName}
        </TableCell>

        <TableCell className="table-cell-centered">
          {" "}
          {formatStage(lead.stage)}
        </TableCell>
        <TableCell className="table-cell-centered">
          {" "}
          {lead.assigneeName}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }} className="fm-l2-child-table-container">
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                className="fm-l2-child-table-heading mb-20 centered"
              >
                {lead.quoteData?.liveQuoteLog?.length > 0
                  ? `Quote Breakdown`
                  : "No Quotes Sent"}
              </Typography>

              {lead?.quoteData?.liveQuoteLog?.length > 0 &&
                lead.quoteData.map((quote, index) => {
                  return (
                    <div
                      className="quote-breakdown-container"
                      key={`quote-breakdown-${quote._id}`}
                    >
                      <Typography
                        variant="h6"
                        gutterBottom
                        component="div"
                        className="fm-l2-child-table-heading mb-20"
                      >
                        METADATA
                      </Typography>

                      {quote?.liveQuoteLog &&
                        quote?.liveQuoteLog?.length > 0 &&
                        quote?.liveQuoteLog[0] &&
                        quote?.liveQuoteLog[0]?.timeStamp &&
                        quote?.liveQuoteLog[0]?.timeStamp?.length > 0 && (
                          <p>
                            <strong>Sent On: </strong>
                            {moment(quote.liveQuoteLog[0].timeStamp[0]).format(
                              "MMM Do YYYY h:mm a"
                            )}
                          </p>
                        )}

                      {quote?.liveQuoteLog &&
                        quote?.liveQuoteLog?.length > 0 &&
                        quote?.liveQuoteLog[0] &&
                        quote?.liveQuoteLog[0]?.recipients &&
                        quote?.liveQuoteLog[0]?.recipients?.length >
                          0(
                            <p>
                              <strong>Sent To: </strong>
                              {quote.liveQuoteLog[0].recipients.join(", ")}
                            </p>
                          )}

                      <hr></hr>

                      <Typography
                        variant="h6"
                        gutterBottom
                        component="div"
                        className="fm-l2-child-table-heading mb-20"
                      >
                        TRIP LEGS
                      </Typography>

                      {quote.legs.map((leg, index) => {
                        return (
                          <Fragment key={`leg-index-${index}`}>
                            <h6>Leg {index + 1}</h6>
                            <p>
                              <strong>Depart: </strong>
                              {leg.departureAirport.airportName}
                            </p>
                            <p>
                              <strong>Arrive: </strong>
                              {leg.arrivalAirport.airportName}
                            </p>
                          </Fragment>
                        );
                      })}

                      <hr></hr>

                      <Typography
                        variant="h6"
                        gutterBottom
                        component="div"
                        className="fm-l2-child-table-heading mb-20"
                      >
                        AIRCRAFT OPTIONS
                      </Typography>
                      {quote.aircraftOptions.map((option, index) => {
                        return (
                          <Fragment key={`leg-index-${index}`}>
                            <p>
                              <strong>{option.aircraft.name}: </strong>
                              {currencyFormatter.format(option.totalCost)}
                            </p>
                          </Fragment>
                        );
                      })}

                      <Link
                        className="evo-blue base-button"
                        to={`/quotes/${quote._id}`}
                      >
                        Preview Quote
                      </Link>

                      {lead.quoteData.length > 1 &&
                        index + 1 < lead.quoteData.length && <hr></hr>}
                    </div>
                  );
                })}

              {/* <Table size="small" aria-label="">
                <TableHead className="fm-l2-child-table-head">
                  <TableRow>
                    <TableCell>Date Requested</TableCell>
                    <TableCell>Routing</TableCell>
                    <TableCell>Trip Dates</TableCell>
                    <TableCell>Lead Name</TableCell>
                    <TableCell>Stage</TableCell>
                    <TableCell>Owner</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="fm-l2-child-table-body">
                  <TableRow></TableRow>
                </TableBody>
              </Table> */}

              {superAdminOrAd && (
                <Link
                  className="base-button evo-blue mt-20 d-block w-200 centered"
                  to={`/flight-matches/${row._id}`}
                >
                  View All Leg Data
                </Link>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable(props) {
  const { superAdminOrAd } = props;

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead className="fm-l2-parent-table-head">
          <TableRow className="fm-l2-parent-table-head-row">
            <TableCell className="table-cell-centered" />
            <TableCell className="table-cell-centered">RECEIVED</TableCell>
            <TableCell className="table-cell-centered">ROUTING</TableCell>
            <TableCell className="table-cell-centered">TRIP DATES</TableCell>
            <TableCell className="table-cell-centered">LEAD NAME</TableCell>
            <TableCell className="table-cell-centered">STAGE</TableCell>
            <TableCell className="table-cell-centered">OWNER</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className="fm-l2-parent-table-body">
          {props.rows.map((row) => (
            <Row key={row._id} row={row} superAdminOrAd={superAdminOrAd} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
