import React, { Fragment, useReducer, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ai from "../../helpers/axios";

import { useParams, Link, useHistory } from "react-router-dom";
import PageHeaderRow from "../shared/page-header-row";
import moment from "moment-timezone";
import CollapsibleTable from "./flightLeg-match-summary-table";
import MobileCollapsibleTable from "./flightLeg-match-summary-table-mobile";

import TripDetailsTableDesktop from "./flightLeg-trip-details-desktop";
import TripDetailsTableMobile from "./flightLeg-trip-details-mobile";

import { useMediaQuery } from "react-responsive";

import { toast } from "react-toastify";

const reducer = (state, action) => {
  switch (action.type) {
    case "fieldChanged": {
      return {
        ...state,
        [action.field]: action.value,
      };
    }
    case "dataBeganDownloading": {
      return {
        ...state,
        loading: true,
      };
    }
    case "dataDownloaded": {
      return {
        ...state,
        flightLeg: action.payload.data,
        loading: false,
      };
    }
    default:
      break;
  }
  return state;
};

const SingleFlightLegContent = (props) => {
  const { id } = useParams();
  const history = useHistory();

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const initialState = {
    flightLeg: {},
    matchSummaryData: [],
    loading: true,
  };

  const { getAccessTokenSilently, user, loginWithRedirect } = useAuth0();

  const userRoles = user && user["https://app.evojets.com/roles"];

  const superAdminOrAd = userRoles.some(function (role) {
    return "Super Admin" === role || "Account Director" === role;
  });

  const [state, dispatch] = useReducer(reducer, initialState);

  const formatCurrency = (x) => {
    return "$" + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const formatStage = (stage) => {
    let formattedStage = "N/A";

    if (stage === "newLead") {
      formattedStage = "New Lead";
    } else if (stage === "inProgress") {
      formattedStage = "In Progress";
    } else if (stage === "archived") {
      formattedStage = "Archived";
    } else if (stage === "warmProspect") {
      formattedStage = "Warm Prospect";
    } else if (stage === "unqualified") {
      formattedStage = "Unqualified";
    } else if (stage === "evoEmptyLeg") {
      formattedStage = "Evo Empty Leg";
    } else if (stage === "repeatClientRequest") {
      formattedStage = "Repeat Client Request";
    } else if (stage === "newClientRequest") {
      formattedStage = "New Client Request";
    } else if (stage === "signedContract") {
      formattedStage = "Signed Contract - LTC";
    } else if (stage === "inProgressTemp") {
      formattedStage = "Soft-Deleted";
    } else if (stage === "hotProspect") {
      formattedStage = "Hot Prospect";
    } else if (stage === "noReply") {
      formattedStage = "No Reply";
    }

    return formattedStage;
  };

  const getData = async () => {
    dispatch({ type: "dataBeganDownloading" });

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let response = await ai
      .auth(token)
      .get(`/api/flightLegs/${id}`)
      .catch((error) => {
        // history.push({ pathname: "/flight-matches" });
        setTimeout(() => {
          toast.error("Unauthorized!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }, 50);

        return console.error(error.response);
      });

    if (response) {
      // console.log(response.data);

      dispatch({
        type: "dataDownloaded",
        payload: {
          data: response.data,
        },
      });

      prepareMatchSummaryData(response.data);
    }
  };

  const formatAircraftSelection = (aircraft) => {
    switch (aircraft) {
      case "heavy_jet":
        return "Heavy Jet";
      case "executive_turboprop":
        return "Turboprop";
      case "compact_light_jet":
        return "VLJ";
      case "light_jet":
        return "Light Jet";
      case "midsize_jet":
        return "Midsize Jet";
      case "super_midsize_jet":
        return "Super Midsize Jet";
      default:
        return "";
    }
  };

  const getAircraftSelection = () => {
    if (state?.flightLeg?.isFromATC) {
      //return the plane data..
      return state?.flightLeg?.atcData?.aircraft[0]?.aircraftName;
    }

    if (state?.flightLeg?.isFromLead) {
      if (state?.flightLeg?.leadData?.aircraftSelection?.length) {
        return state?.flightLeg?.leadData?.aircraftSelection
          .map((aircraft) => formatAircraftSelection(aircraft))
          .join(", ");
      }

      return "N/A";
    }
  };

  useEffect(() => {
    getData();
    document.title = "Flight Leg | Flight Deck by evoJets";
  }, []);

  const prepareMatchSummaryData = (data) => {
    let flightMatches = [...data.flightMatches];

    flightMatches = flightMatches.sort((a, b) => a.priority - b.priority);

    let matchSummaryData = flightMatches.flatMap(
      ({ legId, matchType, aircraftTypes, priority }, index) => {
        let matchData = data.matchData.filter(
          (match) => match._id === legId
        )[0];

        if (!matchData) {
          return [];
        }

        let auditTrailSorted = matchData.auditTrail.filter((event) => {
          // console.log(event);
          return (
            event.matchLegId === data._id && event.eventType === "matchAdded"
          );
        });

        // console.log("AUDIT TRAIL SORTED", auditTrailSorted, data._id);

        let matchAddedEvent = auditTrailSorted[0];

        let returnDate = "N/A";
        let totalDateRange = matchData.totalDateRange - 1;
        let fullDateRangeParsed = null;
        let departDateElement = null;
        let returnDateElement = null;

        if (matchData.isPartOfRoundTrip) {
          if (matchData.isPrimaryLeg) {
            let datePlusTDR = new Date(matchData.departureDateString);
            datePlusTDR.setDate(datePlusTDR.getDate() + totalDateRange);
            returnDate = datePlusTDR;
            fullDateRangeParsed = `${moment(matchData.departureDateString)
              .utc()
              .format("ll")} - ${moment(returnDate).utc().format("ll")}`;
            departDateElement = (
              <span>
                <strong>
                  {moment(matchData.departureDateString).utc().format("ll")}
                </strong>
              </span>
            );
            returnDateElement = (
              <span>{moment(returnDate).utc().format("ll")}</span>
            );

            fullDateRangeParsed = (
              <span>
                {departDateElement} {" - "} {returnDateElement}
              </span>
            );
          } else {
            let datePlusTDR = new Date(matchData.departureDateString);
            datePlusTDR.setDate(datePlusTDR.getDate() - totalDateRange);
            returnDate = datePlusTDR;
            fullDateRangeParsed = `${moment(returnDate)
              .utc()
              .format("ll")} - ${moment(matchData.departureDateString)
              .utc()
              .format("ll")}`;

            departDateElement = (
              <span>{moment(returnDate).utc().format("ll")}</span>
            );
            returnDateElement = (
              <span>
                <strong>
                  {moment(matchData.departureDateString).utc().format("ll")}
                </strong>
              </span>
            );

            fullDateRangeParsed = (
              <span>
                {departDateElement} {" - "} {returnDateElement}
              </span>
            );
          }
        }

        let legOriginLinkURL = null;
        let legOriginCreatedAt = null;

        if (matchData.isFromATC) {
          legOriginLinkURL = `/atc/${matchData.atcId}`;
          legOriginCreatedAt = data.matchDataATCDocs.filter(
            (doc) => doc._id === matchData.atcId
          )[0]?.createdAt;
        } else if (matchData.isFromLead) {
          legOriginLinkURL = `/leads/${matchData.leadId}`;
          legOriginCreatedAt = data.matchDataLeadDocs.filter(
            (doc) => doc._id === matchData.leadId
          )[0]?.createdAt;
        }

        console.log("ROOT DATA", data);

        console.log(
          "Leg Origin CREATED AT",
          legOriginCreatedAt,
          data.matchDataLeadDocs
        );
        // console.log("MATCH DATA", matchData);

        return {
          departureAirport: matchData?.departureAirport?.airportName,
          arrivalAirport: matchData?.arrivalAirport?.airportName,
          departureAirportCode: matchData?.departureAirport?.code,
          arrivalAirportCode: matchData?.arrivalAirport?.code,

          priority,
          aircraftTypes: aircraftTypes.map((type) => type.aircraftType),
          assigneeName: matchData?.assigneeName,
          legOrigin: matchData.isFromATC ? "ATC" : "RFP",
          _id: matchData._id,
          passengerCount: matchData.passengerCount,
          departureDateString: matchData.departureDateString,
          stage: matchData.stage,
          distance: matchData.distance,
          matchAddedEvent,
          firstMatchTimestamp: new Date(matchAddedEvent?.timestamp),
          partOfRoundTrip: matchData.isPartOfRoundTrip,
          returnDate,
          fullDateRangeParsed,
          correspondingLegId: matchData.correspondingLegId,
          legOriginLinkURL,
          legOriginCreatedAt,
        };
      }
    );

    dispatch({
      type: "fieldChanged",
      field: "matchSummaryData",
      value: matchSummaryData,
    });

    console.log("MATCH SUMMARY DATA", matchSummaryData);
  };

  const determineReturnDate = () => {
    let date = false;
    if (state.flightLeg.originTripType !== 2) {
      return false;
    }

    if (state.flightLeg.isFromLead) {
      console.log(
        "state?.flightLeg?.leadData?.estimateData",
        state?.flightLeg?.leadData?.estimateData
      );
      if (
        state?.flightLeg?.leadData?.estimateData[0] &&
        state?.flightLeg?.leadData?.estimateData[0]?.returnDateString
      ) {
        if (state.flightLeg.isPrimaryLeg) {
          date = state?.flightLeg?.leadData?.estimateData[0]?.returnDateString;
        } else if (!state.flightLeg.isPrimaryLeg) {
          date =
            state?.flightLeg?.leadData?.estimateData[0]?.departureDateString;
        }
      }
    }

    if (state.flightLeg.isFromATC) {
      if (state?.flightLeg?.atcData?.returnDate) {
        date = state?.flightLeg?.atcData?.returnDate;
      }
      if (state.flightLeg.isPrimaryLeg) {
        date = state?.flightLeg?.atcData?.returnDate;
      } else if (!state.flightLeg.isPrimaryLeg) {
        date = state?.flightLeg?.atcData?.departureDate;
      }
    }

    // if (date) {
    //   return moment(date).utc().format("ddd, MMM Do YYYY");
    // }

    return false;
  };

  const prepareLegDetailsData = (legData) => {
    let legDetailsData = [];
    let legOneData,
      legTwoData = null;

    if (!legData.isPartOfRoundTrip) {
      if (legData.isFromLead) {
        legData.originData = legData.leadData;
      } else if (legData.isFromATC) {
        legData.originData = legData.atcData;
      }

      legData.isCurrentLeg = true;

      legData.indexText = "One";

      legDetailsData = [{ ...legData }];
      return legDetailsData;
    }

    if (legData.isPrimaryLeg) {
      //sort this first
      legOneData = { ...legData };
      legTwoData = { ...legData.correspondingLegData[0] };
      legOneData.isCurrentLeg = true;
      legOneData.indexText = "One";
      legTwoData.indexText = "Two";

      delete legOneData.correspondingLegData;
    } else {
      legOneData = { ...legData.correspondingLegData[0] };
      legTwoData = { ...legData };
      legTwoData.isCurrentLeg = true;
      legOneData.indexText = "One";
      legTwoData.indexText = "Two";

      delete legTwoData.correspondingLegData;
    }

    if (legData.isFromLead) {
      legOneData.originData = legData.leadData;
      legTwoData.originData = legData.leadData;
    } else if (legData.isFromATC) {
      legOneData.originData = legData.atcData;
      legTwoData.originData = legData.atcData;
    }

    return [legOneData, legTwoData];
  };

  return (
    <Fragment>
      <PageHeaderRow
        h1={`Flight Leg #${id}`}
        pretitle="Business"
        breadcrumbs={[{ link: "/flight-matches", label: "Flight Matches" }]}
        isMobile={isMobile}
        mobileCurrentPageLabel={"Flight Leg"}
      ></PageHeaderRow>

      {/* <div className="flex-table flex-table--6cols flex-table--collapse">
        <div className="column-1">
          <div className="flex-table-cell flex-table-cell--head o-0">
            Leg Type
          </div>
          <div className="flex-table-cell flex-table-cell--body o-1">
            {state?.flightLeg?.originTripType === 1 ? "One Way" : "Round Trip"}
          </div>
        </div>

        <div className="column-2">
          <div className="flex-table-cell flex-table-cell--head o-0">
            Departure Date
          </div>
          <div className="flex-table-cell flex-table-cell--body o-1">
            {moment(state.flightLeg.departureDateString)
              .utc()
              .format("ddd, MMM Do YYYY")}
          </div>
        </div>

        <div className="column-3">
          <div className="flex-table-cell flex-table-cell--head o-0">
            Departure Airport
          </div>
          <div className="flex-table-cell flex-table-cell--body o-1">
            {state?.flightLeg?.departureAirport?.airportName}
          </div>
        </div>

        <div className="column-4">
          <div className="flex-table-cell flex-table-cell--head o-0">
            Arrival Airport
          </div>
          <div className="flex-table-cell flex-table-cell--body o-1">
            {state?.flightLeg?.arrivalAirport?.airportName}
          </div>
        </div>
        <div className="column-5">
          <div className="flex-table-cell flex-table-cell--head o-0">
            Passengers
          </div>
          <div className="flex-table-cell flex-table-cell--body o-1">
            {state?.flightLeg?.passengerCount}
          </div>
        </div>
        <div className="column-6">
          <div className="flex-table-cell flex-table-cell--head o-0">
            Aircraft Selection
          </div>
          <div className="flex-table-cell flex-table-cell--body o-1">
            {getAircraftSelection()}
          </div>
        </div>
      </div> */}

      {!state.loading && !isMobile && (
        <TripDetailsTableDesktop
          superAdminOrAd={superAdminOrAd}
          rows={prepareLegDetailsData(state.flightLeg)}
          currentLegId={id}
        ></TripDetailsTableDesktop>
      )}

      {!state.loading && isMobile && (
        <TripDetailsTableMobile
          superAdminOrAd={superAdminOrAd}
          rows={prepareLegDetailsData(state.flightLeg)}
          currentLegId={id}
        ></TripDetailsTableMobile>
      )}

      {!state.loading && false && (
        <div className="content-block">
          <h3>Current Leg Trip Details</h3>
          <table className="evo-table grid-table">
            <thead>
              <tr>
                <th>Trip Type</th>
                <th>CL Departure Date</th>

                {determineReturnDate() && (
                  <th>Corresponding Leg Departure Date</th>
                )}

                <th>Departure Airport</th>
                <th>Arrival Airport</th>
                <th>Passengers</th>
                <th>Aircraft Selection</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {state?.flightLeg?.originTripType === 1
                    ? "One Way"
                    : "Round Trip"}
                </td>
                <td>
                  {moment(state.flightLeg.departureDateString)
                    .utc()
                    .format("ddd, MMM Do YYYY")}
                </td>

                {determineReturnDate() && <td>{determineReturnDate()}</td>}

                <td>{state.flightLeg.departureAirport.airportName}</td>
                <td>{state.flightLeg.arrivalAirport.airportName}</td>

                <td>{state.flightLeg.passengerCount}</td>
                <td>{getAircraftSelection()}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      {!state.loading && false && (
        <div className="content-block">
          <h3>Current Leg Metadata</h3>
          <table className="evo-table">
            <thead>
              <tr>
                <th>Assignee</th>
                <th>Leg Origin</th>
                <th>Origin Created At</th>
                <th>Origin Stage</th>
                <th>Primary Leg</th>
                <th>Corresponding Leg</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{state.flightLeg.assigneeName}</td>
                <td>
                  {state.flightLeg.isFromATC === true ? (
                    <Link to={`/atc/${state.flightLeg.atcId}`}>
                      View ATC Record
                    </Link>
                  ) : (
                    <Link to={`/leads/${state.flightLeg.leadId}`}>
                      View Lead
                    </Link>
                  )}
                </td>
                <td>
                  {moment(state.flightLeg.originCreatedAt)
                    .utc()
                    .format("ddd, MMM Do YYYY")}
                </td>
                <td>{formatStage(state.flightLeg.stage)}</td>
                <td>{state.flightLeg.isPrimaryLeg ? "Yes" : "No"}</td>
                <td>
                  {state.flightLeg.originTripType === 2 ? (
                    <Link
                      to={`/flight-matches/${state.flightLeg.correspondingLegId}`}
                    >
                      View
                    </Link>
                  ) : (
                    "N/A"
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      {!state.loading && state.flightLeg.matchData.length > 0 && (
        <>
          <h3 className="centered mt-30 mb-30">Flight Matches Summary</h3>
          {!isMobile && (
            <CollapsibleTable
              superAdminOrAd={superAdminOrAd}
              rows={state.matchSummaryData}
            ></CollapsibleTable>
          )}
          {isMobile && (
            <MobileCollapsibleTable
              superAdminOrAd={superAdminOrAd}
              rows={state.matchSummaryData}
            ></MobileCollapsibleTable>
          )}
        </>
      )}
      {/* 
      {superAdminOrAd && (
        // <pre>{JSON.stringify(state.matchSummaryData, null, 2)}</pre>
      )} */}

      {/* <pre>{JSON.stringify(state.flightLeg, null, 2)}</pre> */}
    </Fragment>
  );
};

export default SingleFlightLegContent;
