import React from "react";
import LeadsContent from "../components/leads/leads-content";

const SignedContractLeads = () => {
  return (
    <LeadsContent
      prefilters={["signedContract", "signedContractQC"]}
      defaultStageFilters={{
        label: "Signed Contract",
        value: "signedContract",
      }}
      pageTitle="Signed Contract Leads"
    />
  );
};

export default SignedContractLeads;
