import React, { Fragment } from "react";

import Loader from "./loader";
import DataTable from "react-data-table-component";

const DataTableServerPagination = (props) => {
  const tableData = {
    columns: props.columns,
    data: props?.data?.length ? props.data : [],
  };

  let fixedHeader = true;
  if (props?.overrideFixedHeader) {
    fixedHeader = false;
  }

  console.log("TABLE DATA", tableData);

  return (
    <div className="server-pagination-table-container">
      <DataTable
        data={tableData.data}
        columns={tableData.columns}
        selectableRows={props.disableSelection ? false : true}
        noHeader
        key="dataTable-server-pagination"
        // rowKey="_id"
        // key={JSON.stringify(tableData.data)}
        highlightOnHover
        pagination={props.hideFooter ? false : true}
        paginationServer
        paginationPerPage={props.state.countPerPage}
        paginationRowsPerPageOptions={[25, 50, 100, 150, 200, 250, 500]}
        onChangePage={props.handlePageChange}
        onChangeRowsPerPage={props.handleRowsPerPageChange}
        paginationTotalRows={props.state.totalLength}
        onSelectedRowsChange={props.onSelection}
        clearSelectedRows={props.state.clearSelectedRows}
        paginationDefaultPage={props.state.currentPage}
        responsive={true}
        defaultSortField={props.defaultSortField}
        defaultSortAsc={props.defaultSortAsc}
        selectableRowSelected={props.selectableRowSelected}
        onSort={props.handleSort}
        sortServer={props.sortServer}
        // progressPending={props.state.loading}
        keyField={"_id"}
        // progressComponent={<Loader />}
        persistTableHead={props.persistTableHead}
        selectableRowsHighlight
        className="server-pagination-table"
        onRowClicked={props.onRowClicked}
        fixedHeader={fixedHeader}
        fixedHeaderScrollHeight="60vh"
      ></DataTable>
    </div>
  );
};

export default DataTableServerPagination;
