import React, { Fragment, useReducer, useEffect } from "react";
import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import ai from "../../helpers/axios";

import { useHistory, useParams } from "react-router-dom";

import * as Sentry from "@sentry/react";
import TextInput from "../text-input";
import PhoneInput from "react-phone-number-input";

import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import "react-json-pretty/themes/monikai.css";
import JSONPretty from "react-json-pretty";

let toastOptions = {
  position: "top-right",
  autoClose: 6000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "fieldChanged": {
      return {
        ...state,
        [action.field]: action.value,
      };
    }
    default:
      break;
  }
  return state;
};

const CreateProspectContent = (props) => {
  const { id } = useParams();
  const history = useHistory();

  const initialState = {
    prospectFirstName: "",
    prospectLastName: "",
    prospectEmail: "",
    prospectPhone: "",
    prospectCountryCode: "US",
    // loading: true,
    prospectTagOptions: [
      { label: "RFP", value: "rfp" },
      { label: "Phone Call", value: "phoneCall" },
    ],
    selectedStage: null,
    selectedOwner: null,
    prospectTags: [],
    stageOptions: [
      {
        label: "New Lead",
        value: "newLead",
        stageId: 11,
      },
      {
        label: "In Progress",
        value: "inProgress",
        stageId: 2,
      },
      {
        label: "Archived",
        value: "archived",
        stageId: 8,
      },
      {
        label: "Warm Prospect",
        value: "warmProspect",
        stageId: 7,
      },
      {
        label: "Unqualified",
        value: "unqualified",
        stageId: 5,
      },
      {
        label: "In Limbo",
        value: "inLimbo",
        stageId: 1,
      },
      {
        label: "Closed - No Reply",
        value: "closedNoReply",
        stageId: 10,
      },
      {
        label: "Closed - Ghost",
        value: "closedGhost",
        stageId: 9,
      },
      {
        label: "Closed - Repeat Client",
        value: "repeatClient",
        stageId: 4,
      },
      {
        label: "Closed - Lost to Competitor",
        value: "closedCompetitor",
        stageId: 7,
      },
      {
        label: "Tip Complete",
        value: "tripComplete",
        stageId: 4,
      },
      {
        label: "Signed Contract",
        value: "signedContract",
        stageId: 4,
      },
    ],
  };

  const { getAccessTokenSilently, user, loginWithRedirect } = useAuth0();

  const userRoles = user && user["https://app.evojets.com/roles"];

  const superAdminOrAd = userRoles.some(function (role) {
    return "Super Admin" === role || "Account Director" === role;
  });

  const salesAssistant = userRoles.some(function (role) {
    return "Sales Assistant" === role;
  });

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    loadOwnerOptions();
    document.title = "Create Prospect | Flight Deck by evoJets";
  }, []);

  const handlePhoneNumberChange = (value) => {
    dispatch({
      type: "fieldChanged",
      field: "prospectPhone",
      value: value,
    });
  };

  const loadOwnerOptions = async () => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let { data } = await ai.auth(token).get(`/api/users/roles/dynamicSales`);

    if (data.users.length) {
      let results = data.users.map((user) => {
        return { value: user.user_id, label: user.name, email: user.email };
      });

      dispatch({ type: "fieldChanged", field: "ownerOptions", value: results });

      return results;
    } else {
      return [];
    }
  };

  const handleOwnerSelection = (selectedOwner) => {
    dispatch({
      type: "fieldChanged",
      field: "selectedOwner",
      value: selectedOwner,
    });
    return selectedOwner;
  };

  const handleProspectTagSelection = (selectedTags) => {
    dispatch({
      type: "fieldChanged",
      field: "prospectTags",
      value: selectedTags,
    });
    return selectedTags;
  };

  const handleStageSelection = (selectedStage) => {
    dispatch({
      type: "fieldChanged",
      field: "selectedStage",
      value: selectedStage,
    });
    return selectedStage;
  };

  const handlePhoneCountryChange = (countryCode) => {
    console.log(countryCode);
    dispatch({
      type: "fieldChanged",
      field: "prospectCountryCode",
      value: countryCode,
    });
  };

  const handleTextInputChange = (e) => {
    dispatch({
      type: "fieldChanged",
      field: e.currentTarget.name,
      value: e.currentTarget.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let response = await ai
      .auth(token)
      .post(`/api/prospects/create`, {
        firstName: state.prospectFirstName,
        lastName: state.prospectLastName,
        email: state.prospectEmail,
        phone: state.prospectPhone,
        countryCode: state.prospectCountryCode,
        stage: state.selectedStage.value,
        tags: state.prospectTags?.length
          ? state.prospectTags.map((item) => item.value)
          : [],
        source: "prospectCreation",
        assigneeName: state?.selectedOwner?.label,
        assigneeId: state?.selectedOwner?.value,
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });

    if (response) {
      console.log(response.data);

      if (!response?.data?.userHasAccess) {
        toast.error(
          "This Prospect already exists. Contact your AD to update ownership.",
          toastOptions
        );

        return;
      }

      if (
        response?.data?.userHasAccess &&
        response.data.prospectExisted &&
        response.data?.prospectData
      ) {
        toast.success(
          "This prospected existed already. No prospect data has been updated.",
          toastOptions
        );
      }

      dispatch({
        type: "fieldChanged",
        field: "prospectCreationResponse",
        value: response.data,
      });

      let label = `${response.data?.prospectData?.firstName} ${response.data?.prospectData?.lastName} - ${response.data?.prospectData?.emails[0]}`;

      if (response.data?.prospectData?.companyName) {
        label = `${label} - ${response.data?.prospectData?.companyName}`;
      }

      let phone = "";

      let i = response.data?.prospectData;

      if (i?.phoneNumbers?.length) {
        phone = i.phoneNumbers[0]?.number;
      }

      let countryCode = "";

      if (i?.phoneNumbers?.length) {
        countryCode = i.phoneNumbers[0]?.countryCode;
      }

      //   dispatch({
      //     type: "prospectAutoSelected",
      //     value: {
      //       value: response.data?.prospectData._id,
      //       label,
      //       emails: response?.data?.prospectData?.emails,
      //       email: response.data?.prospectData?.emails[0],
      //       phone,
      //       countryCode,
      //       orProspectId: i.orProspectId,
      //       companyName: i.companyName,
      //       phoneNumbers: i.phoneNumbers,
      //       firstName: i.firstName,
      //       lastName: i.lastName,
      //     },
      //   });

      toast.success(
        `Prospect ${response.data?.prospectData?.firstName} ${response.data?.prospectData?.lastName} was successfully created, and is now selected.`,
        toastOptions
      );

      history.push({ pathname: `/prospects/` });

      return;
    }
  };

  return (
    <Fragment>
      <h1 className="centered mt-20">Create Prospect</h1>

      <form
        className="base-form two-column"
        id="createProspectForm"
        onSubmit={handleSubmit}
      >
        {(superAdminOrAd || salesAssistant) && (
          <div className="form-group">
            <label className="base-label" htmlFor="selectedOwner">
              Prospect Owner
            </label>
            <Select
              key={`selectedOwner-${state.selectedOwner}`}
              className="prospect-owner-select prospect-form-select"
              classNamePrefix="select"
              value={state.selectedOwner}
              options={state.ownerOptions}
              //   defaultValue={state.}
              placeholder="Prospect Owner"
              onChange={handleOwnerSelection}
              name="selectedOwner"
            ></Select>
          </div>
        )}

        <div className="form-group">
          <label className="base-label" htmlFor="prospectTags">
            Prospect Tags
          </label>

          <CreatableSelect
            key={`selectedOwner-${state.prospectTags && state.prospectTags[0]}`}
            className="tag-select prospect-form-select"
            classNamePrefix="select"
            isMulti
            value={state.prospectTags}
            options={state.prospectTagOptions}
            //   defaultValue={state.}
            placeholder="Prospect Tags"
            onChange={handleProspectTagSelection}
            name="prospectTags"
          ></CreatableSelect>
        </div>

        <div className="form-group">
          <label className="base-label" htmlFor="selectedStage">
            Prospect Stage
          </label>
          <Select
            key={`stage-${state.selectedStage}`}
            className="stage-select prospect-form-select"
            classNamePrefix="select"
            value={state.selectedStage}
            options={state.stageOptions}
            placeholder="Prospect Stage"
            onChange={handleStageSelection}
            name="selectedStage"
          ></Select>
        </div>

        <TextInput
          name="prospectFirstName"
          label="First Name"
          handleChange={handleTextInputChange}
          value={state.prospectFirstName}
        ></TextInput>
        <TextInput
          name="prospectLastName"
          label="Last Name"
          handleChange={handleTextInputChange}
          value={state.prospectLastName}
        ></TextInput>
        <TextInput
          name="prospectEmail"
          label="Email"
          type="email"
          handleChange={handleTextInputChange}
          value={state.prospectEmail}
        ></TextInput>

        <div className="form-group">
          <label className="phoneInputLabel" htmlFor="phone">
            Phone Number
          </label>
          <PhoneInput
            placeholder="Phone Number"
            value={state.prospectPhone}
            name="prospectPhone"
            onChange={handlePhoneNumberChange}
            onCountryChange={handlePhoneCountryChange}
            defaultCountry="US"
          />
        </div>
        <input type="submit" value="Submit"></input>
      </form>
      {user && (
        <>
          {/* <JSONPretty id="json-pretty" data={user}></JSONPretty> */}
          <JSONPretty id="json-pretty" data={state}></JSONPretty>
        </>
      )}
    </Fragment>
  );
};

export default CreateProspectContent;
