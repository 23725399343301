import React, { Fragment } from "react";
import NewAirportAutocomplete from "./new-airport-autocomplete";
import DatePicker from "react-date-picker";
import Select from "react-select";
import AsyncSelect from "react-select/async";

const EditFlightForm = (props) => {
  console.log(props.state);
  return (
    <Fragment>
      <h2 id="addFlightHeading" className="centered">
        Edit Flight
      </h2>
      <form id="editFlight" onSubmit={props.handleSubmit}>
        <div className="form-group">
          <label>Departure Airport</label>

          <AsyncSelect
            className="departure-airport-select"
            classNamePrefix="select"
            cacheOptions
            loadOptions={props.loadAirportOptions}
            key={props.state.departureAirportInput.label}
            defaultValue={props.state.departureAirportInput}
            placeholder="Search Airports"
            onChange={props.handleDepartureChange}
            noOptionsMessage={() => "Type to search..."}
          ></AsyncSelect>
        </div>

        <div className="form-group">
          <label>Arrival Airport</label>

          <AsyncSelect
            className="arrival-airport-select"
            classNamePrefix="select"
            cacheOptions
            key={props.state.arrivalAirportInput.label}
            loadOptions={props.loadAirportOptions}
            defaultValue={props.state.arrivalAirportInput}
            placeholder="Search Airports"
            onChange={props.handleArrivalChange}
            noOptionsMessage={() => "Type to search..."}
          ></AsyncSelect>
        </div>

        {/* <div className="form-group">
          <label>Departure Airport</label>
          <NewAirportAutocomplete
            id="departure-airport"
            placeholder="Departure Airport"
            handleChange={props.handleDepartureChange}
            defaultSelected={props.state.departureAirport.airportName}
          ></NewAirportAutocomplete>
        </div>

        <div className="form-group">
          <label>Arrival Airport</label>

          <NewAirportAutocomplete
            id="arrival-airport"
            placeholder="Arrival Airport"
            handleChange={props.handleArrivalChange}
            defaultSelected={props.state.arrivalAirport.airportName}
          ></NewAirportAutocomplete>
        </div> */}

        <div className="form-group">
          <label>Trip Type</label>

          <select
            id="tripType"
            value={props.state.tripType}
            onChange={(e) =>
              props.dispatch({
                type: "tripTypeChanged",
                payload: {
                  tripType: parseInt(e.currentTarget.value),
                },
              })
            }
            name="tripType"
            required
          >
            <option key="placeholder" value={0} hidden>
              Trip Type
            </option>
            <option key="oneWay" value={1}>
              One-Way
            </option>
            <option key="roundTrip" value={2}>
              Round Trip
            </option>
          </select>
        </div>

        <div className="form-group">
          <label>Departure Date</label>

          <DatePicker
            name="departureDate"
            onChange={(date) =>
              props.dispatch({
                type: "fieldChanged",
                field: "departureDate",
                value: date,
              })
            }
            value={props.state.departureDate}
          ></DatePicker>
        </div>

        {props.state.tripType === 2 && (
          <div className="form-group">
            <label>Return Date</label>

            <DatePicker
              name="returnDate"
              onChange={(date) =>
                props.dispatch({
                  type: "fieldChanged",
                  field: "returnDate",
                  value: date,
                })
              }
              value={props.state.returnDate}
            ></DatePicker>
          </div>
        )}

        <div className="form-group">
          <label>Aircraft</label>

          <AsyncSelect
            className="aircraft-select"
            classNamePrefix="select"
            cacheOptions
            isMulti
            key={props.state.loading}
            loadOptions={props.loadAircraftOptions}
            defaultValue={props.state.selectedAircraft}
            placeholder="Search Aircraft"
            onInputChange={props.handleAircraftInputChange}
            onChange={props.handleAircraftSelection}
            noOptionsMessage={() => "Type to search..."}
          ></AsyncSelect>
        </div>

        <div className="form-group containsNumberInput">
          <label htmlFor="passengers">Passengers</label>
          <input
            type="number"
            name="passengers"
            placeholder="Passengers"
            // onChange={(e) =>
            //   dispatch({
            //     type: "fieldChanged",
            //     field: e.target.name,
            //     value: e.target.value,
            //     fieldType: e.target.type,
            //   })
            // }
            onChange={(e) =>
              props.dispatch({
                type: "fieldChanged",
                field: "passengers",
                value: parseInt(e.target.value),
              })
            }
            value={props.state.passengers}
          ></input>
        </div>

        <div className="form-group">
          <label>Owner</label>

          <Select
            className="owner-select"
            classNamePrefix="select"
            options={props.state.userOptions}
            key={props.state.departureAirport.code}
            defaultValue={props.state.selectedOwner}
            placeholder="Assign Flight"
            onInputChange={props.handleOwnerInputChange}
            onChange={props.handleOwnerSelection}
          ></Select>
        </div>

        <div className="form-group">
          <label>Priority</label>

          <select
            id="flightPriority"
            value={props.state.flightPriority}
            onChange={(e) =>
              props.dispatch({
                type: "fieldChanged",
                field: "flightPriority",
                value: e.currentTarget.value,
              })
            }
            name="flightPriority"
            required
          >
            <option key="placeholder" value={0} hidden>
              Flight Priority
            </option>
            <option key="evoEmptyLeg" value={1}>
              Evo Empty Leg
            </option>
            <option key="signedContract" value={2}>
              Signed Contract
            </option>
            <option key="repeatClient" value={3}>
              Repeat Client Request
            </option>
            <option key="newClient" value={4}>
              New Client Request
            </option>
          </select>
        </div>

        <div className="form-group">
          <label>Notes</label>

          <textarea
            value={props.state.flightNotes}
            id="flightNotes"
            placeholder="Notes"
            onChange={(e) =>
              props.dispatch({
                type: "fieldChanged",
                field: "flightNotes",
                value: e.currentTarget.value,
              })
            }
          ></textarea>
        </div>

        <input type="submit" value="Edit Flight"></input>
      </form>
    </Fragment>
  );
};

export default EditFlightForm;
