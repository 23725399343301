import { Link } from "react-router-dom";

const columns = [
  {
    name: "Edit",
    sortable: false,
    cell: (row) => {
      return <Link to={`/users/${row.user_id}`}>Edit</Link>;
    },
    width: "70px",
  },
  {
    name: "Name",
    sortable: true,
    selector: "name",
    width: "170px",
  },

  {
    name: "Email",
    sortable: true,
    selector: "email",
    width: "220px",
  },
  {
    name: "Organization Name",
    sortable: true,
    selector: "app_metadata.organizationName",
    width: "200px",
  },
  {
    name: "Organization ID",
    sortable: false,
    selector: "app_metadata.organizationId",
    width: "250px",
  },
  {
    name: "Phone",
    sortable: false,
    selector: "user_metadata.phoneNumber",
    width: "150px",
  },
];

export default columns;
