import React from "react";
import LeadsContent from "../components/leads/leads-content";

const TripCompleteLeads = () => {
  return (
    <LeadsContent
      prefilters={["tripComplete"]}
      defaultStageFilters={{
        label: "Trip Complete",
        value: "tripComplete",
      }}
      pageTitle="Trip Complete Leads"
    />
  );
};

export default TripCompleteLeads;
