import React, { useState } from "react";

const TextInput = (props) => {
  let otherProps = {};

  const [enabled, setEnabled] = useState(
    props.disabledTillClicked ? false : true
  );

  if (props.handleBlur) {
    otherProps.onBlur = props.handleBlur;
  }

  if (props.max) {
    otherProps.max = props.max;
  }

  if (props.min) {
    otherProps.min = props.min;
  }

  const handleClick = () => {
    setEnabled(true);
  };

  return (
    <div
      className={`form-group ${
        props.containerClasses ? props.containerClasses : ""
      }`}
    >
      <label htmlFor={props.name}>
        {props.label}{" "}
        {props.required ? <span className="required">*</span> : ``}
      </label>

      <input
        // key={props.value}
        type={props.type || "text"}
        name={props.name}
        value={props.value}
        placeholder={props.label || props.placeholder}
        onChange={props.handleChange}
        className={`${
          props.className ? props.className : ""
        } standardTextInput ${enabled ? "" : "disabledTillClicked"}`}
        onClick={handleClick}
        onBlur={props.handleBlur}
        {...otherProps}
      ></input>
    </div>
  );
};

export default TextInput;
