import React, { Fragment } from "react";

import { Hero } from "../components";
import FlightBoardContent from "../components/flight-board-content";

const FlightBoard = () => {
  return (
    <Fragment>
      <Hero />
      <FlightBoardContent />
    </Fragment>
  );
};

export default FlightBoard;
