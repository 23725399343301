import React, { Fragment } from "react";

import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import moment from "moment-timezone";

import { Link } from "react-router-dom";

function Row(props) {
  const { row, superAdminOrAd } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        onClick={() => setOpen(!open)}
        className="fm-l2-parent-table-row"
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell scope="row">
          {row.departureAirportCode} - {row.arrivalAirportCode}
        </TableCell>

        {/* 
        <TableCell scope="row">{row.departureAirport}</TableCell>
        <TableCell>{row.arrivalAirport}</TableCell> */}

        {/* {(superAdminOrAd || row.legOrigin === "ATC") && (
          <TableCell>
            <Link to={row.legOriginLinkURL}>{row.legOrigin}</Link>
          </TableCell>
        )}

        {!superAdminOrAd && row.legOrigin === "RFP" && (
          <TableCell>{row.legOrigin}</TableCell>
        )}
        <TableCell>{row.priority}</TableCell>
        <TableCell>{row.assigneeName}</TableCell>
        <TableCell>{moment(row.legOriginCreatedAt).format("llll")}</TableCell> */}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }} className="fm-l2-child-table-container">
              <Typography
                variant="h6"
                gutterBottom
                component="div"
                className="fm-l2-child-table-heading"
              >
                Additional Data
              </Typography>
              <h6>DEPARTURE AIRPORT</h6>
              <p>{row.departureAirport}</p>
              <h6>ARRIVAL Airport</h6>
              <p>{row.arrivalAirport}</p>
              <h6>OWNER</h6>
              <p>{row.assigneeName}</p>

              <h6>PRIORITY</h6>
              <p>{row.priority}</p>

              <h6>ORIGIN RECORD CREATED AT</h6>
              <p>{moment(row.legOriginCreatedAt).format("llll")}</p>

              <h6>FIRST MATCHED</h6>
              <p>{moment(row.firstMatchTimestamp).format("llll")}</p>
              <h6>PASSENGERS</h6>
              <p>{row.passengerCount}</p>
              <h6>AIRCRAFT MATCHED</h6>
              <p>{row.aircraftTypes.join(", ")}</p>
              <h6>PART OF ROUND TRIP</h6>
              <p>
                {row.partOfRoundTrip ? (
                  <Link to={`/flight-matches/${row.correspondingLegId}`}>
                    Yes, View Other Leg
                  </Link>
                ) : (
                  "No"
                )}
              </p>

              {row.fullDateRangeParsed && (
                <>
                  <h6>TRIP DATES</h6>
                  <p> {row.fullDateRangeParsed}</p>
                </>
              )}

              {/* <Table size="small" aria-label="">
                <TableHead className="fm-l2-child-table-head">
                  <TableRow>
                    <TableCell>FIRST MATCHED</TableCell>
                    <TableCell>PASSENGERS</TableCell>
                    <TableCell>AIRCRAFT MATCHED</TableCell>
                    <TableCell>PART OF ROUND TRIP</TableCell>
                    <TableCell>DEPARTURE DATE</TableCell>
                    {row.fullDateRangeParsed && (
                      <TableCell>TRIP DATES</TableCell>
                    )}
                    <TableCell>DISTANCE</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="fm-l2-child-table-body">
                  <TableRow>
                    <TableCell>
                      {moment(row.firstMatchTimestamp).format("llll")}
                    </TableCell>

                    <TableCell>{row.passengerCount}</TableCell>
                    <TableCell>{row.aircraftTypes.join(", ")}</TableCell>

                    {superAdminOrAd && (
                      <TableCell>
                        {row.partOfRoundTrip ? (
                          <Link
                            to={`/flight-matches/${row.correspondingLegId}`}
                          >
                            Yes, View Other Leg
                          </Link>
                        ) : (
                          "No"
                        )}
                      </TableCell>
                    )}

                    {!superAdminOrAd && (
                      <TableCell>
                        {row.partOfRoundTrip ? "Yes" : "No"}
                      </TableCell>
                    )}

                    <TableCell>
                      {moment(row.departureDateString).utc().format("ll")}
                    </TableCell>

                    {row.fullDateRangeParsed && (
                      <TableCell>{row.fullDateRangeParsed}</TableCell>
                    )}
                    <TableCell>{Math.round(row.distance)} mi</TableCell>
                  </TableRow>
                </TableBody>
              </Table> */}

              {superAdminOrAd && (
                <Link
                  className="base-button evo-blue mt-20 d-block w-200 centered"
                  to={`/flight-matches/${row._id}`}
                >
                  View All Leg Data
                </Link>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable(props) {
  const { superAdminOrAd } = props;
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead className="fm-l2-parent-table-head">
          <TableRow className="fm-l2-parent-table-head-row">
            <TableCell />
            <TableCell>ROUTING</TableCell>
            {/* <TableCell>ARRIVAL AIRPORT</TableCell>
            <TableCell>LEG ORIGIN</TableCell>
            <TableCell>PRIORITY</TableCell>
            <TableCell>ASSIGNEE NAME</TableCell>
            <TableCell>ORIGIN CREATED AT</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody className="fm-l2-parent-table-body">
          {props.rows.map((row) => (
            <Row key={row._id} row={row} superAdminOrAd={superAdminOrAd} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
