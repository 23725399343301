import React, { Fragment, useReducer, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import ai from "../../helpers/axios";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import Select from "react-select";

import ReactModal from "react-modal";
import { useHistory, useLocation } from "react-router-dom";

import TextInput from "../text-input";
import PhoneInput from "react-phone-number-input";

import "react-json-pretty/themes/monikai.css";
import JSONPretty from "react-json-pretty";

import * as Sentry from "@sentry/react";

const reducer = (state, action) => {
  switch (action.type) {
    case "fieldChanged": {
      let value =
        action.fieldType === "number" ? parseInt(action.value) : action.value;

      return {
        ...state,
        [action.field]: value,
      };
    }

    case "resetState": {
      return {
        prospectFirstName: "",
        prospectLastName: "",
        prospectEmail: "",
        prospectPhone: "",
        prospectCountryCode: "US",
        selectedProspectOwner: {},
        prospectOwnerOptions: [],
      };
    }

    default:
      break;
  }
  return state;
};

const CreateProspectModal = (props) => {
  let initialState = {
    prospectFirstName: "",
    prospectLastName: "",
    prospectEmail: "",
    prospectPhone: props?.defaultPhoneNumber || "",
    prospectCountryCode: "US",
    selectedProspectOwner: {},
    prospectOwnerOptions: props.prospectOwnerOptions || [],
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();

  const { getAccessTokenSilently, user, loginWithRedirect } = useAuth0();

  useEffect(() => {}, []);

  // console.log("MODAL STATE", state);

  const handlePhoneNumberChange = (value) => {
    dispatch({
      type: "fieldChanged",
      field: "prospectPhone",
      value: value,
    });
  };

  const handlePhoneCountryChange = (countryCode) => {
    console.log(countryCode);
    dispatch({
      type: "fieldChanged",
      field: "prospectCountryCode",
      value: countryCode,
    });
  };

  const handleTextInputChange = (e) => {
    dispatch({
      type: "fieldChanged",
      field: e.currentTarget.name,
      value: e.currentTarget.value,
    });
  };

  const handleProspectOwnerChange = (selectedOwner) => {
    dispatch({
      type: "fieldChanged",
      field: "selectedProspectOwner",
      value: selectedOwner,
    });
    return selectedOwner;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let prospectData = {
      firstName: state.prospectFirstName,
      lastName: state.prospectLastName,
      email: state.prospectEmail,
      phone: state.prospectPhone,
      countryCode: state.prospectCountryCode,
      source: props.source,
      assigneeId: state?.selectedProspectOwner?.value,
      phoneNumbers: [
        {
          number: state.prospectPhone,
          countryCode: state.prospectCountryCode,
          order: 1,
          type: "mobile",
        },
      ],
    };

    if (
      state?.selectedProspectOwner?.value &&
      state?.selectedProspectOwner?.label
    ) {
      prospectData.assigneeId = state?.selectedProspectOwner?.value;
      prospectData.assigneeName = state?.selectedProspectOwner?.label;
    }

    let prospectDataForAPI = {
      ...prospectData,
      tags: props.tags,
    };
    props.parentDispatch({
      type: "fieldChanged",
      field: "createdProspect",
      value: {
        ...prospectData,
      },
    });

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let response = await ai
      .auth(token)
      .post(`/api/prospects/modalCreate`, {
        ...prospectDataForAPI,
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });

    if (response) {
      console.log(response.data);

      if (response?.data?.details?.length) {
        let toastErrorMessage =
          "Please fill in all fields and ensure they are valid.";
        if (
          response.data.details[0]?.message ===
          `"phoneNumbers[0].number" is not allowed to be empty"`
        ) {
          toastErrorMessage = "Please enter a valid phone number.";
        }

        props.toast.error(toastErrorMessage, props.toastOptions);
        return;
      }

      if (!response?.data?.userHasAccess) {
        props.toast.error(
          "This Prospect already exists. Contact your AD to update ownership.",
          props.toastOptions
        );

        props.parentDispatch({
          type: "fieldChanged",
          field: "createProspectModalIsOpen",
          value: false,
        });

        return;
      }

      if (
        response?.data?.userHasAccess &&
        response.data.prospectExisted &&
        response.data?.prospectData
      ) {
        props.toast.success(
          "This prospected existed already, and is now selected. No prospect data has been updated.",
          props.toastOptions
        );
      }

      props.parentDispatch({
        type: "fieldChanged",
        field: "prospectCreationResponse",
        value: response.data,
      });

      let label = `${response.data?.prospectData?.firstName} ${response.data?.prospectData?.lastName} - ${response.data?.prospectData?.emails[0]}`;

      if (response.data?.prospectData?.companyName) {
        label = `${label} - ${response.data?.prospectData?.companyName}`;
      }

      let phone = "";

      let i = response.data?.prospectData;

      if (i?.phoneNumbers?.length) {
        phone = i.phoneNumbers[0]?.number;
      }
      let countryCode = "";

      if (i?.phoneNumbers?.length) {
        countryCode = i.phoneNumbers[0]?.countryCode;
      }

      props.parentDispatch({
        type: "prospectAutoSelected",
        value: {
          value: response.data?.prospectData._id,
          label,
          emails: response?.data?.prospectData?.emails,
          email: response.data?.prospectData?.emails[0],
          phone,
          countryCode,
          orProspectId: i.orProspectId,
          companyName: i.companyName,
          phoneNumbers: i.phoneNumbers,
          firstName: i.firstName,
          lastName: i.lastName,
        },
      });

      props.parentDispatch({
        type: "fieldChanged",
        field: "createProspectModalIsOpen",
        value: false,
      });

      props.toast.success(
        `Prospect ${response.data?.prospectData?.firstName} ${response.data?.prospectData?.lastName} was successfully created, and is now selected.`,
        props.toastOptions
      );

      return;
    }
  };

  return (
    <ReactModal
      isOpen={props.modalIsOpen}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() =>
        props.parentDispatch({
          type: "fieldChanged",
          field: "createProspectModalIsOpen",
          value: false,
        })
      }
      style={{
        overlay: {},
        content: {
          width: "50%",
          // height: "auto",
          maxWidth: "700px",
          // maxHeight: "50%",
        },
      }}
      appElement={document.getElementById("app")}
    >
      <form
        className="base-form"
        id="createProspectModalForm"
        onSubmit={handleSubmit}
      >
        <h3>Create Prospect</h3>

        {props.showOwnerSelection && (
          <div className="form-group">
            <label className="base-label" htmlFor="selectedStage">
              Prospect Owner
            </label>

            <Select
              // key={`prospectOwner`}
              className="stage-select prospect-form-select"
              classNamePrefix="select"
              value={state.prospectOwner}
              options={props.prospectOwnerOptions}
              placeholder="Prospect Owner"
              onChange={handleProspectOwnerChange}
              name="selectedProspectOwner"
            ></Select>
          </div>
        )}

        <TextInput
          name="prospectFirstName"
          label="First Name"
          handleChange={handleTextInputChange}
          value={state.prospectFirstName}
        ></TextInput>
        <TextInput
          name="prospectLastName"
          label="Last Name"
          handleChange={handleTextInputChange}
          value={state.prospectLastName}
        ></TextInput>
        <TextInput
          name="prospectEmail"
          label="Email"
          type="email"
          handleChange={handleTextInputChange}
          value={state.prospectEmail}
        ></TextInput>

        <div className="form-group">
          <label className="phoneInputLabel" htmlFor="phone">
            Phone Number
          </label>
          <PhoneInput
            placeholder="Phone Number"
            value={state.prospectPhone}
            name="prospectPhone"
            onChange={handlePhoneNumberChange}
            onCountryChange={handlePhoneCountryChange}
            defaultCountry="US"
          />
        </div>
        <input type="submit" value="Submit"></input>
      </form>

      {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
    </ReactModal>
  );
};

export default CreateProspectModal;
