import React from "react";

const TripStatusBanner = ({ stage }) => {
  // Only show for canceled or archived trips
  if (!stage || !["canceled", "archived"].includes(stage)) return null;

  return (
    <div className="status-banner">
      <span className="material-icons">
        {stage === "archived" ? "archive" : "cancel"}
      </span>
      <span className="status-text">
        {stage === "archived" ? "Archived Trip" : "Canceled Trip"}
      </span>
    </div>
  );
};

export default TripStatusBanner;
