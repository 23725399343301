import React, { Fragment, useEffect, useReducer } from "react";
import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";

import ai from "../../helpers/axios";
import columns from "./estimate-columns";
import Search from "../search";
import DataTableServerPagination from "../data-table-server-pagination";
import PageHeaderRowSearch from "../shared/page-header-row-search";

const reducer = (state, action) => {
  switch (action.type) {
    case "search": {
      return {
        ...state,
        query: action.query,
      };
    }
    case "dataBeganDownloading": {
      return {
        ...state,
        loading: action.payload.loadingStatus,
      };
    }
    case "dataDownloaded": {
      return {
        ...state,
        estimates: {
          data: action.payload.data.estimates,
        },
        loading: false,
        totalLength: action.payload.data.countTotal,
      };
    }
    case "pageChanged": {
      return {
        ...state,
        currentPage: action.payload.page,
      };
    }
    case "tableSort": {
      return {
        ...state,
        sortBy: action.payload.sortBy,
        sortDirection: action.payload.sortDirection,
      };
    }
    case "tagFilterChanged": {
      return {
        ...state,
        tagFilters: action.payload.values,
      };
    }
    case "rowsPerPageChanged": {
      return {
        ...state,
        countPerPage: action.payload.currentRowsPerPage,
      };
    }

    case "fieldChanged": {
      return {
        ...state,
        [action.field]: action.value,
      };
    }

    default:
      break;
  }
  return state;
};

const EstimatesContent = () => {
  const initialState = {
    estimates: {
      data: [],
    },
    loading: true,
    countPerPage: 25,
    currentPage: 1,
    selectedRows: [],
    allSelected: false,
    totalLength: null,
    clearSelectedRows: false,
    ownerInputField: "",
    selectedOwner: null,
    query: "",
    tagFilters: ["active"],
    sortDirection: "desc",
    sortBy: "createdAt",
    ownerOptions: [],
  };

  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const [state, dispatch] = useReducer(reducer, initialState);

  console.log(state);

  const getData = async (
    dbQuery,
    loadSilently,
    tagFilters,
    sortBy,
    sortDirection
  ) => {
    let loadingStatus = true;
    if (loadSilently) {
      loadingStatus = false;
    }

    dispatch({ type: "dataBeganDownloading", payload: { loadingStatus } });

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let { data } = await ai.auth(token).get(`/api/estimates/list`, {
      params: {
        query: dbQuery,
        page: state.currentPage,
        countPerPage: state.countPerPage,
        sortBy,
        sortDirection,
        tagFilters,
      },
    });

    dispatch({
      type: "dataDownloaded",
      payload: {
        data,
      },
    });
  };

  useEffect(() => {
    getData(
      state.query,
      false,
      state.tagFilters,
      state.sortBy,
      state.sortDirection
    );
    // loadOwnerOptions();
  }, [state.currentPage, state.countPerPage]);

  const handlePageChange = (page, totalRows) => {
    console.log(page, totalRows);

    dispatch({
      type: "pageChanged",
      payload: {
        page,
        totalRows,
      },
    });
  };

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    dispatch({
      type: "rowsPerPageChanged",
      payload: {
        currentRowsPerPage,
        currentPage,
      },
    });
  };

  const handleSelection = function (rowData) {
    dispatch({
      type: "rowSelection",
      payload: {
        selectedRows: rowData.selectedRows,
        allSelected: rowData.allSelected,
      },
    });
  };

  const handleSearch = (e) => {
    let query = e.target.value.toLowerCase();

    dispatch({ type: "search", query });
    getData(query, true, state.tagFilters, state.sortBy, state.sortDirection);
  };

  const loadOwnerOptions = async () => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });
    let { data } = await ai.auth(token).get(`/api/users/roles/sales`, {});

    if (data.users.length >= 1) {
      console.log(data.users);
      let results = data.users.map((user) => {
        return { value: user.user_id, label: user.name, email: user.email };
      });

      dispatch({ type: "fieldChanged", field: "ownerOptions", value: results });

      return results;
    } else {
      return [];
    }
  };

  const tagFilterOptions = [
    { label: "Active", value: "active" },
    { label: "In Progress", value: "inProgress" },
    { label: "Warm Prospect", value: "warmProspect" },
    { label: "Unqualified", value: "unqualified" },
    { label: "Archived", value: "archived" },
    { label: "Hot Prospect", value: "hotProspect" },
  ];

  const handleTagFilterChange = (selectedValues, action) => {
    console.log(selectedValues, action);
    let values = [];
    if (selectedValues) {
      values = selectedValues.map(({ value }) => value);
    }

    dispatch({ type: "tagFilterChanged", payload: { values } });

    getData(state.query, true, values, state.sortBy, state.sortDirection);
  };

  const handleSort = (column, sortDirection) => {
    console.log("sorted", column.selector, sortDirection);

    dispatch({
      type: "tableSort",
      payload: {
        sortBy: column.selector,
        sortDirection,
      },
    });

    getData(
      state.query,
      true,
      state.tagFilters,
      column.selector,
      sortDirection
    );

    //keyField is required for server sort
  };

  return (
    <Fragment>
      <PageHeaderRowSearch
        h1={`Estimates Management`}
        pretitle="Business"
        breadcrumbs={[{ link: "/leads", label: "Leads" }]}
        rightColumn={
          <div id="quote-actions-row">
            <Search
              onSearch={handleSearch}
              placeholder="Search Estimates"
              query={state.query}
              className="lead-search"
            ></Search>
          </div>
        }
      ></PageHeaderRowSearch>

      <DataTableServerPagination
        onSelection={handleSelection}
        columns={columns}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
        state={state}
        data={state.estimates.data}
        defaultSortField={"createdAt"}
        defaultSortAsc={false}
        handleSort={handleSort}
        sortServer={true}
      ></DataTableServerPagination>

      {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
    </Fragment>
  );
};

export default EstimatesContent;
