import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import App from "./app";
// import { BrowserRouter as Router } from "react-router-dom";
import { Router } from "react-router-dom";

import Auth0ProviderWithHistory from "./auth/auth0-provider-with-history";
import { Auth0Provider, withAuthenticationRequired } from "@auth0/auth0-react";

import "./index.css";
import ScrollToTop from "./helpers/scroll-to-top";
// heroku

import { createBrowserHistory } from "history";
export const history = createBrowserHistory();

const onRedirectCallback = (appState) => {
  // Use the router's history module to replace the url
  history.replace(appState?.returnTo || window.location.pathname);
};

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

Sentry.init({
  dsn: "https://509cc66c15294293bd193f5ecd126c59@o467964.ingest.sentry.io/5786360",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <Router history={history}>
    <Auth0ProviderWithHistory
    // domain={domain}
    // clientId={clientId}
    // redirectUri={window.location.origin}
    // onRedirectCallback={onRedirectCallback}
    // audience={audience}
    >
      <App />
    </Auth0ProviderWithHistory>
  </Router>,
  document.getElementById("root")
);
