import React, { Fragment, useReducer, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ai from "../../helpers/axios";
import moment from "moment-timezone";

import ReactModal from "react-modal";
import currencyFormatter from "../../helpers/currencyFormatter";
import Select from "react-select";

// mui imports
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import NumberFormat from "react-number-format";
import Paper from "@mui/material/Paper";

import { toast } from "react-toastify";

const toastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "fieldChanged": {
      return {
        ...state,
        [action.field]: action.value,
      };
    }

    case "closeModal": {
      return {
        ...state,
        showModal: false,
      };
    }

    case "openModal": {
      return {
        ...state,
        showModal: true,
      };
    }

    default:
      break;
  }
  return state;
};

const CancelTripModule = (props) => {
  const { getAccessTokenSilently, user, loginWithRedirect } = useAuth0();
  let initialState = {
    showModal: false,
    action: "cancel",
    cancellationReason: null,
    cancellationReasonNotes: "",
    clientRefund: null,
    clientRefundNotes: "",
    operatorRefund: null,
    operatorRefundNotes: "",
  };

  const handleCancelTripButton = () => {
    //show modal

    dispatch({
      type: "openModal",
    });

    console.log("CANCEL TRIP CLICKED - MODAL OPENED", props);
  };

  const processTripData = (tripData) => {
    let clientName = tripData?.headerData?.clientName;

    let tripTag = tripData?.tripTag;

    console.log("TRIP DATA", tripData);

    let contractsByStage = [];

    const stageLabelMap = {
      saved: "Saved",
      inReview: "In Review",
      sent: "Sent",
      archived: "Archived",
      ofa: "Out For Approval",
      approved: "Approved",
      signed: "Signed",
    };

    if (tripData?.contractData?.length > 0) {
      contractsByStage = tripData.contractData.reduce(
        (accumulator, contract) => {
          const stage = contract.stage;
          const label = stageLabelMap[stage] || stage; // Default to stage if no label found
          const existingStage = accumulator.find(
            (item) => item.stage === stage
          );

          if (existingStage) {
            existingStage.count += 1;
          } else {
            accumulator.push({ stage, label, count: 1 });
          }

          return accumulator;
        },
        []
      );
    }

    return {
      clientName,
      tripTag,
      contractsByStage,
    };
  };

  initialState = processTripData(props.tripData);

  console.log("INITIAL STATE", initialState);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    console.log(`form submitted`, state.action, state);

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let actionData = {
      cancellationReason: state.cancellationReason?.value,
      cancellationReasonNotes: state.cancellationReasonNotes,
      clientRefund: state.clientRefund,
      clientRefundNotes: state.clientRefundNotes,
      operatorRefund: state.operatorRefund,
      operatorRefundNotes: state.operatorRefundNotes,
    };

    let response = await ai
      .auth(token)
      .put(`/api/trips/${props.tripId}/${state.action}`, actionData)
      .catch((error) => {
        // history.push({ pathname: "/trips" });
        setTimeout(() => {
          toast.error("Unauthorized!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }, 50);

        return console.error(error.response);
      });

    if (response?.data) {
      //toast success
      toast.success("Your trip has been updated.", toastOptions);
      //close modal
      dispatch({
        type: "closeModal",
      });
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  //use effect to run a function on mount
  // function should check props.tripData and look at props.tripData.headerData.anySignedContracts to determine if the action is archive or cancel

  useEffect(() => {
    if (!props.tripData?.headerData?.anySignedContracts) {
      dispatch({
        type: "fieldChanged",
        field: "action",
        value: "archive",
      });
    }

    if (props.tripData?.headerData?.anySignedContracts) {
      dispatch({
        type: "fieldChanged",
        field: "action",
        value: "cancel",
      });
    }

    console.log("TRIP DATA, HEADER DATA", props.tripData?.headerData);
  }, [props.tripData]);

  return (
    <Fragment>
      <button
        onClick={handleCancelTripButton}
        className="btn-danger base-button mt-30"
      >
        {state.action === "cancel"
          ? "Cancel Trip"
          : state.action === "archive"
          ? "Archive Trip"
          : "Cancel Trip"}
      </button>

      <ReactModal
        isOpen={state?.showModal}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => {
          dispatch({
            type: "closeModal",
          });
        }}
        style={{
          overlay: {
            zIndex: 999999,
            backgroundColor: "rgb(32 53 68 / 50%)",
          },
          content: {
            width: "auto",
            // height: "700px",
            // padding: "40px",
            border: "none",
            // maxWidth: "700px",
            overflowY: "scroll",
            maxHeight: "100vh",
            minWidth: "50vw",
            borderRadius: "0",
            boxShadow: "10px 10px 91px -17px rgb(0 0 0 / 25%)",
          },
        }}
        appElement={document.getElementById("app")}
      >
        <div className="modal-header base-modal">
          <h4>
            {" "}
            {state.action === "cancel"
              ? "Cancel Trip"
              : state.action === "archive"
              ? "Archive Trip"
              : "Cancel Trip"}
          </h4>
        </div>
        <div className="modal-body base-modal overflow-scroll">
          <form
            onSubmit={handleFormSubmit}
            className="w-100 base-form mt-20 tight-paragraphs"
          >
            <div>
              <h5>Trip Details</h5>

              <p>
                <strong>Trip #:</strong> {state.tripTag}
              </p>

              <p>
                <strong>Client Name: </strong>
                {state.clientName}
              </p>

              <p>
                <strong>Contracts: </strong>

                {state.contractsByStage?.length > 0 && (
                  <ul>
                    {state.contractsByStage.map((stage) => {
                      return (
                        <li key={stage.label}>
                          <strong>{stage.label}:</strong> {stage.count}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </p>

              <div className="form-group">
                <label>Cancellation Reason</label>
                <Select
                  className="base-select yom-select"
                  isClearable
                  isSearchable
                  placeholder={"Select a Reason"}
                  name="cancellationReason"
                  onChange={(selection) => {
                    dispatch({
                      type: "fieldChanged",
                      field: "cancellationReason",
                      value: selection,
                    });
                  }}
                  options={[
                    {
                      label: "Client - Changed Trip",
                      value: "Client - Changed Trip",
                      notesRequired: true,
                    },
                    {
                      label: "Client - Canceled Part of Trip",
                      value: "Client - Canceled Part of Trip",
                      notesRequired: true,
                    },
                    {
                      label: "Owner - Mechanical/Crew/Ops",
                      value: "Owner - Mechanical/Crew/Ops",
                      notesRequired: false,
                    },
                    {
                      label: "Owner - Plane No Longer Available",
                      value: "Owner - Plane No Longer Available",
                      notesRequired: false,
                    },
                    {
                      label: "Owner - Pulled Plane (after Evo booked it)",
                      value: "Owner - Pulled Plane (after Evo booked it)",
                      notesRequired: false,
                    },
                    {
                      label: "Owner - Not Approved",
                      value: "Owner - Not Approved",
                      notesRequired: false,
                    },
                    {
                      label: "Owner - Other",
                      value: "Owner - Other",
                      notesRequired: true,
                    },
                    {
                      label: "Evo - Found Better Aircraft",
                      value: "Evo - Found Better Aircraft",
                      notesRequired: false,
                    },
                    {
                      label: "Evo - Client Ghost/Non-Compliant",
                      value: "Evo - Client Ghost/Non-Compliant",
                      notesRequired: true,
                    },
                    {
                      label: "Weather",
                      value: "Weather",
                      notesRequired: false,
                    },
                    {
                      label: "Other Logistical",
                      value: "Other Logistical",
                      notesRequired: true,
                    },
                  ]}
                ></Select>
              </div>

              {state.cancellationReason?.notesRequired && (
                <div className="form-group">
                  <label className="base-label" htmlFor="note">
                    Cancellation Reason Notes
                  </label>
                  <textarea
                    name="cancellationReasonNotes"
                    id="cancellationReasonNotes"
                    cols="30"
                    rows="3"
                    value={state?.cancellationReasonNotes || ""}
                    onChange={(e) => {
                      dispatch({
                        type: "fieldChanged",
                        field: "cancellationReasonNotes",
                        value: e.target.value,
                      });
                    }}
                    required
                    placeholder={`Please clarify the situation.`}
                  ></textarea>
                </div>
              )}

              <FormControl className="form-group base-radio-group">
                <label id="refund-label">Client Refund Details</label>

                <RadioGroup
                  aria-labelledby="refund-label"
                  name="refundDetails"
                  value={state.refundToClient}
                  onChange={(e) => {
                    dispatch({
                      type: "fieldChanged",
                      field: "clientRefund",
                      value: e.target.value,
                    });
                  }}
                >
                  <FormControlLabel
                    value="Full"
                    control={<Radio />}
                    label="Full"
                  />
                  <FormControlLabel
                    value="Partial"
                    control={<Radio />}
                    label="Partial"
                  />
                  <FormControlLabel
                    value="N/A"
                    control={<Radio />}
                    label="N/A"
                  />
                  <FormControlLabel
                    value="TBD"
                    control={<Radio />}
                    label="TBD"
                  />
                  <FormControlLabel
                    value="Hold on File"
                    control={<Radio />}
                    label="Hold on File"
                  />
                </RadioGroup>
              </FormControl>

              {state.clientRefund &&
                ["Partial", "TBD", "Hold"].includes(state.clientRefund) && (
                  <div className="form-group">
                    <label className="base-label" htmlFor="note">
                      Client Refund Notes
                    </label>
                    <textarea
                      name="clientRefundNotes"
                      id="clientRefundNotes"
                      cols="30"
                      rows="3"
                      value={state?.clientRefundNotes || ""}
                      onChange={(e) => {
                        dispatch({
                          type: "fieldChanged",
                          field: "clientRefundNotes",
                          value: e.target.value,
                        });
                      }}
                      required
                      placeholder={`Please clarify the client refund details.`}
                    ></textarea>
                  </div>
                )}
            </div>

            <FormControl className="form-group base-radio-group">
              <label id="refund-label">Operator Refund Details</label>

              <RadioGroup
                aria-labelledby="refund-label"
                name="operatorRefund"
                value={state.operatorRefund}
                onChange={(e) => {
                  dispatch({
                    type: "fieldChanged",
                    field: "operatorRefund",
                    value: e.target.value,
                  });
                }}
              >
                <FormControlLabel
                  value="Full"
                  control={<Radio />}
                  label="Full"
                />
                <FormControlLabel
                  value="Partial"
                  control={<Radio />}
                  label="Partial"
                />
                <FormControlLabel value="N/A" control={<Radio />} label="N/A" />
              </RadioGroup>
            </FormControl>

            {state.operatorRefund &&
              ["Partial", "TBD", "Hold"].includes(state.operatorRefund) && (
                <div className="form-group">
                  <label className="base-label" htmlFor="note">
                    Operator Refund Notes
                  </label>
                  <textarea
                    name="operatorRefundNotes"
                    id="operatorRefundNotes"
                    cols="30"
                    rows="3"
                    value={state?.operatorRefundNotes || ""}
                    onChange={(e) => {
                      dispatch({
                        type: "fieldChanged",
                        field: "operatorRefundNotes",
                        value: e.target.value,
                      });
                    }}
                    required
                    placeholder={`Please clarify the operator refund details.`}
                  ></textarea>
                </div>
              )}

            <button className="base-button evo-blue w-100 mt-20" type="submit">
              {state.action === "cancel"
                ? "Cancel Trip"
                : state.action === "archive"
                ? "Archive Trip"
                : "Submit"}
            </button>
          </form>
        </div>
      </ReactModal>
    </Fragment>
  );
};

export default CancelTripModule;
