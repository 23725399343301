import React from "react";

const Search = (props) => {
  return (
    <input
      type="text"
      placeholder={props.placeholder || "Search by Airport"}
      id="searchFlights"
      onChange={props.onSearch}
      value={props.query}
      className={props.className}
      autoComplete={"off"}
      // {...props}
    ></input>
  );
};

export default Search;
