import React from "react";
import LeadsContent from "../components/leads/leads-content";

const ClosedRepeatLeads = () => {
  return (
    <LeadsContent
      prefilters={["repeatClient"]}
      defaultStageFilters={{
        label: "Closed - Repeat",
        value: "repeatClient",
      }}
      pageTitle="Closed - Repeat Leads"
    />
  );
};

export default ClosedRepeatLeads;
