import React, { Fragment, useReducer, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import ai from "../../helpers/axios";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";

// import Select from "react-select";

import { useHistory, useLocation } from "react-router-dom";

import TextInput from "../text-input";

import "react-json-pretty/themes/monikai.css";
import JSONPretty from "react-json-pretty";

import * as Sentry from "@sentry/react";

let initialState = {
  // prospectOptions: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "fieldChanged": {
      let value =
        action.fieldType === "number" ? parseInt(action.value) : action.value;

      return {
        ...state,
        [action.field]: value,
      };
    }

    case "resetState": {
      return {
        ...initialState,
      };
    }

    default:
      break;
  }
  return state;
};

const AircraftAutocomplete = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();

  const { getAccessTokenSilently, user, loginWithRedirect } = useAuth0();

  useEffect(() => {}, []);

  const loadAircraftOptions = async (inputValue) => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });
    let { data } = await ai
      .auth(token)
      .get(`/api/aircrafts/search?q=${inputValue}`, {
        params: {
          query: inputValue,
          // page: 1,
          // countPerPage: 50,
        },
      });

    if (data.aircraft.length >= 1) {
      console.log(data.aircraft);
      return data.aircraft.map((aircraft) => {
        return {
          value: aircraft._id,
          label: aircraft.name,
          aircraftType: aircraft.aircraftTypeData[0],
          seats: aircraft?.seats,
        };
      });
    } else {
      return [];
    }
  };

  return (
    <div
      className={`form-group  ${
        props.containerClasses ? props.containerClasses : ""
      }`}
    >
      <label>
        {props.label || "Aircraft"}{" "}
        {props.required ? <span className="required">*</span> : ``}
      </label>

      <AsyncSelect
        className={`${props.className ? props.className : "aircraft-select"}`}
        classNamePrefix="select"
        loadOptions={loadAircraftOptions}
        placeholder={props.placeholder || "Select an Aircraft"}
        onChange={props.handleChange}
        isClearable={true}
        cacheOptions
        defaultOptions={props.aircraftOptions}
        value={props.selectedAircraft}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      ></AsyncSelect>
    </div>
  );
};

export default AircraftAutocomplete;
