import React, { Fragment, useReducer, useEffect } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import ai from "../../helpers/axios";

const reducer = (state, action) => {
  switch (action.type) {
    case "fieldChanged":
      return { ...state, [action.field]: action.value };
    default:
      throw new Error();
  }
};

const initialState = {
  contents: "",
};

const MessageForm = ({ recordId, recordType, parentDispatch }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { getAccessTokenSilently, user, loginWithRedirect } = useAuth0();

  const handleUrgentSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    handleMessageSubmit("urgent");
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    handleMessageSubmit();
  };

  const handleMessageSubmit = async (sendType) => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    const message = {
      contents: state.contents,
    };

    if (sendType === "urgent") {
      console.log("urgent");
      message.sendType = "urgent";
    }

    if (!recordType || !recordId) {
      return;
    }

    if (recordType === "trip") {
      message.tripId = recordId;
      message.recordType = recordType;
    }
    // if (leadId) {
    //   message.leadId = leadId;
    // }

    let { data } = await ai
      .auth(token)
      .post(`/api/messages/create`, message)
      .catch((e) => console.error(e));

    console.log("SUCCESS");

    //clear the state for 'contents'
    dispatch({
      type: "fieldChanged",
      field: "contents",
      value: "",
    });

    parentDispatch({
      type: "rootFieldChanged",
      field: "latestMessage",
      value: Date.now(),
    });
  };

  const handleFieldChange = (event) => {
    dispatch({
      type: "fieldChanged",
      field: event.target.name,
      value: event.target.value,
    });
  };

  // update

  return (
    <form className="message-form" onSubmit={handleFormSubmit}>
      <input
        type="text"
        name="contents"
        value={state.contents}
        onChange={handleFieldChange}
        placeholder="Type a message"
      />

      <button type="submit" class="send-btn" name="send">
        <span class="material-icons">send</span>
      </button>
      <button
        type="submit"
        class="urgent-btn"
        name="urgent"
        onClick={handleUrgentSubmit}
      >
        <span class="material-icons">feedback</span>
      </button>
    </form>
  );
};

export default MessageForm;
