import React, { Fragment, useReducer, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import ai from "../../helpers/axios";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";

// import Select from "react-select";

import { useHistory, useLocation } from "react-router-dom";

import TextInput from "../text-input";

import "react-json-pretty/themes/monikai.css";
import JSONPretty from "react-json-pretty";

import * as Sentry from "@sentry/react";
import ReactTooltip from "react-tooltip";

let initialState = {
  // prospectOptions: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "fieldChanged": {
      let value =
        action.fieldType === "number" ? parseInt(action.value) : action.value;

      return {
        ...state,
        [action.field]: value,
      };
    }

    case "resetState": {
      return {
        ...initialState,
      };
    }

    default:
      break;
  }
  return state;
};

const OperatorAutocomplete = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();

  const { getAccessTokenSilently, user, loginWithRedirect } = useAuth0();

  useEffect(() => {}, []);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const loadOperators = async (inputValue) => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });
    let { data } = await ai.auth(token).get(`/api/opi/search?q=${inputValue}`);

    let options = [];

    if (data && data?.operators?.length) {
      options = data.operators.flatMap((i) => {
        let firstTwoCities = "";
        let showIndicator = false;
        let allCities = "";

        if (i.cities?.length) {
          firstTwoCities = i.cities.slice(0, 2);
          firstTwoCities = firstTwoCities.join(", ");

          if (i.cities.length > 2) {
            showIndicator = true;
          }

          allCities = i.cities.join(", ");
        }

        let labelString = `<span ${
          showIndicator ? `title="${allCities}"` : ""
        }>${i.operatorName} ${
          firstTwoCities ? `| ${firstTwoCities} |` : "| "
        } OPI ${i.composedScore || "Unrated"}${
          showIndicator
            ? '<span class="material-icons select-label-indicator">info</span>'
            : ""
        }</span>`;

        return {
          label: i.operatorName,
          value: i.operatorName,
          specs: {
            showIndicator,
            firstTwoCities,
            allCities,
            composedScore: i.composedScore,
          },
          ...i,
        };
      });
      props.parentDispatch({
        type: "fieldChanged",
        field: "operatorOptions",
        value: options,
      });
    }

    // ReactTooltip.rebuild();

    return options;
  };

  const handleMouseOver = (e, indicator, allCities) => {
    if (indicator) {
    }
    console.log(e);
  };

  return (
    <div className={`form-group ${props.containerClasses}`}>
      <label>
        {props.label || "Operator"}{" "}
        {props.required ? <span className="required">*</span> : ``}
      </label>

      {props.allowCreate && (
        <AsyncCreatableSelect
          className="operator-select"
          classNamePrefix="select"
          loadOptions={loadOperators}
          placeholder={props.placeholder || "Select an Operator"}
          onChange={props.handleChange}
          isClearable={true}
          cacheOptions
          defaultOptions={props.operatorOptions}
          value={props.selectedOperator}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          formatOptionLabel={(data) => {
            return (
              <>
                <span
                  className={`operator-select-option-holder ${
                    data?.specs?.showIndicator ? "hasToolTip" : ""
                  }`}
                  aria-label={
                    data?.specs?.showIndicator ? data?.specs?.allCities : ""
                  }
                >
                  {data?.specs && (
                    <span className="operator-select-label-text">
                      {data.label}{" "}
                      {data?.specs?.firstTwoCities
                        ? `| ${data?.specs?.firstTwoCities} |`
                        : "| "}{" "}
                      OPI {data?.specs?.composedScore || "Unrated"}
                    </span>
                  )}

                  {!data?.specs && data?.label && (
                    <span className="operator-select-label-text">
                      {data.label}
                    </span>
                  )}

                  {data?.specs?.showIndicator ? (
                    <span className="material-icons select-label-indicator">
                      info
                    </span>
                  ) : (
                    ""
                  )}
                </span>
              </>
            );
          }}
          // menuIsOpen={true}
        ></AsyncCreatableSelect>
      )}

      {!props.allowCreate && (
        <AsyncSelect
          className="operator-select"
          classNamePrefix="select"
          loadOptions={loadOperators}
          placeholder={props.placeholder || "Select an Operator"}
          onChange={props.handleChange}
          isClearable={true}
          cacheOptions
          defaultOptions={props.operatorOptions}
          value={props.selectedOperator}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          formatOptionLabel={(data) => {
            return (
              <>
                <span
                  className={`operator-select-option-holder ${
                    data?.specs?.showIndicator ? "hasToolTip" : ""
                  }`}
                  aria-label={
                    data?.specs?.showIndicator ? data?.specs?.allCities : ""
                  }
                >
                  {data?.specs && (
                    <span className="operator-select-label-text">
                      {data.label}{" "}
                      {data?.specs?.firstTwoCities
                        ? `| ${data?.specs?.firstTwoCities} |`
                        : "| "}{" "}
                      OPI {data?.specs?.composedScore || "Unrated"}
                    </span>
                  )}

                  {!data?.specs && data?.label && (
                    <span className="operator-select-label-text">
                      {data.label}
                    </span>
                  )}

                  {data?.specs?.showIndicator ? (
                    <span className="material-icons select-label-indicator">
                      info
                    </span>
                  ) : (
                    ""
                  )}
                </span>
              </>
            );
          }}
          // menuIsOpen={true}
        ></AsyncSelect>
      )}
    </div>
  );
};

export default OperatorAutocomplete;
