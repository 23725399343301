import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loader from "../components/loader";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";

let toastOptions = {
  position: "top-right",
  autoClose: 6000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  icon: false,
};

const AdminOrADRoute = ({ component, ...args }) => {
  const { user, getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const userRoles = user && user["https://app.evojets.com/roles"];

  const superAdminOrAd =
    user &&
    userRoles.some(function (role) {
      return "Super Admin" === role || "Account Director" === role;
    });

  const checkToken = async () => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect({
        appState: { returnTo: window.location.pathname },
      });
    });
  };

  // useEffect(() => {
  //   console.log("EFFECT RUNNING");
  //   checkToken();
  // });

  if (superAdminOrAd) {
    return (
      <Route
        component={withAuthenticationRequired(component, {
          onRedirecting: () => <Loader />,
        })}
        {...args}
      />
    );
  }

  toast.error("Unauthorized!", toastOptions);
  return <Redirect to="/" />;
};

export default AdminOrADRoute;
