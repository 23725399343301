import React, { Fragment, useReducer, useEffect } from "react";
import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import ai from "../../helpers/axios";
import * as Sentry from "@sentry/react";

import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";

const reducer = (state, action) => {
  switch (action.type) {
    case "search": {
      return {
        ...state,
        query: action.query,
      };
    }

    case "fieldChanged": {
      return {
        ...state,
        [action.field]: action.value,
      };
    }

    case "dataDownloaded": {
      let selectedRole = { id: "", label: "", value: "" };
      if (action?.payload?.data?.user?.userRoles?.length > 0) {
        selectedRole = {
          id: action.payload.data.user.userRoles[0].id,
          name: action.payload.data.user.userRoles[0].name,
          description: action.payload.data.user.userRoles[0].description,
          value: action.payload.data.user.userRoles[0].id,
          label: action.payload.data.user.userRoles[0].name,
        };
      }
      let selectedAccountDirector = {};

      if (
        action?.payload?.data?.user?.app_metadata?.accountDirectorId &&
        state?.availableAccountDirectors?.length
      ) {
        let matchingAD = state.availableAccountDirectors.filter(
          (ad) =>
            ad?.user_id ===
            action?.payload?.data?.user?.app_metadata?.accountDirectorId
        );
        if (matchingAD?.length) {
          matchingAD = matchingAD[0];
        }
        selectedAccountDirector = {
          ...matchingAD,
        };

        console.log("MATCHING AD", matchingAD, selectedAccountDirector);
      }
      // console.log

      return {
        ...state,
        user: action.payload.data.user,
        phone: action.payload.data.user.user_metadata.phoneNumber,
        email: action.payload.data.user.email,
        firstName: action.payload.data.user.name.split(" ")[0],
        lastName: action.payload.data.user.name.split(" ")[1],
        teamColor: action.payload.data.user.app_metadata?.teamColor,
        selectedOrganization: {
          label: action.payload.data.user.app_metadata.organizationName,
          value: action.payload.data.user.app_metadata.organizationId,
        },
        selectedRole,
        selectedAccountDirector,
        // availableRoles: [
        //   ...action.payload.data.roles.map((role) => {
        //     return { ...role, label: role.name, value: role.id };
        //   }),
        // ],
      };
    }

    default:
      break;
  }
  return state;
};

const SingleUserContent = (props) => {
  const history = useHistory();
  const { id } = useParams();

  const initialState = {
    organizations: [],
    availableRoles: [],
    availableAccountDirectors: [],
    availableOutreachUsers: [],
    availableTeamMembers: [],
    selectedOrganization: {},
    selectedOutreachUser: {},
    selectedTeamMembers: [],
    phone: "",
    email: "",
    firstName: "",
    lastName: "",
    // password: "",
    teamColor: "",
    selectedAccountDirector: null,
    user: null,
    selectedRole: { id: "", value: "", label: "" },
  };

  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    document.title = "Edit User | Flight Deck by evoJets";
    getData();
  }, []);

  const getData = async () => {
    dispatch({ type: "dataBeganDownloading" });

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    await Promise.allSettled([
      loadRoles(token),
      loadAccountDirectors(token),
      loadTeamMembers(token),
      loadOutreachUsers(token),
      loadOrganizations(token),
    ]);
  };

  const loadUser = async (token, teamMembers) => {
    let user = await ai
      .auth(token)
      .get(`/api/users/${id}`)
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
      });

    if (user) {
      let data = { ...user.data };

      console.log("USER DATA", data);

      if (
        teamMembers.length &&
        data?.user?.userRoles?.length &&
        data?.user?.userRoles[0]?.id === "rol_pOQalM41JyTqKsF4" &&
        data?.user?.app_metadata?.myTeam?.length
      ) {
        // console.log("AVAILABLE TEAM MEMBERS", state.availableTeamMembers);
        console.log("BEFORE MATCHIN");

        let matched = teamMembers.filter((member) =>
          data?.user?.app_metadata?.myTeam.includes(member.user_id)
        );

        console.log("MATCHED", matched);
        dispatch({
          type: "fieldChanged",
          field: "selectedTeamMembers",
          value: matched,
        });
      }

      dispatch({
        type: "dataDownloaded",
        payload: {
          data,
        },
      });
    }
  };

  const loadRoles = async (token) => {
    let roles = await ai
      .auth(token)
      .get(`/api/users/availableRoles`)
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
      });
    if (
      roles &&
      roles.data &&
      roles.data.roles &&
      roles.data.roles.length > 0
    ) {
      dispatch({
        type: "fieldChanged",
        field: "availableRoles",
        value: roles.data.roles.map((role) => {
          return { ...role, label: role.name, value: role.id };
        }),
      });
    }
  };

  const loadAccountDirectors = async (token) => {
    let accountDirectors = await ai
      .auth(token)
      .get(`/api/users/roles/accountDirectors`)
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
      });

    if (accountDirectors?.data?.users?.length) {
      //account directors loaded
      accountDirectors = accountDirectors.data.users.map((ad) => {
        return {
          ...ad,
          label: ad?.name,
          value: ad?.user_id,
        };
      });

      dispatch({
        type: "fieldChanged",
        value: accountDirectors,
        field: "availableAccountDirectors",
      });
    }
  };

  const loadTeamMembers = async (token) => {
    let teamMembers = await ai
      .auth(token)
      .get(`/api/users/roles/dynamicSales`)
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
      });

    if (teamMembers?.data?.users?.length) {
      //account directors loaded
      teamMembers = teamMembers.data.users.map((user) => {
        return {
          ...user,
          label: user?.name,
          value: user?.user_id,
        };
      });

      dispatch({
        type: "fieldChanged",
        value: teamMembers,
        field: "availableTeamMembers",
      });

      await loadUser(token, teamMembers);
    } else {
      await loadUser(token);
    }
  };

  const loadOutreachUsers = async (token) => {
    let outreachUsers = await ai
      .auth(token)
      .get(`/api/users/outreachUsers`)
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
      });

    if (outreachUsers?.data?.data?.length) {
      //account directors loaded
      outreachUsers = outreachUsers.data.data.map((user) => {
        return {
          label: user?.attributes?.name,
          value: user.id,
        };
      });

      dispatch({
        type: "fieldChanged",
        value: outreachUsers,
        field: "availableOutreachUsers",
      });
    }
  };

  const loadOrganizations = async (token) => {
    let organizations = await ai
      .auth(token)
      .get(`/api/organizations/list`)
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
      });

    if (
      organizations &&
      organizations.data &&
      organizations.data.organizations &&
      organizations.data.organizations.length > 0
    ) {
      console.log("ORGS", organizations.data);

      let organizationsFormatted = organizations.data.organizations.map(
        (organization) => {
          return {
            ...organization,
            label: organization.name,
            value: organization._id,
          };
        }
      );

      dispatch({
        type: "fieldChanged",
        field: "organizations",
        value: organizationsFormatted,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let formData = {
      firstName: state.firstName,
      lastName: state.lastName,
      name: `${state.firstName} ${state.lastName}`,
      phone: state.phone,
      email: state.email,
      organizationId: state.selectedOrganization.value,
      // password: state.password,
      teamColor: state.teamColor,
    };

    if (state.selectedTeamMembers.length) {
      formData.myTeam = state.selectedTeamMembers.map((item) => item?.user_id);
    }

    // if (formData?.organizationId === "600c8d371f10c61723e64402") {
    //   formData.outreachUserId = state?.selectedOutreachUser?.value;

    //   if (!state?.selectedOutreachUser?.value) {
    //     toast.error("Please select an Outreach User.", {
    //       position: "top-right",
    //       autoClose: 5000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //     });

    //     return;
    //   }
    // }

    if (
      formData?.organizationId === "600c8d371f10c61723e64402" &&
      state.selectedRole?.id !== "rol_pOQalM41JyTqKsF4"
    ) {
      //not an AD, but an evo user
      formData.accountDirectorId = state?.selectedAccountDirector?.value;

      if (!state?.selectedAccountDirector?.value) {
        toast.error("Please select an Account Director.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        return;
      }
    }

    console.log(formData);

    if (state.selectedRole.id) {
      formData.roles = [state.selectedRole.id];
    }

    let response = await ai
      .auth(token)
      .put(`/api/users/${id}`, formData)
      .catch((error) => Sentry.captureException(error));

    if (response) {
      // history.push({ pathname: "/organizations/" });
      console.log(response);
      setTimeout(() => {
        toast.success("User Updated Successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }, 50);
    }
  };

  return (
    <Fragment>
      <h2 id="addAirportHeading">Edit User</h2>
      <form id="" className="base-form two-column" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="firstName">First Name</label>
          <input
            type="text"
            name="firstName"
            value={state.firstName}
            placeholder="First Name"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="lastName">Last Name</label>
          <input
            type="text"
            name="lastName"
            value={state.lastName}
            placeholder="Last Name"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="phone">Phone Number</label>
          <input
            type="text"
            name="phone"
            value={state.phone}
            placeholder="Phone Number"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="phone">Email Address</label>
          <input
            type="email"
            name="email"
            value={state.email}
            placeholder="Email Address"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="notificationEmails">Organization</label>

          <Select
            key={`organization-options-key-${JSON.stringify(
              state.selectedOrganization
            )}-${JSON.stringify(state.organizations)}`}
            className="organization-select"
            classNamePrefix="select"
            options={state.organizations}
            defaultValue={state.selectedOrganization}
            placeholder="Organization"
            onChange={(selectedOrganization) => {
              dispatch({
                type: "fieldChanged",
                field: "selectedOrganization",
                value: selectedOrganization,
              });
            }}
          ></Select>
        </div>

        {/* <div className="form-group">
          <label htmlFor="phone">Password</label>
          <input
            type="text"
            name="password"
            value={state.password}
            placeholder="Password"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div> */}

        <div className="form-group">
          <label htmlFor="userRoles">User Role</label>

          <Select
            key={`role-options-key-${JSON.stringify(
              state.selectedRole
            )}-${JSON.stringify(state.availableRoles)}`}
            name="userRoles"
            className="organization-select"
            classNamePrefix="select"
            options={state.availableRoles}
            defaultValue={state.selectedRole}
            placeholder="User Role"
            onChange={(selectedRole) => {
              dispatch({
                type: "fieldChanged",
                field: "selectedRole",
                value: selectedRole,
              });
            }}
            isDisabled={true}
          ></Select>
        </div>

        {state.selectedOrganization?.value === "600c8d371f10c61723e64402" &&
          state.selectedRole?.id !== "" &&
          state.selectedRole?.id !== "rol_pOQalM41JyTqKsF4" && (
            <div className="form-group">
              <label htmlFor="accountDirector">Account Director</label>

              <Select
                key={`account-director-options-key-${JSON.stringify(
                  state.availableAccountDirectors
                )}-${JSON.stringify(state.selectedAccountDirector)}`}
                name="accountDirector"
                className="base-select"
                classNamePrefix="select"
                options={state.availableAccountDirectors}
                defaultValue={state.selectedAccountDirector}
                placeholder="Account Director"
                onChange={(selectedAD) => {
                  dispatch({
                    type: "fieldChanged",
                    field: "selectedAccountDirector",
                    value: selectedAD,
                  });
                }}
              ></Select>
            </div>
          )}

        {state.selectedOrganization?.value === "600c8d371f10c61723e64402" &&
          state.selectedRole?.id === "rol_pOQalM41JyTqKsF4" && (
            <>
              <div className="form-group">
                <label htmlFor="accountDirector">Team Members</label>

                <Select
                  key={`account-director-options-key-${JSON.stringify(
                    state.selectedTeamMembers
                  )}`}
                  name="teamMembers"
                  className="base-select"
                  classNamePrefix="select"
                  options={state.availableTeamMembers}
                  placeholder="Team Members"
                  defaultValue={state.selectedTeamMembers}
                  onChange={(selectedTeamMembers) => {
                    dispatch({
                      type: "fieldChanged",
                      field: "selectedTeamMembers",
                      value: selectedTeamMembers,
                    });
                  }}
                  isMulti
                ></Select>
              </div>

              <div className="form-group">
                <label htmlFor="lastName">Team Color</label>
                <input
                  type="text"
                  name="teamColor"
                  value={state.teamColor || ""}
                  placeholder="Team Color"
                  onChange={(e) =>
                    dispatch({
                      type: "fieldChanged",
                      field: e.target.name,
                      value: e.target.value,
                    })
                  }
                ></input>
              </div>
            </>
          )}

        {/* {state.selectedOrganization?.value === "600c8d371f10c61723e64402" && (
          <div className="form-group">
            <label htmlFor="outreachUserId">Outreach User</label>

            <Select
              key={`account-director-options-key-${JSON.stringify(
                state.availableOutreachUsers
              )}`}
              name="outreachUserId"
              className="base-select"
              classNamePrefix="select"
              options={state.availableOutreachUsers}
              placeholder="Outreach User"
              onChange={(selectedOutreachUser) => {
                dispatch({
                  type: "fieldChanged",
                  field: "selectedOutreachUser",
                  value: selectedOutreachUser,
                });
              }}
            ></Select>
          </div>
        )} */}

        <input type="submit" value="Save User"></input>
      </form>

      {/* <pre>{JSON.stringify(state.selectedAccountDirector, null, 2)}</pre> */}
    </Fragment>
  );
};

export default SingleUserContent;
