import React from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

const BackToTopButton = () => {
  const [visible, setVisible] = React.useState(false);
  //   console.log("BTT BTN");

  const toggleVisible = () => {
    const scrolled = document.getElementById("app").scrollTop;

    // console.log("TOGGLE");
    if (scrolled > 500) {
      setVisible(true);
      // console.log("VISIBLE");
    } else if (scrolled <= 500) {
      setVisible(false);
      // console.log("NOT VISIBLE");
    }
  };

  const scrollToTop = () => {
    document
      .getElementById("page-header")
      .scrollIntoView({ behavior: "smooth" });
  };

  document.getElementById("app").addEventListener("scroll", toggleVisible);

  return (
    <span
      className="material-icons back-to-top-icon"
      style={{
        visibility: visible ? "visible" : "hidden",
        opacity: visible ? "1" : "0",
      }}
      onClick={scrollToTop}
    >
      arrow_upward
    </span>
  );
};

export default BackToTopButton;
