import React from "react";
import LeadsContent from "../components/leads/leads-content";

const WarmProspectLeads = () => {
  return (
    <LeadsContent
      prefilters={["warmProspect"]}
      defaultStageFilters={{ label: "Warm Prospect", value: "warmProspect" }}
      pageTitle="Warm Prospect Leads"
    />
  );
};

export default WarmProspectLeads;
