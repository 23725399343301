import React, { Fragment } from "react";

import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CheckBoxOutlineBlank from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import moment from "moment-timezone";

import formatAircraftSelection from "../../helpers/format-aircraft-selection";
import formatStage from "../../helpers/format-stage";
import currencyFormatter from "../../helpers/currencyFormatter";

import { Link } from "react-router-dom";

const getAircraftSelection = (data) => {
  if (data?.aircraftSelection?.length) {
    return data?.aircraftSelection
      .map((aircraft) => formatAircraftSelection(aircraft))
      .join(", ");
  }
  return "N/A";
};

const capitalizeFirstLetter = (string) => {
  if (!string) {
    return "";
  }
  return string[0].toUpperCase() + string.substring(1);
};

function Row(props) {
  const { row, superAdminOrAd } = props;
  const [open, setOpen] = React.useState(false);
  const { dispatch, selectedRows } = props;

  let rowSelected = selectedRows.filter(
    (selectedRow) => row._id === selectedRow._id
  );
  rowSelected = rowSelected?.length ? true : false;

  // const [selected, setSelected] = React.useState(rowSelected);

  console.log("ROW", row);

  row.itineraryDetails = row.leadData.estimateData[0];

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        onClick={() => setOpen(!open)}
        className="fm-l2-parent-table-row"
        key={JSON.stringify(selectedRows)}
      >
        <TableCell className="centered-important">
          {/* <IconButton
            aria-label="expand row"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              dispatch({
                type: "rowSelectionEvent",
                rowId: row._id,
                selected: !rowSelected,
              });
              // setSelected(!selected);
            }}
          >
            {rowSelected ? <CheckBoxIcon /> : <CheckBoxOutlineBlank />}
          </IconButton> */}
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell className="mobile-lead-details-initial-cell">
          <span className="material-icons lead-info-icon">person</span>
          {capitalizeFirstLetter(row.leadData.firstName)}{" "}
          {capitalizeFirstLetter(row.leadData.lastName)}
          <br />
          <span className="material-icons lead-info-icon">flight</span>
          {row.itineraryDetails.departureAirportCode} -{" "}
          {row.itineraryDetails.arrivalAirportCode}
          <br />
          {/* <span className="material-icons lead-info-icon">schedule</span>
          {formatStage(row.stage)} */}
          <span className="material-icons lead-info-icon">airlines</span>
          {row?.aircraft?.name}
          <br />
          <span className="material-icons lead-info-icon">payments</span>
          {row?.operatorPrice
            ? currencyFormatter.format(row.operatorPrice)
            : "-"}
          <br />
          <span className="material-icons lead-info-icon">credit_card</span>
          {row?.totalClientPayment
            ? currencyFormatter.format(row.totalClientPayment)
            : "-"}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }} className="fm-l2-child-table-container">
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                className="fm-l2-child-table-heading mb-20 mt-20"
              >
                Sourcing Record Data
              </Typography>

              <p key={`operatorName`}>
                <strong>Operator: </strong>
                {row?.operator?.name || "-"}
              </p>

              <p key={`tailNumber`}>
                <strong>Tail #: </strong>
                {row?.tailNumber || "-"}
              </p>

              <p key={`yom`}>
                <strong>YOM: </strong>
                {row?.yomIsRange && row?.yom && row?.yomEnd
                  ? `${row.yom} - ${row.yomEnd}`
                  : row?.yom
                  ? row.yom
                  : row?.fullAircraftData?.yom
                  ? row?.fullAircraftData?.yom
                  : "-"}
              </p>

              <p key={`interiorRefurb`}>
                <strong>Interior Refurb: </strong>
                {row?.interiorRefurbYearIsRange &&
                row?.interiorRefurbYear &&
                row?.interiorRefurbYearEnd
                  ? `${row.interiorRefurbYear} - ${row.interiorRefurbYearEnd}`
                  : row?.interiorRefurbYear}
              </p>

              <p key={`commission`}>
                <strong>Commission: </strong>
                {row?.grossCommission
                  ? currencyFormatter.format(row.grossCommission)
                  : "-"}
              </p>

              <p key={`createdAt-${row.createdAt}`}>
                <strong>Created At: </strong>
                {moment(row.createdAt).format("MMM Do YYYY h:mm a")}
              </p>

              <p key={`leadCreatedAt-${row.createdAt}`}>
                <strong>Lead Created At: </strong>
                {moment(row.leadData.createdAt).format("MMM Do YYYY h:mm a")}
              </p>

              <p className="mt-20">
                <Link
                  to={`/sourcing/${row._id}`}
                  className="evo-blue base-button"
                >
                  View / Edit Sourcing
                </Link>
              </p>

              <p className="mt-30">
                <Link
                  to={`/leads/${row?.leadId}`}
                  className="evo-gunmetal base-button"
                >
                  View Lead
                </Link>
              </p>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable(props) {
  const { dispatch, selectedRows, superAdminOrAd } = props;
  // console.log({ selectedRows });

  return (
    <TableContainer
      component={Paper}
      className="mt-20 accordion-table-with-details"
    >
      <Table aria-label="collapsible table">
        <TableHead className="fm-l2-parent-table-head">
          <TableRow className="fm-l2-parent-table-head-row">
            <TableCell />
            <TableCell>RECORD INFO</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className="fm-l2-parent-table-body">
          {props.rows.map((row) => (
            <Row
              key={row._id}
              dispatch={dispatch}
              row={row}
              selectedRows={selectedRows}
              superAdminOrAd={superAdminOrAd}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
