import React from "react";
import Select from "react-select";
import NumberFormat from "react-number-format";

import { Datepicker, momentTimezone } from "@mobiscroll/react";
import moment from "moment-timezone";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

momentTimezone.moment = moment;

const generateRange = (start, end, multiple = 1) =>
  Array.from(
    { length: Math.ceil((end - start + 1) / multiple) },
    (_, i) => start + i * multiple
  );

const generateRangeOptions = (
  start,
  end,
  excludeTBA,
  ascendingOrder,
  addPercentageLabels,
  multiple = 1,
  suffix
) => {
  let range = generateRange(start, end, multiple);

  if (ascendingOrder) {
    range.sort((a, b) => a - b);
  } else {
    range.sort((a, b) => b - a);
  }

  range = range.map((item) => {
    return {
      value: item,
      label: `${item}${addPercentageLabels ? "%" : ""}${
        suffix ? ` ${suffix} ` : ``
      }`,
    };
  });

  if (!excludeTBA) {
    range.unshift({ value: 0, label: "TBA" });
  }

  return range;
};

const indexToEnglish = (index) => {
  const numberWords = [
    "First",
    "Second",
    "Third",
    "Fourth",
    "Fifth",
    "Sixth",
    "Seventh",
    "Eighth",
    "Ninth",
    "Tenth",
    "Eleventh",
    "Twelfth",
    "Thirteenth",
    "Fourteenth",
    "Fifteenth",
    "Sixteenth",
    "Seventeenth",
    "Eighteenth",
    "Nineteenth",
    "Twentieth",
    "Twenty-first",
    "Twenty-second",
    "Twenty-third",
    "Twenty-fourth",
    "Twenty-fifth",
    "Twenty-sixth",
    "Twenty-seventh",
    "Twenty-eighth",
    "Twenty-ninth",
    "Thirtieth",
    "Thirty-first",
    "Thirty-second",
    "Thirty-third",
    "Thirty-fourth",
    "Thirty-fifth",
    "Thirty-sixth",
    "Thirty-seventh",
    "Thirty-eighth",
    "Thirty-ninth",
    "Fortieth",
    "Forty-first",
    "Forty-second",
    "Forty-third",
    "Forty-fourth",
    "Forty-fifth",
    "Forty-sixth",
    "Forty-seventh",
    "Forty-eighth",
    "Forty-ninth",
    "Fiftieth",
  ];

  if (index < 0 || index >= numberWords.length) {
    return "Out of range";
  }

  return numberWords[index];
};

// Helper function to extract the index from the field name
const extractIndex = (fieldName) => {
  const regex = /\[(\d+)\]/; // Regex pattern to match the index inside square brackets
  const match = fieldName.match(regex);
  if (match && match[1]) {
    return parseInt(match[1], 10); // Parse the index as an integer
  }
  return null; // Return null if index extraction fails
};

// Helper function to extract the property name ("type" or "value") from the field name
const extractProperty = (fieldName) => {
  const regex = /\.(\w+)$/; // Regex pattern to match the property name after the last dot
  const match = fieldName.match(regex);
  if (match && match[1]) {
    return match[1]; // Return the property name
  }
  return null; // Return null if property extraction fails
};

function ContractCancellationPolicies({
  parentDispatch,
  contractCancellationPolicies,
  dusCancellationPolicy,
}) {
  const handleAddContractCancellationPolicy = (e) => {
    e.preventDefault();
    const updatedPolicies = [...contractCancellationPolicies]; // Create a copy of the existing policies array
    updatedPolicies.push({ type: 1, value: "" });

    parentDispatch({
      type: "fieldChanged",
      field: "contractCancellationPolicies",
      value: updatedPolicies,
    });
  };

  //function to delete contract cancellation policy by index, inherited from the button's data-id attribute
  const handleDeleteContractCancellationPolicy = (e) => {
    e.preventDefault();
    const index = parseInt(e.target.getAttribute("data-id"));
    const updatedPolicies = [...contractCancellationPolicies]; // Create a copy of the existing policies array
    updatedPolicies.splice(index, 1); // Remove the policy at the specified index

    parentDispatch({
      type: "fieldChanged",
      field: "contractCancellationPolicies",
      value: updatedPolicies,
    });
  };

  const handleContractCancellationPolicySelectChange = (
    selectedValue,
    action
  ) => {
    const fieldName = action.name;
    const index = extractIndex(fieldName); // Extract the index from the field name
    const property = extractProperty(fieldName); // Extract the property name ("type" or "value") from the field name

    const updatedPolicies = [...contractCancellationPolicies]; // Create a copy of the existing policies array
    updatedPolicies[index] = {
      ...updatedPolicies[index],
      [property]: selectedValue?.value || "",
    }; // Update the specific index's "type" property with the selected value

    parentDispatch({
      type: "fieldChanged",
      field: "contractCancellationPolicies",
      value: updatedPolicies,
    });
  };

  const handleDUSCancellationPolicySelectChange = (selectedValue, action) => {
    const fieldName = action.name;
    const property = extractProperty(fieldName); // Extract the property name ("type" or "value") from the field name

    let updatedDUSPolicy = {
      ...dusCancellationPolicy,
      [property]: selectedValue?.value || "",
    }; // Create a copy of the existing policies object

    if (property === "type") {
      updatedDUSPolicy.value = "";
    }

    parentDispatch({
      type: "fieldChanged",
      field: "dusCancellationPolicy",
      value: updatedDUSPolicy,
    });
  };

  const handleDUSCancellationPolicyDollarChange = (values, sourceInfo) => {
    let { formattedValue, value } = values;
    const { event, source } = sourceInfo;
    if (!event?.target) {
      return;
    }

    value = parseFloat(value);

    if (isNaN(value)) {
      value = null;
    }

    let updatedDUSPolicy = {
      ...dusCancellationPolicy,
      value: value,
    }; // Create a copy of the existing policies object

    parentDispatch({
      type: "fieldChanged",
      field: "dusCancellationPolicy",
      value: updatedDUSPolicy,
    });
  };

  const handleContractCancellationPolicyDollarChange = (values, sourceInfo) => {
    let { formattedValue, value } = values;
    const { event, source } = sourceInfo;
    if (!event?.target) {
      return;
    }

    value = parseFloat(value);

    if (isNaN(value)) {
      value = null;
    }

    const fieldName = event?.target?.name;
    const index = extractIndex(fieldName); // Extract the index from the field name
    const property = extractProperty(fieldName); // Extract the property name ("type" or "value") from the field name

    const updatedPolicies = [...contractCancellationPolicies]; // Create a copy of the existing policies array
    updatedPolicies[index] = {
      ...updatedPolicies[index],
      [property]: value,
    }; // Update the specific index's "type" property with the selected value

    parentDispatch({
      type: "fieldChanged",
      field: "contractCancellationPolicies",
      value: updatedPolicies,
    });
  };

  const handleContractCancellationPolicyDueDateChange = (event) => {
    let value = event?.value;

    const fieldName = event.inst?.props?.name;

    if (!fieldName) {
      return;
    }

    const index = extractIndex(fieldName); // Extract the index from the field name
    const property = extractProperty(fieldName); // Extract the property name ("type" or "value") from the field name

    const updatedPolicies = [...contractCancellationPolicies]; // Create a copy of the existing policies array
    updatedPolicies[index] = {
      ...updatedPolicies[index],
      [property]: value,
    }; // Update the specific index's "type" property with the selected value

    parentDispatch({
      type: "fieldChanged",
      field: "contractCancellationPolicies",
      value: updatedPolicies,
    });
  };

  const handleDUS100 = (event) => {
    console.log("DUS 100", event.target.checked);
    parentDispatch({
      type: "fieldChanged",
      field: "dusCancellationPolicy",
      value: {
        ...dusCancellationPolicy,
        dus100: event.target.checked,
        type: event.target.checked ? "" : 1,
        value: "",
      },
    });
  };

  return (
    <>
      <h3>Contract Cancelation Policy</h3>
      <div className="form-group contract-cancellation-policies-group">
        <label className="contractCancellationPolicies">
          Due Upon Signing Cancellation Penalty
        </label>

        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={dusCancellationPolicy?.dus100}
                onChange={handleDUS100}
              />
            }
            label="100% Non-Cancellable"
          />
        </FormGroup>

        {!dusCancellationPolicy?.dus100 && (
          <div
            className="two-column-group mb-0"
            show={dusCancellationPolicy?.dus100}
          >
            <div className="select-container form-group mb-0">
              <label
                htmlFor={`dusCancellationPolicy.type`}
                className="base-label"
              >
                Type
              </label>
              <Select
                className="base-select yom-select mb-0 z-26"
                defaultValue={{
                  value: dusCancellationPolicy.type,
                  label: dusCancellationPolicy.type === 1 ? "%" : "$",
                }}
                isClearable
                value={{
                  value: dusCancellationPolicy.type,
                  label: dusCancellationPolicy.type === 1 ? "%" : "$",
                }}
                isSearchable
                placeholder={"Select a Type"}
                name={`dusCancellationPolicy.type`}
                options={[
                  { label: "%", value: 1 },
                  { label: "$", value: 2 },
                ]}
                key={`dusCancellationPolicy.type`}
                onChange={handleDUSCancellationPolicySelectChange}
              ></Select>
            </div>

            {dusCancellationPolicy.type === 1 && (
              <div className="select-container form-group mb-0">
                <label
                  htmlFor={`dusCancellationPolicy.value`}
                  className="base-label"
                >
                  Amount
                </label>
                <Select
                  className="base-select yom-select mb-0 z-26"
                  // defaultValue={{
                  //   label: `${policy.value}%`,
                  //   value: `${policy.value}`,
                  // }}
                  isClearable
                  value={{
                    label: `${dusCancellationPolicy.value}%`,
                    value: `${dusCancellationPolicy.value}`,
                  }}
                  isSearchable
                  placeholder={"Select a Percentage"}
                  name={`dusCancellationPolicy.value`}
                  options={generateRangeOptions(0, 100, true, true, true, 5)}
                  onChange={handleDUSCancellationPolicySelectChange}
                ></Select>
              </div>
            )}

            {dusCancellationPolicy.type === 2 && (
              //currency input
              <div className="currency-input form-group mb-0">
                <label
                  htmlFor={`dusCancellationPolicy.value`}
                  className="base-label"
                >
                  Amount
                </label>
                <NumberFormat
                  value={dusCancellationPolicy.value}
                  thousandSeparator={true}
                  prefix={"$"}
                  onValueChange={handleDUSCancellationPolicyDollarChange}
                  placeholder="Penalty Amount"
                  className="semibold-text mb-0"
                  decimalScale={2}
                  name={`dusCancellationPolicy.value`}
                />
              </div>
            )}
          </div>
        )}
      </div>

      {!dusCancellationPolicy?.dus100 &&
        contractCancellationPolicies?.length > 0 &&
        contractCancellationPolicies.map((policy, index) => {
          let indexLabel = indexToEnglish(index);

          let type =
            policy.type === 1
              ? {
                  label: "%",
                  value: 1,
                }
              : policy.type === 2
              ? {
                  label: "$",
                  value: 2,
                }
              : null;

          return (
            <div
              className="form-group contract-cancellation-policies-group"
              key={`policy-${index}`}
            >
              <label className="contractCancellationPolicies">
                {indexLabel} Cancellation Penalty
              </label>

              <div className="four-column-group mb-20">
                <div className="select-container form-group">
                  <label
                    htmlFor={`contractCancellationPolicies[${index}].type`}
                    className="base-label"
                  >
                    Type
                  </label>
                  <Select
                    className="base-select yom-select z-25"
                    defaultValue={type}
                    isClearable
                    value={type}
                    isSearchable
                    placeholder={"Select a Type"}
                    name={`contractCancellationPolicies[${index}].type`}
                    options={[
                      { label: "%", value: 1 },
                      { label: "$", value: 2 },
                    ]}
                    onChange={handleContractCancellationPolicySelectChange}
                  ></Select>
                </div>

                {policy.type === 1 && (
                  <div className="select-container form-group">
                    <label
                      htmlFor={`contractCancellationPolicies[${index}].value`}
                      className="base-label"
                    >
                      Amount
                    </label>
                    <Select
                      className="base-select yom-select z-25"
                      // defaultValue={{
                      //   label: `${policy.value}%`,
                      //   value: `${policy.value}`,
                      // }}
                      isClearable
                      value={{
                        label: `${policy.value}%`,
                        value: `${policy.value}`,
                      }}
                      isSearchable
                      placeholder={"Select a Percentage"}
                      name={`contractCancellationPolicies[${index}].value`}
                      options={generateRangeOptions(
                        0,
                        100,
                        true,
                        true,
                        true,
                        5
                      )}
                      onChange={handleContractCancellationPolicySelectChange}
                    ></Select>
                  </div>
                )}

                {policy.type === 2 && (
                  //currency input
                  <div className="currency-input form-group">
                    <label
                      htmlFor={`contractCancellationPolicies[${index}].value`}
                      className="base-label"
                    >
                      Amount
                    </label>
                    <NumberFormat
                      value={policy.value}
                      thousandSeparator={true}
                      prefix={"$"}
                      onValueChange={
                        handleContractCancellationPolicyDollarChange
                      }
                      placeholder="Penalty Amount"
                      className="semibold-text"
                      decimalScale={2}
                      name={`contractCancellationPolicies[${index}].value`}
                    />
                  </div>
                )}

                <div className="select-container form-group">
                  <label
                    htmlFor={`contractCancellationPolicies[${index}].unitOfTime`}
                    className="base-label"
                  >
                    Unit of Time
                  </label>
                  <Select
                    className="base-select time-select"
                    defaultValue={
                      policy?.unitOfTime === 1
                        ? {
                            label: "Days",
                            value: 1,
                          }
                        : policy?.unitOfTime === 2
                        ? {
                            label: "Hours",
                            value: 2,
                          }
                        : null
                    }
                    isClearable
                    value={
                      policy?.unitOfTime === 1
                        ? {
                            label: "Days",
                            value: 1,
                          }
                        : policy?.unitOfTime === 2
                        ? {
                            label: "Hours",
                            value: 2,
                          }
                        : null
                    }
                    isSearchable
                    placeholder={"Days or Hours"}
                    name={`contractCancellationPolicies[${index}].unitOfTime`}
                    options={[
                      { label: "Days", value: 1 },
                      { label: "Hours", value: 2 },
                    ]}
                    onChange={handleContractCancellationPolicySelectChange}
                  ></Select>
                </div>

                <div className="select-container form-group">
                  <label
                    htmlFor={`contractCancellationPolicies[${index}].timeAmount`}
                    className="base-label"
                  >
                    Amount of Time
                  </label>
                  <Select
                    className="base-select time-select z-20"
                    defaultValue={
                      policy?.amountOfTime && policy?.unitOfTime === 1
                        ? {
                            label: `${policy?.amountOfTime} Days`,
                            value: policy?.amountOfTime,
                          }
                        : policy?.amountOfTime && policy?.unitOfTime === 2
                        ? {
                            label: `${policy?.amountOfTime} Hours`,
                            value: policy?.amountOfTime,
                          }
                        : null
                    }
                    isClearable
                    value={
                      policy?.amountOfTime && policy?.unitOfTime === 1
                        ? {
                            label: `${policy?.amountOfTime} Days`,
                            value: policy?.amountOfTime,
                          }
                        : policy?.amountOfTime && policy?.unitOfTime === 2
                        ? {
                            label: `${policy?.amountOfTime} Hours`,
                            value: policy?.amountOfTime,
                          }
                        : null
                    }
                    isSearchable
                    placeholder={
                      policy.unitOfTime === 1
                        ? "# of Days"
                        : policy?.unitOfTime === 2
                        ? "# of Hours"
                        : "Select a Unit"
                    }
                    name={`contractCancellationPolicies[${index}].amountOfTime`}
                    options={
                      policy?.unitOfTime === 2
                        ? generateRangeOptions(
                            24,
                            96,
                            true,
                            true,
                            false,
                            24,
                            "Hours"
                          )
                        : policy?.unitOfTime === 1
                        ? generateRangeOptions(
                            5,
                            120,
                            true,
                            true,
                            false,
                            1,
                            "Days"
                          )
                        : []
                    }
                    onChange={handleContractCancellationPolicySelectChange}
                  ></Select>
                </div>
              </div>
              <button
                className="base-button evo-blue"
                onClick={handleAddContractCancellationPolicy}
              >
                Add Penalty
              </button>

              {index >= 1 && (
                <button
                  className="base-button btn-danger ml-10"
                  onClick={handleDeleteContractCancellationPolicy}
                  data-id={index}
                >
                  Delete Penalty
                </button>
              )}
            </div>
          );
        })}
    </>
  );
}

export default ContractCancellationPolicies;
