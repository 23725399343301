import { Link } from "react-router-dom";
import countryCodes from "../../helpers/country-codes";
import formatter from "../../helpers/currencyFormatter";
import moment from "moment";

const columns = [
  {
    name: "Edit",
    sortable: false,
    cell: (row) => {
      return <Link to={`/country-settings/${row._id}`}>Edit</Link>;
    },
    width: "70px",
  },
  {
    name: "ISO Code",
    sortable: true,
    selector: "countryISOCode",
    width: "140px",
  },
  {
    name: "Country Name",
    sortable: true,
    selector: "countryISOCode",
    width: "180px",
    cell: (row) => {
      return countryCodes.find((item) => item.value === row.countryISOCode)
        .label;
    },
  },
  {
    name: "Base Cost Adjustment",
    sortable: true,
    selector: "baseCostAdjustment",
    cell: (row) => {
      return `${formatter.format(row.baseCostAdjustment)}`;
    },
    width: "220px",
  },
  {
    name: "Active",
    sortable: true,
    selector: "active",
    cell: (row) => (row.active ? "Yes" : "No"),
    width: "140px",
  },
  {
    name: "Created At",
    sortable: true,
    selector: "createdAt",
    cell: (row) => {
      return moment(row.createdAt).format("llll");
    },
    width: "180px",
  },
  {
    name: "Updated At",
    sortable: true,
    selector: "updatedAt",
    cell: (row) => {
      return moment(row.createdAt).format("llll");
    },
    width: "180px",
  },
];

export default columns;
