import React, { Fragment } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import MyAccountContent from "../components/my-account/my-account-content";
import OutsideOrgMyAccount from "../components/outside-organizations/outside-org-my-account-content";
const MyAccount = () => {
  const { isAuthenticated, user } = useAuth0();

  const outsideOrg = user["https://app.evojets.com/outsideOrganization"];

  return (
    <Fragment>
      {isAuthenticated && !outsideOrg && <MyAccountContent />}
      {isAuthenticated && outsideOrg && <OutsideOrgMyAccount />}
    </Fragment>
  );
};

export default MyAccount;
