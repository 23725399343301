import React from "react";
import LeadsContent from "../components/leads/leads-content";

const UnqualifiedLeads = () => {
  return (
    <LeadsContent
      prefilters={["unqualified"]}
      defaultStageFilters={{ label: "Unqualified", value: "unqualified" }}
      pageTitle="Unqualifled Leads"
    />
  );
};

export default UnqualifiedLeads;
