import formatter from "../../helpers/currencyFormatter";
import timeFormatter from "../../helpers/timeFormatter";

const columns = [
  {
    name: "Aircraft Type",
    selector: "name",
    sortable: true,
    width: "200px",
  },
  {
    name: "Billable Time",
    sortable: true,
    cell: (row) => {
      return timeFormatter(row.billableTime);
    },
    selector: "billableTime",
    width: "200px",
  },
  {
    name: "Est. Flight Time",
    sortable: true,

    cell: (row) => {
      return timeFormatter(row.adjustedTimeLegOne);
    },
    selector: "estFlightTime",
    width: "200px",
  },
  {
    name: "Price Range",
    sortable: true,
    width: "200px",
    selector: "lowSpread",
    cell: (row) => {
      return `${formatter.format(row.lowSpread)} - ${formatter.format(
        row.highSpread
      )}`;
    },
  },
  {
    name: "Final Price",
    sortable: true,
    width: "200px",
    selector: "finalPrice",
    cell: (row) => {
      return `${formatter.format(row.finalPrice)}`;
    },
  },
];

export default columns;
