import React, { Fragment, useReducer, useEffect } from "react";
import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import ai from "../../helpers/axios";
import CreatableSelect from "react-select/creatable";

import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";

import TextInput from "../text-input";

const reducer = (state, action) => {
  switch (action.type) {
    case "search": {
      return {
        ...state,
        query: action.query,
      };
    }
    case "fieldChanged": {
      return {
        ...state,
        operator: {
          ...state.operator,
          [action.field]: action.value,
        },
      };
    }

    case "rootFieldChanged": {
      return {
        ...state,
        [action.field]: action.value,
      };
    }
    case "dataBeganDownloading": {
      return {
        ...state,
        loading: true,
      };
    }
    case "dataDownloaded": {
      let restrictionsInput = [];

      let cities = action?.payload?.data?.operator?.cities;

      if (cities && cities?.length) {
        cities = cities.flatMap((item) => {
          return {
            label: item,
            value: item,
          };
        });
      }

      if (action?.payload?.data?.operator?.restrictions?.length) {
        restrictionsInput =
          action?.payload?.data?.operator?.restrictions.flatMap((item) => {
            if (item === "caution") {
              return { label: "Use With Caution", value: item };
            }

            if (item === "blacklist") {
              return { label: "Blacklist", value: item };
            }

            if (item === "recovery") {
              return { label: "Recovery Only", value: item };
            }
          });
      }

      return {
        ...state,
        operator: action.payload.data.operator,
        opiWeights: action.payload.data.opiWeights,
        restrictionsInput,
        cities,
      };
    }
    default:
      break;
  }
  return state;
};

const SingleOperatorContent = (props) => {
  const { id } = useParams();
  const history = useHistory();

  const initialState = {
    operator: {
      operatorName: "",
      type: "",
      operationalIntegrity: "",
      communication: "",
      aircraftInFleet: "",
      aircraftCondition: "",
      serviceRecord: "",
      onDemandFriendly: "",
      yearsWithPart135: "",
      fleetAge: "",
      safetyRating: "",
      discretionary: "",
      restrictions: [],
      country: "",
      notes: "",
    },
    opiWeights: {
      operationalIntegrity: 4,
      communication: 4,
      aircraftInFleet: 2,
      aircraftCondition: 2,
      serviceRecord: 3,
      onDemandFriendly: 2,
      yearsWithPart135: 1,
      fleetAge: 1,
      safetyRating: 1,
      discretionary: 1,
    },
    composedScore: null,
    restrictionsInput: [],
    loading: true,
    cities: [],
    citiesInputValue: "",
  };

  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const [state, dispatch] = useReducer(reducer, initialState);

  const getData = async () => {
    dispatch({ type: "dataBeganDownloading" });

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let { data } = await ai.auth(token).get(`/api/opi/${id}`);

    console.log(data);

    if (data.operator.discretionary === null) {
      data.operator.discretionary = "";
    }

    dispatch({
      type: "dataDownloaded",
      payload: {
        data,
      },
    });
  };

  const handleRestrictionsSelection = (selectedRestrictions) => {
    console.log("RESTRICTIONS SELECT EVENT", selectedRestrictions);

    if (selectedRestrictions?.value) {
      dispatch({
        type: "fieldChanged",
        field: "restrictions",
        value: [selectedRestrictions?.value],
      });
      return selectedRestrictions;
    }

    dispatch({
      type: "fieldChanged",
      field: "restrictions",
      value: null,
    });

    return selectedRestrictions;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let formData = { ...state.operator };

    if (formData.operationalIntegrity === "") {
      formData.operationalIntegrity = 0;
    }

    if (formData.communication === "") {
      formData.communication = 0;
    }

    if (formData.aircraftInFleet === "") {
      formData.aircraftInFleet = 0;
    }

    if (formData.operationalIntegrity === "") {
      formData.operationalIntegrity = 0;
    }

    if (formData.aircraftCondition === "") {
      formData.aircraftCondition = 0;
    }

    if (formData.serviceRecord === "") {
      formData.serviceRecord = 0;
    }

    if (formData.onDemandFriendly === "") {
      formData.onDemandFriendly = 0;
    }

    if (formData.yearsWithPart135 === "") {
      formData.yearsWithPart135 = 0;
    }

    if (formData.fleetAge === "") {
      formData.fleetAge = 0;
    }

    if (formData.safetyRating === "") {
      formData.safetyRating = 0;
    }

    if (formData.discretionary === "") {
      formData.discretionary = 0;
    }

    if (formData.type && formData.type.value) {
      formData.type = formData.type.value;
    }

    if (state?.cities?.length) {
      formData.cities = state.cities.map((i) => i.value);
    }

    let { data } = await ai.auth(token).put(`/api/opi/${id}`, formData);

    console.log("resp", data);

    history.push({ pathname: "/opi" });

    setTimeout(() => {
      toast.success("Operator Updated Successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }, 50);
  };

  useEffect(() => {
    getData();
    document.title = "Edit Operator | Flight Deck by evoJets";
  }, []);

  const calculateOPI = (operator, opiWeights) => {
    let {
      operationalIntegrity,
      communication,
      aircraftInFleet,
      aircraftCondition,
      serviceRecord,
      onDemandFriendly,
      yearsWithPart135,
      fleetAge,
      safetyRating,
      discretionary,
    } = operator;

    if (operationalIntegrity === "" || operationalIntegrity === null) {
      operationalIntegrity = 0;
    }

    if (communication === "" || communication === null) {
      communication = 0;
    }

    if (aircraftInFleet === "" || aircraftInFleet === null) {
      aircraftInFleet = 0;
    }

    if (aircraftCondition === "" || aircraftCondition === null) {
      aircraftCondition = 0;
    }

    if (serviceRecord === "" || serviceRecord === null) {
      serviceRecord = 0;
    }

    if (onDemandFriendly === "" || onDemandFriendly === null) {
      onDemandFriendly = 0;
    }

    if (yearsWithPart135 === "" || yearsWithPart135 === null) {
      yearsWithPart135 = 0;
    }

    if (fleetAge === "" || fleetAge === null) {
      fleetAge = 0;
    }

    if (safetyRating === "" || safetyRating === null) {
      safetyRating = 0;
    }

    if (discretionary === "" || discretionary === null) {
      discretionary = 0;
    }

    let newScore =
      operationalIntegrity * opiWeights.operationalIntegrity +
      communication * opiWeights.communication +
      aircraftInFleet * opiWeights.aircraftInFleet +
      aircraftCondition * opiWeights.aircraftInFleet +
      serviceRecord * opiWeights.serviceRecord +
      onDemandFriendly * opiWeights.onDemandFriendly +
      yearsWithPart135 * opiWeights.yearsWithPart135 +
      fleetAge * opiWeights.fleetAge +
      safetyRating * opiWeights.safetyRating +
      discretionary * opiWeights.discretionary;

    dispatch({
      type: "rootFieldChanged",
      field: "composedScore",
      value: parseInt(newScore),
    });
  };

  const handleTextInputChange = (e) => {
    let value = e.target.value;

    if (e.target.type === "number") {
      value = parseInt(e.target.value);

      if (
        e.target.value === null ||
        typeof e.target.value === undefined ||
        e.target.value === ""
      ) {
        value = "";
      }
    }

    console.log(e.target.value);

    dispatch({
      type: "fieldChanged",
      field: e.target.name,
      value: value,
    });
  };

  useEffect(() => {
    calculateOPI(state.operator, state.opiWeights);
    console.log("OPI WEIGHTS", state.opiWeights);
  }, [
    state.operator.operationalIntegrity,
    state.operator.communication,
    state.operator.aircraftInFleet,
    state.operator.aircraftCondition,
    state.operator.serviceRecord,
    state.operator.onDemandFriendly,
    state.operator.yearsWithPart135,
    state.operator.fleetAge,
    state.operator.safetyRating,
    state.operator.discretionary,
    state.opiWeights,
  ]);

  const createOption = (label) => ({
    label,
    value: label,
  });

  const handleCitiesChange = (newValue, actionMeta) => {
    dispatch({
      type: "rootFieldChanged",
      field: "cities",
      value: newValue || [],
    });
  };

  const handleCitiesInputChange = (inputValue) => {
    dispatch({
      type: "rootFieldChanged",
      field: "citiesInputValue",
      value: inputValue,
    });
  };

  const handleCitiesKeyDown = (event) => {
    const { citiesInputValue, cities } = state;

    if (!citiesInputValue) return;

    switch (event.key) {
      case "Enter":
      case "Tab":
        event.preventDefault();

        dispatch({
          type: "rootFieldChanged",
          field: "cities",
          value: [...cities, createOption(citiesInputValue)],
        });

        dispatch({
          type: "rootFieldChanged",
          field: "citiesInputValue",
          value: "",
        });

        return;

      default:
        break;
    }
  };
  return (
    <Fragment>
      <h2 id="addAirportHeading">
        Edit{" "}
        {state.operator.operatorName ? state.operator.operatorName : "Operator"}
      </h2>
      <h4 className="centered opi-score">
        Composed Score: {state.composedScore}
      </h4>

      <form id="addAirport" onSubmit={handleSubmit}>
        <TextInput
          type="text"
          name="operatorName"
          value={state.operator.operatorName}
          label="Operator Name"
          handleChange={handleTextInputChange}
        ></TextInput>
        {/* 
        <TextInput
          type="text"
          name="type"
          value={state.operator.type}
          label="Operator Type"
          handleChange={handleTextInputChange}
        ></TextInput> */}

        <TextInput
          type="text"
          name="country"
          value={state.operator.country}
          label="Country"
          handleChange={handleTextInputChange}
        ></TextInput>

        <div className="form-group">
          <label htmlFor="notificationEmails">Cities</label>
          <CreatableSelect
            className="cities-select mb-20 base-select"
            classNamePrefix="select"
            placeholder="Cities"
            components={{ DropdownIndicator: null }}
            isClearable
            isMulti
            menuIsOpen={false}
            onChange={handleCitiesChange}
            onInputChange={handleCitiesInputChange}
            onKeyDown={handleCitiesKeyDown}
            inputValue={state.citiesInputValue}
            value={state.cities}
            name={"newCitiesList"}
            inputId={"newCitiesList"}
          ></CreatableSelect>
        </div>

        <TextInput
          type="number"
          name="operationalIntegrity"
          value={state.operator.operationalIntegrity}
          label="Operational Integrity"
          handleChange={handleTextInputChange}
          max="5"
          min="0"
        ></TextInput>

        <TextInput
          type="number"
          name="communication"
          value={state.operator.communication}
          label="Communication"
          handleChange={handleTextInputChange}
          max="5"
          min="0"
        ></TextInput>

        <TextInput
          type="number"
          name="aircraftInFleet"
          value={state.operator.aircraftInFleet}
          label="Aircraft In Fleet"
          handleChange={handleTextInputChange}
          max="5"
          min="0"
        ></TextInput>

        <TextInput
          type="number"
          name="aircraftCondition"
          value={state.operator.aircraftCondition}
          label="Aircraft Condition"
          handleChange={handleTextInputChange}
          max="5"
          min="0"
        ></TextInput>

        <TextInput
          type="number"
          name="serviceRecord"
          value={state.operator.serviceRecord}
          label="Service Record"
          handleChange={handleTextInputChange}
          max="5"
          min="0"
        ></TextInput>

        <TextInput
          type="number"
          name="onDemandFriendly"
          value={state.operator.onDemandFriendly}
          label="On-Demand Friendly"
          handleChange={handleTextInputChange}
          max="5"
          min="0"
        ></TextInput>

        <TextInput
          type="number"
          name="yearsWithPart135"
          value={state.operator.yearsWithPart135}
          label="Years With Part 135"
          handleChange={handleTextInputChange}
          max="5"
          min="0"
        ></TextInput>

        <TextInput
          type="number"
          name="fleetAge"
          value={state.operator.fleetAge}
          label="Average Age of Fleet"
          handleChange={handleTextInputChange}
          max="5"
          min="0"
        ></TextInput>

        <TextInput
          type="number"
          name="safetyRating"
          value={state.operator.safetyRating}
          label="Safety Rating"
          handleChange={handleTextInputChange}
          max="5"
          min="0"
        ></TextInput>

        <TextInput
          type="number"
          name="discretionary"
          value={state.operator.discretionary}
          label="Discretionary"
          handleChange={handleTextInputChange}
          max="5"
          min="0"
        ></TextInput>

        <div className="form-group">
          <label className="base-label" htmlFor="selectedRestrictions">
            Restrictions
          </label>
          <Select
            key={`selectedRestrictions-${state.restrictionsInput}`}
            className={`operator-restrictions-select mb-20`}
            classNamePrefix="select"
            options={[
              { label: "Blacklist", value: "blacklist" },
              { label: "Use With Caution", value: "caution" },
              { label: "Recovery Only", value: "recovery" },
            ]}
            // value={state.restrictionsInput}
            defaultValue={state.restrictionsInput}
            placeholder="Operator Restrictions"
            onChange={handleRestrictionsSelection}
            name="selectedRestrictions"
            isClearable
          ></Select>
        </div>

        <TextInput
          type="text"
          name="notes"
          value={state.operator.notes}
          label="Notes"
          handleChange={handleTextInputChange}
        ></TextInput>

        <input type="submit" value="Update Operator"></input>
      </form>

      {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
    </Fragment>
  );
};

export default SingleOperatorContent;
