import React, { Fragment, useEffect, useReducer } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import Loader from "../loader";
import DataTable from "react-data-table-component";
import ai from "../../helpers/axios";
import ReactModal from "react-modal";
import { Link } from "react-router-dom";
import TextInput from "../text-input";
import moment from "moment";
import { toast } from "react-toastify";
import Select from "react-select";

import PageHeaderRowSearch from "../shared/page-header-row-search";

const reducer = (state, action) => {
  switch (action.type) {
    case "dataBeganDownloading": {
      return {
        ...state,
        loading: action.payload.loadingStatus,
      };
    }
    case "rowsDeleted": {
      return {
        ...state,
        clearSelectedRows: !state.clearSelectedRows,
        selectedRows: [],
      };
    }
    case "rowSelection": {
      return {
        ...state,
        selectedRows: action.payload.selectedRows,
        allSelected: action.payload.allSelected,
      };
    }
    case "dataDownloaded": {
      // console.log(action.payload.data);
      return {
        ...state,
        phoneCalls: action.payload.data.phoneCalls,
        loading: false,
        totalLength: action.payload.data.countTotal,
      };
    }
    case "fieldChanged": {
      return {
        ...state,
        [action.field]: action.value,
      };
    }
    case "openModal": {
      return {
        ...state,
        modalOpen: true,
        modalRow: action.payload.row || null,
      };
    }
    case "closeModal": {
      return {
        ...state,
        modalOpen: false,
        modalRow: null,
        showWarmProspectForm: false,
      };
    }

    default:
      break;
  }
  return state;
};

const PhoneCallWidget = (props) => {
  const initialState = {
    phoneCalls: [],
    loading: true,
    selectedRows: [],
    allSelected: false,
    totalLength: null,
    clearSelectedRows: false,
    stageFilters: ["newLead"],
    modalOpen: false,
    modalRow: null,
    showWarmProspectForm: false,
    prospectFirstName: "",
    prospectLastName: "",
    prospectEmail: "",
  };

  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const [state, dispatch] = useReducer(reducer, initialState);

  const handleCloseLeadModal = (e) => {
    e.preventDefault();
    dispatch({
      type: "openModal",
      payload: {
        row: e.currentTarget.dataset.row,
      },
    });
  };

  const columns = [
    {
      name: "Send Quote",
      sortable: false,
      cell: (row) => {
        if (row.leadId) {
          return <Link to={`/leads/${row.leadId}/quotes/new`}>New Quote</Link>;
        }
        return <Link to={`/phone-calls/${row._id}/new-quote`}>New Quote</Link>;
      },
      width: "130px",
    },
    {
      name: "Close Lead",
      sortable: false,
      cell: (row) => {
        return (
          <button
            data-row={row._id}
            className="closeLead"
            onClick={handleCloseLeadModal}
          >
            Close Lead
          </button>
        );
      },
      width: "130px",
    },
    {
      name: "Created At",
      selector: "createdAt",
      sortable: true,
      width: "200px",
      cell: (row) => {
        return moment(row.createdAt).format("llll");
      },
    },
    {
      name: "From",
      selector: "from",
      sortable: true,
      width: "150px",
    },

    // {
    //   name: "Stage",
    //   selector: "stage",
    //   sortable: true,
    //   width: "150px",
    // },

    // {
    //   name: "Assignee",
    //   selector: "assigneeName",
    //   sortable: true,
    //   width: "150px",
    // },
    // {
    //   name: "Recording",
    //   selector: "audioLink",
    //   sortable: true,
    //   width: "150px",
    //   cell: (row) => {
    //     if (row.audioLink && row.audioLink !== "") {
    //       return (
    //         <a href={row.audioLink} target="_blank">
    //           Listen
    //         </a>
    //       );
    //     }

    //     return "No recording.";
    //   },
    // },
  ];

  const getData = async (loadSilently, stageFilters) => {
    let loadingStatus = true;
    if (loadSilently) {
      loadingStatus = false;
    }

    console.log(stageFilters);
    dispatch({ type: "dataBeganDownloading", payload: { loadingStatus } });

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let { data } = await ai.auth(token).get(`/api/phoneCalls/list`, {
      params: { stageFilters, widgetView: true },
    });
    console.log(data);
    dispatch({
      type: "dataDownloaded",
      payload: {
        data,
      },
    });
  };

  useEffect(() => {
    getData(false, state.stageFilters);
  }, []);

  const handleSelection = function (rowData) {
    dispatch({
      type: "rowSelection",
      payload: {
        selectedRows: rowData.selectedRows,
        allSelected: rowData.allSelected,
      },
    });
  };

  const handlePhoneCallClose = async (e) => {
    e.preventDefault();

    let buttonId = e.currentTarget.id;
    let phoneCall = state.modalRow;
    let stage = "newLead";
    let successMessage = "";

    if (buttonId === "archive-button") {
      stage = "archived";
      successMessage = "Phone call archived.";
    } else if (buttonId === "warm-prospect-button") {
      stage = "warmProspect";
    } else if (buttonId === "unqualified-button") {
      stage = "unqualified";
      successMessage = "Phone call marked as unqualified.";
    }

    console.log(buttonId, phoneCall);

    if (stage === "warmProspect") {
      dispatch({
        type: "fieldChanged",
        field: "showWarmProspectForm",
        value: true,
      });
      return;
    } else {
      dispatch({
        type: "fieldChanged",
        field: "showWarmProspectForm",
        value: false,
      });
    }

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let response = await ai
      .auth(token)
      .put(`/api/phoneCalls/${phoneCall}/closePhoneCall`, { stage })
      .catch((e) => console.error(e));

    if (response) {
      console.log(response.data);
      dispatch({ type: "closeModal" });

      toast.success(successMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      getData(true, state.stageFilters);

      return response.data;
    }
  };

  const handleWarmProspectSubmit = async (e) => {
    e.preventDefault();

    let phoneCall = state.modalRow;

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let response = await ai
      .auth(token)
      .put(`/api/phoneCalls/${phoneCall}/createProspectAndMarkWarm`, {
        prospectData: {
          firstName: state.prospectFirstName,
          lastName: state.prospectLastName,
          email: state.prospectEmail,
          stage: "warmProspect",
        },
      })
      .catch((e) => console.error(e));

    if (response) {
      console.log(response.data);

      dispatch({ type: "closeModal" });

      toast.success("Prospect created and marked warm.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      getData(false);

      return response.data;
    } else {
      toast.error("There was an error. Please try again.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const handleTextInputChange = (e) => {
    return dispatch({
      type: "fieldChanged",
      field: e.target.name,
      value: e.target.value,
    });
  };

  const handleStageFilterChange = (selectedValues, action) => {
    console.log(selectedValues, action);
    let values = [];
    if (selectedValues) {
      values = selectedValues.map(({ value }) => value);
    }

    dispatch({ type: "fieldChanged", field: "stageFilters", value: values });
    console.log(values);
    getData(true, values);
  };

  const stageFilterOptions = [
    { label: "New Lead", value: "newLead" },
    { label: "In Progress", value: "inProgress" },
    { label: "Warm Prospect", value: "warmProspect" },
    { label: "Unqualified", value: "unqualified" },
    { label: "Archived", value: "archived" },
  ];

  return (
    <Fragment>
      <div id="phoneCall-widget">
        <h3 className="widget-title">Your Team's Phone Calls</h3>
        <div className="phoneCallWidgetTableContainer">
          <DataTable
            data={state.phoneCalls}
            columns={columns}
            // selectableRows
            noHeader
            highlightOnHover
            pagination={false}
            paginationPerPage={50}
            paginationRowsPerPageOptions={[50, 100, 150, 200, 250]}
            onSelectedRowsChange={handleSelection}
            clearSelectedRows={state.clearSelectedRows}
            responsive={true}
            defaultSortField="createdAt"
            defaultSortAsc={false}
          ></DataTable>
        </div>

        <Link
          to="/phone-calls"
          className="evo-blue base-button link-button widget-footer-button"
        >
          View All
        </Link>
      </div>
      <ReactModal
        isOpen={state.modalOpen}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() =>
          dispatch({
            type: "closeModal",
          })
        }
        style={{
          overlay: {},
          content: {
            // width: "auto",
            // height: "auto",
            // maxWidth: "400px",
            // maxHeight: "50%",
          },
        }}
        appElement={document.getElementById("app")}
      >
        <div id="outreach-status-updaters">
          <button
            className="lead-outreach-assignment"
            onClick={handlePhoneCallClose}
            id="archive-button"
          >
            Archive
          </button>
          <button
            className="lead-outreach-assignment"
            onClick={handlePhoneCallClose}
            id="unqualified-button"
          >
            Unqualified
          </button>
          <button
            className="lead-outreach-assignment"
            onClick={handlePhoneCallClose}
            id="warm-prospect-button"
          >
            Warm Prospect
          </button>
        </div>

        {state.showWarmProspectForm && (
          <form
            className="base-form"
            id="warmProspectForm"
            onSubmit={handleWarmProspectSubmit}
          >
            <h3>Create &amp; Mark Prospect as Warm</h3>
            <TextInput
              name="prospectFirstName"
              label="First Name"
              handleChange={handleTextInputChange}
              value={state.prospectFirstName}
            ></TextInput>
            <TextInput
              name="prospectLastName"
              label="Last Name"
              handleChange={handleTextInputChange}
              value={state.prospectLastName}
            ></TextInput>
            <TextInput
              name="prospectEmail"
              label="Email"
              type="email"
              handleChange={handleTextInputChange}
              value={state.prospectEmail}
            ></TextInput>
            <input type="submit" value="Submit"></input>
          </form>
        )}
      </ReactModal>
    </Fragment>
  );
};

export default PhoneCallWidget;
