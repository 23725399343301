import { Link } from "react-router-dom";

const columns = [
  {
    name: "Edit",
    sortable: false,
    cell: (row) => {
      return <Link to={`/distance-params/${row._id}`}>Edit</Link>;
    },
    width: "70px",
  },
  {
    name: "Min Distance",
    sortable: true,
    selector: "minDistance",
    width: "160px",
  },
  {
    name: "Max Distance",
    selector: "maxDistance",
    sortable: true,
    width: "160px",
  },
  {
    name: "Aircraft Type",
    sortable: true,
    selector: "aircraftTypeData[0].name",
    width: "180px",
  },
  {
    name: "BMI",
    selector: "bmi",
    sortable: true,
    width: "100px",
  },
  {
    name: "Wind",
    selector: "wind",
    sortable: true,
    width: "120px",
  },
  {
    name: "MLV",
    selector: "mlv",
    sortable: true,
    width: "120px",
  },
];

export default columns;
