import React from "react";
import LeadsContent from "../components/leads/leads-content";

const Leads = () => {
  return (
    <LeadsContent
      prefilters={["newLead"]}
      defaultStageFilters={{ label: "New Leads", value: "newLead" }}
      defaultOwnerFilters={[]}
      pageTitle="New Leads"
    />
  );
};

export default Leads;
