import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ai from "../../helpers/axios";

import { useReducer } from "react";

import ReactModal from "react-modal";

//import textarea-input component
import TextAreaInput from "../textarea-input";

//import text input
import TextInput from "../text-input";

import { Datepicker, momentTimezone } from "@mobiscroll/react";
import mobiscroll from "@mobiscroll/react";

import CheckBoxOutlineBlank from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import IconButton from "@mui/material/IconButton";

import NumberFormat from "react-number-format";
import Select from "react-select";

import moment from "moment-timezone";

import "@mobiscroll/react/dist/css/mobiscroll.min.css";

momentTimezone.moment = moment;

const toastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "fieldChanged": {
      return {
        ...state,
        [action.field]: action.value,
      };
    }

    case "dataBeganDownloading": {
      return {
        ...state,
        loading: true,
      };
    }

    case "dataDownloaded": {
      return {
        ...state,
        loading: false,
      };
    }

    case "resetForm": {
      return {
        ...state,

        paymentDate: "",
        paymentType: "",
        amount: "",
        recordBeingEdited: null,
      };
    }

    case "recordSelectionEvent": {
      console.log("recordSelectionEvent", action);

      let selectedRecordIds = [];

      selectedRecordIds = [...state.selectedRecords].flatMap((record) => {
        if (record && record._id) {
          return record._id;
        }

        return [];
      });

      let newSelection = new Set([...selectedRecordIds]);

      if (action.selected) {
        newSelection.add(action.recordId);
      }

      if (!action.selected) {
        newSelection.delete(action.recordId);
      }

      newSelection = [...newSelection];

      let selectedRecords = state?.records?.filter((record) =>
        newSelection.includes(record._id)
      );

      console.log("SELECTED RECORDS - EDITED", newSelection);

      return { ...state, selectedRecords: [...selectedRecords] };
    }

    case "addRecordForm": {
      return {
        ...state,
        showFormModal: true,
        modalHeaderText: "Add Record",
      };
    }

    case "editRecordForm": {
      let record = action.record;

      let paymentType = null;
      //handle select fields
      if (record.paymentType) {
        paymentType = {
          label: record.paymentType,
          value: record.paymentType,
        };
      }

      return {
        ...state,
        showFormModal: true,
        modalHeaderText: "Edit Record",
        ...record,
        paymentType,
      };
    }

    default:
      break;
  }
  return state;
};

const PaymentModule = (props) => {
  const { getAccessTokenSilently, user, loginWithRedirect } = useAuth0();

  const userRoles = user && user["https://app.evojets.com/roles"];

  const superAdmin = userRoles.some(function (role) {
    return "Super Admin" === role;
  });

  //filter deleted records
  let records = props.records?.filter((record) => !record.deleted);

  let initialState = {
    showEditModal: false,
    recordBeingEdited: null,
    paymentDate: "",
    paymentType: "",
    amount: "",
    records: records,
    loading: false,
    selectedRecords: [],
    operatorId: "",
    operatorName: "",
    datePickerLabel: "Payment Date",
    notes: "",
    showNotes: false,
    showOperatorSelect: false,
  };

  if (props.recordType === "clientPaymentSchedule") {
    initialState.recordTypeLabel = "Client Payment Schedule";
    initialState.datePickerLabel = "Due Date";
  }

  if (props.recordType === "clientPaymentHistory") {
    initialState.recordTypeLabel = "Client Payment History";
    initialState.showNotes = true;
  }

  if (props.recordType === "operatorPaymentHistory") {
    initialState.recordTypeLabel = "Operator Payment History";
    initialState.showOperatorSelect = true;
    initialState.showNotes = true;
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  //create function to handle edit button, show react Modal with input field
  const handleEditButton = (item) => {
    console.log("EDIT RECORD", item);
    //show modal

    //set recordBeingEdited
    dispatch({
      type: "fieldChanged",
      field: "recordBeingEdited",
      value: item,
    });

    //show modal
    dispatch({
      type: "editRecordForm",
      //pass record to be edited
      record: item,
    });
  };

  const handleAddRecord = async (e) => {
    console.log("add record");

    e.preventDefault();

    let formData = {
      paymentType: state.paymentType?.value,
      amount: state.amount,
      paymentDate: state.paymentDate,
    };

    if (state.showNotes) {
      formData.notes = state.notes;
    }

    const token = await getAccessTokenSilently();
    const response = await ai
      .auth(token)
      .post(
        `/api/trips/${props.tripId}/addPaymentRecord/${props.recordType}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

    //reset form
    dispatch({ type: "resetForm" });

    props.parentDispatch({
      type: "fieldChanged",
      field: "refreshDataToken",
      value: Date.now(),
    });

    console.log(response);
  };

  const handleFormSubmit = async (e) => {
    console.log("submit");

    e.preventDefault();

    //if recordBeingEdited is null, add record
    if (!state.recordBeingEdited) {
      handleAddRecord(e);
    }

    //if recordBeingEdited is not null, edit record
    if (state.recordBeingEdited) {
      handleEditSubmit(e);
    }
  };

  //create function to edit a note
  const handleEditSubmit = async (e) => {
    console.log("edit");

    e.preventDefault();

    let formData = {
      paymentType: state.paymentType?.value,
      amount: state.amount,
      paymentDate: state.paymentDate,
    };

    if (state.showNotes) {
      formData.notes = state.notes;
    }

    console.log(formData);

    const token = await getAccessTokenSilently();
    const response = await ai
      .auth(token)
      .put(
        `/api/trips/${props.tripId}/updatePaymentRecord/${props.recordType}/${state.recordBeingEdited._id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

    dispatch({ type: "fieldChanged", field: "showEditModal", value: false });

    dispatch({ type: "resetForm" });

    props.parentDispatch({
      type: "fieldChanged",
      field: "refreshDataToken",
      value: Date.now(),
    });

    console.log(response);
  };

  //function to delete a note
  const handleDeleteButton = async () => {
    console.log("delete");
    if (state.selectedRecords.length < 1) {
      return;
    }

    let recordIds = state.selectedRecords.map((record) => record._id);

    const token = await getAccessTokenSilently();
    const response = await ai
      .auth(token)
      .delete(
        `/api/trips/${props.tripId}/deletePaymentRecords/${props.recordType}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {
            recordIds: recordIds,
          },
        }
      );

    props.parentDispatch({
      type: "fieldChanged",
      field: "refreshDataToken",
      value: Date.now(),
    });

    console.log(response);
  };

  const handlePriceFieldChange = (values, sourceInfo) => {
    const { formattedValue, value } = values;
    // Event is a Synthetic Event wrapper which holds target and other information. Source tells whether the reason for this function being triggered was an 'event' or due to a 'prop' change
    const { event, source } = sourceInfo;

    console.log("event", sourceInfo);

    if (!event?.target) {
      return;
    }

    dispatch({
      type: "fieldChanged",
      field: event?.target?.name,
      value: value,
      fieldType: "float",
    });
  };

  return (
    <>
      <div
        className="trip-notes-module"
        key={`key-catering-${props.dataDownloadedTimestamp.toString()}`}
      >
        <h3>{props.title}</h3>
        <div className="payment-records-table">
          <table className="cell-padding-5-10">
            <thead className="fm-l2-parent-table-head dynamic-accordion-table-header">
              <tr className="fm-l2-parent-table-head-row">
                {props.columns.map((column) => {
                  return <th key={`column-${column.label}`}>{column.label}</th>;
                })}
                {props.selectable && <th>Select</th>}
              </tr>
            </thead>
            <tbody className="fm-l2-parent-table-body">
              {state.records?.length > 0 &&
                state.records.map((record) => {
                  let recordSelected = state.selectedRecords.some(
                    (selectedRecord) => record._id === selectedRecord._id
                  );

                  return (
                    <tr className="fm-l2-parent-table-row" key={record._id}>
                      {props.columns.map((column) => {
                        let value = record[column.key];

                        if (column.key === "paymentDate") {
                          value = moment(value).format("MM/DD/YYYY");
                        }

                        if (column.key === "enteredBy") {
                          value = record?.enteredBy?.name;
                        }

                        if (column.key === "amount") {
                          //currency
                          value = value.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          });
                        }

                        return <td key={`column-${column.label}`}>{value}</td>;
                      })}

                      <td>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          disabled={false}
                          className="payment-record-checkbox"
                          onClick={(e) => {
                            e.stopPropagation();
                            dispatch({
                              type: "recordSelectionEvent",
                              recordId: record._id,
                              selected: !recordSelected,
                            });
                          }}
                        >
                          {recordSelected ? (
                            <CheckBoxIcon />
                          ) : (
                            <CheckBoxOutlineBlank />
                          )}
                        </IconButton>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>

      {
        //render add record, edit, and delete buttons
        // show add record always
        // show edit button always, but only make it active if one record is selected
        // show delete button always, but only make it active if one or more records are selected
      }

      <div className="payment-records-buttons mt-20">
        <button
          className="base-button evo-blue mr-10"
          onClick={() => {
            dispatch({
              type: "addRecordForm",
            });
          }}
        >
          Add Record
        </button>

        <button
          className="base-button evo-blue mr-10"
          disabled={state.selectedRecords.length !== 1}
          onClick={() => {
            handleEditButton(state.selectedRecords[0]);
          }}
        >
          Edit Record
        </button>

        <button
          className="base-button evo-blue "
          disabled={state.selectedRecords.length < 1}
          onClick={() => {
            handleDeleteButton();
          }}
        >
          {state.selectedRecords?.length > 1
            ? `Delete ${state.selectedRecords.length} Records`
            : `Delete Record`}
        </button>
      </div>

      <ReactModal
        isOpen={state.showFormModal}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => {
          dispatch({
            type: "fieldChanged",
            field: "showFormModal",
            value: false,
          });
        }}
        style={{
          overlay: {
            zIndex: 999999,
            backgroundColor: "rgb(32 53 68 / 50%)",
          },
          content: {
            width: "auto",
            // height: "700px",
            // padding: "40px",
            border: "none",
            maxWidth: "700px",
            maxHeight: "80vh",
            minWidth: "50vw",
            borderRadius: "0",
            boxShadow: "10px 10px 91px -17px rgb(0 0 0 / 25%)",
          },
        }}
        appElement={document.getElementById("app")}
      >
        <div className="modal-header">
          <h4>{state.modalHeaderText}</h4>
        </div>
        <div className="modal-body width-100">
          <form
            onSubmit={handleFormSubmit}
            className="width-100 base-form mt-20"
          >
            <div className="datepickerContainer mb-30">
              <label htmlFor={`date`} className="base-label">
                Date <span className="required">*</span>
              </label>

              <Datepicker
                value={state.paymentDate}
                onChange={(event) => {
                  console.log(event.value);
                  dispatch({
                    type: "fieldChanged",
                    field: "paymentDate",
                    value: event.value,
                  });
                }}
                dataTimezone="utc"
                displayTimezone="local"
                timezonePlugin={momentTimezone}
                controls={["date"]}
              />
            </div>

            <div className="form-group">
              <label htmlFor={`date`} className="base-label">
                Payment Type <span className="required">*</span>
              </label>
              <Select
                className="paymentType-select"
                classNamePrefix="select"
                options={[
                  {
                    label: "Wire",
                    value: "Wire",
                  },
                  {
                    label: "CC",
                    value: "CC",
                  },
                  {
                    label: "Account",
                    value: "Account",
                  },
                ]}
                placeholder="Payment Type"
                onChange={(selectedValue) => {
                  dispatch({
                    type: "fieldChanged",
                    field: "paymentType",
                    value: selectedValue,
                  });
                }}
                key={`key-payment-type`}
                value={state.paymentType}
                name="paymentType"
                required
              ></Select>
            </div>

            <div className="form-group">
              <label htmlFor={`amount`} className="base-label">
                Amount <span className="required">*</span>
              </label>

              <NumberFormat
                value={state.amount}
                thousandSeparator={true}
                prefix={"$"}
                onValueChange={handlePriceFieldChange}
                placeholder="Amount"
                className="semibold-text"
                decimalScale={2}
                name="amount"
              />
            </div>

            {state.showNotes && (
              <TextAreaInput
                label="Notes"
                name="notes"
                value={state.notes}
                handleChange={(e) => {
                  dispatch({
                    type: "fieldChanged",
                    field: "notes",
                    value: e.target.value,
                  });
                }}
              ></TextAreaInput>
            )}

            <button className="base-button evo-blue w-100" type="submit">
              Submit
            </button>
          </form>
        </div>
      </ReactModal>
    </>
  );
};

export default PaymentModule;
