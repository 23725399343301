import React from "react";
import { Link } from "react-router-dom";

const TripHeader = (props) => {
  let {
    aircraftName,
    clientName,
    createdDateFormatted,
    earliestDepartureDateFormatted,
    ownerName,
    tripTag,
  } = props.data;

  return (
    <div id="page-header" className="trip-header">
      <div id="page-title-container">
        <p className="owner-name">{ownerName}</p>
        <p className="created-on">Created on {createdDateFormatted}</p>
        <h1 className="page-title">
          {clientName} | Trip #{tripTag} <br />
          <span>
            Departing {earliestDepartureDateFormatted} - {aircraftName}
          </span>
        </h1>
      </div>

      <div id="page-breadcrumb">
        <ol className="breadcrumb">
          {props.breadcrumbs.map((item) => {
            return (
              <li className="breadcrumb-item" key={item.label}>
                <Link to={item.link}>{item.label}</Link>
              </li>
            );
          })}
          <span className="material-icons">chevron_right</span>
          <li className="breadcrumb-item active">Trip #{tripTag}</li>
        </ol>
      </div>
    </div>
  );
};

export default TripHeader;
