import React from "react";
import PhoneCallQuoteContent from "../components/quotes/phoneCall-quote-content";
import { useParams } from "react-router-dom";

const PhoneCallQuote = () => {
  const { id } = useParams();
  return <PhoneCallQuoteContent phoneCallId={id} leadOrigin="phoneCall" />;
};

export default PhoneCallQuote;
