import { Link } from "react-router-dom";
import moment from "moment";

const columns = [
  //   {
  //     name: "View",
  //     sortable: false,
  //     cell: (row) => {
  //       return <Link to={`/estim/${row._id}`}>View</Link>;
  //     },
  //     width: "70px",
  //   },
  {
    name: "Created At",
    selector: "createdAt",
    sortable: true,
    width: "250px",
    cell: (row) => {
      return moment(row.createdAt).format("llll");
    },
  },
  {
    name: "Departure Airport",
    selector: "departureAirportName",
    sortable: true,
    width: "300px",
  },
  {
    name: "Arrival Airport",
    selector: "arrivalAirportName",
    sortable: true,
    width: "300px",
  },
  {
    name: "Departure Date",
    selector: "departureDate",
    sortable: true,
    width: "150px",
  },
  {
    name: "Return Date",
    selector: "returnDate",
    sortable: true,
    width: "150px",
  },
  {
    name: "Pax",
    selector: "passengerCount",
    sortable: true,
    width: "150px",
  },
  {
    name: "Trip Type",
    selector: "tripType",
    sortable: true,
    width: "150px",
    cell: (row) => (row.tripType === 1 ? "One Way" : "Round Trip"),
  },

  {
    name: "Origin",
    selector: "origin",
    sortable: true,
    width: "150px",
    // cell: (row) => {
    //   let formatted = "";
    //   if (row.leadOrigin === "qfs") {
    //     formatted = "QFS";
    //   } else if (row.leadOrigin === "rfp") {
    //     formatted = "RFP";
    //   } else if (row.leadOrigin === "phoneCall") {
    //     formatted = "Phone Call";
    //   } else {
    //     formatted = "Unset";
    //   }
    //   return formatted;
    // },
  },
];

export default columns;
