import React, { Fragment, useEffect, useReducer } from "react";
import { Link } from "react-router-dom";

import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";

import ai from "../../helpers/axios";
import * as Sentry from "@sentry/react";

import columns from "./trip-request-columns";
import Search from "../search";
import LeadBulkActions from "./lead-bulk-actions";
import DataTableServerPagination from "../data-table-server-pagination";

import PageHeaderRowSearch from "../shared/page-header-row-search";

import Loader from "../loader";

const reducer = (state, action) => {
  switch (action.type) {
    case "search": {
      return {
        ...state,
        query: action.query,
      };
    }
    case "dataBeganDownloading": {
      return {
        ...state,
        loading: action.payload.loadingStatus,
      };
    }
    case "rowsDeleted": {
      return {
        ...state,
        clearSelectedRows: !state.clearSelectedRows,
        selectedRows: [],
      };
    }
    case "rowSelection": {
      return {
        ...state,
        selectedRows: action.payload.selectedRows,
        allSelected: action.payload.allSelected,
      };
    }
    case "dataDownloaded": {
      console.log("Data Downloaded", action.payload.data);
      return {
        ...state,
        leads: {
          data: action.payload.data.leads,
        },
        loading: false,
        totalLength: action.payload.data.countTotal,
      };
    }
    case "pageChanged": {
      return {
        ...state,
        currentPage: action.payload.page,
      };
    }
    case "tableSort": {
      return {
        ...state,
        sortBy: action.payload.sortBy,
        sortDirection: action.payload.sortDirection,
      };
    }
    case "stageFilterChanged": {
      return {
        ...state,
        stageFilters: action.payload.values,
      };
    }
    case "rowsPerPageChanged": {
      return {
        ...state,
        countPerPage: action.payload.currentRowsPerPage,
      };
    }
    case "leadsAssigned": {
      return {
        ...state,
        leads: {
          data: state.leads.data.map((item) => {
            if (action.payload.leadIds.includes(item._id)) {
              console.log(item.firstName, action.payload.assigneeName);
              //item just assigned
              item.assigneeName = action.payload.assigneeName;
              item.assigneeId = action.payload.assigneeId;
            }
            return item;
          }),
        },
      };
    }

    case "fieldChanged": {
      return {
        ...state,
        [action.field]: action.value,
      };
    }

    default:
      break;
  }
  return state;
};

const TripRequestsContent = (props) => {
  const initialState = {
    leads: {
      data: [],
    },
    loading: true,
    countPerPage: 25,
    currentPage: 1,
    selectedRows: [],
    allSelected: false,
    tagFilters: [],
    totalLength: null,
    clearSelectedRows: false,
    ownerInputField: "",
    selectedOwner: null,
    query: "",
    stageFilters: ["newLead"],
    sortDirection: "desc",
    sortBy: "createdAt",
    ownerOptions: [],
    teamMembers: [],
  };

  const { user } = useAuth0();

  const userRoles = user && user["https://app.evojets.com/roles"];

  const superAdminOrAd = userRoles.some(function (role) {
    return "Super Admin" === role || "Account Director" === role;
  });

  // columns[1].cell = (row) => {
  //   let newQuote = (
  //     <Link to={`/leads/${row._id}/quotes/new`}>New Quote {row._id}</Link>
  //   );

  //   if (row.assigneeId && row.assigneeId === user.sub) {
  //     return newQuote;
  //   }

  //   return "Assign Lead First";
  // };

  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const [state, dispatch] = useReducer(reducer, initialState);

  console.log(state);

  const getData = async (
    dbQuery,
    loadSilently,
    stageFilters,
    sortBy,
    sortDirection
  ) => {
    let loadingStatus = true;
    if (loadSilently) {
      loadingStatus = false;
    }

    console.log("props.showOnlyMine", props.showOnlyMine);

    dispatch({ type: "dataBeganDownloading", payload: { loadingStatus } });

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let response = await ai
      .auth(token)
      .get(`/api/leads/trip-requests/list`, {
        params: {
          query: dbQuery,
          page: state.currentPage,
          countPerPage: state.countPerPage,
          sortBy,
          sortDirection,
          // stageFilters,
          myLeads: props.showOnlyMine,
        },
      })
      .catch((error) => {
        console.error(error.response.data);
        Sentry.captureException(error);
      });

    if (response) {
      dispatch({
        type: "dataDownloaded",
        payload: {
          data: response.data,
        },
      });
    }
  };

  useEffect(() => {
    getData(
      state.query,
      false,
      state.stageFilters,
      state.sortBy,
      state.sortDirection
    );
    document.title = "Trip Requests | Flight Deck by evoJets";
    // loadOwnerOptions();
    // getTeamMembers();
  }, [state.currentPage, state.countPerPage]);

  const handlePageChange = (page, totalRows) => {
    console.log(page, totalRows);

    dispatch({
      type: "pageChanged",
      payload: {
        page,
        totalRows,
      },
    });
  };

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    dispatch({
      type: "rowsPerPageChanged",
      payload: {
        currentRowsPerPage,
        currentPage,
      },
    });
  };

  const handleSelection = function (rowData) {
    dispatch({
      type: "rowSelection",
      payload: {
        selectedRows: rowData.selectedRows,
        allSelected: rowData.allSelected,
      },
    });
  };

  const handleSearch = (e) => {
    let query = e.target.value.toLowerCase();

    dispatch({ type: "search", query });
    getData(query, true, state.stageFilters, state.sortBy, state.sortDirection);
  };

  //   const loadOwnerOptions = async () => {
  //     const token = await getAccessTokenSilently().catch((e) => {
  //   console.error(e);
  //   loginWithRedirect();
  // });
  //     console.log("Leads Content");
  //     let response = await ai
  //       .auth(token)
  //       .get(`/api/users/roles/sales`, {})
  //       .catch((error) => {
  //         console.error(error);
  //         Sentry.captureException(error);
  //       });

  //     if (
  //       response &&
  //       response.data &&
  //       response.data.users &&
  //       response.data.users.length >= 1
  //     ) {
  //       console.log(response.data.users);
  //       let results = response.data.users.map((user) => {
  //         return { value: user.user_id, label: user.name, email: user.email };
  //       });

  //       dispatch({ type: "fieldChanged", field: "ownerOptions", value: results });

  //       return results;
  //     } else {
  //       return [];
  //     }
  //   };

  //   const handleOwnerInputChange = (newValue) => {
  //     dispatch({
  //       type: "fieldChanged",
  //       field: "ownerInputField",
  //       value: newValue,
  //     });
  //     return newValue;
  //   };

  //   const handleOwnerSelection = async (selectedOwner) => {
  //     dispatch({
  //       type: "fieldChanged",
  //       field: "selectedOwner",
  //       value: selectedOwner,
  //     });

  //     console.log(selectedOwner);

  //     const token = await getAccessTokenSilently().catch((e) => {
  //   console.error(e);
  //   loginWithRedirect();
  // });

  //     // hit api endpoint to update assignment -leads

  //     let leadIds = state.selectedRows.map((row) => row._id);

  //     let { data } = await ai
  //       .auth(token)
  //       .put(`/api/leads/bulkAssign`, {
  //         leadIds: leadIds,
  //         assigneeId: selectedOwner.value,
  //       })
  //       .catch((e) => console.error(e));

  //     if (data) {
  //       console.log(data);

  //       getData(
  //         state.query,
  //         true,
  //         state.stageFilters,
  //         state.sortBy,
  //         state.sortDirection
  //       );

  //       dispatch({
  //         type: "fieldChanged",
  //         field: "selectedOwner",
  //         value: null,
  //       });

  //       return data;
  //     }

  //     return selectedOwner;
  //   };

  const preSelectCriteria = (row) => {
    let ids = state.selectedRows.map((item) => item._id);
    return ids.includes(row._id);
  };

  const handleLeadStageUpdate = async (e) => {
    e.preventDefault();

    let buttonId = e.currentTarget.id;
    let leadIds = state.selectedRows.map((row) => row._id);
    let stage = "newLead";

    if (buttonId === "in-progress-button") {
      stage = "inProgress";
    } else if (buttonId === "archive-button") {
      stage = "archived";
    } else if (buttonId === "warm-prospect-button") {
      stage = "warmProspect";
    } else if (buttonId === "unqualified-button") {
      stage = "unqualified";
    }

    console.log(buttonId, leadIds);

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let { data } = await ai
      .auth(token)
      .put(`/api/leads/bulkUpdateStages`, {
        leadIds,
        stage,
      })
      .catch((e) => console.error(e));

    if (data) {
      console.log(data);

      getData(
        state.query,
        true,
        state.stageFilters,
        state.sortBy,
        state.sortDirection
      );

      dispatch({
        type: "fieldChanged",
        field: "selectedOwner",
        value: null,
      });

      return data;
    }
  };

  //   const disableAssignmentButtons = () => {
  //     let disabled = "disabled";

  //     if (state.selectedRows.length) {
  //       let someRowsAreNotAssigned = state.selectedRows.some(
  //         (row) => row.assigneeId === undefined
  //       );
  //       if (!someRowsAreNotAssigned) {
  //         disabled = "";
  //       }
  //     }

  //     return disabled;
  //   };

  //   const disableAssignmentSelect = () => {
  //     let disabled = true;

  //     if (state.selectedRows.length) {
  //       disabled = false;
  //     }

  //     return disabled;
  //   };

  //   const stageFilterOptions = [
  //     { label: "New Lead", value: "newLead" },
  //     { label: "In Progress", value: "inProgress" },
  //     { label: "Warm Prospect", value: "warmProspect" },
  //     { label: "Unqualified", value: "unqualified" },
  //     { label: "Archived", value: "archived" },
  //   ];

  //   const getTeamMembers = async () => {
  //     const token = await getAccessTokenSilently().catch((e) => {
  //   console.error(e);
  //   loginWithRedirect();
  // });

  //     let response = await ai
  //       .auth(token)
  //       .get(`/api/users/getTeamMembers`)
  //       .catch((error) => {
  //         console.error(error);
  //         Sentry.captureException(error);
  //         return error;
  //       });

  //     if (response && response.data && response.data.length > 0) {
  //       response.data = response.data.map((member) => member.user_id);
  //       dispatch({
  //         type: "fieldChanged",
  //         field: "teamMembers",
  //         value: response.data,
  //       });
  //     }
  //   };

  //   const handleStageFilterChange = (selectedValues, action) => {
  //     console.log(selectedValues, action);
  //     let values = [];
  //     if (selectedValues) {
  //       values = selectedValues.map(({ value }) => value);
  //     }

  //     dispatch({ type: "stageFilterChanged", payload: { values } });

  //     getData(state.query, true, values, state.sortBy, state.sortDirection);
  //   };

  const handleSort = (column, sortDirection) => {
    console.log("sorted", column.selector, sortDirection);

    dispatch({
      type: "tableSort",
      payload: {
        sortBy: column.selector,
        sortDirection,
      },
    });

    getData(
      state.query,
      true,
      state.stageFilters,
      column.selector,
      sortDirection
    );

    //keyField is required for server sort
  };

  return (
    <Fragment>
      <PageHeaderRowSearch
        h1={`Trip Requests`}
        pretitle="Business"
        breadcrumbs={[{ link: "/leads", label: "Leads" }]}
        rightColumn={
          <div id="quote-actions-row">
            <Search
              onSearch={handleSearch}
              placeholder="Search Trip Requests"
              query={state.query}
              className="lead-search"
            ></Search>
          </div>
        }
      ></PageHeaderRowSearch>

      {state.loading && <Loader></Loader>}

      {!state.loading && (
        <DataTableServerPagination
          onSelection={handleSelection}
          key={JSON.stringify(columns)}
          columns={columns}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
          state={state}
          data={state.leads.data}
          defaultSortField={"createdAt"}
          defaultSortAsc={false}
          selectableRowSelected={preSelectCriteria}
          handleSort={handleSort}
          sortServer={true}
        ></DataTableServerPagination>
      )}

      {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
    </Fragment>
  );
};

export default TripRequestsContent;
