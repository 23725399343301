import React from "react";
import LeadsContent from "../components/leads/leads-content";

const ArchivedLeads = () => {
  return (
    <LeadsContent
      prefilters={["archived"]}
      defaultStageFilters={{ label: "Archived", value: "archived" }}
      pageTitle="Archived Leads"
    />
  );
};

export default ArchivedLeads;
