export const warmProspectTags = [{ label: "No Reply", value: "noReply" }];

export const repeatClientTags = [
  { label: "Trip Canceled/Postponed", value: "tripCanceledPostponed" },
  { label: "No Reply", value: "noReply" },
  { label: "Lost to Competitor", value: "lostToCompetitor" },
  { label: "Logistical Issues", value: "logisticalIssues" },
];

export const archiveTags = [
  { label: "Extremely Unqualified", value: "extremelyUnqualified" },
  { label: "Duplicate Lead", value: "duplicateLead" },
  { label: "No Reply", value: "noReply" },
  { label: "Missing/Bad Contact Info", value: "missingBadContactInfo" },
  { label: "Phone Call - Operator", value: "phoneCallOperator" },
  { label: "Phone Call - Other Dept.", value: "phoneCallOtherDept" },
  { label: "Phone Call - Error", value: "phoneCallError" },
];

export const hotProspectTags = [
  { label: "Trip Canceled/Postponed", value: "tripCanceledPostponed" },
  { label: "Lost to Competitor", value: "lostToCompetitor" },
  { label: "Unqualified with Potential", value: "unqualifiedWithPotential" },
  { label: "Logistical Issues", value: "logisticalIssues" },
  { label: "No Reply", value: "noReply" },
];
