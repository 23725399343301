import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ai from "../../helpers/axios";

import { useReducer } from "react";
import moment from "moment";

import { toast } from "react-toastify";
import { useParams, Link, useHistory } from "react-router-dom";

const toastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "fieldChanged": {
      return {
        ...state,
        [action.field]: action.value,
      };
    }

    case "dataBeganDownloading": {
      return {
        ...state,
        loading: true,
      };
    }

    case "dataDownloaded": {
      return {
        ...state,
        loading: false,
        recordHTML: action?.payload?.data?.html,
        templateData: action?.payload?.data?.templateData,
      };
    }

    default:
      break;
  }
  return state;
};

const ItineraryPreviewContent = (props) => {
  const { getAccessTokenSilently, user, loginWithRedirect } = useAuth0();
  const { tripId, itineraryId } = useParams();

  const userRoles = user && user["https://app.evojets.com/roles"];

  const superAdmin = userRoles.some(function (role) {
    return "Super Admin" === role;
  });

  let initialState = {
    loading: false,
    contractHTML: null,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const getData = async () => {
    dispatch({ type: "dataBeganDownloading" });

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let response = await ai
      .auth(token)
      .get(`/api/trips/${tripId}/itineraries/${itineraryId}/preview`)
      .catch((error) => {
        setTimeout(() => {
          toast.error("Unauthorized!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }, 50);

        return console.error(error.response);
      });

    if (response) {
      console.log("ITINERARY DATA", response.data);

      dispatch({
        type: "dataDownloaded",
        payload: {
          data: response.data,
        },
      });
    }
  };

  const handlePDF = async () => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let response = await ai
      .auth(token)
      .get(`/api/trips/${tripId}/itineraries/${itineraryId}/pdf`, {
        responseType: "arraybuffer",
      })
      .catch((error) => {
        setTimeout(() => {
          toast.error("Unauthorized!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }, 50);

        return console.error(error.response);
      });

    if (response) {
      console.log(response.data);

      dispatch({
        type: "pdfDownloaded",
        payload: {
          data: response.data,
        },
      });

      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      let fileName = "[EVOJETS] Itinerary.pdf";

      if (state?.templateData?.fileName) {
        fileName = state?.templateData?.fileName;
      }

      let link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    }
  };

  useEffect(() => {
    getData();
    document.title = "Itinerary Preview | Flight Deck by evoJets";
  }, []);

  return (
    <>
      <h2 id="addAirportHeading">Preview Itinerary</h2>

      <Link to={`/trips/${tripId}`} className={`base-button evo-blue mr-20`}>
        Return to Trip
      </Link>

      <Link
        to={`/trips/${tripId}/itineraries/${itineraryId}`}
        className={`base-button evo-blue mr-20`}
      >
        Edit Itinerary
      </Link>

      <button className="evo-blue base-button ml-20" onClick={handlePDF}>
        Download PDF
      </button>

      <iframe
        className="contract-preview-container"
        id="contractPreviewIframe"
        sandbox="allow-same-origin"
        srcDoc={state.recordHTML}
      ></iframe>
    </>
  );
};

export default ItineraryPreviewContent;
