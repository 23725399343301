import React, { Fragment, useReducer, useEffect } from "react";
import DatePicker from "react-date-picker";
import { useAuth0 } from "@auth0/auth0-react";

import ai from "../../helpers/axios";
import DataTable from "react-data-table-component";
import AsyncSelect from "react-select/async";
import RFPForm from "../leads/rfp-form";

import oneWayColumns from "./outside-org-one-way-columns";
import roundTripColumns from "./outside-org-round-trip-columns";

import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import "react-json-pretty/themes/monikai.css";
import JSONPretty from "react-json-pretty";

import * as Sentry from "@sentry/react";

const reducer = (state, action) => {
  switch (action.type) {
    case "fieldChanged": {
      let value =
        action.fieldType === "number" ? parseInt(action.value) : action.value;

      return {
        ...state,
        [action.field]: value,
      };
    }

    case "leadFieldChanged": {
      let value =
        action.fieldType === "number" ? parseInt(action.value) : action.value;

      return {
        ...state,
        leadData: {
          ...state.leadData,
          [action.field]: value,
        },
      };
    }
    case "airportChange": {
      return {
        ...state,
        [action.airportType]: action.payload,
      };
    }

    case "owChange": {
      let field =
        action.field === "departureOW" ? "departureAirport" : "arrivalAirport";

      return {
        ...state,
        [field]: {
          ow: action.value,
        },
      };
    }

    case "tripTypeChanged": {
      return {
        ...state,
        tripType: action.payload.tripType,
        returnDate: action.payload.tripType === 1 ? null : state.returnDate,
      };
    }

    case "estimateReturned": {
      return {
        ...state,
        tableData: action.payload.results.tripOptions,
        data: action.payload,
      };
    }

    default:
      break;
  }
  return state;
};

let initialState = {
  departureAirport: {
    airportName: null,
    id: null,
    code: null,
    ow: "",
  },
  arrivalAirport: {
    airportName: null,
    id: null,
    code: null,
    ow: "",
  },
  passengers: 0,
  departureDate: null,
  tripType: 1,
  returnDate: null,
  data: {},
  composedOW: "",
  tableData: [],
  departureAirportInput: "",
  arrivalAirportInput: "",
  leadData: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    companyName: "",
    comments: "",
    cc: "",
    infants: false,
    pets: false,
    aircraftSelection: [],
  },
};

const OutsideOrgCalculatorContent = () => {
  const { getAccessTokenSilently, loginWithRedirect, user } = useAuth0();

  if (user && user["https://app.evojets.com/userMetadata"]) {
    initialState.leadData.firstName =
      user["https://app.evojets.com/userMetadata"]?.first_name;
    initialState.leadData.lastName =
      user["https://app.evojets.com/userMetadata"]?.last_name;
    initialState.leadData.phone =
      user["https://app.evojets.com/userMetadata"]?.phoneNumber;
  }

  if (user) {
    initialState.leadData.email = user?.email;
  }

  if (user && user["https://app.evojets.com/appMetadata"]) {
    initialState.leadData.companyName =
      user["https://app.evojets.com/appMetadata"]?.organizationName;
  }

  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });
    let estimateData = {
      departureAirportId: state.departureAirport.id,
      arrivalAirportId: state.arrivalAirport.id,
      passengers: state.passengers,
      departureDate: state.departureDate,
      tripType: state.tripType,
      returnDate: state.returnDate,
      origin: "flightDeckInternal",
    };
    let { data } = await ai
      .auth(token)
      .post(`/api/estimates/external/create`, estimateData)
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
        return error.response;
      });

    console.log("DATA", data);

    if (data) {
      dispatch({
        type: "estimateReturned",
        payload: data,
      });

      document
        .getElementById("page-title")
        .scrollIntoView({ behavior: "smooth" });

      console.log(data);
    }
  };

  const loadAirportOptions = async (inputValue) => {
    let ip =
      Math.floor(Math.random() * 255) +
      1 +
      "." +
      (Math.floor(Math.random() * 255) + 0) +
      "." +
      (Math.floor(Math.random() * 255) + 0) +
      "." +
      (Math.floor(Math.random() * 255) + 0);
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });
    let { data } = await ai
      .auth(token)
      .get(`/api/airports/search?q=${inputValue}&ip_address=${ip}`)
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
      });

    console.log(data);

    let options = [];
    if (data.length >= 1) {
      options = data.map((i) => ({
        value: i._id,
        label: i.airportName,
        code: i.code,
        ow: i.ow,
      }));
    }

    return options;
  };

  const getOWRatio = async () => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    if (state.departureAirport.id && state.arrivalAirport.id) {
      let { data } = await ai
        .auth(token)
        .get(
          `/api/airports/getOWRatio?departureAirportId=${state.departureAirport.id}&arrivalAirportId=${state.arrivalAirport.id}`
        )
        .catch((error) => console.error(error.response.data));

      if (data) {
        console.log("Composed OW", data);

        dispatch({
          type: "fieldChanged",
          field: "composedOW",
          value: parseFloat(data.ow) || "",
        });
      }

      return;
    }
  };

  useEffect(() => {
    getOWRatio();
    document.title = "Calculator | Flight Deck by evoJets";
  }, [state.departureAirport.id, state.arrivalAirport.id]);

  const handleDepartureChange = (selectedAirport) => {
    dispatch({
      type: "airportChange",
      airportType: "departureAirport",
      payload: {
        airportName: selectedAirport?.label || "",
        id: selectedAirport?.value || "",
        code: selectedAirport?.code || "",
        ow: parseFloat(selectedAirport?.ow) || "",
      },
    });
  };

  const handleArrivalChange = (selectedAirport) => {
    dispatch({
      type: "airportChange",
      airportType: "arrivalAirport",
      payload: {
        airportName: selectedAirport?.label || "",
        id: selectedAirport?.value || "",
        code: selectedAirport?.code || "",
        ow: parseFloat(selectedAirport?.ow) || "",
      },
    });
  };

  const handleRFPSubmit = async (e) => {
    e.preventDefault();

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });
    let leadData = {
      firstName: state.leadData.firstName,
      lastName: state.leadData.lastName,
      email: state.leadData.email,
      phone: state.leadData.phone,
      companyName: state.leadData.companyName,
      comments: `${state.leadData.comments}`,
      estimateId: state.data?.estimateId,
      tripType: state.data?.tripDetails?.tripType,
      leadOrigin: "flightDeckInternal",
    };

    if (state.leadData.cc) {
      leadData.comments = `${leadData.comments} --- User would like to copy ${state.leadData.cc} on quotes.`;
    }

    if (state.leadData.infants) {
      leadData.infants = true;
    }

    if (state.leadData.pets) {
      leadData.pets = true;
    }

    if (state.leadData.aircraftSelection.length) {
      leadData.aircraftSelection = state.leadData.aircraftSelection;
    }

    let { data } = await ai
      .auth(token)
      .post(`/api/leads/external/create`, leadData)
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
        return error.response;
      });

    // dispatch({
    //   type: "leadReturned",
    //   payload: data,
    // });

    setTimeout(() => {
      toast.success("Trip Request Created Successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }, 50);

    setTimeout(() => {
      document
        .getElementById("nav-container")
        .scrollIntoView({ behavior: "smooth" });

      history.push({ pathname: "/trip-requests/" });
    }, 4000);

    console.log(data);
  };

  const handleAirportChange = (e) => {
    dispatch({
      type: "owChange",
      field: e.target.name,
      value: parseFloat(e.target.value) || "",
      fieldType: "float",
    });
  };

  const prepareAircraftOptions = () => {
    // console.log(state.data.estimateDoc);

    console.log("TRIP TYPE", state?.data?.tripDetails?.tripType);
    if (state.data?.tripDetails?.tripType === 1) {
      return state.data?.results?.tripOptions.map((option) => {
        return {
          value: option.aircraftType,
          label: option.aircraftType,
        };
      });
    }

    if (state.data?.tripDetails?.tripType === 2) {
      return state.data?.results?.tripOptions.map((option) => {
        return {
          value: option.aircraftType,
          label: option.aircraftType,
        };
      });
    }

    return [];
  };

  return (
    <Fragment>
      <div className="text-center hero">
        <h1 id="page-title" className="mb-4">
          Flight Deck Calculator
        </h1>
      </div>

      {!state.data.tripDetails && (
        <form className="calculatorForm" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="tripType">Trip Type</label>
            <select
              id="tripType"
              value={state.tripType}
              onChange={(e) =>
                dispatch({
                  type: "tripTypeChanged",
                  payload: {
                    tripType: parseInt(e.currentTarget.value),
                  },
                })
              }
              name="tripType"
              required
            >
              <option key="placeholder" value={0} hidden>
                Trip Type
              </option>

              <option key="oneWay" value={1}>
                One-Way
              </option>
              <option key="roundTrip" value={2}>
                Round Trip
              </option>
            </select>
          </div>

          <div className="form-group">
            <label>Departure Airport</label>

            <AsyncSelect
              className="departure-airport-select"
              classNamePrefix="select"
              cacheOptions
              loadOptions={loadAirportOptions}
              // defaultValue={props.state.selectedAircraft}
              placeholder="Search Airports"
              // onInputChange={props.handleDepartureInputChange}
              onChange={handleDepartureChange}
            ></AsyncSelect>
          </div>

          <div className="form-group">
            <label>Arrival Airport</label>

            <AsyncSelect
              className="arrival-airport-select"
              classNamePrefix="select"
              cacheOptions
              loadOptions={loadAirportOptions}
              // defaultValue={props.state.selectedAircraft}
              placeholder="Search Airports"
              // onInputChange={props.handleDepartureInputChange}
              onChange={handleArrivalChange}
            ></AsyncSelect>
          </div>

          <div className="form-group">
            <label htmlFor="passengers">Passengers</label>
            <input
              type="number"
              name="passengers"
              placeholder="Passengers"
              onChange={(e) =>
                dispatch({
                  type: "fieldChanged",
                  field: e.target.name,
                  value: e.target.value,
                  fieldType: e.target.type,
                })
              }
            ></input>
          </div>

          <div className="form-group">
            <label htmlFor="departureDate">Departure Date</label>

            <DatePicker
              name="departureDate"
              onChange={(date) =>
                dispatch({
                  type: "fieldChanged",
                  field: "departureDate",
                  value: date,
                })
              }
              value={state.departureDate}
            ></DatePicker>
          </div>

          {state.tripType === 2 && (
            <div className="form-group">
              <label htmlFor="returnDate">Return Date</label>

              <DatePicker
                name="returnDate"
                onChange={(date) =>
                  dispatch({
                    type: "fieldChanged",
                    field: "returnDate",
                    value: date,
                  })
                }
                value={state.returnDate}
              ></DatePicker>
            </div>
          )}

          <input type="submit" value="Submit"></input>
        </form>
      )}

      <div id="results" className="outside-org-calculator">
        {state.tableData.length !== 0 &&
          state.data.tripDetails.tripType === 1 && (
            <DataTable
              data={state.tableData}
              columns={oneWayColumns}
              noHeader
              highlightOnHover
              pagination={false}
              responsive={true}
              defaultSortField={"lowSpread"}
            ></DataTable>
          )}

        {state.tableData.length !== 0 &&
          state.data.tripDetails.tripType === 2 && (
            <DataTable
              data={state.tableData}
              columns={roundTripColumns}
              noHeader
              highlightOnHover
              pagination={false}
              responsive={true}
              defaultSortField={"lowSpread"}
            ></DataTable>
          )}

        {state?.data?.tripDetails && (
          <RFPForm
            handleRFPSubmit={handleRFPSubmit}
            dispatch={dispatch}
            state={state}
            aircraftOptions={prepareAircraftOptions()}
            formHeading="Submit Trip Request"
          ></RFPForm>
        )}

        {/* <JSONPretty id="json-pretty" data={state.departureAirport}></JSONPretty>
        <JSONPretty id="json-pretty" data={state.leadData}></JSONPretty>
        <JSONPretty id="json-pretty" data={state.data.estimateDoc}></JSONPretty> */}
      </div>

      {/* <JSONPretty id="json-pretty" data={state.data}></JSONPretty> */}
    </Fragment>
  );
};

export default OutsideOrgCalculatorContent;
