import { Link } from "react-router-dom";
import moment from "moment-timezone";
import { Fragment } from "react";

const columns = [
  {
    name: "Priority",
    selector: "lowestPriority",
    sortable: true,
    width: "90px",
  },

  {
    name: "CL Created At",
    selector: "originCreatedAt",
    sortable: true,
    width: "190px",
    cell: (row) => {
      return moment(row.originCreatedAt).tz("America/New_York").format("llll");
    },
  },

  {
    name: "Match Detail",
    selector: "_id",
    sortable: true,
    width: "145px",
    cell: (row) => {
      return <Link to={`/flight-matches/${row._id}`}>View</Link>;
    },
  },

  {
    name: "Leg Origin",
    selector: "parentRecordId",
    sortable: true,
    width: "150px",
    cell: (row) => {
      let slug, recordLabel;

      console.log(row.origin);
      if (row.origin === "atc") {
        slug = "atc";
        recordLabel = "ATC Record";
      } else {
        slug = "leads";
        recordLabel = "Lead";
      }
      return (
        <Link to={`/${slug}/${row.parentRecordId}`}>View {recordLabel}</Link>
      );
    },
  },

  {
    name: "Assignee",
    selector: "assigneeName",
    sortable: true,
    width: "150px",
    cell: (row) => row.assigneeName || "-",
  },

  {
    name: "Departure Date",
    selector: "departureDateString",
    sortable: true,
    width: "170px",
    cell: (row) => {
      return moment(row.departureDateString).utc().format("ddd, MMM Do YYYY");
    },
  },

  // {
  //   name: "Departure Airport",
  //   selector: "departureAirport.airportName",
  //   sortable: true,
  //   width: "350px",
  // },

  // {
  //   name: "Arrival Airport",
  //   selector: "arrivalAirport.airportName",
  //   sortable: true,
  //   width: "350px",
  // },

  {
    name: "Routing",
    selector: "departureAirport",
    sortable: true,
    width: "120px",
    cell: (row) => {
      return `${row.departureAirport.code} - ${row.arrivalAirport.code}`;
    },
  },

  // {
  //   name: "Matches",
  //   selector: "matchData",
  //   sortable: true,
  //   width: "550px",
  //   cell: (row) => {
  //     let matchesSorted = row.flightMatches.sort(
  //       (a, b) => a.priority - b.priority
  //     );

  //     return matchesSorted.map((flight, index) => {
  //       let matchData = row.matchData.filter(
  //         (item) => item._id === flight.legId
  //       )[0];

  //       let recordId, slug;

  //       if (matchData.isFromLead) {
  //         recordId = matchData.leadId;
  //         slug = "leads";
  //         console.log("isFromLeads", recordId);
  //       }

  //       if (matchData.isFromATC) {
  //         recordId = matchData.atcId;
  //         slug = "atc";
  //         console.log("isFromATC");
  //       }

  //       slug = "flight-matches";
  //       recordId = matchData._id;

  //       // console.log("RECORD", recordId);

  //       let matches = (
  //         <Fragment
  //           key={`fragment-${recordId}-isPrimaryLeg-${matchData.isPrimaryLeg}`}
  //         >
  //           <Link
  //             key={recordId}
  //             className="matchData"
  //             to={`/${slug}/${recordId}`}
  //           >
  //             {matchData.departureAirport.code} -{" "}
  //             {matchData.arrivalAirport.code}
  //           </Link>
  //           {row.matchData.length - 1 !== index && ", "}{" "}
  //         </Fragment>
  //       );

  //       return matches;
  //     });
  //   },
  // },

  {
    name: "Last Match Event",
    selector: "auditTrail",
    sortable: true,
    width: "230px",
    cell: (row) => {
      return moment(
        row.auditTrail
          .filter((item) => item.eventType === "matchAdded")
          .sort((a, b) => new Date(b.date) - new Date(a.date))[0].timestamp
      )
        .tz("America/New_York")
        .format("llll");
    },
  },

  // {
  //   name: "Is Part of Round Trip",
  //   selector: "isPartOfRoundTrip",
  //   sortable: true,
  //   width: "230px",
  //   cell: (row) => {
  //     return row.isPartOfRoundTrip ? "Yes" : "No";
  //   },
  // },
];

export default columns;
