/* eslint-disable default-case */
import React, { Fragment, useReducer, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ai from "../../helpers/axios";
import TextInput from "../text-input";
import { useParams, useHistory, useLocation, Link } from "react-router-dom";
import CreatableSelect from "react-select/creatable";

import { EditorState, ContentState, convertFromHTML } from "draft-js";

import { stateToHTML } from "draft-js-export-html";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// leg form import
import DatePicker from "react-date-picker";
import AsyncSelect from "react-select/async";
import TextAreaInput from "../textarea-input";

import timeFormatter from "../../helpers/timeFormatter";
import NumberFormat from "react-number-format";

import PhoneInput from "react-phone-number-input";

import {
  headerText,
  footerText,
} from "../quotes/default-header-footer-content";

import { toast } from "react-toastify";

import * as Sentry from "@sentry/react";
import JSONPretty from "react-json-pretty";

import ProspectsAutocomplete from "../prospects/prospects-autocomplete";
import CreateProspectModal from "../prospects/create-prospect-modal";
import OperatorAutocomplete from "../opi/operator-autocomplete";

import CreateAuthorizedSignerModal from "../prospects/create-authorized-signer-modal";

import { Datepicker, momentTimezone } from "@mobiscroll/react";
import mobiscroll from "@mobiscroll/react";

import "@mobiscroll/react/dist/css/mobiscroll.min.css";

import moment from "moment-timezone";

// mui imports
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";

import Select from "react-select";

import { find } from "geo-tz";
import FBOAutocomplete from "./fbo-autocomplete";

import CreateFBOModal from "./create-fbo-modal";

momentTimezone.moment = moment;

const toastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "dataBeganDownloading": {
      return {
        ...state,
        loading: true,
      };
    }
    case "fieldChanged": {
      return {
        ...state,
        [action.field]: action.value,
      };
    }
    case "tripContactDetailsChanged": {
      return {
        ...state,
        tripContactDetails: {
          ...state.tripContactDetails,
          [action.payload.field]: action.payload.value,
        },
      };
    }

    case "routingProcessed": {
      return {
        ...state,
        legs: action.value,
        routingProcessed: true,
      };
    }

    case "prospectAutoSelected": {
      let prospectOptions = [action.value, ...state.prospectOptions];

      return {
        ...state,
        selectedClient: action.value,
        prospectOptions,
      };
    }

    case "legAdded": {
      return {
        ...state,
        legs: [
          ...state.legs,
          {
            departureDate: "",
            departureAirport: {
              airportName: "",
              id: "",
              code: "",
              city: "",
              localCode: "",
            },
            arrivalAirport: {
              airportName: "",
              id: "",
              code: "",
              city: "",
              localCode: "",
            },
            passengers: "",
            aircraft: {
              name: "",
              seats: 8,
              yom: "",
              opi: "",
              id: "",
            },
          },
        ],
      };
    }
    case "legDeleted": {
      let newLegsArray = [...state.legs];
      newLegsArray.splice(action.index, 1);
      console.log(newLegsArray);
      return {
        ...state,
        legs: newLegsArray,
      };
    }
    case "legFieldChanged": {
      let newLegsArray = [...state.legs];
      newLegsArray[action.payload.legIndex][action.payload.field] =
        action.payload.value;
      return {
        ...state,
        legs: newLegsArray,
      };
    }

    case "legPaxFieldChanged": {
      let newLegsArray = [...state.legs];
      newLegsArray[action.payload.legIndex].pax[action.payload.paxIndex][
        action.payload.field
      ] = action.payload.value;
      return {
        ...state,
        legs: newLegsArray,
      };
    }

    case "paxAdded": {
      let newLegsArray = [...state.legs];
      newLegsArray[action.legIndex].pax.push({
        firstName: "",
        middleName: "",
        lastName: "",
      });

      return {
        ...state,
        legs: newLegsArray,
      };
    }

    case "paxDeleted": {
      let newLegsArray = [...state.legs];
      newLegsArray[action.legIndex].pax.splice(action.paxIndex, 1);

      return {
        ...state,
        legs: newLegsArray,
      };
    }

    case "legCrewFieldChanged": {
      let newLegsArray = [...state.legs];
      newLegsArray[action.payload.legIndex].crew[action.payload.memberIndex][
        action.payload.field
      ] = action.payload.value;
      return {
        ...state,
        legs: newLegsArray,
      };
    }

    case "crewAdded": {
      let newLegsArray = [...state.legs];
      newLegsArray[action.legIndex].crew.push({
        firstName: "",
        middleName: "",
        lastName: "",
      });

      return {
        ...state,
        legs: newLegsArray,
      };
    }

    case "crewDeleted": {
      let newLegsArray = [...state.legs];
      newLegsArray[action.legIndex].crew.splice(action.memberIndex, 1);

      return {
        ...state,
        legs: newLegsArray,
      };
    }

    case "legGroundTransportationFieldChanged": {
      let newLegsArray = [...state.legs];
      newLegsArray[action.payload.legIndex].groundTransportation[
        action.payload.itemIndex
      ][action.payload.field] = action.payload.value;
      return {
        ...state,
        legs: newLegsArray,
      };
    }

    case "legGroundTransportationAdded": {
      let newLegsArray = [...state.legs];
      newLegsArray[action.legIndex].groundTransportation.push({
        note: "",
      });

      return {
        ...state,
        legs: newLegsArray,
      };
    }

    case "legGroundTransportationDeleted": {
      let newLegsArray = [...state.legs];
      newLegsArray[action.legIndex].groundTransportation.splice(
        action.itemIndex,
        1
      );

      return {
        ...state,
        legs: newLegsArray,
      };
    }

    // catering

    case "legCateringFieldChanged": {
      let newLegsArray = [...state.legs];
      newLegsArray[action.payload.legIndex].catering[action.payload.itemIndex][
        action.payload.field
      ] = action.payload.value;
      return {
        ...state,
        legs: newLegsArray,
      };
    }

    case "legCateringAdded": {
      let newLegsArray = [...state.legs];
      newLegsArray[action.legIndex].catering.push({
        notes: "",
        name: "",
        servings: "",
      });

      return {
        ...state,
        legs: newLegsArray,
      };
    }

    case "legCateringDeleted": {
      let newLegsArray = [...state.legs];
      newLegsArray[action.legIndex].catering.splice(action.itemIndex, 1);

      return {
        ...state,
        legs: newLegsArray,
      };
    }

    // leg notes

    case "legNotesFieldChanged": {
      let newLegsArray = [...state.legs];
      newLegsArray[action.payload.legIndex].notes[action.payload.itemIndex][
        action.payload.field
      ] = action.payload.value;
      return {
        ...state,
        legs: newLegsArray,
      };
    }

    case "legNoteAdded": {
      let newLegsArray = [...state.legs];
      newLegsArray[action.legIndex].notes.push({
        note: "",
      });

      return {
        ...state,
        legs: newLegsArray,
      };
    }

    case "legCateringDeleted": {
      let newLegsArray = [...state.legs];
      newLegsArray[action.legIndex].notes.splice(action.itemIndex, 1);

      return {
        ...state,
        legs: newLegsArray,
      };
    }

    case "flightTimesDownloaded": {
      let { index, values } = action;

      let newLegsArray = [...state.legs];

      // console.log("FLIGHT TIMES DOWNLOADED RUNNING", values);

      values.forEach((item) => {
        if (item.status === "rejected" && isNaN(item?.value?.legIndex)) {
          toast.error(
            `Flight times not available for Leg ${
              item?.value?.legIndex + 1
            }, please input manually.`,
            toastOptions
          );
          return;
        } else if (item.status === "rejected") {
          toast.error(
            `Flight times not available - please input manually.`,
            toastOptions
          );
          return;
        }

        if (
          !item?.value?.timeWithWindAdjustment ||
          !item?.value?.status ||
          isNaN(item?.value?.legIndex)
        ) {
          toast.error(
            "Flight times not available - please input manually.",
            toastOptions
          );
          return;
        }

        newLegsArray[item.value.legIndex].ete = timeFormatter(
          item.value.timeWithWindAdjustment
        );

        // toast.success(
        //   "Flight Times were populated successfully.",
        //   toastOptions
        // );
      });

      console.log("FLIGHT TIMES DOWNLOADED RUNNING", newLegsArray);

      return {
        ...state,
        legs: newLegsArray,
      };
    }

    default:
      break;
  }
  return state;
};

// let etd = new Date();

// let eta = new Date();
// eta = eta.setHours(etd.getHours() + 2);

function convertUTCToLocalDate(date) {
  if (!date) {
    return date;
  }
  date = new Date(date);
  date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
  return date;
}

function convertLocalToUTCDate(date) {
  if (!date) {
    return date;
  }
  date = new Date(date);
  date = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );
  return date;
}

const NewItineraryContent = (props) => {
  const history = useHistory();
  const location = useLocation();

  let { tripId, itineraryId } = useParams();

  let initialState = {
    modalPhoneNumber: "",
    selectedClient: null,
    createProspectModalIsOpen: false,
    createAuthorizedSignerModalIsOpen: false,
    routingProcessed: false,
    tripContactDetails: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      phoneCountry: "US",
    },
    // useDifferentAuthorizedSigner: false,
    // authorizedSigners: [],
    createFBOModalIsOpen: false,
  };

  const leadDataFromLocation = location?.state?.lead;
  const sourcingRecords = location?.state?.sourcingRecords;

  const [state, dispatch] = useReducer(reducer, initialState);

  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const processSourcing = (sourcingRecords) => {
    sourcingRecords.forEach((item) => {
      if (item.operatorPrice) {
        dispatch({
          type: "fieldChanged",
          field: "flightCharges",
          value: item.operatorPrice,
        });
      }
      if (item?.fet?.amount && !isNaN(item?.fet?.amount)) {
        dispatch({
          type: "fieldChanged",
          field: "FET",
          value: item.fet.amount.toFixed(2),
        });
      }

      if (item?.segmentFees?.amount && !isNaN(item?.segmentFees?.amount)) {
        dispatch({
          type: "fieldChanged",
          field: "segmentFees",
          value: item.segmentFees.amount.toFixed(2),
        });
      }

      if (item?.ccFee) {
        dispatch({
          type: "fieldChanged",
          field: "ccFee",
          value: item.ccFee,
        });
      }

      if (item?.paymentType) {
        dispatch({
          type: "fieldChanged",
          field: "paymentType",
          value: item.paymentType,
        });
      }
    });
  };

  if (sourcingRecords?.length) {
    console.log({ sourcingRecords });
  }

  const prepareNewItinerary = async () => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let response = await ai
      .auth(token)
      .get(`/api/trips/${tripId}/itineraries/new`)
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
      });

    if (response && response.data) {
      console.log("NEW ITINERARY DATA", response.data);
      dispatch({
        type: "fieldChanged",
        field: "itineraryData",
        value: response.data,
      });

      dispatch({ type: "fieldChanged", field: "loading", value: false });

      console.log(response?.data, "RESPONSE DATA");

      processRouting(response?.data?.routing);
      // processPricingFromOfr(response?.data?.ofr);

      parseClientData(response.data?.clientData);

      // if (response.data.tripIds?.length) {
      //   //todo
      //   console.log("LEAD HAS TRIP");
      // }
    }
  };

  const prepareEditItinerary = async () => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let response = await ai
      .auth(token)
      .get(`/api/trips/${tripId}/itineraries/${itineraryId}/edit`)
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
      });

    if (response && response.data) {
      console.log("EDIT ITINERARY DATA", response.data);
      dispatch({
        type: "fieldChanged",
        field: "itineraryData",
        value: response.data,
      });

      dispatch({ type: "fieldChanged", field: "loading", value: false });

      console.log(response?.data, "RESPONSE DATA");

      processExistingItinerary(response.data);

      // processRouting(response?.data?.routing);
      // processPricingFromOfr(response?.data?.ofr);

      // parseClientData(response.data?.clientData);

      // if (response.data.tripIds?.length) {
      //   //todo
      //   console.log("LEAD HAS TRIP");
      // }
    }
  };

  const processSubject = () => {
    let estimate = state?.leadData?.estimateData;

    if (!estimate?.length) {
      return;
    }

    estimate = estimate[0];

    let departDate = moment(estimate.departureDateString).format("DD MMM");
    let returnDate = null;

    let subjectText = `${state?.leadData?.firstName} ${state?.leadData?.lastName} | ${departDate} | ${estimate.departureAirportCode}-${estimate.arrivalAirportCode}`;

    if (state?.leadData.tripType === 2) {
      returnDate = moment(estimate.returnDateString).format("DD MMM");

      subjectText = `${state?.leadData?.firstName} ${state?.leadData?.lastName} | ${departDate} - ${returnDate} | ${estimate.departureAirportCode}-${estimate.arrivalAirportCode}`;
    }

    dispatch({
      type: "fieldChanged",
      field: "subject",
      value: subjectText,
    });
  };

  const processExistingItinerary = (itineraryData) => {
    if (itineraryData?.legs && itineraryData?.legs?.length) {
      let routing = [...itineraryData.legs];
      let finalRouting = [];

      console.log("ITINERARY PROCESS ROUTING", routing);

      routing?.forEach((leg) => {
        console.log("ITINERARY ARRIVAL FBO", leg.arrivalFBO);

        let formattedLeg = {
          aircraft: leg.aircraft,
          arrivalAirport: {
            airportName: leg.arrivalAirport?.airportName,
            id: leg.arrivalAirport?._id,
            code: leg.arrivalAirport?.code,
          },
          departureAirport: {
            airportName: leg.departureAirport?.airportName,
            id: leg.departureAirport?._id,
            code: leg.departureAirport?.code,
          },
          departureFBO: {
            fboData: {
              ...leg?.departureFBO,
            },
            value: leg.departureFBO?._id,
            label: leg.departureFBO?.longName,
          },
          arrivalFBO: {
            fboData: {
              ...leg?.arrivalFBO,
            },
            value: leg?.arrivalFBO?._id,
            label: leg?.arrivalFBO?.longName,
          },
          departureDate: leg.departureDate,
          passengers: leg.passengers,
          eta: leg.eta,
          etd: leg.etd,
          ete: leg.ete,
          pax: [...leg.pax],
          crew: [...leg.crew],
          groundTransportation: leg.groundTransportation || [],
          catering: leg.catering || [],
          notes: leg.notes || [
            {
              note: "",
            },
          ],
          tailNumber: leg.tailNumber,
        };

        if (leg.crew) {
          formattedLeg.crew = leg.crew.map((item) => {
            return {
              ...item,
              position: {
                label: item.position,
                value: item.position,
              },
            };
          });
        }

        if (leg.operator) {
          formattedLeg.selectedOperator = {
            id: leg.operator.id,
            name: leg.operator.name,
            label: leg.operator.name,
            value: leg.operator.id,
          };
        }

        console.log("FORMATTED LEG", formattedLeg);
        finalRouting.push(formattedLeg);
      });

      dispatch({
        type: "fieldChanged",
        field: "legs",
        value: finalRouting,
      });
    }

    if (itineraryData?.clientData) {
      parseClientData(itineraryData.clientData);
    }
  };

  const processRouting = (routing) => {
    let finalRouting = [];

    routing?.forEach((leg) => {
      let formattedLeg = {
        aircraft: leg.aircraft,
        arrivalAirport: leg.arrivalAirport,
        departureAirport: leg.departureAirport,
        departureDate: leg.departureDate,
        passengers: leg.passengers,
        eta: leg.eta,
        etd: leg.etd,
        ete: leg.ete,
        pax: [{ firstName: "", middleName: "", lastName: "" }],
        crew: [
          {
            firstName: "",
            middleName: "",
            lastName: "",
            position: { label: "Captain", value: "Captain" },
            phoneNumber: "",
            phoneCountry: "US",
          },
        ],
        groundTransportation: [
          {
            note: "",
          },
        ],
        catering: [
          {
            notes: "",
            name: "",
            servings: "",
          },
          // {
          //   notes: "Parmesan on the side",
          //   name: "Truffle Fries",
          //   servings: 3,
          // },
          // {
          //   notes: "",
          //   name: "Lobster Mac & Cheese",
          //   servings: 3,
          // },
          // {
          //   notes:
          //     "Pistachios must be sourced from Turkey or Sicily, no exceptions.",
          //   name: "Pistachio Gelato",
          //   servings: 3,
          // },

          // {
          //   notes: "Australian Wagyu ONLY",
          //   name: "40oz Tomahawk",
          //   servings: 1,
          // },
          // {
          //   notes: "Extra Spicy",
          //   name: "Roasted Garlic Broccoli",
          //   servings: 1,
          // },
          // {
          //   notes: "Parmesan on the side",
          //   name: "Truffle Fries",
          //   servings: 3,
          // },
          // {
          //   notes: "",
          //   name: "Lobster Mac & Cheese",
          //   servings: 3,
          // },
          // {
          //   notes:
          //     "Pistachios must be sourced from Turkey or Sicily, no exceptions.",
          //   name: "Pistachio Gelato",
          //   servings: 3,
          // },
        ],
        notes: [
          {
            note: "",
          },
        ],
      };

      if (leg.operator) {
        formattedLeg.selectedOperator = {
          id: leg.operator.id,
          name: leg.operator.name,
          label: leg.operator.name,
          value: leg.operator.id,
        };
      }

      finalRouting.push(formattedLeg);
    });

    dispatch({
      type: "fieldChanged",
      field: "legs",
      value: finalRouting,
    });
  };

  const processPricingFromOfr = (ofrData) => {
    if (!ofrData) {
      return;
    }
  };

  useEffect(() => {
    handleFlightTimeCalculation();
  }, [state.routingProcessed]);

  const parseAuthorizedSigners = (clientData) => {
    if (!clientData?.additionalUsers?.length) {
      return false;
    }

    let authorizedSigners = clientData.additionalUsers.flatMap((item) => {
      if (!item?.isAuthorizedSigner) {
        return [];
      }
      let label = `${item.firstName} ${item.lastName} - ${item.relationship}`;
      let value = item?._id;
      return {
        ...item,
        label,
        value,
      };
    });

    dispatch({
      type: "fieldChanged",
      field: "authorizedSigners",
      value: authorizedSigners,
    });
  };

  const parseClientData = (clientData) => {
    if (!clientData) {
      //null
      return false;
    }

    if (!clientData?._id || !clientData?.firstName || !clientData?.lastName) {
      //no contact info
      return false;
    }

    if (clientData?.isBusiness && !clientData?.companyName) {
      //client is business but missing company name
      return false;
    }

    let label = `${clientData.firstName} ${clientData?.lastName} - ${clientData?.emails[0]} - ${clientData?.assigneeName}`;
    let value = clientData?._id;

    if (clientData?.isBusiness) {
      label = clientData.companyName;
    }

    parseAuthorizedSigners(clientData);

    if (clientData?.emails?.length) {
      dispatch({
        type: "fieldChanged",
        field: "email",
        value: clientData.emails[0],
      });
    }

    dispatch({
      type: "fieldChanged",
      field: "tripContactDetails",
      value: {
        firstName: clientData?.firstName,
        lastName: clientData?.lastName,
        email: clientData?.emails[0],
        phone: clientData?.phone,
        phoneCountry: clientData?.phoneCountry || "US",
      },
    });

    dispatch({
      type: "fieldChanged",
      field: "selectedClient",
      value: {
        label,
        value,
        ...clientData,
      },
    });
  };

  const processContractAndTripData = async (contractAndTripData) => {
    let legs = contractAndTripData.legs.map((leg) => {
      let selectedOperator = null;
      if (leg?.operator?.id) {
        selectedOperator = {
          _id: leg.operator.id,
          name: leg.operator.name,
          value: leg.operator.id,
          label: leg.operator.name,
        };

        console.log("OPERATOR LABEL");
      }

      let departureTimeZone = null;
      let arrivalTimeZone = null;
      let departureDate = moment(leg.departureDate);

      console.log(departureDate);

      departureDate.isValid()
        ? (departureDate = departureDate.toDate())
        : (departureDate = "");

      if (contractAndTripData?.departureAirports?.length) {
        let matchingDepartureAirport =
          contractAndTripData?.departureAirports.find(
            (item) => item._id === leg.departureAirport?.id
          );
        //get lat and lon

        // get timezone
        // departureTimeZone = find(
        //   matchingDepartureAirport.lat,
        //   matchingDepartureAirport.lon
        // );
        console.log("MATCHING DEPART TZ", departureTimeZone);

        if (departureTimeZone?.length) {
          departureTimeZone = departureTimeZone[0];
        }

        // pass to datepicker component for etd
      }

      if (contractAndTripData?.departureAirports?.length) {
        let matchingArrivalAirport = contractAndTripData?.arrivalAirports.find(
          (item) => item._id === leg.arrivalAirport?.id
        );

        // arrivalTimeZone = find(
        //   matchingArrivalAirport.lat,
        //   matchingArrivalAirport.lon
        // );

        console.log("ARRIVAL TIME ZONE", arrivalTimeZone);

        // get timezone

        if (arrivalTimeZone?.length) {
          arrivalTimeZone = arrivalTimeZone[0];
        }

        // pass to datepicker component for eta
      }

      return {
        departureDate,
        departureAirport: leg.departureAirport,
        arrivalAirport: leg.arrivalAirport,
        passengers: leg.passengers,
        etd: leg.etd ?? "",
        eta: leg.eta ?? "",
        ete: leg.ete ?? "",
        selectedOperator,
        aircraft: leg.aircraft,
        departureTimeZone,
        arrivalTimeZone,
      };
    });

    //pricing
    Object.keys(contractAndTripData.pricing).forEach((item) => {
      let itemValue = contractAndTripData.pricing[item];

      if (typeof itemValue === "number" && itemValue !== 0) {
        itemValue = itemValue.toFixed(2);
      }

      if (itemValue === 0) {
        itemValue = "";
      }

      dispatch({
        type: "fieldChanged",
        field: item,
        value: itemValue,
      });
    });

    //recipient fields
    Object.keys(contractAndTripData.recipientFields).forEach((item) => {
      let itemValue = contractAndTripData.recipientFields[item];

      if (item === "cc" && itemValue?.length) {
        itemValue = itemValue.map((item) => {
          return { label: item, value: item };
        });
      }

      dispatch({
        type: "fieldChanged",
        field: item,
        value: itemValue,
      });
    });

    if (contractAndTripData.clientData) {
      let i = contractAndTripData.clientData;
      let label = `${contractAndTripData.clientData?.firstAndLastName} - ${contractAndTripData.clientData?.emails[0]}`;

      if (!i?.firstAndLastName) {
        if (!i.firstName) {
          i.firstName = "";
        }

        if (!i.lastName) {
          i.lastName = "";
        }

        label = `${i.firstName} ${i.lastName} - ${i.emails[0]}`;
      }

      if (i.companyName) {
        label = `${label} - ${i.companyName}`;
      }

      if (i.assigneeName && i.assigneeId) {
        label = `${label} - ${i.assigneeName}`;
      }

      contractAndTripData.clientData.label = label;
    }

    dispatch({
      type: "fieldChanged",
      field: "selectedClient",
      value: contractAndTripData.clientData,
    });
    dispatch({
      type: "fieldChanged",
      field: "useDifferentAuthorizedSigner",
      value: contractAndTripData.useDifferentAuthorizedSigner,
    });

    dispatch({
      type: "fieldChanged",
      field: "leadData",
      value: contractAndTripData.leadData,
    });

    dispatch({ type: "fieldChanged", field: "legs", value: legs });
    // dispatch({ type: "fieldChanged", field: "routingProcessed", value: true });

    // console.log({ legs });
  };

  const getContractAndTripData = async () => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    console.log("GET CONTRACT AND TRIP DATA");

    let { data: contractAndTripData } = await ai
      .auth(token)
      .get(`/api/trips/itinerary/${itineraryId}`);

    // await getLeadData();

    console.log({ contractAndTripData });

    processContractAndTripData(contractAndTripData);

    // dispatch({
    //   type: "tripDataDownloaded",
    //   payload: {
    //     tripData,
    //   },
    // });
  };

  useEffect(() => {
    if (!itineraryId) {
      prepareNewItinerary();
    } else {
      prepareEditItinerary();
    }

    document.title = "Itinerary Builder | Flight Deck";
  }, []);

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleTextInputChange = (e) => {
    return dispatch({
      type: "fieldChanged",
      field: e.target.name,
      value: e.target.value,
    });
  };

  const handleEmailChange = (newValue, actionMeta) => {
    // // console.group("Email Value Changed");
    // console.log(newValue);
    // console.log(`action: ${actionMeta.action}`);
    dispatch({
      type: "fieldChanged",
      field: "email",
      value: newValue || [],
    });
    // console.groupEnd();
  };

  const handleCcChange = (newValue, actionMeta) => {
    // console.group("CC Value Changed");
    // console.log(newValue);
    // console.log(`action: ${actionMeta.action}`);
    dispatch({
      type: "fieldChanged",
      field: "cc",
      value: newValue || [],
    });
    // console.groupEnd();
  };

  const handleBccChange = (newValue, actionMeta) => {
    // console.group("BCC Value Changed");
    // console.log(newValue);
    // console.log(`action: ${actionMeta.action}`);
    dispatch({
      type: "fieldChanged",
      field: "bcc",
      value: newValue || [],
    });
    // console.groupEnd();
  };

  const handleEmailInputChange = (inputValue, action) => {
    // console.group("Email Input Changed");
    // console.log(inputValue);
    // console.log("action", action);

    dispatch({
      type: "fieldChanged",
      field: "emailInputValue",
      value: inputValue,
    });

    if (
      action.action === "menu-close" &&
      validateEmail(state.emailInputValue)
    ) {
      dispatch({
        type: "fieldChanged",
        field: "email",
        value: [...state.email, createOption(state.emailInputValue)],
      });
      dispatch({
        type: "fieldChanged",
        field: "emailInputValue",
        value: "",
      });
    }

    // console.groupEnd();
  };

  const handleCcInputChange = (inputValue, action) => {
    console.group("CC Input Changed");
    console.log(inputValue);
    console.log("action", action);

    dispatch({
      type: "fieldChanged",
      field: "ccInputValue",
      value: inputValue,
    });

    if (action.action === "menu-close" && validateEmail(state.ccInputValue)) {
      dispatch({
        type: "fieldChanged",
        field: "cc",
        value: [...state.cc, createOption(state.ccInputValue)],
      });
      dispatch({
        type: "fieldChanged",
        field: "ccInputValue",
        value: "",
      });
    }

    console.groupEnd();
  };

  const handleBccInputChange = (inputValue, action) => {
    console.group("BCC Input Changed");
    console.log(inputValue);
    console.log("action", action);

    dispatch({
      type: "fieldChanged",
      field: "bccInputValue",
      value: inputValue,
    });

    if (action.action === "menu-close" && validateEmail(state.bccInputValue)) {
      dispatch({
        type: "fieldChanged",
        field: "bcc",
        value: [...state.bcc, createOption(state.bccInputValue)],
      });
      dispatch({
        type: "fieldChanged",
        field: "bccInputValue",
        value: "",
      });
    }

    console.groupEnd();
  };

  const emailComponents = {
    DropdownIndicator: null,
  };

  const createOption = (label) => ({
    label,
    value: label,
  });

  const handleEmailKeyDown = (event) => {
    const { emailInputValue, email } = state;
    if (!emailInputValue || validateEmail(emailInputValue)) return;

    switch (event.key) {
      case "Enter":
      case "Tab":
        console.group("Email Value Added");
        console.log(email);
        console.groupEnd();

        if (state.email) {
          dispatch({
            type: "fieldChanged",
            field: "email",
            value: [...email, createOption(emailInputValue)],
          });
        } else {
          dispatch({
            type: "fieldChanged",
            field: "email",
            value: [createOption(emailInputValue)],
          });
        }

        dispatch({
          type: "fieldChanged",
          field: "emailInputValue",
          value: "",
        });
        event.preventDefault();
    }
  };

  const handleCcKeyDown = (event) => {
    const { ccInputValue, cc } = state;
    if (!ccInputValue) return;

    switch (event.key) {
      case "Enter":
      case "Tab":
        console.group("CC Value Added");
        console.log(cc);
        console.groupEnd();
        dispatch({
          type: "fieldChanged",
          field: "cc",
          value: [...cc, createOption(ccInputValue)],
        });

        dispatch({
          type: "fieldChanged",
          field: "ccInputValue",
          value: "",
        });
        event.preventDefault();
    }
  };

  const handleBccKeyDown = (event) => {
    const { bccInputValue, bcc } = state;
    if (!bccInputValue) return;

    switch (event.key) {
      case "Enter":
      case "Tab":
        console.group("Bcc Value Added");
        console.log(bcc);
        console.groupEnd();
        dispatch({
          type: "fieldChanged",
          field: "bcc",
          value: [...bcc, createOption(bccInputValue)],
        });

        dispatch({
          type: "fieldChanged",
          field: "bccInputValue",
          value: "",
        });
        event.preventDefault();
    }
  };

  const handleAddLeg = (event) => {
    event.preventDefault();

    dispatch({ type: "legAdded" });
  };

  const handleAddOption = (event) => {
    event.preventDefault();

    dispatch({ type: "optionAdded" });
  };

  const loadAirportOptions = async (inputValue) => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });
    let { data } = await ai
      .auth(token)
      .get(`/api/airports/search?q=${inputValue}&includeTZ=true`);
    console.log(data);

    let options = [];
    if (data.length >= 1) {
      options = data.map((i) => ({
        value: i._id,
        label: i.airportName,
        code: i.code,
        city: i.city,
        localCode: i.localCode,
        lat: i.lat,
        lon: i.lon,
        tz: i.tz,
      }));
    }

    return options;
  };
  const loadAircraftOptions = async (inputValue) => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });
    let { data } = await ai
      .auth(token)
      .get(`/api/aircrafts/search?q=${inputValue}`, {
        params: {
          query: inputValue,
          page: 1,
          countPerPage: 50,
        },
      });

    if (data.aircraft.length >= 1) {
      console.log(data.aircraft);
      return data.aircraft.map((aircraft) => {
        return {
          value: aircraft._id,
          label: aircraft.name,
          id: aircraft._id,
          yom: aircraft.yom,
          name: aircraft.name,
          opi: aircraft.opi,
          seats: aircraft.seats,
          speed: aircraft.cruiseSpeed,
        };
      });
    } else {
      return [];
    }
  };

  const safetyStandards = [
    { label: "ARG/US Gold+", value: false },
    { label: "ARG/US Platinum", value: false },
    { label: "Wyvern Wingman", value: false },
    { label: "Wyvern Pass", value: false },
    { label: "Wyvern Registered", value: false },
    { label: "ARG/US Gold", value: false },
  ];

  const amenities = [
    { label: "Cabin Attendant", value: false },
    { label: "WiFi (adtl charges may apply)", value: false },
    { label: "Free WiFi", value: false },
    { label: "Enhanced Multimedia", value: false },
    { label: "Standard", value: false },
  ];

  const handleClientChange = (selectedClient) => {
    if (selectedClient) {
      dispatch({
        type: "fieldChanged",
        field: "selectedClient",
        value: selectedClient,
      });
      dispatch({
        type: "fieldChanged",
        field: "tripContactDetails",
        value: {
          firstName: selectedClient?.firstName,
          lastName: selectedClient?.lastName,
          email: selectedClient?.emails[0],
          phone: selectedClient?.phone,
          phoneCountry: selectedClient?.phoneCountry || "US",
        },
      });
    } else {
      // dispatch({
      //   type: "fieldChanged",
      //   field: "outreachProspectId",
      //   value: null,
      // });

      dispatch({
        type: "fieldChanged",
        field: "selectedClient",
        value: selectedClient,
      });
    }
  };

  const handleClientOptionCreation = (inputValue) => {
    console.log("HANDLE CLIENT OPTION CREATION", inputValue);

    dispatch({
      type: "fieldChanged",
      field: "createProspectModalIsOpen",
      value: true,
    });
  };

  const handleFBOCreationRequest = (inputValue) => {
    console.log("HANDLE FBO CREATION", inputValue);

    dispatch({
      type: "fieldChanged",
      field: "createFBOModalIsOpen",
      value: true,
    });
  };

  const handleAuthorizedSignerOptionCreation = (inputValue) => {
    console.log("HANDLE AUTHORIZED SIGNER CREATION", inputValue);

    dispatch({
      type: "fieldChanged",
      field: "createAuthorizedSignerModalIsOpen",
      value: true,
    });
  };

  const handleItinerarySubmit = async (e) => {
    e.preventDefault();

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let legs = state.legs.map(
      ({
        departureDate,
        departureAirport,
        arrivalAirport,
        passengers,
        etd,
        eta,
        ete,
        aircraft,
        selectedOperator,
        arrivalTimeZone,
        departureTimeZone,
        tailNumber,
        departureFBO,
        arrivalFBO,
        pax,
        crew,
        catering,
        groundTransportation,
        notes,
      }) => {
        let operator = {
          id: selectedOperator?.id,
          name: selectedOperator?.operatorName || selectedOperator?.name,
        };

        if (
          selectedOperator?.value &&
          selectedOperator?.label &&
          selectedOperator?.value === selectedOperator?.label
        ) {
          operator = {
            id: null,
            name: selectedOperator?.value,
          };
        }

        crew = crew?.map((item) => {
          return {
            firstName: item.firstName,
            middleName: item.middleName,
            lastName: item.lastName,
            position: item?.position?.value,
            phoneNumber: item.phoneNumber,
            phoneCountry: item.phoneCountry,
          };
        });

        pax = pax?.map((item, index) => {
          return {
            firstName: item.firstName,
            middleName: item.middleName,
            lastName: item.lastName,
            isLeadPax: index === 0,
          };
        });

        departureFBO = {
          id: departureFBO?.value,
          name: departureFBO?.fboData?.longName || departureFBO?.label,
        };

        arrivalFBO = {
          id: arrivalFBO?.value,
          name: arrivalFBO?.fboData?.longName || arrivalFBO?.label,
        };

        return {
          departureDate,
          departureAirport,
          arrivalAirport,
          passengers,
          etd,
          eta,
          ete,
          aircraft,
          arrivalTimeZone,
          departureTimeZone,
          operator,
          tailNumber,
          departureFBO,
          arrivalFBO,
          pax,
          crew,
          catering,
          groundTransportation,
          notes,
        };
      }
    );

    let { tripContactDetails } = state;

    let itineraryData = {
      clientId: state.selectedClient?._id,
      tripContactDetails,
      legs,
    };

    console.log("ITINERARY DATA", itineraryData);

    let response = false;

    if (!itineraryId) {
      //create
      response = await ai
        .auth(token)
        .post(`/api/trips/${tripId}/itineraries/create`, itineraryData)
        .catch((error) => {
          console.error(error);
          Sentry.captureException(error);
        });
    }

    if (itineraryId) {
      //edit
      response = await ai
        .auth(token)
        .put(`/api/trips/${tripId}/itineraries/${itineraryId}`, itineraryData)
        .catch((error) => {
          console.error(error);
          Sentry.captureException(error);
          // console.log(error.response);
          toast.error(
            "Please fill out all required fields: ensure an aircraft is selected for this Aircraft Option, and departure and arrival airports for all legs.",
            toastOptions
          );
        });
    }

    if (response && response.data) {
      console.log("ITINERARY RESP", response.data);
      // dispatch({
      //   type: "fieldChanged",
      //   field: "tripData",
      //   value: response.data,
      // });

      setTimeout(() => {
        toast.success("Itinerary saved successfully!", toastOptions);
      }, 500);

      history.push(
        `/trips/${tripId}/itineraries/${response.data?._id}/preview`
      );

      // dispatch({
      //   type: "fieldChanged",
      //   field: "tripId",
      //   value: "1",
      // });
    }
  };

  const handleAirportChange = async (legIndex, airport, airportIndex) => {
    // airportIndex = 0 = depart
    // airportIndex = 1 = arrive

    let legData = { ...state.legs[legIndex] };

    if (airportIndex === 0) {
      legData.departureAirport = {
        id: airport.value,
        name: airport.label,
      };

      // let departureTimeZone = find(airport.lat, airport.lon);

      let departureTimeZone = null;
      if (airport.tz && airport.tz.length) {
        departureTimeZone = airport.tz[0];
      }

      console.log(`DEPARTURE TIME ZONE CHANGE`, departureTimeZone, airport);

      if (departureTimeZone?.length) {
        dispatch({
          type: "legFieldChanged",
          payload: {
            legIndex: legIndex,
            field: "departureTimeZone",
            value: departureTimeZone,
          },
        });
      }
    }

    if (airportIndex === 1) {
      legData.arrivalAirport = {
        id: airport.value,
        name: airport.label,
      };

      // let arrivalTimeZone = find(airport.lat, airport.lon);

      let arrivalTimeZone = null;

      if (airport.tz && airport.tz.length) {
        arrivalTimeZone = airport.tz[0];
      }

      console.log(
        "ARRIVAL TIME ZONE CHANGE FOR LEG",
        legIndex,
        arrivalTimeZone
      );

      if (arrivalTimeZone?.length) {
        dispatch({
          type: "legFieldChanged",
          payload: {
            legIndex: legIndex,
            field: "arrivalTimeZone",
            value: arrivalTimeZone,
          },
        });
      }

      console.log("ARRIVAL AIRPORT CHANGE", airport);
    }

    await handleFlightTimeCalculationByIndex(legData, legIndex);
  };

  const handleAircraftChange = async (legIndex, updatedAircraft) => {
    // airportIndex = 0 = depart
    // airportIndex = 1 = arrive

    let legData = { ...state.legs[legIndex] };

    legData.aircraft = updatedAircraft;

    await handleFlightTimeCalculationByIndex(legData, legIndex);
  };

  const handleFlightTimeCalculationByIndex = async (legData, legIndex) => {
    //todo - update syntax
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let params = {
      legData: {
        arrivalAirportId: legData.arrivalAirport.id,
        departureAirportId: legData.departureAirport.id,
        legIndex: legIndex,
        aircraftId: legData.aircraft.id,
      },
    };

    let response = await ai
      .auth(token)
      .post(`/api/trips/flightTimes`, params)
      .catch((err) => {
        console.error(err.response.data);
        toast.error(
          "Please fill out all required fields: ensure an aircraft is selected for this Aircraft Option, and departure and arrival airports for all legs.",
          toastOptions
        );
        return;
      });

    if (
      response &&
      response?.data &&
      response?.data?.length &&
      response?.data[0]?.value
    ) {
      console.log(response?.data[0]?.value);

      let returnedValueForLeg = response?.data[0]?.value;

      let composedString = "";

      if (returnedValueForLeg?.timeWithWindAdjustment) {
        composedString = timeFormatter(
          returnedValueForLeg?.timeWithWindAdjustment
        );
      }

      console.log("COMPOSED STRING", composedString);

      dispatch({
        type: "legFieldChanged",
        payload: {
          legIndex,
          field: "ete",
          value: composedString,
        },
        // values: response.data,
      });

      handleETEChange(legIndex, composedString);

      return toast.success(`ETE and ETA for Leg ${legIndex + 1} were updated.`);
    }
  };

  const calculateETAForLeg = async (ete, etd, leg) => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    console.log("LEG ETA RECALCULATION");

    let etaFactors = {
      ete,
      etd,
      leg,
    };

    //re-calculate ETA

    let response = await ai
      .auth(token)
      .post(`/api/trips/calculateETAOneLeg`, etaFactors)
      .catch((err) => {
        console.error(err.response.data);
        // toast.error(
        //   // "Please fill out all required fields: ensure an aircraft is selected for this Aircraft Option, and departure and arrival airports for all legs.",
        //   toastOptions
        // );
        return;
      });

    // console.log({ etaRESP: response });

    return response;
  };

  const handleETDChange = async (legIndex, updatedETD) => {
    console.log(legIndex, state.legs[legIndex], updatedETD);

    let leg = { ...state.legs[legIndex] };

    let pattern = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;

    const isValid = pattern.test(leg.ete);

    if (!isValid && leg.ete !== "" && leg.ete !== null) {
      dispatch({
        type: "legFieldChanged",
        payload: {
          legIndex: legIndex,
          field: "ete",
          value: "",
        },
      });

      return toast.error(
        `Please enter a valid ETE for Leg ${
          legIndex + 1
        } to recalculate the ETA.`,
        toastOptions
      );
    }

    let hours = parseInt(leg.ete.split(":")[0]);
    let minutes = parseInt(leg.ete.split(":")[1]);

    console.log(
      `ETD was updated to ${updatedETD}, and the ESTIMATED FLIGHT TIME (ETE) is ${hours} hours ${minutes} minutes. RAW ETE IS ${leg.ete}.`
    );

    let etaResponse = await calculateETAForLeg(leg.ete, updatedETD, leg);
    if (!etaResponse.data) {
      return;
    }
    console.log("ETA RESPONSE", etaResponse.data);

    // console.log(`The revised ETA is ${etaResponse.data}.`);
    if (etaResponse.data?.eta) {
      dispatch({
        type: "legFieldChanged",
        payload: {
          field: "eta",
          value: etaResponse?.data?.eta,
          legIndex,
        },
      });
    }

    if (etaResponse.data?.departureTimeZone) {
      dispatch({
        type: "legFieldChanged",
        payload: {
          field: "departureTimeZone",
          value: etaResponse?.data?.departureTimeZone,
          legIndex,
        },
      });
    }

    if (etaResponse.data?.arrivalTimeZone) {
      dispatch({
        type: "legFieldChanged",
        payload: {
          field: "arrivalTimeZone",
          value: etaResponse?.data?.arrivalTimeZone,
          legIndex,
        },
      });
    }
  };

  const handleETEChange = async (legIndex, updatedETE) => {
    console.log(legIndex, state.legs[legIndex], updatedETE);

    let leg = { ...state.legs[legIndex] };

    let pattern = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;

    const isValid = pattern.test(updatedETE);

    if (!isValid) {
      dispatch({
        type: "legFieldChanged",
        payload: {
          legIndex: legIndex,
          field: "ete",
          value: "",
        },
      });

      return toast.error(
        `Please enter a valid ETE for Leg ${legIndex + 1}`,
        toastOptions
      );
    }

    let hours = parseInt(updatedETE.split(":")[0]);
    let minutes = parseInt(updatedETE.split(":")[1]);

    console.log("updated ETE is", hours, "hours", minutes, "minutes");

    if (!state.legs[legIndex]?.etd) {
      return toast.error(
        `Please enter a valid ETD for Leg ${
          legIndex + 1
        } to recalculate the ETA.`,
        toastOptions
      );
    }

    let etaResponse = await calculateETAForLeg(
      updatedETE,
      state.legs[legIndex]?.etd,
      leg
    );

    console.log("ETA RESP IN HANDLE ETE CHANGE FUNCTION", etaResponse.data);

    dispatch({
      type: "legFieldChanged",
      payload: {
        field: "eta",
        value: etaResponse.data?.eta,
        legIndex,
      },
    });
  };

  const handleFlightTimeCalculation = async (legData) => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    if (!state.routingProcessed) {
      return;
    }

    let params = {
      legData: state.legs.flatMap((item, index) => {
        if (!item?.aircraft?.id) {
          return [];
        }

        return {
          arrivalAirportId: item.arrivalAirport.id,
          departureAirportId: item.departureAirport.id,
          legIndex: index,
          aircraftId: item?.aircraft?.id,
        };
      }),
    };

    if (params?.legData?.length === 0) {
      return;
    }

    let response = await ai
      .auth(token)
      .post(`/api/trips/flightTimesMulti`, params)
      .catch((err) => {
        console.error(err.response.data);
        toast.error(
          "Please fill out all required fields: ensure an aircraft is selected for this Aircraft Option, and departure and arrival airports for all legs.",
          toastOptions
        );
        return;
      });

    if (response && response.data) {
      console.log("TIMES", response.data);
      dispatch({
        type: "flightTimesDownloaded",
        values: response.data,
      });
      return response.data;
    }
  };

  const handleFieldChange = (e) => {
    console.log("EVENT", e.target.name);

    // if (e.target.type === "number") {
    //   return dispatch({
    //     type: "fieldChanged",
    //     field: e.target.name,
    //     value: parseInt(e.target.value),
    //     fieldType: e.target.type,
    //   });
    // }

    dispatch({
      type: "fieldChanged",
      field: e.target.name,
      value: e.target.value,
      fieldType: e.target.type,
    });
  };

  const handleCheckboxChange = (e) => {
    console.log("EVENT", e.target.name);

    dispatch({
      type: "fieldChanged",
      field: e.target.name,
      value: e.target.checked,
    });
  };

  const handlePriceFieldChange = (values, sourceInfo) => {
    const { formattedValue, value } = values;
    // Event is a Synthetic Event wrapper which holds target and other information. Source tells whether the reason for this function being triggered was an 'event' or due to a 'prop' change
    const { event, source } = sourceInfo;

    console.log("event", sourceInfo);

    if (!event?.target) {
      return;
    }

    dispatch({
      type: "fieldChanged",
      field: event?.target?.name,
      value: value,
      fieldType: "float",
    });
  };

  const handleOperatorChange = (selectedOperator, legIndex) => {
    console.log("OPERATOR CHANGE", selectedOperator, legIndex);

    if (selectedOperator) {
      dispatch({
        type: "legFieldChanged",
        payload: {
          legIndex: legIndex,
          field: "selectedOperator",
          value: selectedOperator,
        },
      });
    } else {
      resetSelection("selectedOperator", legIndex);
    }
  };

  const handleFBOChange = (selectedOperator, legIndex) => {
    console.log("FBO CHANGE", selectedOperator, legIndex);

    if (selectedOperator) {
      dispatch({
        type: "legFieldChanged",
        payload: {
          legIndex: legIndex,
          field: "selectedOperator",
          value: selectedOperator,
        },
      });
    } else {
      resetSelection("selectedOperator", legIndex);
    }
  };

  const resetSelection = (fieldName, legIndex) => {
    setTimeout(() => {
      dispatch({
        type: "legFieldChanged",
        payload: {
          legIndex: legIndex,
          field: "selectedOperator",
          value: null,
        },
      });
    }, 0);
  };

  const handleDepartureDateChange = async (legIndex, updatedDepartureDate) => {
    console.log(legIndex, state.legs[legIndex], updatedDepartureDate);

    let leg = { ...state.legs[legIndex] };

    const updatedETD = moment
      .tz(leg.etd, leg.departureTimeZone) // Start with original ETD
      .set({
        year: moment(updatedDepartureDate).year(),
        month: moment(updatedDepartureDate).month(),
        date: moment(updatedDepartureDate).date(),
      })
      .toISOString();

    console.log({
      etd: leg.etd,
      currentDepartDate: leg.departureDate,
      updatedDepartureDate: updatedDepartureDate,
      updatedETD: updatedETD,
    });

    // Update the ETD field with the new date + existing time
    dispatch({
      type: "legFieldChanged",
      payload: {
        field: "etd",
        value: updatedETD,
        legIndex,
      },
    });
  };

  const handleMatchPax = (e) => {
    e.preventDefault();
    e.stopPropagation();

    //get pax from leg 1
    //replace pax in all other legs with same pax

    let pax = state.legs[0]?.pax;

    if (!pax?.length) {
      return;
    }

    let updatedLegs = state.legs.map((leg, index) => {
      if (index === 0) {
        return leg;
      }

      return {
        ...leg,
        pax,
      };
    });

    dispatch({
      type: "fieldChanged",
      field: "legs",
      value: updatedLegs,
    });
  };

  useEffect(() => {
    if (document) {
      document.getElementById("page-heading").scrollIntoView({
        behavior: "smooth",
        // block: "start",
        // inline: "nearest",
      });
    }

    if (sourcingRecords?.length) {
      processSourcing(sourcingRecords);
    }

    processSubject();
  }, [state.loading, sourcingRecords]);

  return (
    <Fragment>
      <h2 className="base-heading" id="page-heading">
        {itineraryId ? `Edit Itinerary` : `New Itinerary`}
      </h2>

      {itineraryId && (
        <Link
          to={`/trips/${tripId}`}
          className="evo-blue base-button mb-20 d-inline-block w-auto"
        >
          Back to Trip
        </Link>
      )}

      <form
        id="new-itinerary"
        onSubmit={handleItinerarySubmit}
        className="base-form"
      >
        <ProspectsAutocomplete
          onCreateOption={handleClientOptionCreation}
          handleProspectChange={handleClientChange}
          prospectOptions={[]}
          parentDispatch={dispatch}
          selectedProspect={state.selectedClient}
          label="Client"
          placeholder="Select a Client"
        />

        <CreateProspectModal
          modalIsOpen={state.createProspectModalIsOpen}
          parentDispatch={dispatch}
          toast={toast}
          defaultPhoneNumber={state.modalPhoneNumber}
          toastOptions={toastOptions}
        />

        <CreateFBOModal
          modalIsOpen={state.createFBOModalIsOpen}
          parentDispatch={dispatch}
          toast={toast}
          toastOptions={toastOptions}
          modalStateKey={`createFBOModalIsOpen`}
        />

        {/* <h3>Trip Contact Details</h3>
        <div className="flex-row column-gap-1pct flex-align-center mb-20 mt-20">
          <TextInput
            type={"text"}
            name={`trip-contact-first-name`}
            label="First Name"
            handleChange={(e) => {
              dispatch({
                type: "tripContactDetailsChanged",
                payload: {
                  field: "firstName",
                  value: e.target.value || "",
                },
              });
            }}
            className="base-input"
            containerClasses="flex-half mb-0"
            value={state?.tripContactDetails?.firstName}
          ></TextInput>

          <TextInput
            type={"text"}
            name={`trip-contact-last-name`}
            label="Last Name"
            handleChange={(e) => {
              dispatch({
                type: "tripContactDetailsChanged",
                payload: {
                  field: "lastName",
                  value: e.target.value || "",
                },
              });
            }}
            className="base-input"
            containerClasses="flex-half mb-0"
            value={state?.tripContactDetails?.lastName}
          ></TextInput>

          <div
            className="form-group flex-half ml-0"
            key={`trip-contact-phoneNumber`}
          >
            <label className="phoneInputLabel" htmlFor="phone">
              Phone Number
            </label>

            <PhoneInput
              placeholder="Phone Number"
              value={state?.tripContactDetails?.phoneNumber}
              name={`trip-contact-phoneNumber`}
              onChange={(value) => {
                dispatch({
                  type: "tripContactDetailsChanged",
                  payload: {
                    field: "phoneNumber",
                    value: value || "",
                  },
                });
              }}
              onCountryChange={(value) => {
                dispatch({
                  type: "tripContactDetailsChanged",
                  payload: {
                    field: "phoneCountry",
                    value: value || "",
                  },
                });
              }}
              defaultCountry={state?.tripContactDetails?.phoneCountry}
              className={"mb-0 base-phone-input"}
            />
          </div> 

          <TextInput
            type={"email"}
            name={`trip-contact-email`}
            label="Email"
            handleChange={(e) => {
              dispatch({
                type: "tripContactFieldChanged",
                field: "email",
                value: e.target.value || "",
              });
            }}
            className="base-input"
            containerClasses="flex-half mb-0"
            value={state?.tripContactDetails?.email}
          ></TextInput>
        </div>

        {/* <div className="form-group">
          <label>Use a Different Authorized Signer?</label>
          <Checkbox
            checked={state.useDifferentAuthorizedSigner}
            onChange={handleCheckboxChange}
            name="useDifferentAuthorizedSigner"
          />
        </div> */}

        {state.useDifferentAuthorizedSigner && (
          <>
            <div className="form-group mt20">
              <label htmlFor="selectedAuthorizedSigner">
                Authorized Signer
              </label>

              <CreatableSelect
                className="authorized-signer-select"
                classNamePrefix="select"
                placeholder="Authorized Signer"
                isClearable
                options={state.authorizedSigners}
                onChange={(val) =>
                  dispatch({
                    type: "fieldChanged",
                    field: "selectedAuthorizedSigner",
                    value: val,
                  })
                }
                value={state.selectedAuthorizedSigner}
                name={"selectedAuthorizedSigner"}
                onCreateOption={handleAuthorizedSignerOptionCreation}
                formatCreateLabel={(inputValue) => "Add an Authorized Signer"}
              ></CreatableSelect>
            </div>

            <CreateAuthorizedSignerModal
              modalIsOpen={state.createAuthorizedSignerModalIsOpen}
              parentDispatch={dispatch}
              toast={toast}
              toastOptions={toastOptions}
              modalStateKey={`createAuthorizedSignerModalIsOpen`}
            />
          </>
        )}

        <div className="flight-details-container">
          <h3>Routing</h3>

          {state?.legs?.length > 0 &&
            state?.legs?.map((leg, index) => (
              <div className="flight-details-block" key={index}>
                <div className="flex-row column-gap-1pct">
                  <div className="datepickerContainer mb-0">
                    <label
                      htmlFor={`departureDate[${index}]`}
                      className="base-label"
                    >
                      Date <span className="required">*</span>
                    </label>

                    <Datepicker
                      value={leg.departureDate}
                      onChange={(event) => {
                        console.log(event.value);

                        handleDepartureDateChange(index, event.value);

                        dispatch({
                          type: "legFieldChanged",
                          payload: {
                            legIndex: index,
                            field: "departureDate",
                            value: event.value,
                          },
                        });
                      }}
                      dataTimezone="utc"
                      displayTimezone="local"
                      timezonePlugin={momentTimezone}
                      controls={["date"]}
                    />
                  </div>
                  <div className={`form-group flex-half`}>
                    <label htmlFor={`etd[${index}]`}>ETD</label>
                    {/* {leg.etd} {leg.departureTimeZone} */}
                    <Datepicker
                      onChange={(e) => {
                        handleETDChange(index, e.value);

                        dispatch({
                          type: "legFieldChanged",
                          payload: {
                            legIndex: index,
                            field: "etd",
                            value: e.value || null,
                          },
                        });
                      }}
                      // label="With Data Timezone"
                      timezonePlugin={momentTimezone}
                      dataTimezone="utc"
                      displayTimezone={leg.departureTimeZone}
                      controls={["time"]}
                      value={leg.etd}
                    ></Datepicker>
                  </div>

                  <div className={`form-group flex-half`}>
                    <label htmlFor={`eta[${index}]`}>ETA</label>
                    {/* {leg.eta} {leg.arrivalTimeZone} */}
                    <Datepicker
                      onChange={(e) => {
                        dispatch({
                          type: "legFieldChanged",
                          payload: {
                            legIndex: index,
                            field: "eta",
                            value: e.value || null,
                          },
                        });
                      }}
                      timezonePlugin={momentTimezone}
                      dataTimezone="utc"
                      displayTimezone={leg.arrivalTimeZone}
                      controls={["time"]}
                      value={leg.eta}
                    ></Datepicker>
                  </div>

                  <div className="airportContainer flex-grow-1 mb-0">
                    <label
                      htmlFor={`departureAirport[${index}]`}
                      className="base-label"
                    >
                      Depart
                    </label>

                    <AsyncSelect
                      key={`departure-${leg.departureAirport?.airportName}`}
                      name={`departureAirport[${index}]`}
                      className="departure-airport-select mb-0"
                      classNamePrefix="select"
                      cacheOptions
                      loadOptions={loadAirportOptions}
                      placeholder="Search Airports"
                      defaultValue={{
                        label: leg?.departureAirport?.airportName,
                        value: leg?.departureAirport?.id,
                      }}
                      // onInputChange={props.handleDepartureInputChange}
                      onChange={(selectedAirport) => {
                        handleAirportChange(index, selectedAirport, 0);

                        dispatch({
                          type: "legFieldChanged",
                          payload: {
                            legIndex: index,
                            field: "departureAirport",
                            value: {
                              airportName: selectedAirport?.label || "",
                              id: selectedAirport?.value || "",
                              code: selectedAirport?.code || "",
                              city: selectedAirport?.city || "",
                              localCode: selectedAirport?.localCode || "",
                            },
                          },
                        });
                      }}
                    ></AsyncSelect>
                  </div>
                  <div className="airportContainer flex-grow-1">
                    <label
                      htmlFor={`departureAirport[${index}]`}
                      className="base-label"
                    >
                      Arrive
                    </label>

                    <AsyncSelect
                      key={`arrival-${leg.arrivalAirport.airportName}`}
                      name={`arrivalAirport[${index}]`}
                      className="arrival-airport-select mb-0"
                      classNamePrefix="select"
                      cacheOptions
                      loadOptions={loadAirportOptions}
                      placeholder="Search Airports"
                      defaultValue={{
                        label: leg.arrivalAirport.airportName,
                        value: leg.arrivalAirport.id,
                      }}
                      // onInputChange={props.handleDepartureInputChange}
                      onChange={(selectedAirport) => {
                        handleAirportChange(index, selectedAirport, 1);

                        dispatch({
                          type: "legFieldChanged",
                          payload: {
                            legIndex: index,
                            field: "arrivalAirport",
                            value: {
                              airportName: selectedAirport?.label || "",
                              id: selectedAirport?.value || "",
                              code: selectedAirport?.code || "",
                              city: selectedAirport?.city || "",
                              localCode: selectedAirport?.localCode || "",
                            },
                          },
                        });
                      }}
                    ></AsyncSelect>
                  </div>
                </div>

                <div className="flex-row column-gap-1pct">
                  <TextInput
                    // type={"time"}
                    name={`ete[${index}]`}
                    label="ETE"
                    handleChange={(e) => {
                      dispatch({
                        type: "legFieldChanged",
                        payload: {
                          legIndex: index,
                          field: "ete",
                          value: e.target.value || "",
                        },
                      });
                    }}
                    handleBlur={(e) => {
                      handleETEChange(index, e.target.value);
                    }}
                    className="base-input"
                    containerClasses="flex-half mb-0"
                    value={leg.ete}
                  ></TextInput>

                  <TextInput
                    type={"number"}
                    name={`passengers[${index}]`}
                    label="PAX"
                    handleChange={(e) => {
                      dispatch({
                        type: "legFieldChanged",
                        payload: {
                          legIndex: index,
                          field: "passengers",
                          value: parseInt(e.target.value) || "",
                        },
                      });
                    }}
                    className="base-input"
                    containerClasses="flex-half mb-0"
                    value={leg.passengers}
                    required
                  ></TextInput>

                  <div className="form-group flex-grow-1">
                    <label htmlFor={`aircraft[${index}]`}>Aircraft</label>
                    <AsyncSelect
                      name={`aircraft[${index}]`}
                      className="aircraft-select mb-0"
                      classNamePrefix="select"
                      cacheOptions
                      key={`aircraft[${index}]${leg?.aircraft?.name}`}
                      loadOptions={loadAircraftOptions}
                      placeholder="Search Aircraft"
                      defaultValue={{
                        label: leg?.aircraft?.name,
                        value: leg?.aircraft?.id,
                      }}
                      // onInputChange={handleInputchange}
                      onChange={(selectedAircraft) => {
                        console.log(selectedAircraft);

                        handleAircraftChange(index, selectedAircraft);

                        dispatch({
                          type: "legFieldChanged",
                          payload: {
                            legIndex: index,
                            field: "aircraft",
                            value: {
                              name: selectedAircraft.name,
                              seats: selectedAircraft.seats,
                              yom: selectedAircraft.yom,
                              // opi: selectedAircraft.opi || "",
                              id: selectedAircraft.id,
                            },
                          },
                        });
                      }}
                    ></AsyncSelect>
                  </div>
                  <OperatorAutocomplete
                    handleChange={(selectedOperator) =>
                      handleOperatorChange(selectedOperator, index)
                    }
                    defaultOperatorOptions={state.operatorOptions}
                    parentDispatch={dispatch}
                    selectedOperator={leg.selectedOperator}
                    containerClasses="flex-grow-1"
                    allowCreate
                  ></OperatorAutocomplete>
                </div>

                <TextInput
                  placeholder="Tail Number"
                  label="Tail Number"
                  name={`tailNumber[${index}]`}
                  handleChange={(e) => {
                    dispatch({
                      type: "legFieldChanged",
                      payload: {
                        legIndex: index,
                        field: "tailNumber",
                        value: e.target.value || "",
                      },
                    });
                  }}
                  value={leg.tailNumber}
                ></TextInput>

                <FBOAutocomplete
                  placeholder="Search FBOs"
                  label="DEPARTURE FBO"
                  selectedFBO={leg?.departureFBO}
                  airports={[leg?.departureAirport]}
                  handleChange={(selectedFBO) => {
                    dispatch({
                      type: "legFieldChanged",
                      payload: {
                        legIndex: index,
                        field: "departureFBO",
                        value: selectedFBO,
                      },
                    });
                  }}
                  allowCreate
                  handleCreateOption={handleFBOCreationRequest}
                ></FBOAutocomplete>

                <FBOAutocomplete
                  placeholder="Search FBOs"
                  label="ARRIVAL FBO"
                  selectedFBO={leg?.arrivalFBO}
                  airports={[leg?.arrivalAirport]}
                  handleChange={(selectedFBO, action) => {
                    console.log("SELECTED FBO, ARRIVAL", selectedFBO, action);

                    dispatch({
                      type: "legFieldChanged",
                      payload: {
                        legIndex: index,
                        field: "arrivalFBO",
                        value: selectedFBO,
                      },
                    });
                  }}
                  allowCreate
                  handleCreateOption={handleFBOCreationRequest}
                ></FBOAutocomplete>

                <hr className="mt-30"></hr>
                <h4>Passengers</h4>

                {index === 0 && state?.legs?.length > 1 && (
                  <button
                    className="evo-action-button evo-blue action-button base-button mb-20"
                    onClick={handleMatchPax}
                  >
                    Sync Pax To All Legs
                  </button>
                )}

                <h6>LEAD PASSENGER</h6>

                {leg?.pax?.map((passenger, paxIndex) => {
                  return (
                    <>
                      {" "}
                      {paxIndex === 1 && <h5>Additional Passengers</h5>}
                      <div className="flex-row column-gap-1pct flex-align-center">
                        <TextInput
                          type={"text"}
                          name={`pax[firstName][${index}]`}
                          label="First Name"
                          handleChange={(e) => {
                            dispatch({
                              type: "legPaxFieldChanged",
                              payload: {
                                legIndex: index,
                                field: "firstName",
                                value: e.target.value || "",
                                paxIndex: paxIndex,
                              },
                            });
                          }}
                          className="base-input"
                          containerClasses="flex-half mb-0"
                          value={passenger.firstName}
                        ></TextInput>

                        <TextInput
                          type={"text"}
                          name={`middleName[${index}]`}
                          label="Middle Name"
                          handleChange={(e) => {
                            dispatch({
                              type: "legPaxFieldChanged",
                              payload: {
                                legIndex: index,
                                field: "middleName",
                                value: e.target.value || "",
                                paxIndex: paxIndex,
                              },
                            });
                          }}
                          className="base-input"
                          containerClasses="flex-half mb-0"
                          value={passenger.middleName}
                        ></TextInput>

                        <TextInput
                          type={"text"}
                          name={`lastName[${index}]`}
                          label="Last Name"
                          handleChange={(e) => {
                            dispatch({
                              type: "legPaxFieldChanged",
                              payload: {
                                legIndex: index,
                                field: "lastName",
                                value: e.target.value || "",
                                paxIndex: paxIndex,
                              },
                            });
                          }}
                          className="base-input"
                          containerClasses="flex-half mb-0"
                          value={passenger.lastName}
                        ></TextInput>

                        {paxIndex >= 1 && (
                          <button
                            className="deleteLeg action-button delete-button"
                            onClick={(e) => {
                              e.preventDefault();
                              return dispatch({
                                type: "paxDeleted",
                                legIndex: index,
                                paxIndex: paxIndex,
                              });
                            }}
                            type="button"
                          >
                            Delete Pax {paxIndex + 1}
                          </button>
                        )}
                      </div>
                    </>
                  );
                })}

                <button
                  className="action-button base-blue-button base-gunmetal-button"
                  onClick={(e) => {
                    e.preventDefault();
                    return dispatch({
                      type: "paxAdded",
                      legIndex: index,
                    });
                  }}
                >
                  Add Pax
                </button>
                <hr className="mt-30 mb-20"></hr>

                <h4>Crew</h4>

                {leg?.crew?.map((member, memberIndex) => {
                  return (
                    <div className="flex-row column-gap-1pct flex-align-center flex-wrap">
                      <div className="form-group  w-25">
                        <label>Position</label>

                        <Select
                          key={`crew-member-options`}
                          className="owner-select"
                          classNamePrefix="select"
                          options={[
                            {
                              label: "Captain",
                              value: "Captain",
                            },
                            {
                              label: "Second",
                              value: "Second",
                            },
                            {
                              label: "Cabin",
                              value: "Cabin",
                            },
                          ]}
                          value={member?.position}
                          placeholder="Member Position"
                          onChange={(selectedPosition) => {
                            dispatch({
                              type: "legCrewFieldChanged",
                              payload: {
                                legIndex: index,
                                field: "position",
                                value: selectedPosition,
                                memberIndex: memberIndex,
                              },
                            });
                          }}
                        ></Select>
                      </div>
                      <TextInput
                        type={"text"}
                        name={`crew[firstName][${index}]`}
                        label="First Name"
                        handleChange={(e) => {
                          dispatch({
                            type: "legCrewFieldChanged",
                            payload: {
                              legIndex: index,
                              field: "firstName",
                              value: e.target.value || "",
                              memberIndex: memberIndex,
                            },
                          });
                        }}
                        className="base-input"
                        containerClasses="flex-half mb-0"
                        value={member.firstName}
                      ></TextInput>
                      <TextInput
                        type={"text"}
                        name={`middleName[${index}]`}
                        label="Middle Name"
                        handleChange={(e) => {
                          dispatch({
                            type: "legCrewFieldChanged",
                            payload: {
                              legIndex: index,
                              field: "middleName",
                              value: e.target.value || "",
                              memberIndex: memberIndex,
                            },
                          });
                        }}
                        className="base-input"
                        containerClasses="flex-half mb-0"
                        value={member.middleName}
                      ></TextInput>
                      <TextInput
                        type={"text"}
                        name={`lastName[${index}]`}
                        label="Last Name"
                        handleChange={(e) => {
                          dispatch({
                            type: "legCrewFieldChanged",
                            payload: {
                              legIndex: index,
                              field: "lastName",
                              value: e.target.value || "",
                              memberIndex: memberIndex,
                            },
                          });
                        }}
                        className="base-input"
                        containerClasses="flex-half mb-0"
                        value={member.lastName}
                      ></TextInput>

                      <div
                        className="form-group fb-100 ml-0"
                        key={`crew-phoneNumber-${index}`}
                      >
                        <label className="phoneInputLabel" htmlFor="phone">
                          Phone Number
                        </label>

                        <PhoneInput
                          placeholder="Phone Number"
                          value={member.phoneNumber}
                          name={`prospectPhone[${index}]`}
                          onChange={(value) => {
                            dispatch({
                              type: "legCrewFieldChanged",
                              payload: {
                                legIndex: index,
                                field: "phoneNumber",
                                value: value || "",
                                memberIndex: memberIndex,
                              },
                            });
                          }}
                          onCountryChange={(value) => {
                            dispatch({
                              type: "legCrewFieldChanged",
                              payload: {
                                legIndex: index,
                                field: "phoneCountry",
                                value: value || "",
                                memberIndex: memberIndex,
                              },
                            });
                          }}
                          defaultCountry={member.phoneCountry}
                          className="mb-0 base-phone-input"
                        />
                      </div>

                      {memberIndex >= 1 && (
                        <button
                          className="deleteLeg action-button delete-button"
                          onClick={(e) => {
                            e.preventDefault();
                            return dispatch({
                              type: "crewDeleted",
                              legIndex: index,
                              memberIndex: memberIndex,
                            });
                          }}
                          type="button"
                        >
                          Delete Member {memberIndex + 1}
                        </button>
                      )}
                    </div>
                  );
                })}

                <button
                  className="action-button base-blue-button base-gunmetal-button"
                  onClick={(e) => {
                    e.preventDefault();
                    return dispatch({
                      type: "crewAdded",
                      legIndex: index,
                    });
                  }}
                >
                  Add Member
                </button>

                <hr className="mb-30"></hr>

                {/* start ground transport */}
                <h4>Ground Transportation</h4>

                {leg?.groundTransportation?.map((item, itemIndex) => {
                  return (
                    <div className="flex-row column-gap-1pct flex-align-center flex-wrap">
                      <TextAreaInput
                        type={"text"}
                        name={`groundTransportation[${itemIndex}]`}
                        label="Add Ground Transportation Notes"
                        handleChange={(e) => {
                          dispatch({
                            type: "legGroundTransportationFieldChanged",
                            payload: {
                              legIndex: index,
                              field: "note",
                              value: e.target.value || "",
                              itemIndex: itemIndex,
                            },
                          });
                        }}
                        className="base-input"
                        containerClasses="flex-half mb-0"
                        value={item.note}
                      ></TextAreaInput>

                      {itemIndex >= 1 && (
                        <button
                          className="deleteGroundTransportation action-button delete-button"
                          onClick={(e) => {
                            e.preventDefault();
                            return dispatch({
                              type: "groundTransportationDeleted",
                              legIndex: index,
                              itemIndex: itemIndex,
                            });
                          }}
                          type="button"
                        >
                          Delete Ground Transportation {itemIndex + 1}
                        </button>
                      )}
                    </div>
                  );
                })}

                <button
                  className="action-button base-blue-button base-gunmetal-button"
                  onClick={(e) => {
                    e.preventDefault();
                    return dispatch({
                      type: "groundTransportationAdded",
                      legIndex: index,
                    });
                  }}
                >
                  Add Ground Transportation
                </button>
                {/* end ground transport */}

                <hr className="mb-30"></hr>

                {/* start catering */}
                <h4>Leg Catering</h4>

                {leg?.catering?.map((item, itemIndex) => {
                  return (
                    <div className="flex-row column-gap-1pct flex-align-center flex-wrap">
                      <TextInput
                        type={"text"}
                        name={`catering-name[${itemIndex}]`}
                        label="Add Catering Item"
                        handleChange={(e) => {
                          dispatch({
                            type: "legCateringFieldChanged",
                            payload: {
                              legIndex: index,
                              field: "name",
                              value: e.target.value || "",
                              itemIndex: itemIndex,
                            },
                          });
                        }}
                        className="base-input"
                        containerClasses="flex-half mb-0"
                        value={item.name}
                      ></TextInput>

                      <TextInput
                        type={"number"}
                        name={`catering-servings[${itemIndex}]`}
                        label="Number Of Servings"
                        handleChange={(e) => {
                          dispatch({
                            type: "legCateringFieldChanged",
                            payload: {
                              legIndex: index,
                              field: "servings",
                              value: parseInt(e.target.value) || "",
                              itemIndex: itemIndex,
                            },
                          });
                        }}
                        className="base-input"
                        containerClasses="flex-half mb-0"
                        value={item.servings}
                      ></TextInput>

                      <TextAreaInput
                        name={`catering-notes[${itemIndex}]`}
                        label="Item Notes"
                        handleChange={(e) => {
                          dispatch({
                            type: "legCateringFieldChanged",
                            payload: {
                              legIndex: index,
                              field: "notes",
                              value: e.target.value || "",
                              itemIndex: itemIndex,
                            },
                          });
                        }}
                        className="base-input"
                        containerClasses="flex-half mb-0"
                        value={item.notes}
                      ></TextAreaInput>

                      {itemIndex >= 1 && (
                        <button
                          className="deleteLegNote action-button delete-button"
                          onClick={(e) => {
                            e.preventDefault();
                            return dispatch({
                              type: "legCateringDeleted",
                              legIndex: index,
                              itemIndex: itemIndex,
                            });
                          }}
                          type="button"
                        >
                          Delete Catering Item {itemIndex + 1}
                        </button>
                      )}
                    </div>
                  );
                })}

                <button
                  className="action-button base-blue-button base-gunmetal-button"
                  onClick={(e) => {
                    e.preventDefault();
                    return dispatch({
                      type: "legCateringAdded",
                      legIndex: index,
                    });
                  }}
                >
                  Add Catering Item
                </button>
                {/* end catering */}

                <hr className="mb-30"></hr>

                {/* start leg notes */}
                <h4>Leg Notes</h4>

                {leg?.notes?.map((item, itemIndex) => {
                  return (
                    <div className="flex-row column-gap-1pct flex-align-center flex-wrap">
                      <TextAreaInput
                        type={"text"}
                        name={`legNote[${itemIndex}]`}
                        label="Add Leg Notes"
                        handleChange={(e) => {
                          dispatch({
                            type: "legNotesFieldChanged",
                            payload: {
                              legIndex: index,
                              field: "note",
                              value: e.target.value || "",
                              itemIndex: itemIndex,
                            },
                          });
                        }}
                        className="base-input"
                        containerClasses="flex-half mb-0"
                        value={item.note}
                      ></TextAreaInput>

                      {itemIndex >= 1 && (
                        <button
                          className="deleteLegNote action-button delete-button"
                          onClick={(e) => {
                            e.preventDefault();
                            return dispatch({
                              type: "legNoteDeleted",
                              legIndex: index,
                              itemIndex: itemIndex,
                            });
                          }}
                          type="button"
                        >
                          Delete Leg Note {itemIndex + 1}
                        </button>
                      )}
                    </div>
                  );
                })}

                <button
                  className="action-button base-blue-button base-gunmetal-button"
                  onClick={(e) => {
                    e.preventDefault();
                    return dispatch({
                      type: "legNoteAdded",
                      legIndex: index,
                    });
                  }}
                >
                  Add Leg Notes
                </button>
                {/* end leg notes */}

                {index >= 1 && (
                  <button
                    className="deleteLeg action-button  delete-button ml-20"
                    onClick={(e) => {
                      e.preventDefault();
                      return dispatch({ type: "legDeleted", index: index });
                    }}
                    type="button"
                  >
                    Delete Leg {index + 1}
                  </button>
                )}
              </div>
            ))}
          <button
            className="action-button base-blue-button mt-0"
            onClick={handleAddLeg}
            type="button"
          >
            Add Leg
          </button>
        </div>

        <input type="submit" value="Save and Preview"></input>
      </form>

      <JSONPretty id="json-pretty" data={state}></JSONPretty>
    </Fragment>
  );
};

export default NewItineraryContent;
