import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ai from "../../helpers/axios";

import { useReducer } from "react";

import ReactModal from "react-modal";

//import textarea-input component
import TextAreaInput from "../textarea-input";

const reducer = (state, action) => {
  switch (action.type) {
    case "fieldChanged": {
      return {
        ...state,
        [action.field]: action.value,
      };
    }

    case "dataBeganDownloading": {
      return {
        ...state,
        loading: true,
      };
    }

    case "dataDownloaded": {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      break;
  }
  return state;
};

const TripNotesModule = (props) => {
  const { getAccessTokenSilently, user, loginWithRedirect } = useAuth0();

  const userRoles = user && user["https://app.evojets.com/roles"];

  const superAdmin = userRoles.some(function (role) {
    return "Super Admin" === role;
  });

  let initialState = {
    showEditModal: false,
    noteBeingEdited: null,
    addNoteText: "",
    tripNotes: [...props.tripNotes],
    loading: false,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  //create function to handle edit button, show react Modal with input field
  const handleEditButton = (id, noteText) => {
    console.log("edit");
    console.log(id, noteText);
    //show modal

    //set noteBeingEdited to note with id
    dispatch({
      type: "fieldChanged",
      field: "noteBeingEdited",
      value: { id, note: noteText },
    });

    dispatch({ type: "fieldChanged", field: "showEditModal", value: true });
  };

  const handleAddNote = async (e) => {
    console.log("add note");

    e.preventDefault();

    const token = await getAccessTokenSilently();
    const response = await ai.auth(token).post(
      `/api/trips/${props.tripId}/addNote`,
      {
        noteText: state.addNoteText,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "fieldChanged", field: "addNoteText", value: "" });

    props.parentDispatch({
      type: "fieldChanged",
      field: "refreshDataToken",
      value: Date.now(),
    });

    console.log(response);
  };

  //create function to edit a note
  const handleEditSubmit = async (e) => {
    console.log("edit");

    e.preventDefault();

    const token = await getAccessTokenSilently();
    const response = await ai.auth(token).put(
      `/api/trips/${props.tripId}/updateNote/${state.noteBeingEdited.id}`,
      {
        noteText: state.noteBeingEdited.note,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch({ type: "fieldChanged", field: "showEditModal", value: false });
    dispatch({ type: "fieldChanged", field: "noteBeingEdited", value: null });

    props.parentDispatch({
      type: "fieldChanged",
      field: "refreshDataToken",
      value: Date.now(),
    });

    console.log(response);
  };

  //function to delete a note
  const handleDeleteButton = async (id) => {
    console.log("delete");
    console.log(id);

    const token = await getAccessTokenSilently();
    const response = await ai
      .auth(token)
      .delete(`/api/trips/${props.tripId}/deleteNote/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

    props.parentDispatch({
      type: "fieldChanged",
      field: "refreshDataToken",
      value: Date.now(),
    });

    console.log(response);
  };

  return (
    <React.Fragment>
      <div
        className="trip-notes-module"
        // key={`key-tripNotes-${props.dataDownloadedTimestamp.toString()}`}
      >
        <h3>TRIP NOTES</h3>

        {state.tripNotes && (
          <div className="trip-notes-table">
            <table>
              <thead>
                <tr>
                  <th>Created By</th>
                  <th>Note</th>
                  <th className="text-align-right">Actions</th>
                </tr>
              </thead>
              <tbody>
                {state.tripNotes.map((note) => {
                  return (
                    <tr key={note._id}>
                      <td>{note?.createdBy?.name}</td>
                      <td>{note.note}</td>
                      <td>
                        <button
                          className="base-button evo-blue"
                          onClick={(e) => {
                            handleEditButton(note._id, note.note);
                          }}
                        >
                          Edit
                        </button>{" "}
                        <button
                          className="base-button btn-danger"
                          onClick={(e) => handleDeleteButton(note._id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        <div className="trip-notes-form">
          <form onSubmit={handleAddNote} className="base-form">
            <TextAreaInput
              name="addNote"
              id="addNote"
              cols="30"
              rows="10"
              value={state.addNoteText}
              handleChange={(e) => {
                dispatch({
                  type: "fieldChanged",
                  field: "addNoteText",
                  value: e.target.value,
                });
              }}
              label="Add a Note"
              className="mb-0"
            ></TextAreaInput>

            <button
              className="base-button evo-blue w-100 mb-20 mt-0"
              type="submit"
            >
              Submit
            </button>
          </form>
        </div>
      </div>

      <ReactModal
        isOpen={state.showEditModal}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => {
          dispatch({
            type: "fieldChanged",
            field: "showEditModal",
            value: false,
          });
        }}
        style={{
          overlay: {
            zIndex: 999999,
            backgroundColor: "rgb(32 53 68 / 50%)",
          },
          content: {
            width: "auto",
            // height: "700px",
            // padding: "40px",
            border: "none",
            maxWidth: "700px",
            maxHeight: "80vh",
            minWidth: "50vw",
            borderRadius: "0",
            boxShadow: "10px 10px 91px -17px rgb(0 0 0 / 25%)",
          },
        }}
        appElement={document.getElementById("app")}
      >
        <div className="modal-header edit-note-modal">
          <h4>Edit Note</h4>
        </div>
        <div className="modal-body edit-note-modal">
          <form onSubmit={handleEditSubmit} className="w-100">
            <div className="form-group">
              <label className="base-label" htmlFor="note">
                Note
              </label>
              <textarea
                name="note"
                id="note"
                cols="30"
                rows="3"
                value={state?.noteBeingEdited?.note}
                onChange={(e) => {
                  dispatch({
                    type: "fieldChanged",
                    field: "noteBeingEdited",
                    value: {
                      ...state.noteBeingEdited,
                      note: e.target.value,
                    },
                  });
                }}
              ></textarea>
            </div>
            <button className="base-button evo-blue w-100" type="submit">
              Submit
            </button>
          </form>
        </div>
      </ReactModal>
    </React.Fragment>
  );
};

export default TripNotesModule;
