import React from "react";
import LeadsContent from "../components/leads/leads-content";

const InLimboLeads = () => {
  return (
    <LeadsContent
      prefilters={["inLimbo"]}
      defaultStageFilters={{
        label: "In Limbo",
        value: "inLimbo",
      }}
      pageTitle="In Limbo Leads"
    />
  );
};

export default InLimboLeads;
