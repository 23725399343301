import { Link } from "react-router-dom";
import formatter from "../../helpers/currencyFormatter";

const columns = [
  {
    name: "Edit",
    sortable: false,
    cell: (row) => {
      return <Link to={`/spreads/${row._id}`}>Edit</Link>;
    },
    width: "70px",
  },
  {
    name: "Bracket Start",
    sortable: true,
    cell: (row) => {
      return `${formatter.format(row.bracketStart)}`;
    },
    width: "180px",
  },
  {
    name: "Bracket End",
    selector: "bracketEnd",
    sortable: true,
    cell: (row) => {
      return `${formatter.format(row.bracketEnd)}`;
    },

    width: "150px",
  },
  {
    name: "Low Percentage",
    sortable: true,
    cell: (row) => {
      return `${(row.lowPercentage * 100).toFixed(2)}%`;
    },
    width: "180px",
  },
  {
    name: "High Percentage",
    sortable: true,
    cell: (row) => {
      return `${(row.highPercentage * 100).toFixed(2)}%`;
    },
    width: "180px",
  },
];

export default columns;
