import React, { Fragment } from "react";

import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import moment from "moment-timezone";

import formatAircraftSelection from "../../helpers/format-aircraft-selection";
import formatStage from "../../helpers/format-stage";
import currencyFormatter from "../../helpers/currencyFormatter";

import { Link } from "react-router-dom";

const getAircraftSelection = (data) => {
  if (data?.aircraftSelection?.length) {
    return data?.aircraftSelection
      .map((aircraft) => formatAircraftSelection(aircraft))
      .join(", ");
  }
  return "N/A";
};

function Row(props) {
  const { row, superAdminOrAd } = props;
  const [open, setOpen] = React.useState(true);

  console.log("ROW", row);

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        onClick={() => setOpen(!open)}
        className="fm-l2-parent-table-row"
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell className="mobile-lead-details-initial-cell">
          <span className="material-icons lead-info-icon">person</span>
          {row.firstName} {row.lastName}
          <br />
          <span className="material-icons lead-info-icon">flight</span>
          {row.itineraryDetails.departureAirportCode} -{" "}
          {row.itineraryDetails.arrivalAirportCode}
          <br />
          <span className="material-icons lead-info-icon">date_range</span>
          {row.itineraryDetails.departureDate}
          {row.tripType === 2
            ? ` - ${row.itineraryDetails.returnDate}`
            : ""}{" "}
          <br />
          <span className="material-icons lead-info-icon">schedule</span>
          {formatStage(row.stage)}
        </TableCell>
        {/* 
        <TableCell>{formatStage(row.stage)}</TableCell>

        <TableCell>{row.assigneeName || "-"}</TableCell>
        <TableCell>
          {row.firstName} {row.lastName}
        </TableCell>
        <TableCell>{row.phone}</TableCell>
        <TableCell>{row.email}</TableCell>

        {row.companyName !== "" && <TableCell>{row.companyName}</TableCell>} */}

        {/* 
        <TableCell>{row.assigneeName}</TableCell>
        <TableCell>{row.assigneeName}</TableCell>
        <TableCell>{row.assigneeName}</TableCell>
 */}
        {/* <TableCell scope="row">
          {row.isCurrentLeg ? "Current Leg" : `Leg ${row.indexText}`}
        </TableCell>
        <TableCell>{row.departureDate}</TableCell>
        <TableCell>{row.departureAirport.airportName}</TableCell>
        <TableCell>{row.arrivalAirport.airportName}</TableCell>
        <TableCell>{row.passengerCount}</TableCell> */}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }} className="fm-l2-child-table-container">
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                className="fm-l2-child-table-heading mb-20 mt-20"
              >
                Trip Request Metadata
              </Typography>

              <p>
                <strong>Created At: </strong>
                {moment(row.createdAt).format("MMM Do YYYY h:mm a")}
              </p>

              <p>
                <strong>Stage: </strong>
                {formatStage(row.stage)}
              </p>

              <p>
                <strong>Assignee: </strong>
                {row.assigneeName || "-"}
              </p>

              <p>
                <strong>Trip Request ID: </strong>
                {row._id}
              </p>

              <hr></hr>

              <Typography
                variant="h5"
                gutterBottom
                component="div"
                className="fm-l2-child-table-heading mb-20 mt-20"
              >
                Quote Details
              </Typography>

              {row.quotes.length > 0 &&
                row.quotes.map((quote) => {
                  return (
                    <p key={`${quote}-p`}>
                      <Link key={`quote${quote}`} to={`/quotes/${quote}`}>
                        View Quote: {quote}
                      </Link>
                    </p>
                  );
                })}

              {!row.quotes.length && (
                <p>
                  <strong>No Quotes Sent.</strong>
                </p>
              )}

              <hr></hr>

              <Typography
                variant="h5"
                gutterBottom
                component="div"
                className="fm-l2-child-table-heading mb-20 mt-20"
              >
                Trip Details
              </Typography>

              <p>
                <strong>Lead ID: </strong>
                {row._id}
              </p>

              <p>
                <strong>Trip Type: </strong>
                {row.tripType === 1 ? "One Way" : "Round Trip"}
              </p>

              <p>
                <strong>Departure Date: </strong>
                {row.itineraryDetails.departureDate}
              </p>

              {row.tripType === 2 && (
                <p>
                  <strong>Return Date: </strong>
                  {row.itineraryDetails.returnDate || "N/A"}
                </p>
              )}

              <p>
                <strong>Departure Airport: </strong>
                {row.itineraryDetails.departureAirportName}
              </p>

              <p>
                <strong>Arrival Airport: </strong>
                {row.itineraryDetails.arrivalAirportName}
              </p>

              <p>
                <strong>Departure Time: </strong>
                {row?.departureTime ? row?.departureTime : "N/A"}
              </p>

              {row.tripType === 2 && (
                <p>
                  <strong>Return Time: </strong>
                  {row?.returnTime ? row?.returnTime : "N/A"}
                </p>
              )}

              <hr></hr>

              <Typography
                variant="h5"
                gutterBottom
                component="div"
                className="fm-l2-child-table-heading mb-20 mt-20"
              >
                Additional Details
              </Typography>

              <p>
                <strong>Passengers: </strong>
                {row.itineraryDetails.passengerCount}
              </p>

              <p>
                <strong>Aircraft Selection: </strong>
                {getAircraftSelection()}
              </p>
              <p>
                <strong>Baggage: </strong>
                {row.baggage.length ? row.baggage.join(", ") : "N/A"}
              </p>
              <p>
                <strong>Pets: </strong>
                {row.pets ? "Yes" : "No"}
              </p>
              <p>
                <strong>Infants: </strong>
                {row.infants ? "Yes" : "No"}
              </p>

              <p>
                <strong>Comments: </strong>
                {row.comments}
              </p>

              <hr></hr>

              <Typography
                variant="h5"
                gutterBottom
                component="div"
                className="fm-l2-child-table-heading mb-20 mt-20"
              >
                Estimate Details
              </Typography>

              {row.tripOptions.map(
                ({ aircraftName, lowSpread, highSpread }) => {
                  return (
                    <p key={`${aircraftName}-p`}>
                      <strong>{aircraftName}: </strong>
                      {currencyFormatter.format(lowSpread)} -{" "}
                      {currencyFormatter.format(highSpread)}
                    </p>
                  );
                }
              )}

              {/* <p>

                <strong>Owner: </strong>
                {row?.originData?.assigneeName || "Not Yet Assigned"}
              </p>

              <p>
                <strong>Leg Origin: </strong>
                {row.isFromATC === true ? (
                  <Link to={`/atc/${row.atcId}`}>View ATC Record</Link>
                ) : (
                  <Link to={`/leads/${row.leadId}`}>View Lead</Link>
                )}
              </p>

              <p>
                <strong>
                  {row.isFromATC
                    ? `ATC Record Created At:`
                    : `Lead Created At: `}
                </strong>
                {moment(row?.originData?.createdAt)
                  .utc()
                  .format("ddd, MMM Do YYYY")}
              </p>

              <p>
                <strong>Stage: </strong>
                {formatStage(row.stage)}
              </p>

              {superAdminOrAd && (
                <Link
                  className="base-button evo-blue mt-20 d-block w-200 centered"
                  to={`/flight-matches/${row._id}`}
                >
                  View All Leg Data
                </Link>
              )} */}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable(props) {
  // const { superAdminOrAd } = props;
  return (
    <TableContainer
      component={Paper}
      className="mt-20 accordion-table-with-details"
    >
      <Table aria-label="collapsible table">
        <TableHead className="fm-l2-parent-table-head">
          <TableRow className="fm-l2-parent-table-head-row">
            <TableCell />
            <TableCell>TRIP REQUEST INFO</TableCell>
            {/* <TableCell>STAGE</TableCell>
            <TableCell>ASSIGNEE</TableCell>
            <TableCell>LEAD NAME</TableCell>
            <TableCell>PHONE NUMBER</TableCell>
            <TableCell>EMAIL</TableCell>

            {props.rows[0].companyName !== "" && <TableCell>COMPANY</TableCell>} */}
          </TableRow>
        </TableHead>
        <TableBody className="fm-l2-parent-table-body">
          {props.rows.map((row) => (
            <Row key={row._id} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
