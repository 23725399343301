import React, { Fragment, useEffect } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import LeadsWidget from "../leads/leads-widget";
import PhoneCallWidget from "../phoneCalls/phoneCall-widget";
import FlightBoardContent from "../flight-board-content";
import AdminWidgets from "../admin-widgets/adminWidgets";

import FlightLegsWidget from "../flightLegs/flightLeg-widget";

const HomeContent = () => {
  const { isAuthenticated, user } = useAuth0();

  const userRoles = user && user["https://app.evojets.com/roles"];

  const salesTeam =
    user &&
    userRoles.some(function (role) {
      return (
        "Sales Assistant" === role ||
        "Account Executive" === role ||
        "Dynamic Sales Assistant" === role
      );
    });

  const accountDirector =
    user &&
    userRoles.some(function (role) {
      return "Account Director" === role;
    });

  const superAdmin =
    user &&
    userRoles.some(function (role) {
      return "Super Admin" === role;
    });

  useEffect(() => {
    document.title = "Home | Flight Deck by evoJets";
  }, []);

  return (
    <>
      {(superAdmin || accountDirector) && <AdminWidgets></AdminWidgets>}
      {/* <FlightBoardContent></FlightBoardContent> */}
      {(salesTeam || accountDirector) && (
        <div id="sales-widgets-container" className="mt-20">
          <LeadsWidget></LeadsWidget>
          <PhoneCallWidget></PhoneCallWidget>
          <FlightLegsWidget></FlightLegsWidget>
        </div>
      )}
    </>
  );
};

export default HomeContent;
