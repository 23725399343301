import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loader from "../components/loader";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";

const AdminOrSalesRoute = ({ component, ...args }) => {
  const { user, getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const userRoles = user && user["https://app.evojets.com/roles"];
  const history = useHistory();

  const checkToken = async () => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect({
        appState: { returnTo: window.location.pathname },
      });
    });
  };

  // useEffect(() => {
  //   console.log("EFFECT RUNNING");
  //   checkToken();
  // });

  const salesTeam =
    user &&
    userRoles.some(function (role) {
      return (
        "Super Admin" === role ||
        "Account Director" === role ||
        "Sales Assistant" === role ||
        "Account Executive" === role ||
        "Dynamic Sales Assistant" === role
      );
    });

  if (salesTeam) {
    return (
      <Route
        component={withAuthenticationRequired(component, {
          onRedirecting: () => <Loader />,
        })}
        {...args}
      />
    );
  }
};

export default AdminOrSalesRoute;
