import React, { Fragment, useEffect, useReducer } from "react";

import Select from "react-select";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";
import AsyncSelect from "react-select/async";
import { useParams, Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import NumberFormat from "react-number-format";
import PhoneInput from "react-phone-number-input";
import { useHistory } from "react-router-dom";
import { Datepicker, momentTimezone } from "@mobiscroll/react";

import ai from "../../helpers/axios";
import timeFormatter from "../../helpers/timeFormatter";

import ProspectsAutocomplete from "../prospects/prospects-autocomplete";
import AircraftAutocomplete from "../aircrafts/aircraft-autocomplete";

import TextInput from "../text-input";
import Loader from "../loader";

import { EditorState, ContentState, convertFromHTML } from "draft-js";

import { stateToHTML } from "draft-js-export-html";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import StateDisplay from "../reusable-modules/state-display";

const toastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

const invoiceTypesOptions = [
  {
    label: "Flight Charges",
    value: "flightCharges",
    notesRequired: true,
  },
  {
    label: "Catering",
    value: "catering",
    notesRequired: false,
  },
  {
    label: "Ground Transportation",
    value: "groundTransportation",
    notesRequired: false,
  },

  {
    label: "Cancellation Fee",
    value: "cancellationFee",
    notesRequired: false,
  },

  {
    label: "Refund",
    value: "refund",
    notesRequired: false,
  },
  {
    label: "Credit Card Processing Fee",
    value: "creditCardProcessingFee",
    notesRequired: false,
  },
  {
    label: "Other",
    value: "other",
    notesRequired: true,
  },
];

const paymentOptions = [
  {
    label: "Credit Card",
    value: "creditCard",
  },
  {
    label: "Wire",
    value: "wire",
  },
  {
    label: "Funds on Account",
    value: "fundsOnAccount",
  },
  {
    label: "Other",
    value: "other",
  },
];

const reducer = (state, action) => {
  switch (action.type) {
    case "addNewLeg": {
      return {
        ...state,
        legs: [
          ...state.legs,
          {
            departureDate: "",
            departureAirport: {
              airportName: "",
              id: "",
              code: "",
              city: "",
              localCode: "",
            },
            arrivalAirport: {
              airportName: "",
              id: "",
              code: "",
              city: "",
              localCode: "",
            },
            pax: "",
            aircraft: {
              name: "",
              seats: 8,
              yom: "",
              opi: "",
              id: "",
            },
          },
        ],
      };
    }
    case "deleteLeg": {
      let newLegsArray = [...state.legs];
      newLegsArray.splice(action.index, 1);
      return {
        ...state,
        legs: newLegsArray,
      };
    }
    case "changeLeg": {
      let newLegsArray = [...state.legs];

      newLegsArray[action?.payload?.legIndex][action?.payload?.field] =
        action?.payload?.value;
      return {
        ...state,
        legs: newLegsArray,
      };
    }
    case "addNewInvoiceDetail": {
      return {
        ...state,
        legs: action.payload?.legs || state.legs,
        invoiceDetails: [
          ...state.invoiceDetails,
          {
            index: action.payload?.index || 0,
            typeLabel: action.payload?.typeLabel || { label: "", value: "" },
            description: action.payload?.description || "",
            amount: action.payload?.amount || "",
          },
        ],
      };
    }
    case "deleteInvoiceDetail": {
      let newDetailsArray = [...state.invoiceDetails];
      newDetailsArray.splice(action.index, 1);
      return {
        ...state,
        invoiceDetails: newDetailsArray,
      };
    }
    case "changeInvoiceDetailField": {
      let invoiceDetailsArray = [...state.invoiceDetails];
      invoiceDetailsArray[action?.payload?.invoiceDetailIndex][
        action?.payload?.field
      ] = action?.payload?.value;
      return {
        ...state,
        invoiceDetails: invoiceDetailsArray,
      };
    }
    case "addNewPaymentDetail": {
      return {
        ...state,
        paymentDetails: [
          ...state.paymentDetails,
          {
            date: "",
            typeLabel: { label: "", value: "" },
            notes: "",
            amount: "",
          },
        ],
      };
    }
    case "changePaymentDetailField": {
      let newPaymentDetailsArray = [...state.paymentDetails];
      newPaymentDetailsArray[action?.payload?.paymentDetailIndex][
        action?.payload?.field
      ] = action?.payload?.value;
      return {
        ...state,
        paymentDetails: newPaymentDetailsArray,
      };
    }
    case "deletePaymentDetail": {
      let newPaymentDetailsArray = [...state.paymentDetails];
      newPaymentDetailsArray.splice(action.index, 1);

      return {
        ...state,
        paymentDetails: newPaymentDetailsArray,
      };
    }
    case "beginDataDownload": {
      return {
        ...state,
        loading: true,
      };
    }
    case "endDataDownload": {
      return {
        ...state,
        data: action?.payload?.data,
        loading: false,
        dataDownloadedTimestamp: Date.now(),
        operatorFinancialRecords:
          action?.payload?.data?.operatorFinancialRecords,
      };
    }
    case "fieldChanged": {
      return {
        ...state,
        [action.field]: action.value,
      };
    }
    default:
      return state;
  }
};

let invoiceNotesHTML = ` <h3>Please Note:</h3>
<p>Payment terms (deposits, schedules, methods, etc) related to this invoice may apply, and will be communicated elsewhere in writing by evoJets.</p>

<p>Please contact your evoJets representative with any questions.</p>

<p>Thank you for flying with evoJets!</p>`;

const invoiceNotesConverted = convertFromHTML(invoiceNotesHTML);

const defaultInvoiceNotesEditorState = EditorState.createWithContent(
  ContentState.createFromBlockArray(
    invoiceNotesConverted.contentBlocks,
    invoiceNotesConverted.entityMap
  )
);

const NewInvoiceContent = (props) => {
  const { tripId, invoiceId } = useParams();
  const history = useHistory();

  const { getAccessTokenSilently, loginWithRedirect, user } = useAuth0();

  const handleInvoiceSubmit = async (e) => {
    e.preventDefault();

    const token = await getAccessTokenSilently().catch((e) => {
      return loginWithRedirect();
    });
    let stopEarly = false;
    let invoiceDetails =
      state?.invoiceDetails
        ?.map((detail) => {
          const matchingOption = invoiceTypesOptions.find(
            (option) => option.value === detail.typeLabel?.value
          );

          // Check if notes are required and if description is blank
          if (matchingOption?.notesRequired && !detail.description?.trim()) {
            toast.error(
              `Please enter a description for all "Flight Charges" and "Other" line items under Invoice Details`,
              toastOptions
            );
            stopEarly = true;
          }

          return {
            typeLabel: detail.typeLabel?.value,
            description: detail.description || " ", // Ensures description is never empty
            amount: detail.amount,
          };
        })
        .filter((detail) => detail.typeLabel && detail.amount) || [];

    if (stopEarly) {
      return;
    }

    let invoiceNotes = stateToHTML(
      state.invoiceNotesEditor.getCurrentContent()
    );

    const invoiceData = {
      signerName: state.signerName,
      signerEmail: state.signerEmail,
      signerPhone: state.signerPhone,
      signerPhoneCountry: state.signerPhoneCountry,
      companyName: state.companyName,
      tripId: tripId,
      clientId: state?.selectedClient?.value,
      // paymentDueDate: state.paymentDueDate,

      invoiceDetails,
      paymentDetails:
        state?.paymentDetails
          ?.map((detail) => ({
            date: detail.date,
            typeLabel: detail.typeLabel?.value,
            notes: detail.notes,
            amount: detail.amount,
          }))
          .filter(
            (detail) => detail.date && detail.amount && detail.typeLabel
          ) || [],

      // TODO: Add all legs when we will have airports data from the API
      tripSummary:
        state.legs.map((leg) => ({
          departureAirport: leg?.departureAirport || "",
          arrivalAirport: leg?.arrivalAirport || "",
          date: leg.departureDate,
          ete: leg?.ete || "",
          pax: leg.pax,
          aircraft: {
            id: leg.aircraft.value,
            name: leg.aircraft.label,
          },
          distance: leg.distance,
        })) || [],
      notes: invoiceNotes,
    };

    let response;

    if (!invoiceId || invoiceId === "new") {
      if (tripId) {
        response = await ai
          .auth(token)
          .post(`/api/invoices/create/`, invoiceData)
          .catch((error) => {
            console.error(error);
            Sentry.captureException(error);
          });
      }
    } else {
      response = await ai
        .auth(token)
        .put(`/api/invoices/${invoiceId}`, invoiceData)
        .catch((error) => {
          console.error(error);
          Sentry.captureException(error);
        });
    }

    if (!!response && response.data) {
      setTimeout(() => {
        toast.success("Invoice saved successfully!", toastOptions);
      }, 0);
    }

    let path = `/trips/${tripId}/invoices/${response.data._id}/preview`;
    if (e.target.id === "view-trip") {
      path = `/trips/${tripId}/`;
    }

    history.push(path);
  };

  const handleClientOptionCreation = (inputValue) => {
    dispatch({
      type: "fieldChanged",
      field: "createProspectModalIsOpen",
      value: true,
    });
  };

  const handleClientChange = (selectedClient) => {
    if (selectedClient) {
      selectedClient._id = selectedClient?.value;

      // Dispatch the selected client
      dispatch({
        type: "fieldChanged",
        field: "selectedClient",
        value: selectedClient,
      });

      // Dispatch signerPhone (use the first phone number in the phoneNumbers array if it exists, otherwise fallback to phone)
      const signerPhone =
        selectedClient.phoneNumbers?.[0]?.number || selectedClient.phone;
      dispatch({
        type: "fieldChanged",
        field: "signerPhone",
        value: signerPhone,
      });

      // Dispatch signerName (combine first and last name)
      const signerName = `${selectedClient.firstName} ${selectedClient.lastName}`;
      dispatch({
        type: "fieldChanged",
        field: "signerName",
        value: signerName,
      });

      // Dispatch signerPhoneCountry (use the first phone number's country code if it exists, otherwise fallback to countryCode)
      const signerPhoneCountry =
        selectedClient.phoneNumbers?.[0]?.countryCode ||
        selectedClient.countryCode;
      dispatch({
        type: "fieldChanged",
        field: "signerPhoneCountry",
        value: signerPhoneCountry,
      });

      // Dispatch signerEmail (use the email from selectedClient)
      dispatch({
        type: "fieldChanged",
        field: "signerEmail",
        value: selectedClient.email,
      });

      // Dispatch companyName if it exists

      dispatch({
        type: "fieldChanged",
        field: "companyName",
        value: selectedClient.companyName || "",
      });

      console.log("SELECTED CLIENT", selectedClient);
    } else {
      dispatch({
        type: "fieldChanged",
        field: "selectedClient",
        value: selectedClient,
      });
    }
  };

  const handleTextInputChange = (e) => {
    return dispatch({
      type: "fieldChanged",
      field: e.target.name,
      value: e.target.value,
    });
  };

  const handleAddLeg = (event) => {
    event.preventDefault();

    dispatch({ type: "addNewLeg" });
  };

  const handleAddDetail = (event) => {
    event.preventDefault();
    dispatch({ type: "addNewInvoiceDetail" });
  };

  const handleAddPaymentDetail = (event) => {
    event.preventDefault();

    dispatch({ type: "addNewPaymentDetail" });
  };

  const handleAircraftChange = async (legIndex, updatedAircraft, action) => {
    let legData = { ...state.legs[legIndex] };

    console.log("LEG INDex", legIndex, "AIRCRAFT", updatedAircraft);

    let processedAircraft = {
      id: updatedAircraft.value,
      name: updatedAircraft.label,
      value: updatedAircraft.value,
      label: updatedAircraft.label,
    };

    legData.aircraft = processedAircraft;

    dispatch({
      type: "changeLeg",
      payload: {
        field: "aircraft",
        legIndex: legIndex,
        value: processedAircraft,
      },
    });

    //need to recalculate flight times

    await handleFlightTimeCalculationByIndex(legData, legIndex);
  };

  const handleETEChange = async (legIndex, updatedETE) => {
    let leg = { ...state.legs[legIndex] };

    let pattern = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;

    const isValid = pattern.test(updatedETE);

    if (!isValid) {
      dispatch({
        type: "changeLeg",
        payload: {
          legIndex: legIndex,
          field: "ete",
          value: "",
        },
      });

      return toast.error(
        `Please enter a valid ETE for Leg ${legIndex + 1}`,
        toastOptions
      );
    }

    // if (!state.legs[legIndex]?.etd) {
    //   return toast.error(
    //     `Please enter a valid ETD for Leg ${
    //       legIndex + 1
    //     } to recalculate the ETA.`,
    //     toastOptions
    //   );
    // }

    // let etaResponse = await calculateETAForLeg(
    //   updatedETE,
    //   state.legs[legIndex]?.etd,
    //   leg
    // );

    // dispatch({
    //   type: "changeLeg",
    //   payload: {
    //     field: "eta",
    //     value: etaResponse.data?.eta,
    //     legIndex,
    //   },
    // });
  };

  const handleFlightTimeCalculationMulti = async (legData) => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let params = {
      legData: legData.map((leg, index) => {
        return {
          arrivalAirportId: leg.arrivalAirport.id,
          departureAirportId: leg.departureAirport.id,
          aircraftId: leg.aircraft.id,
          legIndex: index,
        };
      }),
    };

    let response = await ai
      .auth(token)
      .post(`/api/trips/flightTimesMulti`, params)
      .catch((err) => {
        console.error(err.response.data);
        toast.error(
          "Please fill out all required fields: ensure an aircraft is selected for this Aircraft Option, and departure and arrival airports for all legs.",
          toastOptions
        );
        return;
      });

    if (response && response?.data && response?.data?.length) {
      // dispatch({
      //   type: "changeLeg",
      //   payload: {
      //     legIndex,
      //     field: "ete",
      //     value: composedString,
      //   },
      // });

      // handleETEChange(legIndex, composedString);

      let distanceResponse = response.data.map((leg) => {
        return {
          distance: leg.value.distance,
        };
      });

      console.log(`flightTime response`, response.data, distanceResponse);
      return distanceResponse;

      // return toast.success(`ETE and ETA for Leg ${legIndex + 1} were updated.`);
    }
  };

  const handleFlightTimeCalculationByIndex = async (legData, legIndex) => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    console.log("FLIGHT TIMES BY INDEX RUNNING");

    console.log(`LEGDATA`, legData);

    if (
      !legData?.arrivalAirport?.id ||
      !legData?.departureAirport?.id ||
      !legData?.aircraft?.id
    ) {
      return;
    }

    // return

    let params = {
      legData: {
        arrivalAirportId: legData.arrivalAirport.id,
        departureAirportId: legData.departureAirport.id,
        legIndex: legIndex,
        aircraftId: legData.aircraft.id,
      },
    };

    let response = await ai
      .auth(token)
      .post(`/api/trips/flightTimes`, params)
      .catch((err) => {
        console.error(err.response.data);
        toast.error(
          "Please fill out all required fields: ensure an aircraft is selected for this Aircraft Option, and departure and arrival airports for all legs.",
          toastOptions
        );
        return;
      });

    if (
      response &&
      response?.data &&
      response?.data?.length &&
      response?.data[0]?.value
    ) {
      let returnedValueForLeg = response?.data[0]?.value;

      let composedString = "";

      if (returnedValueForLeg?.timeWithWindAdjustment) {
        composedString = timeFormatter(
          returnedValueForLeg?.timeWithWindAdjustment
        );
      }

      dispatch({
        type: "changeLeg",
        payload: {
          legIndex,
          field: "ete",
          value: composedString,
        },
      });

      dispatch({
        type: "changeLeg",
        payload: {
          legIndex,
          field: "distance",
          value: returnedValueForLeg.distance,
        },
      });

      // handleETEChange(legIndex, composedString);

      console.log(`flightTime response`, response.data);
      return response.data;

      // return toast.success(`ETE and ETA for Leg ${legIndex + 1} were updated.`);
    }
  };

  const handleAirportChange = async (legIndex, airport, airportIndex) => {
    let legData = { ...state.legs[legIndex] };

    if (airportIndex === 0) {
      legData.departureAirport = {
        id: airport.value,
        name: airport.label,
      };

      let departureTimeZone = null;
      if (airport.tz && airport.tz.length) {
        departureTimeZone = airport.tz[0];
      }

      if (departureTimeZone?.length) {
        dispatch({
          type: "changeLeg",
          payload: {
            legIndex: legIndex,
            field: "departureTimeZone",
            value: departureTimeZone,
          },
        });
      }
    }

    if (airportIndex === 1) {
      legData.arrivalAirport = {
        id: airport.value,
        name: airport.label,
      };

      let arrivalTimeZone = null;

      if (airport.tz && airport.tz.length) {
        arrivalTimeZone = airport.tz[0];
      }

      if (arrivalTimeZone?.length) {
        dispatch({
          type: "changeLeg",
          payload: {
            legIndex: legIndex,
            field: "arrivalTimeZone",
            value: arrivalTimeZone,
          },
        });
      }
    }

    await handleFlightTimeCalculationByIndex(legData, legIndex);
  };

  const loadAirportOptions = async (inputValue) => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });
    let { data } = await ai
      .auth(token)
      .get(`/api/airports/search?q=${inputValue}&includeTZ=true`);

    let options = [];
    if (data.length >= 1) {
      options = data.map((i) => ({
        value: i._id,
        label: i.airportName,
        code: i.code,
        city: i.city,
        localCode: i.localCode,
        lat: i.lat,
        lon: i.lon,
        tz: i.tz,
      }));
    }

    return options;
  };

  const calculateETAForLeg = async (ete, etd, leg) => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let etaFactors = {
      ete,
      etd,
      leg,
    };

    //re-calculate ETA

    let response = await ai
      .auth(token)
      .post(`/api/trips/calculateETAOneLeg`, etaFactors)
      .catch((err) => {
        console.error(err.response.data);
        return;
      });

    return response;
  };

  // const handleETDChange = async (legIndex, updatedETD) => {
  //   let leg = { ...state.legs[legIndex] };

  //   let pattern = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;

  //   const isValid = pattern.test(leg.ete);

  //   if (!isValid && leg.ete !== "" && leg.ete !== null) {
  //     dispatch({
  //       type: "changeLeg",
  //       payload: {
  //         legIndex: legIndex,
  //         field: "ete",
  //         value: "",
  //       },
  //     });

  //     return toast.error(
  //       `Please enter a valid ETE for Leg ${
  //         legIndex + 1
  //       } to recalculate the ETA.`,
  //       toastOptions
  //     );
  //   }

  //   let etaResponse = await calculateETAForLeg(leg.ete, updatedETD, leg);
  //   if (!etaResponse.data) {
  //     return;
  //   }

  //   if (etaResponse.data?.eta) {
  //     dispatch({
  //       type: "changeLeg",
  //       payload: {
  //         field: "eta",
  //         value: etaResponse?.data?.eta,
  //         legIndex,
  //       },
  //     });
  //   }

  //   if (etaResponse.data?.departureTimeZone) {
  //     dispatch({
  //       type: "changeLeg",
  //       payload: {
  //         field: "departureTimeZone",
  //         value: etaResponse?.data?.departureTimeZone,
  //         legIndex,
  //       },
  //     });
  //   }

  //   if (etaResponse.data?.arrivalTimeZone) {
  //     dispatch({
  //       type: "changeLeg",
  //       payload: {
  //         field: "arrivalTimeZone",
  //         value: etaResponse?.data?.arrivalTimeZone,
  //         legIndex,
  //       },
  //     });
  //   }
  // };

  const getTotals = () => {
    let totalCharges = 0;
    let totalPayments = 0;
    let balanceDue = 0;

    state.invoiceDetails?.forEach((detail) => {
      console.log("INVOICE LINE ITEMS", detail);
      const amount = parseFloat(detail.amount);
      // Subtract refund amounts from totalCharges
      if (detail?.typeLabel?.value === "refund") {
        totalCharges -= amount; // Refunds reduce totalCharges
      } else {
        totalCharges += amount;
      }
    });

    state.paymentDetails?.forEach((detail) => {
      totalPayments += parseFloat(detail.amount);
    });

    balanceDue = totalCharges - totalPayments;

    return {
      totalCharges,
      totalPayments,
      balanceDue,
    };
  };

  let initialState = {
    modalPhoneNumber: "",
    selectedClient: null,
    createProspectModalIsOpen: false,
    createAuthorizedSignerModalIsOpen: false,
    routingProcessed: false,
    // paymentDueDate: "",
    prospectOptions: [],
    loading: true,
    useDifferentAuthorizedSigner: false,
    companyName: "",
    signerName: "",
    companyName: "",

    signerEmail: "",
    signerPhone: "",
    signerPhoneCountry: "US",

    authorizedSigners: [],

    paymentType: "",
    flightCharges: "",
    FET: "",
    ccFee: "",
    segmentFees: "",
    catering: "",
    groundTransportation: "",
    otherExpense: "",
    paymentApplied: "",
    discount: "",
    totalCharges: "",

    email: "",
    emailInputValue: "",
    subject: "",

    cc: [],
    ccInputValue: "",
    legs: [],
    invoiceDetails: [],
    paymentDetails: [],
    data: null,
    dataDownloadedTimestamp: null,
    invoiceNotesEditor: defaultInvoiceNotesEditorState,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  //extract logic out to two functions

  const prepareEditForm = (selectedInvoice) => {
    // Parse the client data and pre-fill the fields
    if (selectedInvoice?.clientData) {
      parseClientData(selectedInvoice.clientData);
    }

    // Pre-fill invoice details
    dispatch({
      type: "fieldChanged",
      field: "invoiceDetails",
      value: selectedInvoice?.invoiceDetails?.map((detail) => ({
        ...detail,
        typeLabel: invoiceTypesOptions.find(
          (option) => option.value === detail.typeLabel
        ),
      })),
    });

    // Pre-fill trip summary
    if (selectedInvoice?.tripSummary?.length) {
      dispatch({
        type: "fieldChanged",
        field: "legs",
        value: selectedInvoice.tripSummary.map((summary) => ({
          ...summary,
          pax: summary.pax, // Ensure PAX is pre-filled
          aircraft: {
            name: summary.aircraft?.name || "",
            id: summary.aircraft?.id || "",
            value: summary.aircraft?.id || "",
            label: summary.aircraft?.name || "",
          },
          departureDate: summary.date,
          departureAirport: {
            airportName: summary.departureAirport?.airportName || "",
            id: summary.departureAirport?.id || "",
            code: summary.departureAirport?.code || "",
            city: summary.departureAirport?.city || "",
            localCode: summary.departureAirport?.localCode || "",
          },
          arrivalAirport: {
            airportName: summary.arrivalAirport?.airportName || "",
            id: summary.arrivalAirport?.id || "",
            code: summary.arrivalAirport?.code || "",
            city: summary.arrivalAirport?.city || "",
            localCode: summary.arrivalAirport?.localCode || "",
          },
        })),
      });
    }

    // Pre-fill payment details
    dispatch({
      type: "fieldChanged",
      field: "paymentDetails",
      value: selectedInvoice?.paymentDetails?.map((payment) => {
        const matchedTypeLabel = paymentOptions.find((option) => {
          return option.value.toLowerCase() === payment.typeLabel.toLowerCase();
        });

        console.log(
          "Payment typeLabel:",
          payment.typeLabel,
          "Matched Option:",
          matchedTypeLabel
        );

        return {
          ...payment,
          typeLabel: matchedTypeLabel || null, // Set to null if not matched to avoid issues
        };
      }),
    });
    // Handle signer details if not already set
    if (!state.signerName && selectedInvoice.signerName) {
      dispatch({
        type: "fieldChanged",
        field: "signerName",
        value: selectedInvoice.signerName,
      });
    }

    if (!state.signerEmail && selectedInvoice.signerEmail) {
      dispatch({
        type: "fieldChanged",
        field: "signerEmail",
        value: selectedInvoice.signerEmail,
      });
    }

    if (!state.signerPhone && selectedInvoice.signerPhone) {
      dispatch({
        type: "fieldChanged",
        field: "signerPhone",
        value: selectedInvoice.signerPhone,
      });
    }

    // If there is a company name in the invoice data, pre-fill it
    if (selectedInvoice.companyName) {
      dispatch({
        type: "fieldChanged",
        field: "companyName",
        value: selectedInvoice.companyName,
      });
    }

    if (selectedInvoice?.notes) {
      let invoiceNotes = convertFromHTML(selectedInvoice.notes);

      let invoiceNotesEditorState = EditorState.createWithContent(
        ContentState.createFromBlockArray(
          invoiceNotes.contentBlocks,
          invoiceNotes.entityMap
        )
      );

      dispatch({
        type: "fieldChanged",
        field: "invoiceNotesEditor",
        value: invoiceNotesEditorState,
      });
    }

    if (selectedInvoice.clientData && selectedInvoice?.clientData?.length) {
      let i = selectedInvoice.clientData[0];
      let label = `${i?.firstAndLastName} - ${i.emails[0]}`;

      if (!i?.firstAndLastName) {
        if (!i.firstName) {
          i.firstName = "";
        }

        if (!i.lastName) {
          i.lastName = "";
        }

        label = `${i.firstName} ${i.lastName} - ${i.emails[0]}`;
      }

      if (i.companyName) {
        label = `${label} - ${i.companyName}`;
      }

      if (i.assigneeName && i.assigneeId) {
        label = `${label} - ${i.assigneeName}`;
      }

      i.label = label;

      dispatch({
        type: "fieldChanged",
        field: "selectedClient",
        value: i,
      });
    }

    // If the payment due date exists, pre-fill it
    // if (selectedInvoice.paymentDueDate) {
    //   dispatch({
    //     type: "fieldChanged",
    //     field: "paymentDueDate",
    //     value: selectedInvoice.paymentDueDate,
    //   });
    // }
  };

  const prepareNewInvoice = async (tripDetails) => {
    //get all signed contracts
    const signedContracts = tripDetails?.data?.contractData?.filter(
      (contract) => contract.stage === "signed"
    );

    let firstLegAircraftName,
      flightCharges,
      firstLegAircraftId = null;

    //create invoice details array to seed flight charges correctly
    const invoiceDetailsArray = signedContracts.reduce((acc, contract) => {
      // Check if legs array exists and has at least one leg, and if flightCharges is defined
      if (
        contract.legs &&
        contract.legs.length > 0 &&
        contract.legs[0].aircraft &&
        contract.pricing.flightCharges !== undefined
      ) {
        firstLegAircraftName = contract.legs[0].aircraft.name;
        firstLegAircraftId = contract.legs[0].aircraft.id;
        flightCharges = contract.pricing.flightCharges;

        acc.push({
          typeLabel: {
            label: "Flight Charges",
            value: "flightCharges",
          },
          description: firstLegAircraftName,
          amount: flightCharges,
        });
      }

      return acc;
    }, []);

    //update invoice details in state
    dispatch({
      type: "fieldChanged",
      field: "invoiceDetails",
      value: invoiceDetailsArray,
    });

    //handle client payment history

    const paymentHistoryArray = tripDetails?.data?.clientPaymentHistory?.map(
      (payment) => {
        return {
          date: payment.paymentDate,
          amount: payment.amount,
          typeLabel: {
            label: payment.paymentType,
            value: payment.paymentType,
          },
          notes: payment.notes,
        };
      }
    );

    //update payment details  in state
    dispatch({
      type: "fieldChanged",
      field: "paymentDetails",
      value: paymentHistoryArray,
    });

    if (tripDetails) {
      dispatch({
        type: "endDataDownload",
        payload: {
          data: tripDetails.data,
        },
      });

      let processedLegs = tripDetails.data.contractData[0].legs;

      if (processedLegs.length) {
        if (firstLegAircraftId && firstLegAircraftName) {
          processedLegs = processedLegs.map((leg) => {
            return {
              ...leg,
              aircraft: {
                name: firstLegAircraftName,
                id: firstLegAircraftId,
                value: firstLegAircraftId,
                label: firstLegAircraftName,
              },
              pax: leg.passengers || leg.pax,
            };
          });
        }

        let distanceResponse = await handleFlightTimeCalculationMulti(
          processedLegs
        );

        // Add distance values to the processed legs
        processedLegs = processedLegs.map((leg, index) => {
          return {
            ...leg,
            distance: distanceResponse[index]?.distance || 0, // Ensure distance is directly assigned
          };
        });

        console.log("PROCESSED LEGS", processedLegs);
      }

      dispatch({
        type: "fieldChanged",
        field: "legs",
        value: processedLegs,
      });

      parseClientData(tripDetails.data?.clientData[0]);
    }

    console.log("TRIP DATA", tripDetails.data);
  };

  const prepareTripAndInvoiceData = async ({ tripId, invoiceId }) => {
    dispatch({ type: "beginDataDownload" });

    const token = await getAccessTokenSilently().catch((e) => {
      return loginWithRedirect();
    });

    const tripDetails = await ai
      .auth(token)
      .get(`/api/trips/${tripId}`)
      .catch((error) => {
        setTimeout(() => {
          toast.error("Something went wrong! Please try again later!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }, 50);
      });

    console.log("TRIP DATA", tripDetails.data);

    if (!invoiceId || invoiceId === "new") {
      //if the invoice is new

      prepareNewInvoice(tripDetails);
    }

    let selectedInvoice;
    if (invoiceId && invoiceId !== "new") {
      //existing invoice, get only the current invoice by ID
      let invoiceResponse = await ai
        .auth(token)
        .get(`/api/invoices/${invoiceId}`);

      if (
        invoiceResponse &&
        invoiceResponse.data &&
        invoiceResponse.data.invoice
      ) {
        selectedInvoice = invoiceResponse.data.invoice;
      }

      console.log("SELECTED INVOICE", selectedInvoice);
    }

    if (selectedInvoice) {
      // Invoice is not new, so we prepare the form with the existing data
      prepareEditForm(selectedInvoice);

      if (tripDetails) {
        dispatch({
          type: "endDataDownload",
          payload: {
            data: tripDetails.data,
          },
        });
      }
    }
  };

  const parseClientData = (clientData) => {
    if (!clientData) {
      return false;
    }

    if (!clientData?._id || !clientData?.firstName || !clientData?.lastName) {
      //no contact info
      return false;
    }

    if (clientData?.isBusiness && !clientData?.companyName) {
      //client is business but missing company name
      return false;
    }

    let label = `${clientData.firstName} ${clientData?.lastName}`;
    let value = clientData?._id;

    if (clientData?.isBusiness) {
      label = clientData.companyName;

      dispatch({
        type: "fieldChanged",
        field: "companyName",
        value: clientData.companyName,
      });
    }

    if (clientData?.firstName && clientData?.lastName) {
      dispatch({
        type: "fieldChanged",
        field: "signerName",
        value: `${clientData.firstName} ${clientData?.lastName}`,
      });
    }

    if (clientData?.phoneNumbers?.length) {
      if (
        clientData?.phoneNumbers[0]?.number &&
        clientData?.phoneNumbers[0]?.countryCode
      ) {
        dispatch({
          type: "fieldChanged",
          field: "signerPhone",
          value: clientData?.phoneNumbers[0]?.number,
        });
        dispatch({
          type: "fieldChanged",
          field: "signerPhoneCountry",
          value: clientData?.phoneNumbers[0]?.countryCode,
        });
      }
    } else if (clientData?.workPhones?.length) {
      dispatch({
        type: "fieldChanged",
        field: "signerPhone",
        value: clientData?.workPhones[0],
      });
    } else if (clientData?.mobilePhones?.length) {
      dispatch({
        type: "fieldChanged",
        field: "signerPhone",
        value: clientData?.mobilePhones[0],
      });
    }

    parseAuthorizedSigners(clientData);

    if (clientData?.emails?.length) {
      dispatch({
        type: "fieldChanged",
        field: "email",
        value: clientData.emails[0],
      });

      dispatch({
        type: "fieldChanged",
        field: "signerEmail",
        value: clientData.emails[0],
      });
    }

    dispatch({
      type: "fieldChanged",
      field: "selectedClient",
      value: {
        label,
        value,
        ...clientData,
      },
    });
  };

  const parseAuthorizedSigners = (clientData) => {
    if (!clientData?.additionalUsers?.length) {
      return false;
    }

    let authorizedSigners = clientData.additionalUsers.flatMap((item) => {
      if (!item?.isAuthorizedSigner) {
        return [];
      }
      let label = `${item.firstName} ${item.lastName} - ${item.relationship}`;
      let value = item?._id;
      return {
        ...item,
        label,
        value,
      };
    });

    dispatch({
      type: "fieldChanged",
      field: "authorizedSigners",
      value: authorizedSigners,
    });
  };

  useEffect(() => {
    prepareTripAndInvoiceData({
      invoiceId,
      tripId,
    });

    document.title = "Trip Invoice | Flight Deck";

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceId, tripId]);

  if (state.loading) {
    return <Loader />;
  }

  return (
    <Fragment>
      <Link
        to={`/trips/${tripId}`}
        className="d-inline-block mb-0 mt-20 evo-blue base-button"
      >
        Back to Trip
      </Link>
      <h2 className="base-heading mt-0" id="page-heading">
        {invoiceId && invoiceId !== "new" ? `Edit Invoice` : `New Invoice`}
      </h2>

      <form
        id="new-invoice"
        onSubmit={handleInvoiceSubmit}
        className="base-form"
      >
        <ProspectsAutocomplete
          onCreateOption={handleClientOptionCreation}
          handleProspectChange={handleClientChange}
          prospectOptions={[]}
          parentDispatch={dispatch}
          selectedProspect={state.selectedClient}
          label="Client"
          placeholder="Select a Client"
          required
        />

        <div className="flex-row column-gap-1pct four-columns">
          <TextInput
            name="signerName"
            label="Signer Name"
            handleChange={handleTextInputChange}
            value={state.signerName}
          ></TextInput>

          <TextInput
            name="signerEmail"
            label="Signer Email"
            handleChange={handleTextInputChange}
            value={state.signerEmail}
            type={`email`}
          ></TextInput>

          <TextInput
            name="companyName"
            label="On Behalf Of"
            handleChange={handleTextInputChange}
            value={state.companyName}
            className={"companyName"}
          ></TextInput>

          <div className="form-group ml-0" key={`signerPhone`}>
            <label className="phoneInputLabel" htmlFor="phone">
              Phone Number
            </label>

            <PhoneInput
              placeholder="Phone Number"
              value={state?.signerPhone}
              name={"signerPhone"}
              onChange={(value) => {
                dispatch({
                  type: "fieldChanged",
                  field: "signerPhone",
                  value: value,
                });
              }}
              onCountryChange={(value) => {
                dispatch({
                  type: "fieldChanged",
                  field: "signerPhoneCountry",
                  value: value || "",
                });
              }}
              defaultCountry={state?.signerPhoneCountry}
              className="mb-0 base-phone-input"
            />
          </div>
        </div>

        <div className="flight-details-container">
          <h3>Trip Summary</h3>
          {state?.legs?.length > 0 &&
            state?.legs?.map((leg, index) => (
              <div className="flight-details-block" key={index}>
                <div className="flex-row column-gap-1pct">
                  <div className="datepickerContainer mb-0">
                    <label
                      htmlFor={`departureDate[${index}]`}
                      className="base-label"
                    >
                      Date <span className="required">*</span>
                    </label>

                    <Datepicker
                      value={leg.departureDate}
                      onChange={(event) => {
                        dispatch({
                          type: "changeLeg",
                          payload: {
                            legIndex: index,
                            field: "departureDate",
                            value: event.value,
                          },
                        });
                      }}
                      dataTimezone="utc"
                      displayTimezone="local"
                      timezonePlugin={momentTimezone}
                      controls={["date"]}
                    />
                  </div>

                  <div className="airportContainer flex-grow-1 mb-0">
                    <label
                      htmlFor={`departureAirport[${index}]`}
                      className="base-label"
                    >
                      Depart <span className="required">* </span>
                      {leg.departureTimeZone ? (
                        <>({leg.departureTimeZone})</>
                      ) : null}
                    </label>

                    <AsyncSelect
                      key={`departure-${leg.departureAirport?.airportName}`}
                      name={`departureAirport[${index}]`}
                      className="departure-airport-select mb-0"
                      classNamePrefix="select"
                      cacheOptions
                      loadOptions={loadAirportOptions}
                      placeholder="Search Airports"
                      defaultValue={{
                        label: leg.departureAirport.airportName,
                        value: leg.departureAirport.id,
                      }}
                      onChange={(selectedAirport) => {
                        handleAirportChange(index, selectedAirport, 0);

                        dispatch({
                          type: "changeLeg",
                          payload: {
                            legIndex: index,
                            field: "departureAirport",
                            value: {
                              airportName: selectedAirport?.label || "",
                              id: selectedAirport?.value || "",
                              code: selectedAirport?.code || "",
                              city: selectedAirport?.city || "",
                              localCode: selectedAirport?.localCode || "",
                            },
                          },
                        });
                      }}
                    ></AsyncSelect>
                  </div>
                  <div className="airportContainer flex-grow-1">
                    <label
                      htmlFor={`arrivalAirport[${index}]`}
                      className="base-label"
                    >
                      Arrive <span className="required">* </span>
                      {leg.arrivalTimeZone ? (
                        <>({leg.arrivalTimeZone})</>
                      ) : null}
                    </label>

                    <AsyncSelect
                      key={`arrival-${leg.arrivalAirport.airportName}`}
                      name={`arrivalAirport[${index}]`}
                      className="arrival-airport-select mb-0"
                      classNamePrefix="select"
                      cacheOptions
                      loadOptions={loadAirportOptions}
                      placeholder="Search Airports"
                      defaultValue={{
                        label: leg.arrivalAirport.airportName,
                        value: leg.arrivalAirport.id,
                      }}
                      onChange={(selectedAirport) => {
                        handleAirportChange(index, selectedAirport, 1);

                        dispatch({
                          type: "changeLeg",
                          payload: {
                            legIndex: index,
                            field: "arrivalAirport",
                            value: {
                              airportName: selectedAirport?.label || "",
                              id: selectedAirport?.value || "",
                              code: selectedAirport?.code || "",
                              city: selectedAirport?.city || "",
                              localCode: selectedAirport?.localCode || "",
                            },
                          },
                        });
                      }}
                    ></AsyncSelect>
                  </div>
                </div>

                <div className="flex-row column-gap-1pct mt-20">
                  <TextInput
                    type={"number"}
                    name={`pax[${index}]`}
                    label="PAX"
                    handleChange={(e) => {
                      dispatch({
                        type: "changeLeg",
                        payload: {
                          legIndex: index,
                          field: "pax",
                          value: parseInt(e.target.value) || "",
                        },
                      });
                    }}
                    className="base-input"
                    containerClasses="mb-0"
                    value={leg.pax}
                    required
                  ></TextInput>

                  <AircraftAutocomplete
                    handleChange={(updatedAircraft, action) => {
                      handleAircraftChange(index, updatedAircraft, action);
                    }}
                    defaultAircraftOptions={state.aircraftOptions}
                    parentDispatch={dispatch}
                    selectedAircraft={leg.aircraft}
                    required
                    containerClasses="fb-50"
                  ></AircraftAutocomplete>

                  <TextInput
                    // type={"time"}
                    name={`ete[${index}]`}
                    label="ETE"
                    handleChange={(e) => {
                      dispatch({
                        type: "changeLeg",
                        payload: {
                          legIndex: index,
                          field: "ete",
                          value: e.target.value || "",
                        },
                      });
                    }}
                    handleBlur={(e) => {
                      handleETEChange(index, e.target.value);
                    }}
                    className="base-input"
                    containerClasses="flex-half mb-0"
                    value={leg.ete}
                    required
                  ></TextInput>
                </div>

                {index >= 1 && (
                  <button
                    className="deleteLeg action-button base-gunmetal-button"
                    onClick={(e) => {
                      e.preventDefault();
                      return dispatch({ type: "deleteLeg", index: index });
                    }}
                    type="button"
                  >
                    Delete Leg {index + 1}
                  </button>
                )}
              </div>
            ))}
          <button
            className="action-button base-blue-button mt-0"
            onClick={handleAddLeg}
            type="button"
          >
            Add Leg
          </button>
        </div>

        <div className="flight-details-container">
          <h3>Invoice Details</h3>

          {state?.invoiceDetails?.length > 0 &&
            state?.invoiceDetails?.map((detail, index) => {
              console.log("DETAIL", detail);

              let isNotesRequired = detail?.typeLabel?.notesRequired;

              return (
                <div className="flight-details-block" key={index}>
                  <div className="flex-row column-gap-1pct">
                    <div className="form-group flex-half">
                      <label htmlFor={`type[${index}]`}>
                        Type <span className="required">*</span>
                      </label>
                      <Select
                        className="type-select"
                        classNamePrefix="select"
                        options={invoiceTypesOptions}
                        placeholder="Type"
                        onChange={(selection) => {
                          dispatch({
                            type: "changeInvoiceDetailField",
                            payload: {
                              invoiceDetailIndex: index,
                              field: "typeLabel",
                              value: selection,
                            },
                          });
                        }}
                        key={`type${index}`}
                        value={detail.typeLabel}
                        defaultValue={detail.typeLabel}
                      ></Select>
                    </div>
                    <div className="form-group flex-half">
                      <label htmlFor={`description[${index}]`}>
                        Description{" "}
                        {isNotesRequired ? (
                          <span className="required">*</span>
                        ) : (
                          ``
                        )}
                      </label>
                      <textarea
                        id={`description${index}`}
                        placeholder="Description"
                        onChange={(e) => {
                          dispatch({
                            type: "changeInvoiceDetailField",
                            payload: {
                              invoiceDetailIndex: index,
                              field: "description",
                              value: e.target.value,
                            },
                          });
                        }}
                        key={`description[${index}]`}
                        value={state.invoiceDetails[index].description || ""}
                        required={isNotesRequired}
                      ></textarea>
                    </div>
                    <div className="form-group flex-half">
                      <label htmlFor={`amount[${index}]`}>
                        Amount <span className="required">*</span>
                      </label>
                      <NumberFormat
                        thousandSeparator={true}
                        prefix={"$"}
                        onValueChange={(event) => {
                          dispatch({
                            type: "changeInvoiceDetailField",
                            payload: {
                              invoiceDetailIndex: index,
                              field: "amount",
                              value: event.value,
                            },
                          });
                        }}
                        placeholder="Amount"
                        className="semibold-text mb-0"
                        decimalScale={2}
                        name={`amount[${index}]`}
                        value={state.invoiceDetails[index].amount}
                      />
                    </div>
                  </div>
                  {index >= 1 && (
                    <button
                      className="deleteLeg action-button base-gunmetal-button"
                      onClick={(e) => {
                        e.preventDefault();
                        return dispatch({
                          type: "deleteInvoiceDetail",
                          index: index,
                        });
                      }}
                      type="button"
                    >
                      Delete Detail {index + 1}
                    </button>
                  )}
                </div>
              );
            })}
          <button
            className="action-button base-blue-button mt-0"
            onClick={handleAddDetail}
            type="button"
          >
            Add Detail
          </button>
        </div>

        <div className="flight-details-container">
          <h3>Payment Details</h3>

          {state.paymentDetails?.length > 0 &&
            state.paymentDetails.map((detail, index) => (
              <div className="flight-details-block" key={index}>
                <div className="flex-row column-gap-1pct">
                  <div className="datepickerContainer mb-0 form-group flex-half">
                    <label
                      htmlFor={`departureDate[${index}]`}
                      className="base-label"
                    >
                      Date <span className="required">*</span>
                    </label>

                    <Datepicker
                      value={detail.date}
                      onChange={(event) => {
                        dispatch({
                          type: "changePaymentDetailField",
                          payload: {
                            paymentDetailIndex: index,
                            field: "date",
                            value: event.value,
                          },
                        });
                      }}
                      dataTimezone="utc"
                      displayTimezone="local"
                      timezonePlugin={momentTimezone}
                      controls={["date"]}
                    />
                  </div>
                  <div className="form-group flex-half">
                    <label htmlFor={`paymentDetailType[${index}]`}>
                      Type <span className="required">*</span>
                    </label>
                    <Select
                      className="type-select"
                      classNamePrefix="select"
                      options={paymentOptions}
                      placeholder="Type"
                      onChange={(selection) => {
                        dispatch({
                          type: "changePaymentDetailField",
                          payload: {
                            paymentDetailIndex: index,
                            field: "typeLabel",
                            value: selection,
                          },
                        });
                      }}
                      defaultValue={detail.typeLabel}
                      value={detail.typeLabel}
                      key={`paymentDetailType${index}`}
                    ></Select>
                  </div>
                  <div className="form-group flex-half">
                    <label htmlFor={`paymentDetailsNotes[${index}]`}>
                      Notes
                      {state.paymentDetails[index].type === "other" && (
                        <span className="required">*</span>
                      )}
                    </label>
                    <textarea
                      id={`paymentDetailsNotes${index}`}
                      placeholder="Notes"
                      value={detail.notes || ""}
                      onChange={(event) => {
                        dispatch({
                          type: "changePaymentDetailField",
                          payload: {
                            paymentDetailIndex: index,
                            field: "notes",
                            value: event.target.value,
                          },
                        });
                      }}
                      key={`paymentDetailsNotes[${index}]`}
                    ></textarea>
                  </div>
                  <div className="form-group flex-half">
                    <label htmlFor={`amount[${index}]`}>
                      Amount <span className="required">*</span>
                    </label>
                    <NumberFormat
                      value={detail.amount}
                      thousandSeparator={true}
                      prefix={"$"}
                      onValueChange={(event) => {
                        dispatch({
                          type: "changePaymentDetailField",
                          payload: {
                            paymentDetailIndex: index,
                            field: "amount",
                            value: event.value,
                          },
                        });
                      }}
                      placeholder="Amount"
                      className="semibold-text mb-0"
                      decimalScale={2}
                      name={`amount[${index}]`}
                    />
                  </div>
                </div>
                {index >= 1 && (
                  <button
                    className="deleteLeg action-button base-gunmetal-button"
                    onClick={(e) => {
                      e.preventDefault();
                      return dispatch({
                        type: "deletePaymentDetail",
                        index: index,
                      });
                    }}
                    type="button"
                  >
                    Delete Payment Detail {index + 1}
                  </button>
                )}
              </div>
            ))}

          <button
            className="action-button base-blue-button mt-0"
            onClick={handleAddPaymentDetail}
            type="button"
          >
            Add Payment Detail
          </button>
        </div>

        <div className="notes-container">
          <h3>Invoice Notes</h3>

          <Editor
            editorState={state.invoiceNotesEditor}
            wrapperClassName="header-editor-wrapper"
            editorClassName="notes-editor"
            onEditorStateChange={(editorState) =>
              dispatch({
                type: "fieldChanged",
                field: "invoiceNotesEditor",
                value: editorState,
              })
            }
          />
        </div>

        <button
          className="action-button mt-0  mb-20 large-save-button evo-blue"
          onClick={handleInvoiceSubmit}
          type="submit"
          id="preview"
        >
          {`Save and Preview`}
        </button>

        <button
          className="action-button mt-0 mb-20 ml-20 large-save-button evo-gunmetal"
          onClick={handleInvoiceSubmit}
          type="submit"
          id="view-trip"
        >
          {`Save and View Trip`}
        </button>
      </form>

      {false && (
        <div className="invoice-totals-parent">
          <div className="flex invoice-totals">
            <h3 className="h3-total">Total Charges</h3>
            <NumberFormat
              value={getTotals().totalCharges}
              thousandSeparator={true}
              prefix={"$"}
              placeholder="Total Charges"
              className="semibold-text mb-0 ml-4"
              decimalScale={2}
              name="totalCharges"
              disabled
            />
          </div>
          <div className="flex invoice-totals">
            <h3 className="h3-total">Total Payments</h3>
            <NumberFormat
              value={getTotals().totalPayments}
              thousandSeparator={true}
              prefix={"$"}
              placeholder="Total Payments"
              className="semibold-text mb-0 ml-4"
              decimalScale={2}
              name="totalPayments"
              disabled
            />
          </div>
          <div className="flex invoice-totals">
            <h3 className="h3-total">Balance Due</h3>
            <NumberFormat
              value={getTotals().balanceDue}
              thousandSeparator={true}
              prefix={"$"}
              placeholder="Balance Due"
              className="semibold-text mb-0 ml-4"
              decimalScale={2}
              name="balanceDue"
              disabled
            />
          </div>
        </div>
      )}

      <StateDisplay user={user} data={state}></StateDisplay>
    </Fragment>
  );
};

export default NewInvoiceContent;
