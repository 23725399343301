import React from "react";
import AsyncSelect from "react-select/async";
import Select from "react-select";

const LeadBulkActionsMobile = (props) => {
  const selectedRows = props.state.selectedRows;
  const superAdminOrAd = props.superAdminOrAd;

  return (
    <div
      id="lead-bulk-actions"
      key={props.disableAssignmentButtons}
      className="mobile-version"
    >
      {superAdminOrAd && (
        <Select
          key={`selectedOwner-${props.state.selectedOwner}`}
          className="owner-select"
          classNamePrefix="select"
          value={props.state.selectedOwner}
          options={props.ownerOptions}
          // defaultValue={props.initialTags}
          placeholder="Assign Lead"
          isDisabled={props.disableAssignmentSelect()}
          onChange={props.handleOwnerSelection}
        ></Select>
      )}

      <Select
        key={`outreach-stages`}
        className="stage-select"
        classNamePrefix="select"
        // value
        options={props.stageFilterOptions}
        // defaultValue={props.initialTags}
        placeholder="Lead Stage"
        isDisabled={props.disableStageAssignmentSelect()}
        onChange={props.handleLeadStageUpdate}
      ></Select>
    </div>
  );
};

export default LeadBulkActionsMobile;
