import moment from "moment";
import { Link } from "react-router-dom";

const columns = [
  {
    name: "Edit",
    sortable: false,
    cell: (row) => {
      return <Link to={`/atc/${row._id}`}>Edit</Link>;
    },
    width: "70px",
  },

  {
    name: "Owner",
    selector: "owner.ownerName",
    sortable: true,
    cell: (row) => {
      return row?.owner?.ownerName || "No owner";
    },
    width: "140px",
  },
  {
    name: "Priority",
    selector: "flightPriority",
    sortable: true,
    cell: (row) => {
      switch (row.flightPriority) {
        case 1:
          return "Evo Empty Leg";

        case 2:
          return "Signed Contract";
        case 3:
          return "Repeat Client Request";
        case 4:
          return "New Client Request";
        default:
          return "";
      }
    },
    width: "180px",
  },
  {
    name: "Aircraft",
    selector: "aircraft[0].aircraftName",
    sortable: true,
    cell: (row) => {
      if (row.aircraft.length >= 1) {
        return row.aircraft.map(({ aircraftName }) => aircraftName).join(", ");
      }
      return "No aircraft listed.";
    },
    width: "180px",
  },
  {
    name: "Departure Date",
    selector: "departureDate",
    sortable: true,
    right: true,
    width: "170px",
    cell: (row) => {
      return (row.departureDate = moment(row.departureDate).format("L"));
    },
  },
  {
    name: "Return Date",
    selector: "returnDate",
    sortable: true,
    right: true,
    width: "150px",
    cell: (row) => {
      return row.tripType === 2 ? moment(row.returnDate).format("L") : "";
    },
  },
  {
    name: "Departure Airport",
    selector: "departureAirport.airportName",
    sortable: true,
    width: "350px",
  },
  {
    name: "Arrival Airport",
    selector: "arrivalAirport.airportName",
    sortable: true,
    width: "350px",
  },

  {
    name: "Trip Type",
    selector: "tripType",
    sortable: true,
    width: "120px",
    cell: (row) => {
      return row.tripType === 1 ? "One Way" : "Round Trip";
    },
  },

  {
    name: "Flight Notes",
    selector: "flightNotes",
    sortable: true,
    width: "350px",
  },
  {
    name: "Added By",
    selector: "createdBy.userName",
    sortable: true,
    width: "150px",
  },
  {
    name: "Passengers",
    selector: "passengers",
    sortable: true,
    width: "150px",
  },
  {
    name: "Created At",
    selector: "createdAt",
    cell: (row) => {
      return moment(row.createdAt).format("llll");
    },
    sortable: true,
    width: "200px",
  },
];

export default columns;
