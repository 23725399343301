import React, { Fragment } from "react";

import { Hero } from "../components";
import { useAuth0 } from "@auth0/auth0-react";
import NewAirportAutocomplete from "../components/new-airport-autocomplete";

const AirportsAPI = () => {
  const { isAuthenticated } = useAuth0();

  return (
    <Fragment>
      <Hero />
      {isAuthenticated && <NewAirportAutocomplete />}
    </Fragment>
  );
};

export default AirportsAPI;
