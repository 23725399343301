import { Link } from "react-router-dom";
import moment from "moment";

const columns = [
  {
    name: "Operator Name",
    selector: "operatorName",
    sortable: true,
    width: "200px",
  },
  {
    name: "Score",
    selector: "composedScore",
    sortable: true,
    width: "120px",
  },

  {
    name: "Country",
    selector: "country",
    sortable: true,
    width: "150px",
  },
  {
    name: "Cities",
    selector: "cities",
    sortable: true,
    width: "150px",
  },
  // {
  //   name: "Type",
  //   selector: "type",
  //   sortable: true,
  //   width: "150px",
  // },
  {
    name: "Restrictions",
    selector: "restrictions",
    sortable: true,
    width: "200px",
    cell: (row) => {
      let restrictions = [];
      if (row?.restrictions?.length) {
        restrictions = row.restrictions.flatMap((item) => {
          let label = [];
          if (item === "caution") {
            label = "Use With Caution";
          }

          if (item === "blacklist") {
            label = "Blacklist";
          }

          if (item === "recovery") {
            label = "Recovery Only";
          }

          return label;
        });

        return restrictions.join(", ");
      }
    },
  },
  {
    name: "Updated At",
    selector: "updatedAt",
    sortable: true,
    width: "200px",
    cell: (row) => {
      return moment(row.createdAt).format("llll");
    },
  },
];

export default columns;
