import React, { useState, Fragment, useEffect } from "react";

// import MainNav from "./main-nav";
import AuthNav from "./auth-nav";
import logo from "../assets/images/evojets-logo.png";
import { Link, NavLink } from "react-router-dom";
import { push as Menu } from "react-burger-menu";
import { SlideDown } from "react-slidedown";
import "react-slidedown/lib/slidedown.css";

import ai from "../helpers/axios";

import { useAuth0 } from "@auth0/auth0-react";

import useWindowDimensions from "../helpers/useWindowDimensions";

const NavBar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const [organization, setOrganization] = useState(null);

  const [businessMenu, setBusinessMenu] = useState(true);
  const [operatorsMenu, setOperatorsMenu] = useState(true);

  const [rfpManagementMenu, setRFPManagementMenu] = useState(true);

  const [settingsMenu, setSettingsMenu] = useState(true);

  const [tripsMenu, setTripsMenu] = useState(true);

  const { user, logout, getAccessTokenSilently, loginWithRedirect } =
    useAuth0();
  const userRoles = user && user["https://app.evojets.com/roles"];

  const superAdmin = userRoles.some(function (role) {
    return "Super Admin" === role;
  });

  const { height, width } = useWindowDimensions();

  const superAdminOrSales = userRoles.some(function (role) {
    return (
      "Super Admin" === role ||
      "Account Director" === role ||
      "Sales Assistant" === role ||
      "Dynamic Sales Assistant" === role ||
      "Account Executive" === role
    );
  });

  // console.log(userRoles);

  const superAdminOrAd = userRoles.some(function (role) {
    return "Super Admin" === role || "Account Director" === role;
  });

  const outsideOrg = user["https://app.evojets.com/outsideOrganization"];
  const outsideOrgAdmin =
    user["https://app.evojets.com/outsideOrganization"] &&
    userRoles.some((role) => "Organization Admin" === role);

  const updateMenuOpenState = (state) => {
    setMenuOpen(state.isOpen);
    state.isOpen
      ? document.body.classList.add("main-menu-open")
      : document.body.classList.remove("main-menu-open");
  };

  const getData = async () => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      loginWithRedirect();
      return;
    });

    let { data } = await ai.auth(token).get(`/api/organizations/myOrg`);

    setOrganization(data);

    // console.log("ORG DATA NAVBAR", data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="nav-container mb-3" id="nav-container">
      <Menu
        isOpen={menuOpen}
        burgerButtonClassName={"menu-toggle-icon"}
        onStateChange={updateMenuOpenState}
        customBurgerIcon={<img alt="menu toggle" src={`/bars-regular.svg`} />}
        pageWrapId={"page-wrap"}
        outerContainerId={"app"}
        noOverlay
        width={width >= 500 ? 280 : "100%"}
        customOnKeyDown={(e) => {
          e = e || window.event;

          if (e.key === "Escape" || e.keyCode === 27) {
            setMenuOpen(false);
          }
        }}
        // onOpen={() => {
        //   setMenuOpen(true);
        //   setTimeout(() => {
        //     document
        //       .querySelectorAll(".bm-item")
        //       .forEach((x) => (x.tabIndex = -1));

        //     document.querySelector("#react-burger-cross-btn").tabIndex = -1;

        //     document.querySelector(".bm-menu-wrap").tabIndex = -1;
        //   }, 500);
        // }}
        // customCrossIcon={false}
      >
        <NavLink
          className="menu-item"
          onClick={() => (width <= 500 ? setMenuOpen(false) : null)}
          to="/"
          exact
          activeClassName="selected-link"
          tabIndex="-1"
        >
          <span className="material-icons">speed</span> Dashboard
        </NavLink>

        {(superAdminOrSales || outsideOrg) && (
          <NavLink
            className="menu-item"
            onClick={() => (width <= 500 ? setMenuOpen(false) : null)}
            to="/calculator"
            activeClassName="selected-link"
            exact
          >
            <span className="material-icons">calculate</span> Calculator
          </NavLink>
        )}

        {superAdminOrSales && (
          <NavLink
            className="menu-item"
            onClick={() => (width <= 500 ? setMenuOpen(false) : null)}
            to="/flight-board"
            activeClassName="selected-link"
            exact
          >
            <span className="material-icons">flight</span> Flight Board
          </NavLink>
        )}

        {superAdminOrSales && (
          <NavLink
            className="menu-item"
            onClick={() => (width <= 500 ? setMenuOpen(false) : null)}
            to="/flight-matches"
            activeClassName="selected-link"
            exact
          >
            <span className="material-icons">sync_alt</span> Flight Matches
          </NavLink>
        )}

        {superAdminOrSales && (
          <button
            className="menuOperatorsToggle menuToggle"
            onClick={(e) => {
              e.preventDefault();
              setOperatorsMenu(!operatorsMenu);
            }}
          >
            <span className="material-icons">public</span> Operators
          </button>
        )}

        {superAdminOrSales && (
          <SlideDown className={"my-dropdown-slidedown"}>
            {operatorsMenu ? (
              <div id="operatorsMenu" className={`operatorsMenu submenu`}>
                <NavLink
                  className="menu-item"
                  onClick={() => (width <= 500 ? setMenuOpen(false) : null)}
                  activeClassName="selected-link"
                  to="/opi"
                  exact
                >
                  All
                </NavLink>
                <NavLink
                  className="menu-item"
                  onClick={() => (width <= 500 ? setMenuOpen(false) : null)}
                  activeClassName="selected-link"
                  to="/restricted-operators"
                  exact
                >
                  Restricted
                </NavLink>
              </div>
            ) : null}
          </SlideDown>
        )}

        {/* {superAdminOrSales && (
          <NavLink
            className="menu-item"
            onClick={() => (width <= 500 ? setMenuOpen(false) : null)}
            activeClassName="selected-link"
            to="/opi"
            exact
          >
            <span className="material-icons">insights</span> OPI
          </NavLink>
        )}

        {superAdminOrSales && (
          <NavLink
            className="menu-item"
            onClick={() => (width <= 500 ? setMenuOpen(false) : null)}
            activeClassName="selected-link"
            to="/restricted-operators"
            exact
          >
            <span className="material-icons">airplanemode_inactive</span>
            Restricted
          </NavLink>
        )} */}

        {outsideOrg && (
          <NavLink
            className="menu-item"
            onClick={() => (width <= 500 ? setMenuOpen(false) : null)}
            to="/trip-requests"
            activeClassName="selected-link"
            exact
          >
            <span className="material-icons">flight</span> Trip Requests
          </NavLink>
        )}

        {superAdminOrSales && (
          <NavLink
            className="menu-item"
            onClick={() => (width <= 500 ? setMenuOpen(false) : null)}
            to="/sourcing"
            activeClassName="selected-link"
            exact
          >
            <span className="material-icons">airplane_ticket</span> Sourcing
          </NavLink>
        )}

        {superAdminOrSales && (
          <button
            className="menuBusinessToggle menuToggle"
            onClick={(e) => {
              e.preventDefault();
              setBusinessMenu(!businessMenu);
            }}
          >
            <span className="material-icons">work</span> Business
          </button>
        )}

        {superAdminOrSales && (
          <SlideDown className={"my-dropdown-slidedown"}>
            {businessMenu ? (
              <div id="businessMenu" className={`businessMenu submenu`}>
                {superAdmin && (
                  <NavLink
                    className="menu-item"
                    onClick={() => (width <= 500 ? setMenuOpen(false) : null)}
                    to="/estimates"
                    exact
                    activeClassName="selected-link"
                  >
                    Estimates
                  </NavLink>
                )}

                <NavLink
                  className="menu-item"
                  onClick={() => (width <= 500 ? setMenuOpen(false) : null)}
                  to="/quotes"
                  exact
                  activeClassName="selected-link"
                >
                  Quotes
                </NavLink>
                {/* 
                <NavLink
                  className="menu-item"
                  onClick={() => (width <= 500 ? setMenuOpen(false) : null)}
                  // to={{ key: Math.random(), pathname: "/new-quote" }}
                  to="/new-quote"
                  exact
                  activeClassName="selected-link"
                >
                  New QFS
                </NavLink> */}

                <NavLink
                  className="menu-item"
                  onClick={() => (width <= 500 ? setMenuOpen(false) : null)}
                  to="/prospects"
                  exact
                  activeClassName="selected-link"
                >
                  Prospects
                </NavLink>
              </div>
            ) : null}
          </SlideDown>
        )}

        {superAdminOrSales && (
          <button
            className="menuRFPManagementToggle menuToggle"
            onClick={(e) => {
              e.preventDefault();
              setRFPManagementMenu(!rfpManagementMenu);
            }}
          >
            <span className="material-icons">work</span> Lead Management
          </button>
        )}

        {superAdminOrSales && (
          <SlideDown className={"my-dropdown-slidedown"}>
            {rfpManagementMenu ? (
              <div
                id="rfpManagementMenu"
                className={`rfpManagementMenu submenu`}
              >
                <NavLink
                  className="menu-item"
                  onClick={() => (width <= 500 ? setMenuOpen(false) : null)}
                  to="/leads"
                  exact
                  activeClassName="selected-link"
                >
                  All Leads
                </NavLink>

                <NavLink
                  className="menu-item"
                  onClick={() => (width <= 500 ? setMenuOpen(false) : null)}
                  to="/leads/new"
                  exact
                  activeClassName="selected-link"
                >
                  New Leads
                </NavLink>

                <NavLink
                  className="menu-item"
                  onClick={() => (width <= 500 ? setMenuOpen(false) : null)}
                  to="/phone-calls"
                  exact
                  activeClassName="selected-link"
                >
                  Phone Calls
                </NavLink>

                {superAdminOrAd && (
                  <NavLink
                    className="menu-item"
                    onClick={() => (width <= 500 ? setMenuOpen(false) : null)}
                    to="/my-leads"
                    exact
                    activeClassName="selected-link"
                  >
                    My Leads
                  </NavLink>
                )}

                <NavLink
                  className="menu-item"
                  onClick={() => (width <= 500 ? setMenuOpen(false) : null)}
                  to="/new-lead"
                  exact
                  activeClassName="selected-link"
                >
                  Lead From Scratch
                </NavLink>
              </div>
            ) : null}
          </SlideDown>
        )}

        {superAdminOrSales && (
          <button
            className="menuTripsToggle menuToggle"
            onClick={(e) => {
              e.preventDefault();
              setTripsMenu(!tripsMenu);
            }}
          >
            <span className="material-icons">connecting_airports</span> Trips
          </button>
        )}

        {superAdminOrSales && (
          <SlideDown className={"my-dropdown-slidedown"}>
            {tripsMenu ? (
              <div id="tripsMenu" className={`tripsMenu submenu`}>
                {superAdminOrSales && (
                  <NavLink
                    className="menu-item"
                    onClick={() => (width <= 500 ? setMenuOpen(false) : null)}
                    to="/trips"
                    exact
                    activeClassName="selected-link"
                  >
                    All Trips
                  </NavLink>
                )}
              </div>
            ) : null}
          </SlideDown>
        )}

        {superAdmin && (
          <button
            className="menuSettingsToggle menuToggle"
            onClick={(e) => {
              e.preventDefault();
              setSettingsMenu(!settingsMenu);
            }}
          >
            <span className="material-icons">settings</span> Settings
          </button>
        )}

        {superAdmin && (
          <SlideDown className={"my-dropdown-slidedown"}>
            {settingsMenu ? (
              <div id="settingsMenu" className={`settingsMenu submenu`}>
                <NavLink
                  className="menu-item"
                  onClick={() => (width <= 500 ? setMenuOpen(false) : null)}
                  to="/aircraft"
                  exact
                  activeClassName="selected-link"
                >
                  Aircraft
                </NavLink>
                <NavLink
                  className="menu-item"
                  onClick={() => (width <= 500 ? setMenuOpen(false) : null)}
                  to="/aircraft-types"
                  exact
                  activeClassName="selected-link"
                >
                  Aircraft Types
                </NavLink>

                <NavLink
                  className="menu-item"
                  onClick={() => (width <= 500 ? setMenuOpen(false) : null)}
                  to="/airports"
                  exact
                  activeClassName="selected-link"
                >
                  Airports
                </NavLink>

                <NavLink
                  className="menu-item"
                  onClick={() => (width <= 500 ? setMenuOpen(false) : null)}
                  to="/country-settings"
                  exact
                  activeClassName="selected-link"
                >
                  Country Settings
                </NavLink>

                <NavLink
                  className="menu-item"
                  onClick={() => (width <= 500 ? setMenuOpen(false) : null)}
                  to="/distance-params"
                  exact
                  activeClassName="selected-link"
                >
                  Distance Params
                </NavLink>

                <NavLink
                  className="menu-item"
                  onClick={() => (width <= 500 ? setMenuOpen(false) : null)}
                  activeClassName="selected-link"
                  to="/exclusion-rules"
                  exact
                >
                  Exclusion Rules
                </NavLink>

                <NavLink
                  className="menu-item"
                  onClick={() => (width <= 500 ? setMenuOpen(false) : null)}
                  activeClassName="selected-link"
                  to="/export"
                  exact
                >
                  Export
                </NavLink>

                <NavLink
                  className="menu-item"
                  onClick={() => (width <= 500 ? setMenuOpen(false) : null)}
                  to="/organizations"
                  exact
                  activeClassName="selected-link"
                >
                  Organizations
                </NavLink>

                <NavLink
                  className="menu-item"
                  onClick={() => (width <= 500 ? setMenuOpen(false) : null)}
                  to="/spreads"
                  exact
                  activeClassName="selected-link"
                >
                  Spreads
                </NavLink>

                <NavLink
                  className="menu-item"
                  onClick={() => (width <= 500 ? setMenuOpen(false) : null)}
                  to="/users"
                  exact
                  activeClassName="selected-link"
                >
                  Users
                </NavLink>
              </div>
            ) : null}
          </SlideDown>
        )}

        {outsideOrgAdmin && (
          <NavLink
            className="menu-item"
            to="/settings/users"
            onClick={() => (width <= 500 ? setMenuOpen(false) : null)}
            activeClassName="selected-link"
            exact
          >
            <span className="material-icons">account_circle</span> Users
          </NavLink>
        )}

        {outsideOrgAdmin && organization?.developerCredentials && (
          <NavLink
            className="menu-item"
            to="/developers"
            onClick={() => (width <= 500 ? setMenuOpen(false) : null)}
            activeClassName="selected-link"
            exact
          >
            <span className="material-icons">code</span> Developers
          </NavLink>
        )}

        {!outsideOrg && (
          <NavLink
            className="menu-item"
            to="/my-account"
            onClick={() => (width <= 500 ? setMenuOpen(false) : null)}
            activeClassName="selected-link"
            exact
          >
            <span className="material-icons">account_circle</span> My Account
          </NavLink>
        )}

        <NavLink
          className="menu-item"
          onClick={() =>
            logout({
              returnTo: window.location.origin,
            })
          }
          // activeClassName="selected-link"
          to="#"
          exact
        >
          <span className="material-icons">logout</span>
          Logout
        </NavLink>
      </Menu>
      <nav
        className={`navbar navbar-expand-md navbar-light bg-light ${
          menuOpen ? `menu-open` : `menu-closed`
        }`}
      >
        <div className="container">
          <div className={`logo-container`} id="logo-container">
            <Link to="/">
              <img alt="Logo" src={logo}></img>
            </Link>
          </div>
          {/* <MainNav /> */}
          <AuthNav />
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
