import { Link } from "react-router-dom";

import formatter from "../../helpers/currencyFormatter";

const columns = [
  {
    name: "Edit",
    sortable: false,
    cell: (row) => {
      return <Link to={`/aircraft-types/${row._id}`}>Edit</Link>;
    },
    width: "70px",
  },
  {
    name: "Aircraft Type",
    selector: "name",
    sortable: true,
    width: "200px",
  },
  {
    name: "Speed",
    sortable: true,
    selector: "speed",
    width: "80px",
  },
  {
    name: "Hourly Rate",
    sortable: true,
    selector: "hourlyRate",
    cell: (row) => {
      return `${formatter.format(row.hourlyRate)}`;
    },
    width: "140px",
  },
  {
    name: "Fees",
    sortable: true,
    selector: "fees",
    cell: (row) => {
      return `${row.fees}%`;
    },
    width: "70px",
  },
  {
    name: "Minimum Fee",
    sortable: true,
    cell: (row) => {
      return `${formatter.format(row.minFee)}`;
    },
    width: "140px",
  },
  {
    name: "Return Fees",
    sortable: true,
    selector: "returnFees",
    cell: (row) => {
      return `${row.returnFees}%`;
    },
    width: "140px",
  },
  {
    name: "Daily Minimum",
    sortable: true,
    selector: "dailyMin",
    cell: (row) => {
      return `${row.dailyMin} hrs`;
    },
    width: "150px",
  },
  {
    name: "Commission %",
    sortable: true,
    selector: "commissionPercent",
    cell: (row) => {
      return `${row.commissionPercent * 100}%`;
    },
    width: "150px",
  },
  {
    name: "Commission Min",
    selector: "commissionMin",
    sortable: true,
    cell: (row) => {
      return `${formatter.format(row.commissionMin)}`;
    },
    width: "180px",
  },
  {
    name: "Min Pax",
    selector: "minPassengers",
    sortable: true,
    width: "100px",
  },
  {
    name: "Max Pax",
    selector: "maxPassengers",
    sortable: true,
    width: "120px",
  },
];

export default columns;
