import React, { Fragment, useReducer, useEffect } from "react";
import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import ai from "../../helpers/axios";

import { useHistory, useParams } from "react-router-dom";

import TextInput from "../text-input";

const reducer = (state, action) => {
  switch (action.type) {
    case "search": {
      return {
        ...state,
        query: action.query,
      };
    }
    case "fieldChanged": {
      return {
        ...state,
        opiWeights: {
          ...state.opiWeights,
          [action.field]: action.value,
        },
      };
    }

    case "rootFieldChanged": {
      return {
        ...state,
        [action.field]: action.value,
      };
    }
    case "dataBeganDownloading": {
      return {
        ...state,
        loading: true,
      };
    }
    case "dataDownloaded": {
      return {
        ...state,
        opiWeights: action.payload.data,
      };
    }
    default:
      break;
  }
  return state;
};

const UpdateOPIWeightContent = (props) => {
  const { id } = useParams();
  const history = useHistory();

  const initialState = {
    opiWeights: {
      operationalIntegrity: 4,
      communication: 4,
      aircraftInFleet: 2,
      aircraftCondition: 2,
      serviceRecord: 3,
      onDemandFriendly: 2,
      yearsWithPart135: 1,
      fleetAge: 1,
      safetyRating: 1,
      discretionary: 1,
    },
    operator: {
      operationalIntegrity: 5,
      communication: 5,
      aircraftInFleet: 5,
      aircraftCondition: 5,
      serviceRecord: 5,
      onDemandFriendly: 5,
      yearsWithPart135: 5,
      fleetAge: 5,
      safetyRating: 5,
      discretionary: 5,
    },
    maxScore: 105,
    loading: true,
  };

  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const [state, dispatch] = useReducer(reducer, initialState);

  const calculateOPI = (operator, opiWeights) => {
    let {
      operationalIntegrity,
      communication,
      aircraftInFleet,
      aircraftCondition,
      serviceRecord,
      onDemandFriendly,
      yearsWithPart135,
      fleetAge,
      safetyRating,
      discretionary,
    } = operator;

    if (operationalIntegrity === "" || operationalIntegrity === null) {
      operationalIntegrity = 0;
    }

    if (communication === "" || communication === null) {
      communication = 0;
    }

    if (aircraftInFleet === "" || aircraftInFleet === null) {
      aircraftInFleet = 0;
    }

    if (aircraftCondition === "" || aircraftCondition === null) {
      aircraftCondition = 0;
    }

    if (serviceRecord === "" || serviceRecord === null) {
      serviceRecord = 0;
    }

    if (onDemandFriendly === "" || onDemandFriendly === null) {
      onDemandFriendly = 0;
    }

    if (yearsWithPart135 === "" || yearsWithPart135 === null) {
      yearsWithPart135 = 0;
    }

    if (fleetAge === "" || fleetAge === null) {
      fleetAge = 0;
    }

    if (safetyRating === "" || safetyRating === null) {
      safetyRating = 0;
    }

    if (discretionary === "" || discretionary === null) {
      discretionary = 0;
    }

    let newScore =
      operationalIntegrity * opiWeights.operationalIntegrity +
      communication * opiWeights.communication +
      aircraftInFleet * opiWeights.aircraftInFleet +
      aircraftCondition * opiWeights.aircraftInFleet +
      serviceRecord * opiWeights.serviceRecord +
      onDemandFriendly * opiWeights.onDemandFriendly +
      yearsWithPart135 * opiWeights.yearsWithPart135 +
      fleetAge * opiWeights.fleetAge +
      safetyRating * opiWeights.safetyRating +
      discretionary * opiWeights.discretionary;

    dispatch({
      type: "rootFieldChanged",
      field: "maxScore",
      value: parseInt(newScore),
    });
  };

  useEffect(() => {
    calculateOPI(state.operator, state.opiWeights);
  }, [state.opiWeights]);

  const getData = async () => {
    dispatch({ type: "dataBeganDownloading" });

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let { data } = await ai.auth(token).get(`/api/opi/getOPIWeights/`);

    dispatch({
      type: "dataDownloaded",
      payload: {
        data,
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let {
      operationalIntegrity,
      communication,
      aircraftInFleet,
      aircraftCondition,
      serviceRecord,
      onDemandFriendly,
      yearsWithPart135,
      fleetAge,
      safetyRating,
      discretionary,
    } = state.opiWeights;

    let formData = {
      operationalIntegrity,
      communication,
      aircraftInFleet,
      aircraftCondition,
      serviceRecord,
      onDemandFriendly,
      yearsWithPart135,
      fleetAge,
      safetyRating,
      discretionary,
    };

    if (formData.operationalIntegrity === "") {
      formData.operationalIntegrity = 0;
    }

    if (formData.communication === "") {
      formData.communication = 0;
    }

    if (formData.aircraftInFleet === "") {
      formData.aircraftInFleet = 0;
    }

    if (formData.operationalIntegrity === "") {
      formData.operationalIntegrity = 0;
    }

    if (formData.aircraftCondition === "") {
      formData.aircraftCondition = 0;
    }

    if (formData.serviceRecord === "") {
      formData.serviceRecord = 0;
    }

    if (formData.onDemandFriendly === "") {
      formData.onDemandFriendly = 0;
    }

    if (formData.yearsWithPart135 === "") {
      formData.yearsWithPart135 = 0;
    }

    if (formData.fleetAge === "") {
      formData.fleetAge = 0;
    }

    if (formData.safetyRating === "") {
      formData.safetyRating = 0;
    }

    if (formData.discretionary === "") {
      formData.discretionary = 0;
    }

    let { data } = await ai
      .auth(token)
      .put(`/api/opi/update/opiWeights`, formData);

    console.log("resp", data);

    // history.push({ pathname: "/opi" });

    setTimeout(() => {
      toast.success("OPI Weights Updated Successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }, 50);
  };

  useEffect(() => {
    getData();
    document.title = "Edit OPI Weights | Flight Deck by evoJets";
  }, []);

  const handleTextInputChange = (e) => {
    let value = e.target.value;

    if (e.target.type === "number") {
      value = parseInt(e.target.value);

      if (
        e.target.value === null ||
        typeof e.target.value === undefined ||
        e.target.value === ""
      ) {
        value = "";
      }
    }

    console.log(e.target.value);

    dispatch({
      type: "fieldChanged",
      field: e.target.name,
      value: value,
    });
  };

  return (
    <Fragment>
      <h2 id="addAirportHeading">Edit OPI Weights</h2>
      <h4 className="centered opi-score">Maximum Score: {state.maxScore}</h4>

      <form id="addAirport" onSubmit={handleSubmit}>
        <TextInput
          type="number"
          name="operationalIntegrity"
          value={state.opiWeights.operationalIntegrity}
          label="Operational Integrity"
          handleChange={handleTextInputChange}
        ></TextInput>

        <TextInput
          type="number"
          name="communication"
          value={state.opiWeights.communication}
          label="Communication"
          handleChange={handleTextInputChange}
        ></TextInput>

        <TextInput
          type="number"
          name="aircraftInFleet"
          value={state.opiWeights.aircraftInFleet}
          label="Aircraft In Fleet"
          handleChange={handleTextInputChange}
        ></TextInput>

        <TextInput
          type="number"
          name="aircraftCondition"
          value={state.opiWeights.aircraftCondition}
          label="Aircraft Condition"
          handleChange={handleTextInputChange}
        ></TextInput>

        <TextInput
          type="number"
          name="serviceRecord"
          value={state.opiWeights.serviceRecord}
          label="Service Record"
          handleChange={handleTextInputChange}
        ></TextInput>

        <TextInput
          type="number"
          name="onDemandFriendly"
          value={state.opiWeights.onDemandFriendly}
          label="On-Demand Friendly"
          handleChange={handleTextInputChange}
        ></TextInput>

        <TextInput
          type="number"
          name="yearsWithPart135"
          value={state.opiWeights.yearsWithPart135}
          label="Years With Part 135"
          handleChange={handleTextInputChange}
        ></TextInput>

        <TextInput
          type="number"
          name="fleetAge"
          value={state.opiWeights.fleetAge}
          label="Average Age of Fleet"
          handleChange={handleTextInputChange}
        ></TextInput>

        <TextInput
          type="number"
          name="safetyRating"
          value={state.opiWeights.safetyRating}
          label="Safety Rating"
          handleChange={handleTextInputChange}
        ></TextInput>

        <TextInput
          type="number"
          name="discretionary"
          value={state.opiWeights.discretionary}
          label="Discretionary"
          handleChange={handleTextInputChange}
        ></TextInput>

        <input type="submit" value="Update OPI Weights"></input>
      </form>

      {/* <pre>{JSON.stringify(state.opiWeights, null, 2)}</pre> */}
    </Fragment>
  );
};

export default UpdateOPIWeightContent;
