import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ai from "../../helpers/axios";

import { useReducer } from "react";
import moment from "moment";

import ReactModal from "react-modal";

//import textarea-input component
import TextAreaInput from "../textarea-input";

//import text input
import TextInput from "../text-input";

import { Datepicker, momentTimezone } from "@mobiscroll/react";
import mobiscroll from "@mobiscroll/react";

import "@mobiscroll/react/dist/css/mobiscroll.min.css";

const reducer = (state, action) => {
  switch (action.type) {
    case "fieldChanged": {
      return {
        ...state,
        [action.field]: action.value,
      };
    }

    case "dataBeganDownloading": {
      return {
        ...state,
        loading: true,
      };
    }

    case "dataDownloaded": {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      break;
  }
  return state;
};

const CateringModule = (props) => {
  const { getAccessTokenSilently, user, loginWithRedirect } = useAuth0();

  const userRoles = user && user["https://app.evojets.com/roles"];

  const superAdmin = userRoles.some(function (role) {
    return "Super Admin" === role;
  });

  let initialState = {
    showEditModal: false,
    cateringBeingEdited: null,
    cateringNoteText: "",
    cateringItemText: "",
    cateringServings: "",
    catering: props.catering,

    loading: false,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  console.log(props);

  //create function to handle edit button, show react Modal with input field
  const handleEditButton = (item) => {
    console.log("EDIT CATERING ITEM", item);
    //show modal

    //set cateringBeingEdited
    dispatch({
      type: "fieldChanged",
      field: "cateringBeingEdited",
      value: item,
    });

    dispatch({ type: "fieldChanged", field: "showEditModal", value: true });
  };

  const handleAddCatering = async (e) => {
    console.log("add catering");

    e.preventDefault();

    const token = await getAccessTokenSilently();
    const response = await ai.auth(token).post(
      `/api/trips/${props.tripId}/addCatering`,
      {
        catering: {
          notes: state.cateringNoteText,
          item: state.cateringItemText,
          servings: state.cateringServings,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    //reset catering form
    dispatch({ type: "fieldChanged", field: "cateringNoteText", value: "" });
    dispatch({ type: "fieldChanged", field: "cateringItemText", value: "" });
    dispatch({ type: "fieldChanged", field: "cateringServings", value: "" });

    props.parentDispatch({
      type: "fieldChanged",
      field: "refreshDataToken",
      value: Date.now(),
    });

    console.log(response);
  };

  //create function to edit a note
  const handleEditSubmit = async (e) => {
    console.log("edit");

    e.preventDefault();

    const token = await getAccessTokenSilently();
    const response = await ai.auth(token).put(
      `/api/trips/${props.tripId}/updateCatering/${state.cateringBeingEdited.id}`,
      {
        catering: {
          notes: state.cateringNoteText,
          item: state.cateringItemText,
          servings: state.cateringServings,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch({ type: "fieldChanged", field: "showEditModal", value: false });
    dispatch({ type: "fieldChanged", field: "noteBeingEdited", value: null });

    props.parentDispatch({
      type: "fieldChanged",
      field: "refreshDataToken",
      value: Date.now(),
    });

    console.log(response);
  };

  //function to delete a note
  const handleDeleteButton = async (id) => {
    console.log("delete");
    console.log(id);

    const token = await getAccessTokenSilently();
    const response = await ai
      .auth(token)
      .delete(`/api/trips/${props.tripId}/deleteCatering/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

    props.parentDispatch({
      type: "fieldChanged",
      field: "refreshDataToken",
      value: Date.now(),
    });

    console.log(response);
  };

  return (
    <>
      <div
        className="trip-notes-module"
        key={`key-catering-${props.dataDownloadedTimestamp.toString()}`}
      >
        <h3>CATERING</h3>
        {state.catering && (
          <div className="trip-notes-table">
            <table>
              <thead>
                <tr>
                  <th>Created By</th>
                  <th>Item</th>
                  <th>Servings</th>
                  <th>Notes</th>

                  <th className="text-align-right">Actions</th>
                </tr>
              </thead>
              <tbody>
                {state.catering.map((item) => {
                  return (
                    <tr key={item._id}>
                      <td>{item.createdBy.name}</td>
                      <td>{item.item || "-"}</td>
                      <td>{item.servings || "-"}</td>
                      <td>{item.notes || "-"}</td>

                      <td>
                        <button
                          className="base-button evo-blue"
                          onClick={(e) => {
                            handleEditButton(item);
                          }}
                        >
                          Edit
                        </button>{" "}
                        <button
                          className="base-button btn-danger"
                          onClick={(e) => handleDeleteButton(item._id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        <div className="trip-notes-form">
          <form onSubmit={handleAddCatering} className="base-form">
            <TextInput
              name="cateringItem"
              id="cateringItem"
              value={state.cateringItemText}
              handleChange={(e) => {
                dispatch({
                  type: "fieldChanged",
                  field: "cateringItemText",
                  value: e.target.value,
                });
              }}
              label="Add an Item"
              className="mb-0"
            ></TextInput>

            <TextInput
              name="cateringServings"
              id="cateringServings"
              type="number"
              value={state.cateringServings}
              handleChange={(e) => {
                dispatch({
                  type: "fieldChanged",
                  field: "cateringServings",
                  value: e.target.value,
                });
              }}
              label="Number of Servings"
              className="mb-0 base-input"
            ></TextInput>

            <TextAreaInput
              name="cateringNotes"
              id="cateringNotes"
              cols="30"
              rows="10"
              value={state.cateringNoteText}
              handleChange={(e) => {
                dispatch({
                  type: "fieldChanged",
                  field: "cateringNoteText",
                  value: e.target.value,
                });
              }}
              label="Item Notes"
              className="mb-0"
            ></TextAreaInput>

            <button
              className="base-button evo-blue w-100 mb-20 mt-0"
              type="submit"
            >
              Submit
            </button>
          </form>
        </div>
      </div>

      <ReactModal
        isOpen={state.showEditModal}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => {
          dispatch({
            type: "fieldChanged",
            field: "showEditModal",
            value: false,
          });
        }}
        style={{
          overlay: {
            zIndex: 999999,
            backgroundColor: "rgb(32 53 68 / 50%)",
          },
          content: {
            width: "auto",
            // height: "700px",
            // padding: "40px",
            border: "none",
            maxWidth: "700px",
            maxHeight: "80vh",
            minWidth: "50vw",
            borderRadius: "0",
            boxShadow: "10px 10px 91px -17px rgb(0 0 0 / 25%)",
          },
        }}
        appElement={document.getElementById("app")}
      >
        <div className="modal-header edit-note-modal">
          <h4>Edit Catering Item</h4>
        </div>
        <div className="modal-body edit-note-modal">
          <form onSubmit={handleEditSubmit} className="w-100 base-form mt-20">
            <TextInput
              name="cateringItem"
              id="cateringItem"
              value={state?.cateringBeingEdited?.item || ""}
              handleChange={(e) => {
                dispatch({
                  type: "fieldChanged",
                  field: "cateringBeingEdited",
                  value: {
                    ...state.cateringBeingEdited,
                    item: e.target.value,
                  },
                });
              }}
              label="Item Name"
              className="mb-0"
            ></TextInput>

            <TextInput
              name="cateringServings"
              id="cateringServings"
              type="number"
              value={state?.cateringBeingEdited?.servings || ""}
              handleChange={(e) => {
                dispatch({
                  type: "fieldChanged",
                  field: "cateringBeingEdited",
                  value: {
                    ...state.cateringBeingEdited,
                    servings: e.target.value,
                  },
                });
              }}
              label="Number of Servings"
              className="mb-0 base-input"
            ></TextInput>

            <div className="form-group">
              <label className="base-label" htmlFor="note">
                Item Notes
              </label>
              <textarea
                name="itemNotes"
                id="itemNotes"
                cols="30"
                rows="3"
                value={state?.cateringBeingEdited?.notes || ""}
                onChange={(e) => {
                  dispatch({
                    type: "fieldChanged",
                    field: "cateringBeingEdited",
                    value: {
                      ...state.cateringBeingEdited,
                      notes: e.target.value,
                    },
                  });
                }}
              ></textarea>
            </div>

            <button className="base-button evo-blue w-100" type="submit">
              Submit
            </button>
          </form>
        </div>
      </ReactModal>

      {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
    </>
  );
};

export default CateringModule;
