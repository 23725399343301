import React, { Fragment, useEffect, useReducer } from "react";
import { ToastContainer, toast, Slide } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";

import ai from "../../helpers/axios";
import columns from "./spread-columns";
import TableBulkActions from "../table-bulk-actions";

import DataTableServerPagination from "../data-table-server-pagination";
import PageHeaderRowSearch from "../shared/page-header-row-search";

const reducer = (state, action) => {
  switch (action.type) {
    case "search": {
      return {
        ...state,
        query: action.query,
      };
    }
    case "dataBeganDownloading": {
      return {
        ...state,
        loading: action.payload.loadingStatus,
      };
    }
    case "rowsDeleted": {
      return {
        ...state,
        clearSelectedRows: !state.clearSelectedRows,
        selectedRows: [],
      };
    }
    case "rowSelection": {
      return {
        ...state,
        selectedRows: action.payload.selectedRows,
        allSelected: action.payload.allSelected,
      };
    }
    case "dataDownloaded": {
      console.log(action.payload);
      return {
        ...state,
        spreads: {
          data: action.payload.data.spreads,
        },
        loading: false,
        totalLength: action.payload.data.countTotal,
      };
    }
    case "pageChanged": {
      return {
        ...state,
        currentPage: action.payload.page,
      };
    }
    case "rowsPerPageChanged": {
      return {
        ...state,
        countPerPage: action.payload.currentRowsPerPage,
      };
    }
    default:
      break;
  }
  return state;
};

const SpreadsContent = () => {
  const initialState = {
    spreads: {
      data: [],
    },
    loading: true,
    countPerPage: 25,
    currentPage: 1,
    query: "",
    selectedRows: [],
    allSelected: false,
    tagFilters: [],
    totalLength: null,
    clearSelectedRows: false,
  };

  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const [state, dispatch] = useReducer(reducer, initialState);

  console.log(state);

  const getData = async (loadSilently) => {
    let loadingStatus = true;
    if (loadSilently) {
      loadingStatus = false;
    }

    dispatch({ type: "dataBeganDownloading", payload: { loadingStatus } });

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });
    let { data } = await ai.auth(token).get(`/api/spreads/list`, {
      params: {
        page: state.currentPage,
        countPerPage: state.countPerPage,
      },
    });

    dispatch({
      type: "dataDownloaded",
      payload: {
        data,
      },
    });
  };

  useEffect(() => {
    getData("");
    document.title = "Spreads | Flight Deck by evoJets";
  }, [state.currentPage, state.countPerPage]);

  const handlePageChange = (page, totalRows) => {
    dispatch({
      type: "pageChanged",
      payload: {
        page,
        totalRows,
      },
    });
  };

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    dispatch({
      type: "rowsPerPageChanged",
      payload: {
        currentRowsPerPage,
        currentPage,
      },
    });
  };

  const handleSelection = function (rowData) {
    dispatch({
      type: "rowSelection",
      payload: {
        selectedRows: rowData.selectedRows,
        allSelected: rowData.allSelected,
      },
    });
  };

  const handleDelete = async (e) => {
    e.preventDefault();

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let rowIds = state.selectedRows.map((item) => item._id);
    let { data } = await ai.auth(token).delete("/api/spreads/deleteMany", {
      data: { ids: rowIds },
    });

    if (data.deletedCount) {
      getData("", true);

      toast.success("Spreads Deleted Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      console.log("No Rows Deleted");
    }

    // state changes happen regardless if rows successfully deleted or not
    dispatch({
      type: "rowsDeleted",
    });
  };

  return (
    <Fragment>
      {/* <ToastContainer transition={Slide} /> */}

      <PageHeaderRowSearch
        h1={`Spreads`}
        pretitle="Settings"
        breadcrumbs={[{ link: "/spreads", label: "Spreads" }]}
        rightColumn={
          <div className="header-actions-row">
            <TableBulkActions
              state={state}
              handleDelete={handleDelete}
              hideTags
              deleteBtnAlignment={"align-right"}
            />
          </div>
        }
      ></PageHeaderRowSearch>

      <DataTableServerPagination
        onSelection={handleSelection}
        columns={columns}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
        state={state}
        data={state.spreads.data}
        defaultSortField={"bracketStart"}
      ></DataTableServerPagination>

      {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
    </Fragment>
  );
};

export default SpreadsContent;
