import React, { Fragment, useEffect, useReducer } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import ai from "../../helpers/axios";

import columns from "./opi-columns";
import nonAdminColumns from "./opi-columns-non-admin";
import PageHeaderRowSearch from "../shared/page-header-row-search";
import DataTable from "react-data-table-component";
import Search from "../search";

import { Link } from "react-router-dom";

const reducer = (state, action) => {
  switch (action.type) {
    case "search": {
      return {
        ...state,
        query: action.query,
      };
    }
    case "dataBeganDownloading": {
      return {
        ...state,
        loading: action.payload.loadingStatus,
      };
    }

    case "dataDownloaded": {
      console.log(action.payload);
      return {
        ...state,
        operators: {
          data: action.payload.data.operators,
        },
        opiWeights: action.payload.data.opiWeights,
        loading: false,
        totalLength: action.payload.data.countTotal,
      };
    }

    default:
      break;
  }
  return state;
};

let restrictedColumn = {
  name: "Restrictions",
  selector: "restrictions",
  sortable: true,
  width: "200px",
  cell: (row) => {
    let restrictions = [];
    if (row?.restrictions?.length) {
      restrictions = row.restrictions.flatMap((item) => {
        let label = [];
        if (item === "caution") {
          label = "Use With Caution";
        }

        if (item === "blacklist") {
          label = "Blacklist";
        }

        if (item === "recovery") {
          label = "Recovery Only";
        }

        return label;
      });

      return restrictions.join(", ");
    }
  },
};

let notesColumn = {
  name: "Notes",
  selector: "notes",
  sortable: false,
  width: "200px",
};

const insertItem = (array, index, item) => {
  let newArray = [...array];

  newArray.splice(index, 0, item);

  return newArray;
};

const RestrictedOperatorsContent = () => {
  const initialState = {
    operators: {
      data: [],
    },
    loading: true,
    countPerPage: 25,
    currentPage: 1,
    query: "",
    selectedRows: [],
    allSelected: false,
    tagFilters: [],
    totalLength: null,
    clearSelectedRows: false,
  };

  const { getAccessTokenSilently, user, loginWithRedirect } = useAuth0();

  const userRoles = user && user["https://app.evojets.com/roles"];

  const superAdminOrAD = userRoles.some(function (role) {
    return "Super Admin" === role || "Account Director" === role;
  });

  let revisedColumns = insertItem(columns, 2, restrictedColumn);
  let revisedNonAdminColumns = insertItem(nonAdminColumns, 2, restrictedColumn);

  revisedColumns = insertItem(revisedColumns, 4, notesColumn);
  revisedNonAdminColumns = insertItem(revisedNonAdminColumns, 4, notesColumn);

  const superAdmin = userRoles.some(function (role) {
    return "Super Admin" === role;
  });

  const [state, dispatch] = useReducer(reducer, initialState);

  const getData = async (query, loadSilently) => {
    let loadingStatus = true;
    if (loadSilently) {
      loadingStatus = false;
    }

    dispatch({ type: "dataBeganDownloading", payload: { loadingStatus } });

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let { data } = await ai.auth(token).get(`/api/opi/list-restricted`, {
      params: {
        query: query,
      },
    });

    dispatch({
      type: "dataDownloaded",
      payload: {
        data,
      },
    });
  };

  useEffect(() => {
    getData("");
    document.title = "Restricted Operators | Flight Deck by evoJets";
  }, []);

  //   const handlePageChange = (page, totalRows) => {
  //     dispatch({
  //       type: "pageChanged",
  //       payload: {
  //         page,
  //         totalRows,
  //       },
  //     });
  //   };

  //   const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
  //     dispatch({
  //       type: "rowsPerPageChanged",
  //       payload: {
  //         currentRowsPerPage,
  //         currentPage,
  //       },
  //     });
  //   };

  //   const handleSelection = function (rowData) {
  //     dispatch({
  //       type: "rowSelection",
  //       payload: {
  //         selectedRows: rowData.selectedRows,
  //         allSelected: rowData.allSelected,
  //       },
  //     });
  //   };

  const handleSearch = (e) => {
    let query = e.target.value.toLowerCase();
    dispatch({ type: "search", query });

    getData(query, true);
  };

  return (
    <Fragment>
      <PageHeaderRowSearch
        h1={`Restricted Operators`}
        pretitle="Settings"
        breadcrumbs={[
          { link: "/restricted-operators", label: "Restricted Operators" },
        ]}
        rightColumn={
          <div
            id="operator-actions-row"
            className="header-actions-row base-header-actions-row has-delete-btn opi-header"
          >
            <Search
              placeholder="Search Operators"
              onSearch={handleSearch}
              query={state.query}
              className="opi-search"
            ></Search>

            {superAdminOrAD && (
              <Link to="/opi/new" className="evo-blue base-button">
                Add Operator
              </Link>
            )}
          </div>
        }
      ></PageHeaderRowSearch>

      <div
        className={`flight-table-container standard-data-table-container opi-table ${
          !superAdminOrAD && `opi-restricted-auto-width`
        }`}
      >
        <DataTable
          data={state.operators.data}
          columns={superAdminOrAD ? revisedColumns : revisedNonAdminColumns}
          noHeader
          highlightOnHover
          pagination={true}
          paginationPerPage={25}
          paginationRowsPerPageOptions={[25, 50, 100, 150, 200, 250]}
          defaultSortField="restrictions"
          defaultSortAsc={true}
          responsive={true}
        ></DataTable>
      </div>

      {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
    </Fragment>
  );
};

export default RestrictedOperatorsContent;
