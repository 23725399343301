import React, { Fragment, useReducer, useEffect } from "react";
import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import ai from "../../helpers/axios";

import { useHistory, useParams } from "react-router-dom";

const reducer = (state, action) => {
  switch (action.type) {
    case "search": {
      return {
        ...state,
        query: action.query,
      };
    }
    case "fieldChanged": {
      return {
        ...state,
        aircraftType: {
          ...state.aircraftType,
          [action.field]: action.value,
        },
      };
    }
    case "dataBeganDownloading": {
      return {
        ...state,
        loading: true,
      };
    }
    case "dataDownloaded": {
      return {
        ...state,
        aircraftType: action.payload.data,
      };
    }
    default:
      break;
  }
  return state;
};

const SingleAircraftContent = (props) => {
  const { id } = useParams();
  const history = useHistory();

  const initialState = {
    aircraftType: {
      name: "",
      speed: "",
      hourlyRate: "",

      fees: "",
      minFee: "",
      returnFees: "",
      dailyMin: "",

      commissionMin: "",
      commissionPercent: "",
      maxPassengers: "",
      minPassengers: "",
    },
    loading: true,
  };

  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const [state, dispatch] = useReducer(reducer, initialState);

  const getData = async () => {
    dispatch({ type: "dataBeganDownloading" });

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let { data } = await ai.auth(token).get(`/api/aircraftTypes/${id}`);

    dispatch({
      type: "dataDownloaded",
      payload: {
        data,
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let { data } = await ai
      .auth(token)
      .put(`/api/aircraftTypes/${id}`, state.aircraftType);
    console.log("resp", data);

    history.push({ pathname: "/aircraft-types/" });

    setTimeout(() => {
      toast.success("Aircraft Type Successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }, 50);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Fragment>
      <h2 id="addAirportHeading">Edit Aircraft Type</h2>
      <form id="addAirport" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            name="name"
            value={state.aircraftType.name}
            placeholder="Name"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="speed">Speed</label>
          <input
            type="number"
            name="speed"
            value={state.aircraftType.speed}
            placeholder="Speed"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="hourlyRate">Hourly Rate</label>
          <input
            type="number"
            name="hourlyRate"
            value={state.aircraftType.hourlyRate}
            placeholder="Hourly Rate"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="fees">Fees</label>
          <input
            type="number"
            name="fees"
            value={state.aircraftType.fees}
            placeholder="Fees"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="minFee">Minimum Fee</label>
          <input
            type="number"
            name="minFee"
            value={state.aircraftType.minFee}
            placeholder="Minimum Fee"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="returnFees">Return Fees</label>
          <input
            type="number"
            name="returnFees"
            value={state.aircraftType.returnFees}
            placeholder="Return Fees"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="dailyMin">Daily Minimum</label>
          <input
            type="number"
            name="dailyMin"
            value={state.aircraftType.dailyMin}
            placeholder="Daily Minimum"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="commissionPercent">Commission %</label>
          <input
            type="number"
            name="commissionPercent"
            value={state.aircraftType.commissionPercent}
            placeholder="Commission %"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="commissionMin">Commission Minimum</label>
          <input
            type="number"
            name="commissionMin"
            value={state.aircraftType.commissionMin}
            placeholder="Commission Minimum"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="minPassengers">Minimum Passengers</label>
          <input
            type="number"
            name="minPassengers"
            value={state.aircraftType.minPassengers}
            placeholder="Minimum Passengers"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="maxPassengers">Maximum Passengers</label>
          <input
            type="number"
            name="maxPassengers"
            value={state.aircraftType.maxPassengers}
            placeholder="Maximum Passengers"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <input type="submit" value="Update Aircraft Type"></input>
      </form>

      {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
    </Fragment>
  );
};

export default SingleAircraftContent;
