import React, { Fragment, useReducer, useEffect } from "react";
import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import ai from "../../helpers/axios";

import { useHistory, useParams } from "react-router-dom";

const reducer = (state, action) => {
  switch (action.type) {
    case "search": {
      return {
        ...state,
        query: action.query,
      };
    }
    case "fieldChanged": {
      return {
        ...state,
        airport: {
          ...state.airport,
          [action.field]: action.value,
        },
      };
    }
    case "dataBeganDownloading": {
      return {
        ...state,
        loading: true,
      };
    }
    case "dataDownloaded": {
      return {
        ...state,
        airport: action.payload.data,
      };
    }
    default:
      break;
  }
  return state;
};

const SingleAirportContent = (props) => {
  const { id } = useParams();
  const history = useHistory();

  const initialState = {
    airport: {
      code: "",
      airportName: "",
      airportActualName: "",
      city: "",
      lat: "",
      lon: "",
      municipality: "",
      airportType: "",
      positioning: "",
      ow: "",
      localCode: "",
      isoCountry: "",
      isoRegion: "",
      id: "",
      oldId: "",
    },
    loading: true,
  };

  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const [state, dispatch] = useReducer(reducer, initialState);

  const getData = async () => {
    dispatch({ type: "dataBeganDownloading" });

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let { data } = await ai.auth(token).get(`/api/airports/${id}`);

    dispatch({
      type: "dataDownloaded",
      payload: {
        data,
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let formData = { ...state.airport };

    formData.ow = formData.ow.toString();

    //set formData.id to fd2 ID
    formData.id = formData.oldId;
    delete formData.oldId;

    let { data } = await ai.auth(token).put(`/api/airports/${id}`, formData);

    console.log("resp", data);

    history.push({ pathname: "/airports/" });

    setTimeout(() => {
      toast.success("Airport Updated Successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }, 50);
  };

  useEffect(() => {
    getData();
    document.title = "Edit Airport | Flight Deck by evoJets";
  }, []);

  return (
    <Fragment>
      <h2 id="addAirportHeading">Edit Airport</h2>
      <form id="addAirport" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="code">Code</label>
          <input
            type="text"
            name="code"
            value={state.airport.code}
            placeholder="Code"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="airportName">Airport Name</label>

          <input
            type="text"
            name="airportName"
            value={state.airport.airportName}
            placeholder="Airport Name"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="oldId">Airport Old ID</label>

          <input
            type="text"
            name="oldId"
            value={state.airport.oldId}
            placeholder="Airport Old ID"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: "oldId",
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="airportActualName">Airport Actual Name</label>

          <input
            type="text"
            name="airportActualName"
            value={state.airport.airportActualName}
            placeholder="Airport Actual Name"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="city">City</label>
          <input
            type="text"
            name="city"
            value={state.airport.city}
            placeholder="City"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="lat">Latitude</label>
          <input
            type="number"
            name="lat"
            value={state.airport.lat}
            placeholder="Latitude"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="lon">Longitude</label>
          <input
            type="number"
            name="lon"
            value={state.airport.lon}
            placeholder="Longitude"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="municipality">Municipality</label>
          <input
            type="text"
            name="municipality"
            value={state.airport.municipality}
            placeholder="Municipality"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="airportType">Airport Type</label>
          <input
            type="text"
            name="airportType"
            value={state.airport.airportType}
            placeholder="Airport Type"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="positioning">Positioning</label>
          <input
            type="number"
            name="positioning"
            value={state.airport.positioning}
            placeholder="Positioning"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="ow">One Way Ratio</label>
          <input
            type="number"
            name="ow"
            value={state.airport.ow}
            placeholder="One Way Ratio"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value.toString(),
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="localCode">Local Code</label>
          <input
            type="text"
            name="localCode"
            value={state.airport.localCode}
            placeholder="Local Code"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="isoCountry">ISO Country</label>

          <input
            type="text"
            name="isoCountry"
            value={state.airport.isoCountry}
            placeholder="ISO Country"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>
        <div className="form-group">
          <label htmlFor="isoRegion">ISO Region</label>

          <input
            type="text"
            name="isoRegion"
            value={state.airport.isoRegion}
            placeholder="ISO Region"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <input type="submit" value="Update Airport"></input>
      </form>

      {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
    </Fragment>
  );
};

export default SingleAirportContent;
