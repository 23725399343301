import React, { Fragment, useReducer, useEffect } from "react";
import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import ai from "../../helpers/axios";
import * as Sentry from "@sentry/react";

import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";

const reducer = (state, action) => {
  switch (action.type) {
    case "search": {
      return {
        ...state,
        query: action.query,
      };
    }

    case "fieldChanged": {
      return {
        ...state,
        [action.field]: action.value,
      };
    }

    case "dataDownloaded": {
      let userRoles = { id: "", label: "", value: "" };
      if (action.payload.data.user.userRoles.length > 0) {
        userRoles = {
          id: action.payload.data.user.userRoles[0].id,
          name: action.payload.data.user.userRoles[0].name,
          description: action.payload.data.user.userRoles[0].description,
          value: action.payload.data.user.userRoles[0].id,
          label: action.payload.data.user.userRoles[0].name,
        };
      }

      return {
        ...state,
        user: action.payload.data.user,
        phone: action.payload.data.user.user_metadata.phoneNumber,
        email: action.payload.data.user.email,
        firstName: action.payload.data.user.name.split(" ")[0],
        lastName: action.payload.data.user.name.split(" ")[1],
        selectedOrganization: {
          label: action.payload.data.user.app_metadata.organizationName,
          value: action.payload.data.user.app_metadata.organizationId,
        },
        userRoles,
        // availableRoles: [
        //   ...action.payload.data.roles.map((role) => {
        //     return { ...role, label: role.name, value: role.id };
        //   }),
        // ],
      };
    }

    default:
      break;
  }
  return state;
};

const OutsideOrgSingleUserContent = (props) => {
  const { id } = useParams();

  const history = useHistory();

  const initialState = {
    organizations: [],
    selectedOrganization: {},
    user: {
      phone: "",
      email: "",
      firstName: "",
      lastName: "",
      name: " ",
      user_metadata: {
        signature: "",
        phoneNumber: "",
      },
      app_metadata: {
        organizationId: "",
        organizationName: "",
        accountDirectorId: "",
      },
    },
    phone: "",
    email: "",
    firstName: "",
    lastName: "",
    userRoles: { id: "", label: "", value: "" },
  };

  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    document.title = "Edit User | Flight Deck by evoJets";
    // loadOrganizations();
    getData();
  }, []);

  const getData = async () => {
    dispatch({ type: "dataBeganDownloading" });

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let users = await ai
      .auth(token)
      .get(`/api/users/myOrganization/${id}`)
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
      });

    // let roles = await ai
    //   .auth(token)
    //   .get(`/api/users/availableRoles`)
    //   .catch((error) => {
    //     console.error(error);
    //     Sentry.captureException(error);
    //   });

    let roles = [];

    if (users && roles) {
      let data = { ...users.data, ...roles.data };

      dispatch({
        type: "dataDownloaded",
        payload: {
          data,
        },
      });
    }
  };

  const loadOrganizations = async (inputValue) => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });
    let { data } = await ai.auth(token).get(`/api/organizations/list`);

    console.log(data);

    if (data.organizations.length > 0) {
      console.log(data);

      let organizationsFormatted = data.organizations.map((organization) => {
        return {
          ...organization,
          label: organization.name,
          value: organization._id,
        };
      });

      dispatch({
        type: "fieldChanged",
        field: "organizations",
        value: organizationsFormatted,
      });

      return data;
    } else {
      return [];
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let formData = {
      firstName: state.firstName,
      lastName: state.lastName,
      name: `${state.firstName} ${state.lastName}`,
      phone: state.phone,
      email: state.email,
    };
    console.log(formData);

    let response = await ai
      .auth(token)
      .put(`/api/users/myOrganization/${id}`, formData)
      .catch((error) => Sentry.captureException(error));

    if (response && response.data) {
      history.push({ pathname: "/settings/users" });
      // console.log(response);
      setTimeout(() => {
        toast.success("User Updated Successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }, 50);
    }
  };

  return (
    <Fragment>
      <h2 id="addAirportHeading">Edit User</h2>
      <form id="" className="base-form two-column" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="firstName">First Name</label>
          <input
            type="text"
            name="firstName"
            value={state.firstName}
            placeholder="First Name"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="lastName">Last Name</label>
          <input
            type="text"
            name="lastName"
            value={state.lastName}
            placeholder="Last Name"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="phone">Phone Number</label>
          <input
            type="text"
            name="phone"
            value={state.phone}
            placeholder="Phone Number"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="phone">Email Address</label>
          <input
            type="email"
            name="email"
            value={state.email}
            placeholder="Email Address"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        {/* <div className="form-group">
          <label htmlFor="organization">Organization</label>

          <Select
            key={`organization-options-key-${JSON.stringify(
              state.user.app_metadata
            )}`}
            name="organization"
            className="organization-select"
            classNamePrefix="select"
            options={state.organizations}
            defaultValue={state.selectedOrganization}
            placeholder="Organization"
            onChange={(selectedOrganization) => {
              dispatch({
                type: "fieldChanged",
                field: "selectedOrganization",
                value: selectedOrganization,
              });
            }}
          ></Select>
        </div> */}
        {/* 
        <div className="form-group">
          <label htmlFor="userRoles">User Role</label>

          <Select
            key={`role-options-key-${JSON.stringify(state.userRoles)}`}
            name="userRoles"
            className="organization-select"
            classNamePrefix="select"
            options={state.availableRoles}
            defaultValue={state.userRoles}
            placeholder="User Role"
            onChange={(selectedRole) => {
              dispatch({
                type: "fieldChanged",
                field: "userRoles",
                value: selectedRole,
              });
            }}
          ></Select>
        </div> */}

        <input type="submit" value="Save User"></input>
      </form>

      {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
    </Fragment>
  );
};

export default OutsideOrgSingleUserContent;
