import React, { Fragment, useEffect, useReducer } from "react";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth0 } from "@auth0/auth0-react";

import ai from "../helpers/axios";
import { flightTagOptions as tagOptions } from "../helpers/tag-options";
import columns from "../helpers/atc-flight-table-columns";

import Search from "./search";
import TableBulkActions from "./table-bulk-actions";
import AddFlightForm from "./add-flight-form";
import FlightTable from "./flight-table";

import * as Sentry from "@sentry/react";

const reducer = (state, action) => {
  switch (action.type) {
    case "search": {
      return {
        ...state,
        query: action.query,
      };
    }
    case "beginFlightSubmit": {
      return {
        ...state,
        loading: false,
        tagFilters: [],
        flights: {
          ...state.flights,
          data: [...state.flights.data].concat(action.payload.optimisticData),
        },
      };
    }
    case "dataBeganDownloading": {
      return {
        ...state,
        flights: {
          ...state.flights,
          loading: action.payload.loadingStatus,
        },
      };
    }
    case "fieldChanged": {
      return {
        ...state,
        [action.field]: action.value,
      };
    }

    case "rowsDeleted": {
      return {
        ...state,
        clearSelectedRows: !state.clearSelectedRows,
        selectedRows: [],
      };
    }
    case "rowSelection": {
      return {
        ...state,
        selectedRows: action.payload.selectedRows,
        allSelected: action.payload.allSelected,
      };
    }
    case "tripTypeChanged": {
      return {
        ...state,
        tripType: action.payload.tripType,
        returnDate: action.payload.tripType === 1 ? null : state.returnDate,
      };
    }
    case "airportChange": {
      return {
        ...state,
        [action.airportType]: action.payload,
      };
    }
    case "dataDownloaded": {
      return {
        ...state,
        flights: {
          ...state.flights,
          data: action.payload.data,
          loading: false,
        },
      };
    }
    case "flightsFiltered": {
      return {
        ...state,
        flights: {
          ...state.flights,
          filteredFlights: action.payload.filteredFlights,
        },
      };
    }
    default:
      break;
  }
  return state;
};

const initialState = {
  flights: {
    loading: false,
    data: [],
    filteredFlights: null,
  },
  query: "",
  selectedRows: [],
  allSelected: false,
  clearSelectedRows: false,
  flightNotes: "",
  tripType: 1,
  flightPriority: 0,
  departureDate: null,
  returnDate: null,
  passengers: "",
  aircraftInputField: "",
  ownerInputField: "",
  selectedAircraft: [],
  selectedOwner: "",
  departureAirportInput: "",
  arrivalAirportInput: "",
  tagFilters: [],
  ownerOptions: [],
};

const FlightBoardContent = () => {
  const { getAccessTokenSilently, loginWithRedirect, user } = useAuth0();
  let ip =
    Math.floor(Math.random() * 255) +
    1 +
    "." +
    (Math.floor(Math.random() * 255) + 0) +
    "." +
    (Math.floor(Math.random() * 255) + 0) +
    "." +
    (Math.floor(Math.random() * 255) + 0);
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleSearch = (e) => {
    let query = e.target.value.toLowerCase();
    dispatch({ type: "search", query });
    if (query.length === 0 || query.length > 2) {
      filterFlights(query, state.flights, state.tagFilters);
    }
  };

  const filterFlights = (query, allFlights, selectedTags) => {
    let filteredFlights = [...allFlights.data];

    console.log("inside filter flights", query, selectedTags);

    filteredFlights = filteredFlights.filter((flight) => {
      return (
        flight.departureAirport.airportName.toLowerCase().indexOf(query) > -1 ||
        flight.arrivalAirport.airportName.toLowerCase().indexOf(query) > -1
      );
    });

    if (selectedTags.length > 0) {
      filteredFlights = filteredFlights.filter((flight) => {
        return selectedTags.some((tag) => flight.flightPriority === tag.value);
      });
    }

    dispatch({
      type: "flightsFiltered",
      payload: {
        filteredFlights,
      },
    });
  };

  const getData = async (dbQuery, loadSilently) => {
    let loadingStatus = true;
    if (loadSilently) {
      loadingStatus = false;
    }

    dispatch({ type: "dataBeganDownloading", payload: { loadingStatus } });

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });
    let response = await ai
      .auth(token)
      .get(`/api/flights/list`, {
        params: {
          query: dbQuery,
        },
      })
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
      });

    if (response && response.data) {
      dispatch({
        type: "dataDownloaded",
        payload: {
          data: response.data,
        },
      });
    }
  };

  useEffect(() => {
    getData("");
    document.title = "Flight Board | Flight Deck";
    loadOwnerOptions();
  }, []);

  const handleDepartureChange = (selectedAirport) => {
    dispatch({
      type: "airportChange",
      airportType: "departureAirport",
      payload: {
        airportName: selectedAirport?.label || "",
        id: selectedAirport?.value || "",
        code: selectedAirport?.code || "",
      },
    });
  };

  const handleArrivalChange = (selectedAirport) => {
    dispatch({
      type: "airportChange",
      airportType: "arrivalAirport",
      payload: {
        airportName: selectedAirport?.label || "",
        id: selectedAirport?.value || "",
        code: selectedAirport?.code || "",
      },
    });
  };

  const handleSelection = function (rowData) {
    dispatch({
      type: "rowSelection",
      payload: {
        selectedRows: rowData.selectedRows,
        allSelected: rowData.allSelected,
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let fieldsFilled =
      state.departureAirport.airportName &&
      state.departureAirport.code &&
      state.departureAirport.id &&
      state.arrivalAirport.airportName &&
      state.arrivalAirport.code &&
      state.arrivalAirport.id &&
      state.departureDate &&
      state.flightPriority &&
      state.tripType &&
      state.selectedAircraft.length >= 1 &&
      state.selectedOwner.value &&
      state.selectedOwner.label &&
      state.passengers &&
      state.selectedOwner.email;

    if (!fieldsFilled) {
      alert("Please fill in all fields.");
      return;
    }

    if (state.tripType === 2) {
      if (!state.returnDate) {
        alert("Please set a return date.");
        return;
      }
    }

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let formattedAircraft = state.selectedAircraft.map((aircraft) => {
      return {
        aircraftName: aircraft.label,
        aircraftId: aircraft.value,
      };
    });

    let formData = {
      departureAirport: state.departureAirport,
      arrivalAirport: state.arrivalAirport,
      flightNotes: state.flightNotes,
      tripType: state.tripType,
      flightPriority: state.flightPriority,
      departureDate: state.departureDate,
      passengers: state.passengers,
      aircraft: formattedAircraft,
      owner: {
        ownerId: state.selectedOwner.value,
        ownerName: state.selectedOwner.label,
        ownerEmail: state.selectedOwner.email,
      },
    };

    console.log("form data", formData);

    let optimisticData = [
      {
        departureAirport: state.departureAirport,
        arrivalAirport: state.arrivalAirport,
        flightNotes: state.flightNotes,
        tripType: state.tripType,
        flightPriority: state.flightPriority,
        departureDate: state.departureDate,
        aircraft: formattedAircraft,
        owner: {
          ownerId: state.selectedOwner.value,
          ownerName: state.selectedOwner.label,
          ownerEmail: state.selectedOwner.email,
        },
        createdBy: {
          userName: user.name,
        },
      },
    ];

    console.log("optimistic data", optimisticData);

    if (state.tripType === 2) {
      formData.returnDate = state.returnDate;
      optimisticData[0].returnDate = state.returnDate;
    }

    dispatch({
      type: "beginFlightSubmit",
      payload: {
        optimisticData,
      },
    });

    try {
      let { data } = await ai.auth(token).post(`/api/flights/create`, formData);
      console.log(data);
      await getData("", true);
      toast.success("Flight Added Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } catch (error) {
      toast.error("There was an error adding your flight.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      await getData("", true);
      console.log(error);
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let rowIds = state.selectedRows.map((item) => item._id);
    let { data } = await ai.auth(token).delete("/api/flights/deleteMany", {
      data: { ids: rowIds },
    });

    if (data) {
      getData("", true);

      toast.success("Flight Deleted Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      console.log("No Rows Deleted");
    }

    // state changes happen regardless if rows successfully deleted or not
    dispatch({
      type: "rowsDeleted",
    });
  };

  const handleTagChange = (selectedTags, actionData) => {
    let tags = selectedTags ? selectedTags.map((tag) => tag.value) : [];

    dispatch({
      type: "fieldChanged",
      field: "selectedTags",
      value: tags,
    });
  };

  const handleTagFilterChange = (selectedTags, actionData) => {
    let tags = selectedTags ? selectedTags : [];

    dispatch({
      type: "fieldChanged",
      field: "tagFilters",
      value: tags,
    });

    filterFlights(state.query, state.flights, tags);
  };

  const handleAircraftInputChange = (newValue) => {
    const inputValue = newValue;
    dispatch({
      type: "fieldChanged",
      field: "aircraftInputField",
      value: inputValue,
    });
    return inputValue;
  };

  const handleAircraftSelection = (selectedAircraft, actionData) => {
    console.log(selectedAircraft);
    dispatch({
      type: "fieldChanged",
      field: "selectedAircraft",
      value: selectedAircraft,
    });
    return selectedAircraft;
  };

  const loadAircraftOptions = async (inputValue) => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });
    // let { data } = await ai.auth(token).get(`/api/aircrafts/list`, {
    //   params: {
    //     query: inputValue,
    //     page: 1,
    //     countPerPage: 50,
    //   },
    // });

    let { data } = await ai
      .auth(token)
      .get(`/api/aircrafts/search?q=${inputValue}`, {
        params: {
          query: inputValue,
          // page: 1,
          // countPerPage: 50,
        },
      });

    if (data.aircraft.length >= 1) {
      console.log(data.aircraft);
      return data.aircraft.map((aircraft) => {
        return { value: aircraft._id, label: aircraft.name };
      });
    } else {
      return [];
    }
  };

  const handleOwnerInputChange = (newValue) => {
    dispatch({
      type: "fieldChanged",
      field: "ownerInputField",
      value: newValue,
    });
    return newValue;
  };

  const handleOwnerSelection = (selectedOwner) => {
    console.log(selectedOwner);
    dispatch({
      type: "fieldChanged",
      field: "selectedOwner",
      value: selectedOwner,
    });
    return selectedOwner;
  };

  const loadOwnerOptions = async (inputValue) => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });
    let response = await ai
      .auth(token)
      .get(`/api/users/roles/sales`, {})
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
      });

    if (response && response.data) {
      if (response.data.users.length >= 1) {
        console.log(response.data.users);
        let results = response.data.users.map((user) => {
          return { value: user.user_id, label: user.name, email: user.email };
        });

        dispatch({
          type: "fieldChanged",
          field: "ownerOptions",
          value: results,
        });

        return results;
      } else {
        return [];
      }
    }
  };

  const loadAirportOptions = async (inputValue) => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });
    let response = await ai
      .auth(token)
      .get(`/api/airports/search?q=${inputValue}&ip_address=${ip}`)
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
      });

    let options = [];
    if (response.data.length >= 1) {
      options = response.data.map((i) => ({
        value: i._id,
        label: i.airportName,
        code: i.code,
      }));
    }

    return options;
  };

  return (
    <Fragment>
      {/* <ToastContainer transition={Slide} /> */}

      <div id="search-bulk-actions-container" className="homepage">
        <Search onSearch={handleSearch} query={state.query}></Search>
        <TableBulkActions
          state={state}
          handleDelete={handleDelete}
          tagOptions={tagOptions}
          handleTagFilterChange={handleTagFilterChange}
        />
      </div>
      <FlightTable
        onSelection={handleSelection}
        columns={columns}
        state={state}
      ></FlightTable>
      <AddFlightForm
        dispatch={dispatch}
        state={state}
        handleSubmit={handleSubmit}
        handleDepartureChange={handleDepartureChange}
        handleArrivalChange={handleArrivalChange}
        handleTagChange={handleTagChange}
        tagOptions={tagOptions}
        ownerOptions={state.ownerOptions}
        handleAircraftInputChange={handleAircraftInputChange}
        loadAircraftOptions={loadAircraftOptions}
        handleAircraftSelection={handleAircraftSelection}
        handleOwnerSelection={handleOwnerSelection}
        loadAirportOptions={loadAirportOptions}
      ></AddFlightForm>

      {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
    </Fragment>
  );
};

export default FlightBoardContent;
