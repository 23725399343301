import React, { Fragment, useEffect, useReducer } from "react";
import { Link } from "react-router-dom";

import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";

import ai from "../../helpers/axios";
import * as Sentry from "@sentry/react";

import columns from "./prospect-columns";
import Search from "../search";
import LeadBulkActions from "../leads/lead-bulk-actions";
import DataTableServerPagination from "../data-table-server-pagination";

import PageHeaderRowSearch from "../shared/page-header-row-search";

const reducer = (state, action) => {
  switch (action.type) {
    case "search": {
      return {
        ...state,
        query: action.query,
      };
    }
    case "dataBeganDownloading": {
      return {
        ...state,
        loading: action.payload.loadingStatus,
      };
    }
    case "rowsDeleted": {
      return {
        ...state,
        clearSelectedRows: !state.clearSelectedRows,
        selectedRows: [],
      };
    }
    case "rowSelection": {
      return {
        ...state,
        selectedRows: action.payload.selectedRows,
        allSelected: action.payload.allSelected,
      };
    }
    case "dataDownloaded": {
      console.log("Data Downloaded", action.payload.data);
      return {
        ...state,
        prospects: {
          data: action.payload.data.prospects,
        },
        loading: false,
        totalLength: action.payload.data.countTotal,
      };
    }
    case "pageChanged": {
      return {
        ...state,
        currentPage: action.payload.page,
      };
    }
    case "tableSort": {
      return {
        ...state,
        sortBy: action.payload.sortBy,
        sortDirection: action.payload.sortDirection,
      };
    }
    case "stageFilterChanged": {
      return {
        ...state,
        stageFilters: action.payload.values,
      };
    }
    case "rowsPerPageChanged": {
      return {
        ...state,
        countPerPage: action.payload.currentRowsPerPage,
      };
    }
    case "leadsAssigned": {
      return {
        ...state,
        leads: {
          data: state.leads.data.map((item) => {
            if (action.payload.leadIds.includes(item._id)) {
              console.log(item.firstName, action.payload.assigneeName);
              //item just assigned
              item.assigneeName = action.payload.assigneeName;
              item.assigneeId = action.payload.assigneeId;
            }
            return item;
          }),
        },
      };
    }

    case "fieldChanged": {
      return {
        ...state,
        [action.field]: action.value,
      };
    }

    case "tableDataChanged": {
      return {
        ...state,
        leads: {
          data: action.value,
        },
      };
    }

    default:
      break;
  }
  return state;
};

const ProspectsContent = (props) => {
  const initialState = {
    prospects: {
      data: [],
    },
    loading: true,
    countPerPage: 25,
    currentPage: 1,
    selectedRows: [],
    allSelected: false,
    tagFilters: [],
    totalLength: null,
    clearSelectedRows: false,
    ownerInputField: "",
    selectedOwner: null,
    query: "",
    stageFilters: ["newLead"],
    stageFilters: [],
    sortDirection: "desc",
    sortBy: "createdAt",
    ownerOptions: [],
    teamMembers: [],
    defaultStageFilters: { label: "New Lead", value: "newLead" },
  };

  if (props.prefilters && props.prefilters.length) {
    initialState.stageFilters = props.prefilters;
  }

  if (props.defaultStageFilters) {
    initialState.defaultStageFilters = props.defaultStageFilters;
  }

  if (props.qfsOnly) {
    initialState.defaultStageFilters = [];
    initialState.stageFilters = [];
  }

  const { user } = useAuth0();

  const userRoles = user && user["https://app.evojets.com/roles"];

  const superAdminOrAd = userRoles.some(function (role) {
    return "Super Admin" === role || "Account Director" === role;
  });

  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const [state, dispatch] = useReducer(reducer, initialState);

  console.log(state);

  const getData = async (
    dbQuery,
    loadSilently,
    stageFilters,
    sortBy,
    sortDirection
  ) => {
    let loadingStatus = true;
    if (loadSilently) {
      loadingStatus = false;
    }

    console.log("props.showOnlyMine", props.showOnlyMine);

    dispatch({ type: "dataBeganDownloading", payload: { loadingStatus } });

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let response = await ai
      .auth(token)
      .get(`/api/prospects/list`, {
        params: {
          query: dbQuery,
          page: state.currentPage,
          countPerPage: state.countPerPage,
          sortBy,
          sortDirection,
          stageFilters,
          myProspects: props.showOnlyMine,
          myTeamProspects: props.showMyTeamProspects,
        },
      })
      .catch((error) => {
        console.error(error.response.data);
        Sentry.captureException(error);
      });

    if (response) {
      dispatch({
        type: "dataDownloaded",
        payload: {
          data: response.data,
        },
      });
    }
  };

  useEffect(() => {
    getData(
      state.query,
      false,
      state.stageFilters,
      state.sortBy,
      state.sortDirection
    );
    loadOwnerOptions();
    getTeamMembers();
    document.title = `${
      props.pageTitle ? props.pageTitle : "Prospects"
    } | Flight Deck by evoJets`;
  }, [state.currentPage, state.countPerPage]);

  const handlePageChange = (page, totalRows) => {
    console.log(page, totalRows);

    dispatch({
      type: "pageChanged",
      payload: {
        page,
        totalRows,
      },
    });
  };

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    dispatch({
      type: "rowsPerPageChanged",
      payload: {
        currentRowsPerPage,
        currentPage,
      },
    });
  };

  const handleSelection = function (rowData) {
    dispatch({
      type: "rowSelection",
      payload: {
        selectedRows: rowData.selectedRows,
        allSelected: rowData.allSelected,
      },
    });
  };

  //   const handleDelete = async (e) => {
  //     e.preventDefault();

  //     const token = await getAccessTokenSilently().catch((e) => {
  //       console.error(e);
  //       loginWithRedirect();
  //     });

  //     let rowIds = state.selectedRows.map((item) => item._id);
  //     let { data } = await ai.auth(token).delete("/api/airports/deleteMany", {
  //       data: { ids: rowIds },
  //     });

  //     if (data.deletedCount) {
  //       getData("", true);

  //       toast.success("Lead Deleted Successfully", {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //       });
  //     } else {
  //       console.log("No Rows Deleted");
  //     }

  //     // state changes happen regardless if rows successfully deleted or not
  //     dispatch({
  //       type: "rowsDeleted",
  //     });
  //   };

  const handleSearch = (e) => {
    let query = e.target.value.toLowerCase();

    dispatch({ type: "search", query });
    getData(query, true, state.stageFilters, state.sortBy, state.sortDirection);
  };

  const loadOwnerOptions = async () => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });
    let { data } = await ai.auth(token).get(`/api/users/roles/sales`, {});

    if (data.users.length >= 1) {
      console.log(data.users);
      let results = data.users.map((user) => {
        return { value: user.user_id, label: user.name, email: user.email };
      });

      dispatch({ type: "fieldChanged", field: "ownerOptions", value: results });

      return results;
    } else {
      return [];
    }
  };

  // const handleOwnerInputChange = (newValue) => {
  //   dispatch({
  //     type: "fieldChanged",
  //     field: "ownerInputField",
  //     value: newValue,
  //   });
  //   return newValue;
  // };

  // const handleOwnerSelection = async (selectedOwner) => {
  //   dispatch({
  //     type: "fieldChanged",
  //     field: "selectedOwner",
  //     value: selectedOwner,
  //   });

  //   console.log(selectedOwner);

  //   const token = await getAccessTokenSilently().catch((e) => {
  //     console.error(e);
  //     loginWithRedirect();
  //   });

  //   // hit api endpoint to update assignment -leads

  //   let leadIds = state.selectedRows.map((row) => row._id);

  //   let { data } = await ai
  //     .auth(token)
  //     .put(`/api/leads/bulkAssign`, {
  //       leadIds: leadIds,
  //       assigneeId: selectedOwner.value,
  //     })
  //     .catch((e) => console.error(e));

  //   if (data) {
  //     console.log(data);

  //     getData(
  //       state.query,
  //       true,
  //       state.stageFilters,
  //       state.sortBy,
  //       state.sortDirection
  //     );

  //     dispatch({
  //       type: "fieldChanged",
  //       field: "selectedRows",
  //       value: [],
  //     });

  //     dispatch({
  //       type: "fieldChanged",
  //       field: "clearSelectedRows",
  //       value: !state.clearSelectedRows,
  //     });

  //     dispatch({
  //       type: "fieldChanged",
  //       field: "selectedOwner",
  //       value: null,
  //     });

  //     return data;
  //   }

  //   return selectedOwner;
  // };

  // const selectedRowCriteria = (row) => {
  //   let ids = state.selectedRows.map((item) => item._id);
  //   return ids.includes(row._id);
  // };

  // const handleLeadStageUpdate = async (selectedStage) => {
  //   let leadIds = state.selectedRows.map((row) => row._id);
  //   let stage = null;

  //   stage = selectedStage?.value;

  //   if (!stage || !leadIds) {
  //     return;
  //   }

  //   const token = await getAccessTokenSilently().catch((e) => {
  //     console.error(e);
  //     loginWithRedirect();
  //   });

  //   let { data } = await ai
  //     .auth(token)
  //     .put(`/api/prospects/bulkUpdateStages`, {
  //       leadIds,
  //       stage,
  //     })
  //     .catch((e) => console.error(e));

  //   if (data) {
  //     console.log(data);

  //     getData(
  //       state.query,
  //       true,
  //       state.stageFilters,
  //       state.sortBy,
  //       state.sortDirection
  //     );

  //     dispatch({
  //       type: "fieldChanged",
  //       field: "selectedRows",
  //       value: [],
  //     });

  //     dispatch({
  //       type: "fieldChanged",
  //       field: "clearSelectedRows",
  //       value: !state.clearSelectedRows,
  //     });

  //     dispatch({
  //       type: "fieldChanged",
  //       field: "selectedOwner",
  //       value: null,
  //     });

  //     return data;
  //   }
  // };

  // const disableStageAssignmentSelect = () => {
  //   let disabled = "disabled";

  //   if (state.selectedRows.length) {
  //     let someRowsAreNotAssigned = state.selectedRows.some(
  //       (row) => row.assigneeId === undefined
  //     );
  //     if (!someRowsAreNotAssigned) {
  //       disabled = "";
  //     }
  //   }

  //   return disabled;
  // };

  // const disableAssignmentSelect = () => {
  //   let disabled = true;

  //   if (state.selectedRows.length) {
  //     disabled = false;
  //   }

  //   return disabled;
  // };

  const stageFilterOptions = [
    { label: "New Prospect", value: "newLead" },
    { label: "In Progress", value: "inProgress" },
    { label: "Warm Prospect", value: "warmProspect" },
    // { label: "Unqualified", value: "unqualified" },
    { label: "Archived", value: "archived" },
    // { label: "In Limbo", value: "inLimbo" },
    // { label: "Signed Contract", value: "signedContract" },
    // { label: "Trip Complete", value: "tripComplete" },
    // { label: "Closed - No Reply", value: "closedNoReply" },
    // { label: "Closed - Ghost", value: "closedGhost" },
    { label: "Repeat Client", value: "repeatClient" },
    // { label: "Closed - Lost to Competitor", value: "closedCompetitor" },
  ];

  const getTeamMembers = async () => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let { data } = await ai.auth(token).get(`/api/users/getTeamMembers`);

    if (data.length) {
      data = data.map((member) => member.user_id);
    }

    dispatch({
      type: "fieldChanged",
      field: "teamMembers",
      value: data,
    });
  };

  const handleStageFilterChange = (selectedValues, action) => {
    console.log(selectedValues, action);
    let values = [];
    if (selectedValues) {
      values = selectedValues.map(({ value }) => value);
    }

    dispatch({ type: "stageFilterChanged", payload: { values } });

    getData(state.query, true, values, state.sortBy, state.sortDirection);
  };

  const handleSort = (column, sortDirection) => {
    console.log("sorted", column.selector, sortDirection);

    dispatch({
      type: "tableSort",
      payload: {
        sortBy: column.selector,
        sortDirection,
      },
    });

    getData(
      state.query,
      true,
      state.stageFilters,
      column.selector,
      sortDirection
    );

    //keyField is required for server sort
  };

  const onRowClicked = (row, event) => {
    // confirm if row id is inside selectedRows array
    let arr = [...state.selectedRows];
    arr = arr.filter((selectedRow) => selectedRow._id === row._id);

    if (arr.length) {
      // row is already in array...uncheck it

      let newSelectedRowsArr = [...state.selectedRows];

      newSelectedRowsArr = newSelectedRowsArr.filter(
        (selectedRow) => selectedRow._id !== row._id
      );

      dispatch({
        type: "fieldChanged",
        field: "selectedRows",
        value: newSelectedRowsArr,
      });

      let newData = [...state.prospects.data];

      dispatch({ type: "tableDataChanged", value: newData });

      return;
    } else {
      // push row into selectedRows array
      let currentSelectedRowsArray = [...state.selectedRows];
      currentSelectedRowsArray.push(row);

      dispatch({
        type: "fieldChanged",
        field: "selectedRows",
        value: currentSelectedRowsArray,
      });

      let newData = [...state.prospects.data];
      dispatch({ type: "tableDataChanged", value: newData });

      return;
    }
  };

  const preSelectCriteria = (row) => {
    let selectedRows = [...state.selectedRows];

    selectedRows = selectedRows.filter(
      (selectedRow) => selectedRow._id === row._id
    );

    console.log("SELECTED ROWS", selectedRows.length);

    return selectedRows.length;
  };

  return (
    <Fragment>
      {/* <pre>{JSON.stringify(state.query, null, 2)}</pre> */}

      {/* <pre>{JSON.stringify(state.teamMembers, null, 2)}</pre>
      <pre>{JSON.stringify(user.sub, null, 2)}</pre>
 */}

      <PageHeaderRowSearch
        h1={`${props.pageTitle ? props.pageTitle : "Prospect Management"}`}
        pretitle="Business"
        breadcrumbs={[{ link: "/prospects", label: "Prospects" }]}
        rightColumn={
          <div id="quote-actions-row">
            <Search
              onSearch={handleSearch}
              placeholder="Search Prospects"
              query={state.query}
              className="lead-search"
            ></Search>
          </div>
        }
      ></PageHeaderRowSearch>
      {/* 
      <LeadBulkActions
        superAdminOrAd={superAdminOrAd}
        ownerOptions={state.ownerOptions}
        handleOwnerSelection={handleOwnerSelection}
        state={state}
        handleLeadStageUpdate={handleLeadStageUpdate}
        disableStageAssignmentSelect={disableStageAssignmentSelect}
        stageFilterOptions={stageFilterOptions}
        handleStageFilterChange={handleStageFilterChange}
        disableAssignmentSelect={disableAssignmentSelect}
        defaultStageFilters={state.defaultStageFilters}
      ></LeadBulkActions> */}

      <DataTableServerPagination
        onSelection={handleSelection}
        key={JSON.stringify(columns)}
        columns={columns}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
        state={state}
        data={state.prospects.data}
        defaultSortField={"createdAt"}
        defaultSortAsc={false}
        selectableRowSelected={preSelectCriteria}
        handleSort={handleSort}
        sortServer={true}
        onRowClicked={onRowClicked}
      ></DataTableServerPagination>

      {/* <pre>{JSON.stringify(state.prospects.data, null, 2)}</pre> */}
    </Fragment>
  );
};

export default ProspectsContent;
