import React, { Fragment, useEffect, useReducer } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import Loader from "../loader";
import DataTable from "react-data-table-component";
import ai from "../../helpers/axios";
import ReactModal from "react-modal";
import { Link } from "react-router-dom";
import TextInput from "../text-input";
import moment from "moment";
import { toast } from "react-toastify";
import Select from "react-select";

import PageHeaderRowSearch from "../shared/page-header-row-search";
import ProspectsAutocomplete from "../prospects/prospects-autocomplete";
import CreateProspectModal from "../prospects/create-prospect-modal";

import "react-json-pretty/themes/monikai.css";
import JSONPretty from "react-json-pretty";

import {
  archiveTags,
  warmProspectTags,
  repeatClientTags,
} from "../leads/lead-stage-tags";

const toastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "dataBeganDownloading": {
      return {
        ...state,
        loading: action.payload.loadingStatus,
      };
    }
    case "rowsDeleted": {
      return {
        ...state,
        clearSelectedRows: !state.clearSelectedRows,
        selectedRows: [],
      };
    }
    case "rowSelection": {
      return {
        ...state,
        selectedRows: action.payload.selectedRows,
        allSelected: action.payload.allSelected,
      };
    }
    case "dataDownloaded": {
      // console.log(action.payload.data);
      return {
        ...state,
        phoneCalls: action.payload.data.phoneCalls,
        loading: false,
        totalLength: action.payload.data.countTotal,
      };
    }
    case "fieldChanged": {
      return {
        ...state,
        [action.field]: action.value,
      };
    }

    case "prospectAutoSelected": {
      let prospectOptions = [action.value, ...state.prospectOptions];

      return {
        ...state,
        selectedProspect: action.value,
        prospectOptions,
        modalOpen: true,
      };
    }

    case "openModal": {
      console.log("OPEN MODAL PAYLOAD", action.payload);
      return {
        ...state,
        modalOpen: true,
        modalRow: action.payload.row || null,
        modalAction: action.payload.action || null,
      };
    }

    case "closeModal": {
      return {
        ...state,
        modalOpen: false,
        modalRow: null,
        modalAction: null,
        selectedProspect: null,
        outreachProspectId: null,
        selectedStageTag: null,
      };
    }

    case "hideModal": {
      return {
        ...state,
        modalOpen: false,
      };
    }

    default:
      break;
  }
  return state;
};

const PhoneCallContent = (props) => {
  const initialState = {
    phoneCalls: [],
    loading: true,
    selectedRows: [],
    allSelected: false,
    totalLength: null,
    clearSelectedRows: false,
    stageFilters: ["newLead"],
    modalOpen: false,
    modalRow: null,
    modalAction: null,
    selectedStageTag: null,
    prospectFirstName: "",
    prospectLastName: "",
    prospectEmail: "",
    ownerOptions: [],
    selectedProspect: null,
    prospectOptions: [],
    prospectExists: false,
    outreachProspectId: null,
    createProspectModalIsOpen: false,
  };

  const { getAccessTokenSilently, loginWithRedirect, user } = useAuth0();

  const userRoles = user && user["https://app.evojets.com/roles"];

  const superAdminOrAd = userRoles.some(function (role) {
    return "Super Admin" === role || "Account Director" === role;
  });

  const dynamicSalesAssistant =
    user &&
    userRoles.some(function (role) {
      return "Dynamic Sales Assistant" === role;
    });
  let prospectOwnerOptions = [
    {
      label: `${user.name}`,
      value: user.sub,
    },
  ];

  if (dynamicSalesAssistant) {
    //dynamic sales assistant
    let parentUsers = user["https://app.evojets.com/appMetadata"]?.parentUsers;

    if (parentUsers?.length) {
      parentUsers.forEach((member) => {
        let formattedMember = {
          label: `${member.name}`,
          value: member.userId,
        };
        prospectOwnerOptions.push(formattedMember);
      });
    }

    console.log("PROSPECT OWNER OPTIONS", prospectOwnerOptions);
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const handleCloseLeadModal = (e) => {
    e.preventDefault();

    dispatch({
      type: "openModal",
      payload: {
        row: state.selectedRows[0],
        action: e.currentTarget.id,
      },
    });
  };

  const columns = [
    {
      name: "Send Quote",
      sortable: false,
      cell: (row) => {
        if (row.leadId) {
          return <Link to={`/leads/${row.leadId}/quotes/new`}>New Quote</Link>;
        }
        return <Link to={`/phone-calls/${row._id}/new-quote`}>New Quote</Link>;
      },
      width: "130px",
    },
    {
      name: "View Lead",
      sortable: false,
      cell: (row) => {
        if (row?.leadId) {
          return <Link to={`/leads/${row.leadId}`}>View</Link>;
        }
        return "-";
      },
      width: "140px",
    },
    // {
    //   name: "Close Lead",
    //   sortable: false,
    //   cell: (row) => {
    //     if (row.leadId) {
    //       return "-";
    //     }

    //     return (
    //       <button
    //         data-row={row._id}
    //         data-phonenumber={row.from}
    //         className="closeLead"
    //         onClick={handleCloseLeadModal}
    //       >
    //         Close Lead
    //       </button>
    //     );
    //   },
    //   width: "130px",
    // },

    {
      name: "Created At",
      selector: "createdAt",
      sortable: true,
      width: "250px",
      cell: (row) => {
        return moment(row.createdAt).format("llll");
      },
    },
    {
      name: "Stage",
      selector: "stage",
      sortable: true,
      width: "130px",
      cell: (row) => {
        if (row.stage === "active") {
          return "Active";
        } else if (row.stage === "inProgress") {
          return "In Progress";
        } else if (row.stage === "archived") {
          return "Archived";
        } else if (row.stage === "warmProspect") {
          return "Warm Prospect";
        } else if (row.stage === "unqualified") {
          return "Unqualified";
        } else if (row.stage === "newLead") {
          return "New Lead";
        }
      },
    },
    {
      name: "From",
      selector: "from",
      sortable: true,
      width: "150px",
    },
    {
      name: "Assigned To",
      selector: "assigneeName",
      sortable: true,
      width: "150px",
    },
    {
      name: "Assigned At",
      selector: "assignedAt",
      sortable: true,
      width: "200px",
      cell: (row) =>
        row.assignedAt ? moment(row.assignedAt).format("llll") : "-",
    },
    {
      name: "Recording",
      selector: "audioLink",
      sortable: true,
      width: "150px",
      cell: (row) => {
        if (row.audioLink && row.audioLink !== "") {
          return (
            <a href={row.audioLink} target="_blank">
              Listen
            </a>
          );
        }

        return "No recording.";
      },
    },
    {
      name: "Opportunity ID",
      selector: "orOpportunityId",
      sortable: true,
      width: "190px",
      cell: (row) => (
        <a
          href={`https://app1a.outreach.io/opportunities/${row.orOpportunityId}/activity`}
          target="_blank"
        >
          {row.orOpportunityId}
        </a>
      ),
    },
    {
      name: "Prospect ID",
      selector: "orProspectId",
      sortable: true,
      width: "150px",
      cell: (row) => {
        if (row.orProspectId) {
          return (
            <a
              href={`https://app1a.outreach.io/prospects/${row.orProspectId}/activity`}
              target="_blank"
            >
              {row.orProspectId}
            </a>
          );
        }

        return "No Prospect";
      },
    },
  ];

  const getData = async (loadSilently, stageFilters) => {
    let loadingStatus = true;
    if (loadSilently) {
      loadingStatus = false;
    }

    console.log(stageFilters);
    dispatch({ type: "dataBeganDownloading", payload: { loadingStatus } });

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let { data } = await ai
      .auth(token)
      .get(`/api/phoneCalls/list`, { params: { stageFilters } });
    console.log(data);
    dispatch({
      type: "dataDownloaded",
      payload: {
        data,
      },
    });
  };

  useEffect(() => {
    getData(false, state.stageFilters);
    loadOwnerOptions();

    document.title = "Phone Calls | Flight Deck";
  }, []);

  const loadOwnerOptions = async () => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });
    console.log("Leads Content");
    let { data } = await ai.auth(token).get(`/api/users/roles/sales`, {});

    if (data.users.length >= 1) {
      console.log(data.users);
      let results = data.users.map((user) => {
        return { value: user.user_id, label: user.name, email: user.email };
      });

      dispatch({ type: "fieldChanged", field: "ownerOptions", value: results });

      return results;
    } else {
      return [];
    }
  };

  const handleSelection = function (rowData) {
    console.log(rowData);

    // check rowData.selectedRows for any rows that are already selected, and remove them from the array, only returning new rows

    //get last row in rowData.selectedRows

    dispatch({
      type: "rowSelection",
      payload: {
        selectedRows: rowData.selectedRows,
        allSelected: rowData.allSelected,
      },
    });
  };

  const handleOwnerSelection = async (selectedOwner) => {
    dispatch({
      type: "fieldChanged",
      field: "selectedOwner",
      value: selectedOwner,
    });

    console.log(selectedOwner);

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    // hit api endpoint to update assignment -leads

    let phoneCallIds = state.selectedRows.map((row) => row._id);

    let { data } = await ai
      .auth(token)
      .put(`/api/phoneCalls/bulkAssign`, {
        phoneCallIds: phoneCallIds,
        assigneeId: selectedOwner.value,
      })
      .catch((e) => console.error(e));

    if (data) {
      console.log(data);

      getData(false, state.stageFilters);

      dispatch({
        type: "fieldChanged",
        field: "selectedRows",
        value: [],
      });

      dispatch({
        type: "fieldChanged",
        field: "clearSelectedRows",
        value: !state.clearSelectedRows,
      });

      dispatch({
        type: "fieldChanged",
        field: "selectedOwner",
        value: null,
      });

      return data;
    }

    return selectedOwner;
  };

  const handlePhoneCallClose = async (e) => {
    e.preventDefault();

    let buttonId = e.currentTarget.id;
    let phoneCall = state.modalRow;
    let stage = "newLead";
    let successMessage = "";

    if (buttonId === "archive-button") {
      stage = "archived";
      successMessage = "Phone call archived.";
    } else if (buttonId === "warm-prospect-button") {
      stage = "warmProspect";
    } else if (buttonId === "unqualified-button") {
      stage = "unqualified";
      successMessage = "Phone call marked as unqualified.";
    }

    console.log(buttonId, phoneCall);

    if (stage === "warmProspect") {
      dispatch({
        type: "fieldChanged",
        field: "showWarmProspectForm",
        value: true,
      });
      return;
    } else {
      dispatch({
        type: "fieldChanged",
        field: "showWarmProspectForm",
        value: false,
      });
    }

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let response = await ai
      .auth(token)
      .put(`/api/phoneCalls/${phoneCall}/closePhoneCall`, { stage })
      .catch((e) => console.error(e));

    if (response) {
      console.log(response.data);
      dispatch({ type: "closeModal" });

      toast.success(successMessage, toastOptions);

      getData(false);

      return response.data;
    }
  };

  const handleWarmProspectSubmit = async (e) => {
    e.preventDefault();

    let phoneCall = state.modalRow;

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let response = await ai
      .auth(token)
      .put(`/api/phoneCalls/${phoneCall}/createProspectAndMarkWarm`, {
        prospectData: {
          ...state.selectedProspect,
        },
      })
      .catch((e) => console.error(e));

    if (response) {
      console.log(response.data);

      dispatch({ type: "closeModal" });

      toast.success("Prospect created and marked warm.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      getData(false);

      return response.data;
    } else {
      toast.error("There was an error. Please try again.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const handleTextInputChange = (e) => {
    return dispatch({
      type: "fieldChanged",
      field: e.target.name,
      value: e.target.value,
    });
  };

  const handleStageFilterChange = (selectedValues, action) => {
    console.log(selectedValues, action);
    let values = [];
    if (selectedValues) {
      values = selectedValues.map(({ value }) => value);
    }

    dispatch({ type: "fieldChanged", field: "stageFilters", value: values });
    console.log(values);
    getData(true, values);
  };

  const stageFilterOptions = [
    { label: "New Lead", value: "newLead" },
    { label: "In Progress", value: "inProgress" },
    // { label: "No Reply", value: "noReply" },
    { label: "Warm Prospect", value: "warmProspect" },
    { label: "Unqualified", value: "unqualified" },
    { label: "Archived", value: "archived" },
  ];

  const disableAssignmentSelect = () => {
    let disabled = true;

    if (state.selectedRows.length) {
      disabled = false;
    }

    return disabled;
  };

  const handleProspectOptionCreation = (inputValue) => {
    console.log("HANDLE PROSPECT OPTION CREATION", inputValue, state);

    dispatch({
      type: "hideModal",
    });
    dispatch({
      type: "fieldChanged",
      field: "createProspectModalIsOpen",
      value: true,
    });
  };

  const handleProspectChange = (selectedProspect) => {
    if (selectedProspect) {
      dispatch({
        type: "fieldChanged",
        field: "selectedProspect",
        value: selectedProspect,
      });

      dispatch({
        type: "fieldChanged",
        field: "outreachProspectId",
        value: selectedProspect.orProspectId,
      });

      dispatch({
        type: "fieldChanged",
        field: "modalOpen",
        value: true,
      });
    } else {
      dispatch({
        type: "fieldChanged",
        field: "outreachProspectId",
        value: null,
      });

      dispatch({
        type: "fieldChanged",
        field: "selectedProspect",
        value: selectedProspect,
      });
    }
  };

  const handleStageUpdateWithTags = async (event) => {
    event.preventDefault();

    let phoneCallId = state.selectedRows.map((row) => row._id)[0];
    let stage =
      state.modalAction === "showWarmProspectModal"
        ? "warmProspect"
        : state.modalAction === "showArchiveModal"
        ? "archived"
        : null;

    if (!stage || !phoneCallId) {
      // if stage or leadIds is invalid, or if more than one lead is selected, exit
      return;
    }

    if (stage === "warmProspect" && !state.selectedProspect) {
      toast.error("Please select a prospect.", toastOptions);
      return;
    }

    if (!state.selectedStageTag) {
      toast.error("Please select a reason.", toastOptions);

      return;
    }

    console.log(
      "STAGE UPDATE WITH TAGS - BEFORE API CALL",
      stage,
      phoneCallId,
      state.selectedStageTag,
      state.selectedProspect
    );

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let response = await ai
      .auth(token)
      .put(`/api/phoneCalls/updateStageWithTag`, {
        phoneCallId,
        stage,
        tag: state.selectedStageTag,
        prospectData: {
          ...state.selectedProspect,
        },
      })
      .catch((e) => console.error(e));

    if (response) {
      console.log(response?.data);

      toast.success(`Phone call successfully updated.`, toastOptions);

      dispatch({ type: "closeModal" });

      getData(false, state.stageFilters);

      dispatch({
        type: "fieldChanged",
        field: "selectedRows",
        value: [],
      });

      dispatch({
        type: "fieldChanged",
        field: "clearSelectedRows",
        value: !state.clearSelectedRows,
      });

      return response?.data;
    }
  };

  return (
    <Fragment>
      {state.loading ? (
        <Loader></Loader>
      ) : (
        <Fragment>
          <PageHeaderRowSearch
            h1={`Phone Calls`}
            pretitle="Business"
            extraClasses="phone-calls mb-30"
            breadcrumbs={[{ link: "/phone-calls", label: "Leads" }]}
            rightColumn={
              <div id="filterByStage">
                <Select
                  className="stage-select phone-call-stage-select"
                  classNamePrefix="select"
                  placeholder="Filter by Stage"
                  isMulti
                  options={stageFilterOptions}
                  onChange={handleStageFilterChange}
                  defaultValue={[{ label: "New Lead", value: "newLead" }]}
                ></Select>
              </div>
            }
          ></PageHeaderRowSearch>

          {superAdminOrAd && (
            <Select
              key={`selectedOwner-${state.selectedOwner}`}
              className="owner-select owner-phone-select mb-30"
              classNamePrefix="select"
              value={state.selectedOwner}
              options={state.ownerOptions}
              placeholder={`Assign ${
                state.selectedRows.length > 1 ? `Phone Calls` : `Phone Call`
              }`}
              isDisabled={disableAssignmentSelect()}
              onChange={handleOwnerSelection}
            ></Select>
          )}

          <div className="mb-20 d-flex">
            <button
              className={`base-button evo-blue mr-20 ${
                state?.selectedRows?.length !== 1 ? "disabled" : ""
              }`}
              id="showWarmProspectModal"
              onClick={handleCloseLeadModal}
            >
              Warm Prospect
            </button>
            <button
              className={`base-button btn-danger ${
                state?.selectedRows?.length !== 1 ? "disabled" : ""
              }`}
              id="showArchiveModal"
              onClick={handleCloseLeadModal}
            >
              Archive
            </button>
          </div>

          <DataTable
            data={state.phoneCalls}
            columns={columns}
            selectableRows
            selectableRowsNoSelectAll
            selectableRowsSingle
            noHeader
            highlightOnHover
            pagination={true}
            paginationPerPage={50}
            paginationRowsPerPageOptions={[50, 100, 150, 200, 250]}
            onSelectedRowsChange={handleSelection}
            clearSelectedRows={state.clearSelectedRows}
            responsive={true}
            defaultSortField="createdAt"
            defaultSortAsc={false}
          ></DataTable>

          <CreateProspectModal
            modalIsOpen={state.createProspectModalIsOpen}
            parentDispatch={dispatch}
            toast={toast}
            defaultPhoneNumber={state.modalPhoneNumber}
            toastOptions={toastOptions}
            showOwnerSelection={dynamicSalesAssistant}
            prospectOwnerOptions={prospectOwnerOptions}
            key={`state.modalPhoneNumber-${state.modalPhoneNumber}`}
          />

          <ReactModal
            isOpen={state.modalOpen}
            shouldCloseOnEsc={true}
            shouldCloseOnOverlayClick={true}
            onRequestClose={() => {
              dispatch({
                type: "closeModal",
              });
            }}
            style={{
              overlay: {},
              content: {
                width: "auto",
                // height: "700px",
                maxWidth: "700px",
                maxHeight: "50%",
              },
            }}
            appElement={document.getElementById("app")}
          >
            {/* if action is to archive, just select a reason, if it's to mark warm - select a prospect and a reason */}

            <h2>Stage Update Confirmation</h2>

            <p>Please select a reason for this update.</p>

            <form
              className="base-form modal-form"
              onSubmit={handleStageUpdateWithTags}
            >
              {state.modalAction === "showWarmProspectModal" && (
                <ProspectsAutocomplete
                  onCreateOption={handleProspectOptionCreation}
                  handleProspectChange={handleProspectChange}
                  prospectOptions={state.prospectOptions}
                  parentDispatch={dispatch}
                  selectedProspect={state.selectedProspect}
                />
              )}

              <div className="form-group mb-0">
                <label>Reason</label>

                <Select
                  key={`stage-tags`}
                  className="base-select mb-0"
                  classNamePrefix="select"
                  // value
                  options={
                    state.modalAction === "showArchiveModal"
                      ? archiveTags
                      : state.modalAction === "showWarmProspectModal"
                      ? warmProspectTags
                      : []
                  }
                  value={state.selectedStageTag}
                  placeholder="Select a Reason"
                  onChange={(selectedTag) => {
                    dispatch({
                      type: "fieldChanged",
                      field: "selectedStageTag",
                      value: selectedTag,
                    });
                  }}
                ></Select>
              </div>
              <button className="base-button evo-blue">Submit</button>
            </form>
          </ReactModal>
          {/* 
          <ReactModal
            isOpen={state.modalOpen}
            shouldCloseOnEsc={true}
            shouldCloseOnOverlayClick={true}
            onRequestClose={() => {
              dispatch({
                type: "closeModal",
              });

              dispatch({
                type: "fieldChanged",
                field: "outreachProspectId",
                value: null,
              });

              dispatch({
                type: "fieldChanged",
                field: "selectedProspect",
                value: null,
              });
            }}
            style={{
              overlay: {},
              content: {
                width: "auto",
                // height: "700px",
                maxWidth: "700px",
                maxHeight: "50%",
              },
            }}
            appElement={document.getElementById("app")}
          >
            <div id="outreach-status-updaters">
              <button
                className="lead-outreach-assignment"
                onClick={handlePhoneCallClose}
                id="archive-button"
              >
                Archive
              </button>
              <button
                className="lead-outreach-assignment"
                onClick={handlePhoneCallClose}
                id="warm-prospect-button"
              >
                Warm Prospect
              </button>
            </div>

            {state.showWarmProspectForm && (
              <form
                className="base-form"
                id="warmProspectForm"
                onSubmit={handleWarmProspectSubmit}
              >
                <h3>Create &amp; Mark Prospect as Warm</h3>
                <ProspectsAutocomplete
                  onCreateOption={handleProspectOptionCreation}
                  handleProspectChange={handleProspectChange}
                  prospectOptions={state.prospectOptions}
                  parentDispatch={dispatch}
                  selectedProspect={state.selectedProspect}
                />

                <input type="submit" value="Submit"></input>
              </form>
            )}
          </ReactModal> */}
        </Fragment>
      )}

      {user &&
        user["https://app.evojets.com/roles"].includes("Super Admin") && (
          <>
            {/* <JSONPretty id="json-pretty" data={user}></JSONPretty>
          <JSONPretty
            id="json-pretty"
            data={(() => {
              let mutatedState = { ...state };
              delete mutatedState.phoneCalls;
              return mutatedState;
            })()}
          ></JSONPretty> */}
          </>
        )}
    </Fragment>
  );
};

export default PhoneCallContent;
