import React, { Fragment, useReducer, useEffect } from "react";
import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import ai from "../../helpers/axios";

import { useHistory, useParams } from "react-router-dom";

import TextInput from "../text-input";
import countryCodes from "../../helpers/country-codes";
import Select from "react-select";

const reducer = (state, action) => {
  switch (action.type) {
    case "search": {
      return {
        ...state,
        query: action.query,
      };
    }
    case "fieldChanged": {
      return {
        ...state,
        exclusionRule: {
          ...state.exclusionRule,
          [action.field]: action.value,
        },
      };
    }

    case "rootFieldChanged": {
      return {
        ...state,
        [action.field]: action.value,
      };
    }
    case "dataBeganDownloading": {
      return {
        ...state,
        loading: true,
      };
    }
    // case "dataDownloaded": {
    //   return {
    //     ...state,
    //     isoCountries: action.payload.data,
    //   };
    // }
    default:
      break;
  }
  return state;
};

const NewExclusionRuleContent = (props) => {
  const history = useHistory();

  const initialState = {
    loading: true,
    isoCountries: countryCodes,
    exclusionRule: {
      active: true,
      tripCategory: "",
      originCountryISOCode: "",
      destinationCountryISOCode: "",
    },
    tripCategorySelect: null,
    originCountryISOCodeSelect: null,
    destinationCountryISOCodeSelect: null,
  };

  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const [state, dispatch] = useReducer(reducer, initialState);

  //   const getData = async () => {
  //     dispatch({ type: "dataBeganDownloading" });

  //     const token = await getAccessTokenSilently().catch((e) => {
  //   console.error(e);
  //   loginWithRedirect();
  // });

  //     let { data } = await ai.auth(token).get(`/api/exclusionRules/ISOCountries`);

  //     dispatch({
  //       type: "dataDownloaded",
  //       payload: {
  //         data,
  //       },
  //     });
  //   };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let formData = { ...state.exclusionRule };

    let { data } = await ai
      .auth(token)
      .post(`/api/exclusionRules/create`, formData);

    console.log("resp", data);

    history.push({ pathname: "/exclusion-rules" });

    setTimeout(() => {
      toast.success("Exclusion Rule Created Successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }, 50);
  };

  useEffect(() => {
    // getData();
    document.title = "Add Exclusion Rule | Flight Deck by evoJets";
  }, []);

  const handleTextInputChange = (e) => {
    let value = e.target.value;

    if (e.target.type === "number") {
      value = parseInt(e.target.value);

      if (
        e.target.value === null ||
        typeof e.target.value === undefined ||
        e.target.value === ""
      ) {
        value = "";
      }
    }

    console.log(e.target.value);

    dispatch({
      type: "fieldChanged",
      field: e.target.name,
      value: value,
    });
  };

  const handleTripCategoryChange = (tripCategory) => {
    dispatch({
      type: "fieldChanged",
      field: "tripCategory",
      value: tripCategory.value,
    });

    dispatch({
      type: "rootFieldChanged",
      field: "tripCategorySelect",
      value: tripCategory,
    });

    return tripCategory;
  };

  const handleOriginCountryChange = (countryCode) => {
    dispatch({
      type: "fieldChanged",
      field: "originCountryISOCode",
      value: countryCode.value,
    });

    dispatch({
      type: "rootFieldChanged",
      field: "originCountryISOCodeSelect",
      value: countryCode,
    });

    return countryCode;
  };

  const handleDestinationCountryChange = (countryCode) => {
    dispatch({
      type: "fieldChanged",
      field: "destinationCountryISOCode",
      value: countryCode.value,
    });

    dispatch({
      type: "rootFieldChanged",
      field: "destinationCountryISOCodeSelect",
      value: countryCode,
    });

    return countryCode;
  };

  return (
    <Fragment>
      <h2 id="addAirportHeading">Add Exclusion Rule</h2>

      <form id="addAirport" onSubmit={handleSubmit}>
        <div className="full-width-row">
          <input
            name="active"
            type="checkbox"
            value="active"
            id="active"
            checked={state.exclusionRule.active}
            onChange={(e) => {
              dispatch({
                type: "fieldChanged",
                field: "active",
                value: e.target.checked ? true : false,
              });
            }}
          ></input>
          <label htmlFor="active" className="base-label ml-10 b-2 p-relative">
            Active
          </label>
        </div>

        <div className="form-group ml-0">
          <label>Trip Category</label>
          <Select
            className="countryCode-select"
            classNamePrefix="select"
            options={[
              { label: "International", value: "international" },
              { label: "All International", value: "allInternational" },
            ]}
            placeholder="Trip Category"
            onChange={handleTripCategoryChange}
            value={state.tripCategorySelect}
          ></Select>
        </div>

        <div className="form-group mr-0 ml-4p">
          <label>Origin Country</label>
          <Select
            className="countryCode-select"
            classNamePrefix="select"
            options={state.isoCountries}
            placeholder="Origin Country"
            onChange={handleOriginCountryChange}
            value={state.originCountryISOCodeSelect}
          ></Select>
        </div>

        {state.exclusionRule.tripCategory === "allInternational" && (
          <div className="form-group mr-0 ml-0">
            <label>Destination Country</label>
            <Select
              className="countryCode-select"
              classNamePrefix="select"
              options={state.isoCountries}
              placeholder="Destination Country"
              onChange={handleDestinationCountryChange}
              value={state.destinationCountryISOCodeSelect}
            ></Select>
          </div>
        )}

        <input type="submit" value="Add Exclusion Rule"></input>
      </form>

      {/* <pre>{JSON.stringify(state.exclusionRule, null, 2)}</pre> */}
    </Fragment>
  );
};

export default NewExclusionRuleContent;
