import React, { Fragment, useEffect, useReducer } from "react";
import { Link } from "react-router-dom";

import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";

import ai from "../../helpers/axios";
import * as Sentry from "@sentry/react";

import columns from "./flightLeg-widget-columns";
import DataTableServerPagination from "../data-table-server-pagination";

const reducer = (state, action) => {
  switch (action.type) {
    case "search": {
      return {
        ...state,
        query: action.query,
      };
    }
    case "dataBeganDownloading": {
      return {
        ...state,
        loading: action.payload.loadingStatus,
      };
    }
    case "rowsDeleted": {
      return {
        ...state,
        clearSelectedRows: !state.clearSelectedRows,
        selectedRows: [],
      };
    }
    case "rowSelection": {
      return {
        ...state,
        selectedRows: action.payload.selectedRows,
        allSelected: action.payload.allSelected,
      };
    }
    case "dataDownloaded": {
      //   console.log("Data Downloaded", action.payload.data.data);
      return {
        ...state,
        flightLegs: action.payload.data.data,
        loading: false,
        totalLength: action.payload.data.count,
      };
    }
    case "pageChanged": {
      return {
        ...state,
        currentPage: action.payload.page,
      };
    }
    case "tableSort": {
      return {
        ...state,
        sortBy: action.payload.sortBy,
        sortDirection: action.payload.sortDirection,
      };
    }
    case "stageFilterChanged": {
      return {
        ...state,
        stageFilters: action.payload.values,
      };
    }
    case "rowsPerPageChanged": {
      return {
        ...state,
        countPerPage: action.payload.currentRowsPerPage,
      };
    }

    case "fieldChanged": {
      return {
        ...state,
        [action.field]: action.value,
      };
    }

    case "tableDataChanged": {
      return {
        ...state,
        flightLegs: action.value,
      };
    }

    default:
      break;
  }
  return state;
};

const FlightLegsWidget = (props) => {
  const initialState = {
    flightLegs: {
      data: [],
    },
    loading: true,
    countPerPage: 25,
    currentPage: 1,
    selectedRows: [],
    allSelected: false,
    tagFilters: [],
    totalLength: null,
    clearSelectedRows: false,
    ownerInputField: "",
    selectedOwner: null,
    query: "",
    stageFilters: ["newLead", "inProgress"],
    sortDirection: "desc",
    sortBy: "originCreatedAt",
    ownerOptions: [],
    teamMembers: [],
    defaultStageFilters: { label: "New Lead", value: "newLead" },
  };

  if (props.prefilters && props.prefilters.length) {
    initialState.stageFilters = props.prefilters;
  }

  if (props.defaultStageFilters) {
    initialState.defaultStageFilters = props.defaultStageFilters;
  }

  if (props.qfsOnly) {
    initialState.defaultStageFilters = [];
    initialState.stageFilters = [];
  }

  const { user } = useAuth0();

  const userRoles = user && user["https://app.evojets.com/roles"];

  const superAdminOrAd = userRoles.some(function (role) {
    return "Super Admin" === role || "Account Director" === role;
  });

  const accountDirector = userRoles.some((role) => {
    return "Account Director" === role;
  });

  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const [state, dispatch] = useReducer(reducer, initialState);

  console.log(state);

  const getData = async (
    dbQuery,
    loadSilently,
    stageFilters,
    sortBy,
    sortDirection
  ) => {
    let loadingStatus = true;
    if (loadSilently) {
      loadingStatus = false;
    }

    // console.log("props.showOnlyMine", props.showOnlyMine);

    dispatch({ type: "dataBeganDownloading", payload: { loadingStatus } });

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let response = await ai
      .auth(token)
      .get(`/api/flightLegs/list`, {
        params: {
          query: dbQuery,
          page: state.currentPage,
          countPerPage: state.countPerPage,
          stageFilters,
          sortBy,
          sortDirection,
          widgetView: true,
          //   myLeads: props.showOnlyMine,
          //   qfsOnly: props.qfsOnly,
          //   myTeamLeads: props.showMyTeamLeads,
        },
      })
      .catch((error) => {
        console.error(error.response.data);
        Sentry.captureException(error);
      });

    if (response) {
      console.log("LEGS DOWNLOADED", response.data);
      dispatch({
        type: "dataDownloaded",
        payload: {
          data: response.data,
        },
      });
    }
  };

  useEffect(() => {
    getData(
      state.query,
      false,
      state.stageFilters,
      state.sortBy,
      state.sortDirection
    );
    // loadOwnerOptions();
    // getTeamMembers();
    document.title = `${
      props.pageTitle ? props.pageTitle : "Flight Matches"
    } | Flight Deck by evoJets`;
  }, [state.currentPage, state.countPerPage]);

  const handlePageChange = (page, totalRows) => {
    console.log(page, totalRows);

    dispatch({
      type: "pageChanged",
      payload: {
        page,
        totalRows,
      },
    });
  };

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    dispatch({
      type: "rowsPerPageChanged",
      payload: {
        currentRowsPerPage,
        currentPage,
      },
    });
  };

  const handleSelection = function (rowData) {
    dispatch({
      type: "rowSelection",
      payload: {
        selectedRows: rowData.selectedRows,
        allSelected: rowData.allSelected,
      },
    });
  };

  const handleSearch = (e) => {
    let query = e.target.value.toLowerCase();

    dispatch({ type: "search", query });
    getData(query, true, state.stageFilters, state.sortBy, state.sortDirection);
  };

  const handleSort = (column, sortDirection) => {
    console.log("sorted", column.selector, sortDirection);

    dispatch({
      type: "tableSort",
      payload: {
        sortBy: column.selector,
        sortDirection,
      },
    });

    getData(
      state.query,
      true,
      state.stageFilters,
      column.selector,
      sortDirection
    );

    //keyField is required for server sort
  };

  const onRowClicked = (row, event) => {
    // check if row id is inside selectedRows array
    let arr = [...state.selectedRows];
    arr = arr.filter((selectedRow) => selectedRow._id === row._id);

    if (arr.length) {
      // row is already in there...uncheck it?

      let newSelectedRowsArr = [...state.selectedRows];

      newSelectedRowsArr = newSelectedRowsArr.filter(
        (selectedRow) => selectedRow._id !== row._id
      );

      // console.log("NEW SELECTED ROWS ARRAY, ITEM EXISTS", newSelectedRowsArr);

      dispatch({
        type: "fieldChanged",
        field: "selectedRows",
        value: newSelectedRowsArr,
      });

      let newData = [...state.flightLegs];

      dispatch({ type: "tableDataChanged", value: newData });

      return;
    } else {
      //push row into selectedRows
      let currentSelectedRowsArray = [...state.selectedRows];
      currentSelectedRowsArray.push(row);

      dispatch({
        type: "fieldChanged",
        field: "selectedRows",
        value: currentSelectedRowsArray,
      });

      let newData = [...state.flightLegs];
      dispatch({ type: "tableDataChanged", value: newData });

      return;
    }
  };

  const preSelectCriteria = (row) => {
    let selectedRows = [...state.selectedRows];

    selectedRows = selectedRows.filter(
      (selectedRow) => selectedRow._id === row._id
    );

    console.log("SELECTED ROWS", selectedRows.length);

    return selectedRows.length;
  };

  return (
    <Fragment>
      <h3 className="widget-title flightMatchWidgetHeading">
        Recent Flight Matches
      </h3>
      <DataTableServerPagination
        onSelection={handleSelection}
        key={JSON.stringify(columns)}
        columns={columns}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
        state={state}
        data={state.flightLegs}
        defaultSortField={"auditTrail"}
        defaultSortAsc={false}
        selectableRowSelected={preSelectCriteria}
        onRowClicked={onRowClicked}
        handleSort={handleSort}
        disableSelection={true}
        sortServer={true}
        persistTableHead={true}
        hideFooter
        // disableSelection
      ></DataTableServerPagination>

      <Link
        to="/flight-matches"
        className="evo-blue base-button link-button widget-footer-button"
      >
        View All
      </Link>
    </Fragment>
  );
};

export default FlightLegsWidget;
