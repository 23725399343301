import React, { useCallback, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { PlaidLink } from "react-plaid-link";
import ai from "../helpers/axios";

const PlaidContent = () => {
  const { isAuthenticated, getAccessTokenSilently, loginWithRedirect } =
    useAuth0();
  const [linkToken, setLinkToken] = useState(null);

  const onSuccess = async (plaidPublicToken, metadata) => {
    // send token to server
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    const response = ai.auth(token).post("/api/plaid/exchangeToken", {
      publicToken: plaidPublicToken,
    });

    console.log(plaidPublicToken, metadata, response);
  };

  const generateToken = async () => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });
    let data = await ai.auth(token).post(`/api/plaid/create`);
    if (data.data.link_token) {
      setLinkToken(data.data.link_token);
      console.log(data.data.link_token, "linktoken set");
    }
  };

  const checkAccounts = async () => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });
    let data = await ai.auth(token).get(`/api/plaid/accounts`);
    console.log(data);
  };

  const getTransactions = async () => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });
    let data = await ai.auth(token).get(`/api/plaid/transactions`);
    console.log(data);
  };

  useEffect(() => {
    generateToken();
  }, []);

  const refreshTransactions = async () => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });
    let data = await ai.auth(token).get(`/api/plaid/transactions/refresh`);
    console.log(data);
  };

  useEffect(() => {
    generateToken();
  }, []);

  if (isAuthenticated) {
    return (
      <div className="text-center hero">
        <h1 className="mb-4">Plaid Test</h1>
        {linkToken && (
          <PlaidLink token={linkToken} onSuccess={onSuccess}>
            Connect a bank account
          </PlaidLink>
        )}

        <button onClick={checkAccounts}>Accounts</button>
        <button onClick={getTransactions}>Get Transactions</button>
        <button onClick={refreshTransactions}>Refresh Transactions</button>
      </div>
    );
  }
};

export default PlaidContent;
