import React from "react";
import "react-json-pretty/themes/monikai.css";
import JSONPretty from "react-json-pretty";

const StateDisplay = (props) => {
  if (props?.user && props?.user?.sub === "auth0|5fe3c41e81637b00685cb5ff") {
    return (
      <div className="mt-30">
        <h2>State Display</h2>
        <JSONPretty id="json-pretty" data={props.data}></JSONPretty>
      </div>
    );
  } else {
    return null;
  }
};

export default StateDisplay;
