import React from "react";

const DeleteButton = (props) => {
  return (
    <input
      disabled={props.buttonStatus}
      type="button"
      id="deleteButton"
      className={props.alignment}
      value={props.buttonText}
      onClick={props.handleDelete}
    />
  );
};

export default DeleteButton;
