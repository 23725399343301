import { Link } from "react-router-dom";

const columns = [
  {
    name: "Edit",
    sortable: false,
    cell: (row) => {
      return <Link to={`/airports/${row._id}`}>Edit</Link>;
    },
    width: "70px",
  },

  {
    name: "Airport Name",
    selector: "airportActualName",
    sortable: true,
    width: "200px",
  },
  {
    name: "Positioning",
    selector: "positioning",
    sortable: true,
    width: "120px",
  },
  {
    name: "Code",
    selector: "code",
    sortable: true,
    width: "100px",
  },
  {
    name: "OW",
    selector: "ow",
    sortable: true,
    width: "80px",
  },
  {
    name: "Local Code",
    selector: "localCode",
    sortable: true,
    width: "140px",
  },
  {
    name: "City",
    selector: "city",
    sortable: true,
    width: "200px",
  },
  {
    name: "Municipality",
    selector: "municipality",
    sortable: true,
    width: "200px",
  },
];

export default columns;
