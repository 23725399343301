import React, { Fragment, useEffect, useReducer } from "react";
import { Link } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";

import ai from "../../helpers/axios";
import * as Sentry from "@sentry/react";

import columns from "./trip-request-columns";
import DataTableServerPagination from "../data-table-server-pagination";

const reducer = (state, action) => {
  switch (action.type) {
    case "search": {
      return {
        ...state,
        query: action.query,
      };
    }
    case "dataBeganDownloading": {
      return {
        ...state,
        loading: action.payload.loadingStatus,
      };
    }
    case "rowsDeleted": {
      return {
        ...state,
        clearSelectedRows: !state.clearSelectedRows,
        selectedRows: [],
      };
    }
    case "rowSelection": {
      return {
        ...state,
        selectedRows: action.payload.selectedRows,
        allSelected: action.payload.allSelected,
      };
    }
    case "dataDownloaded": {
      console.log("Data Downloaded", action.payload.data);
      return {
        ...state,
        leads: {
          data: action.payload.data.leads,
        },
        loading: false,
        totalLength: action.payload.data.countTotal,
      };
    }
    case "pageChanged": {
      return {
        ...state,
        currentPage: action.payload.page,
      };
    }
    case "tableSort": {
      return {
        ...state,
        sortBy: action.payload.sortBy,
        sortDirection: action.payload.sortDirection,
      };
    }
    case "stageFilterChanged": {
      return {
        ...state,
        stageFilters: action.payload.values,
      };
    }
    case "rowsPerPageChanged": {
      return {
        ...state,
        countPerPage: action.payload.currentRowsPerPage,
      };
    }
    case "leadsAssigned": {
      return {
        ...state,
        leads: {
          data: state.leads.data.map((item) => {
            if (action.payload.leadIds.includes(item._id)) {
              console.log(item.firstName, action.payload.assigneeName);
              //item just assigned
              item.assigneeName = action.payload.assigneeName;
              item.assigneeId = action.payload.assigneeId;
            }
            return item;
          }),
        },
      };
    }

    case "fieldChanged": {
      return {
        ...state,
        [action.field]: action.value,
      };
    }

    default:
      break;
  }
  return state;
};

const TripRequestsWidget = (props) => {
  const initialState = {
    leads: {
      data: [],
    },
    loading: true,
    countPerPage: 25,
    currentPage: 1,
    selectedRows: [],
    allSelected: false,
    tagFilters: [],
    totalLength: null,
    clearSelectedRows: false,
    ownerInputField: "",
    selectedOwner: null,
    query: "",
    stageFilters: ["newLead"],
    sortDirection: "desc",
    sortBy: "createdAt",
    ownerOptions: [],
    teamMembers: [],
  };

  const { user } = useAuth0();

  const userRoles = user && user["https://app.evojets.com/roles"];

  const superAdminOrAd = userRoles.some(function (role) {
    return "Super Admin" === role || "Account Director" === role;
  });

  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const [state, dispatch] = useReducer(reducer, initialState);

  const getData = async (
    dbQuery,
    loadSilently,
    stageFilters,
    sortBy,
    sortDirection
  ) => {
    let loadingStatus = true;
    if (loadSilently) {
      loadingStatus = false;
    }

    console.log("props.showOnlyMine", props.showOnlyMine);

    dispatch({ type: "dataBeganDownloading", payload: { loadingStatus } });

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let response = await ai
      .auth(token)
      .get(`/api/leads/trip-requests/list`, {
        params: {
          query: dbQuery,
          page: state.currentPage,
          countPerPage: state.countPerPage,
          sortBy,
          sortDirection,
          // stageFilters,
          myLeads: props.showOnlyMine,
        },
      })
      .catch((error) => {
        console.error(error.response.data);
        Sentry.captureException(error);
      });

    if (response) {
      dispatch({
        type: "dataDownloaded",
        payload: {
          data: response.data,
        },
      });
    }
  };

  useEffect(() => {
    getData(
      state.query,
      false,
      state.stageFilters,
      state.sortBy,
      state.sortDirection
    );
  }, [state.currentPage, state.countPerPage]);

  const handlePageChange = (page, totalRows) => {
    console.log(page, totalRows);

    dispatch({
      type: "pageChanged",
      payload: {
        page,
        totalRows,
      },
    });
  };

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    dispatch({
      type: "rowsPerPageChanged",
      payload: {
        currentRowsPerPage,
        currentPage,
      },
    });
  };

  //   const handleSelection = function (rowData) {
  //     dispatch({
  //       type: "rowSelection",
  //       payload: {
  //         selectedRows: rowData.selectedRows,
  //         allSelected: rowData.allSelected,
  //       },
  //     });
  //   };

  const handleSort = (column, sortDirection) => {
    console.log("sorted", column.selector, sortDirection);

    dispatch({
      type: "tableSort",
      payload: {
        sortBy: column.selector,
        sortDirection,
      },
    });

    getData(
      state.query,
      true,
      state.stageFilters,
      column.selector,
      sortDirection
    );
  };

  return (
    <Fragment>
      <DataTableServerPagination
        // onSelection={handleSelection}
        key={JSON.stringify(columns)}
        columns={columns}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
        state={state}
        data={state.leads.data}
        defaultSortField={"createdAt"}
        defaultSortAsc={false}
        handleSort={handleSort}
        sortServer={true}
        hideFooter
        disableSelection
      ></DataTableServerPagination>

      <div className="centered-content-container">
        <Link to="/trip-requests" className="base-button evo-blue centered-btn">
          View All Trip Requests
        </Link>
      </div>

      {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
    </Fragment>
  );
};

export default TripRequestsWidget;
