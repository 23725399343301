import React, { Fragment } from "react";
import Select from "react-select";

const RFPForm = (props) => {
  return (
    <Fragment>
      <h2 className="form-heading lead-form-heading">
        {props.formHeading || "Submit Lead"}
      </h2>

      <form className="calculatorForm" onSubmit={props.handleRFPSubmit}>
        <div className="form-group">
          <label htmlFor="firstName">First Name</label>
          <input
            id="firstName"
            name="firstName"
            value={props.state.leadData.firstName}
            type="text"
            autoComplete="new-password"
            onChange={(e) =>
              props.dispatch({
                type: "leadFieldChanged",
                field: "firstName",
                value: e.currentTarget.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="lastName">Last Name</label>
          <input
            id="lastName"
            name="lastName"
            value={props.state.leadData.lastName}
            type="text"
            autoComplete="one-time-code"
            onChange={(e) =>
              props.dispatch({
                type: "leadFieldChanged",
                field: "lastName",
                value: e.currentTarget.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            id="email"
            name="email"
            value={props.state.leadData.email}
            type="email"
            autoComplete="new-password"
            onChange={(e) =>
              props.dispatch({
                type: "leadFieldChanged",
                field: "email",
                value: e.currentTarget.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="phone">Phone</label>
          <input
            id="phone"
            type="phone"
            name="phone"
            autoComplete="new-password"
            value={props.state.leadData.phone}
            onChange={(e) =>
              props.dispatch({
                type: "leadFieldChanged",
                field: "phone",
                value: e.currentTarget.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="companyName">Company</label>
          <input
            id="companyName"
            type="text"
            name="companyName"
            autoComplete="new-password"
            value={props.state.leadData.companyName}
            onChange={(e) =>
              props.dispatch({
                type: "leadFieldChanged",
                field: "companyName",
                value: e.currentTarget.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="cc">Where would you like quotes to be sent?</label>
          <input
            id="cc"
            name="cc"
            value={props.state.leadData.cc}
            type="email"
            autoComplete="new-password"
            onChange={(e) =>
              props.dispatch({
                type: "leadFieldChanged",
                field: "cc",
                value: e.currentTarget.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="departureTime">Departure Time</label>

          <Select
            className="base-select"
            classNamePrefix="select"
            options={[
              { value: "Flexible", label: "Flexible" },

              { value: "12:00 AM", label: "12:00 AM" },
              { value: "12:30 AM", label: "12:30 AM" },
              { value: "1:00 AM", label: "1:00 AM" },
              { value: "1:30 AM", label: "1:30 AM" },
              { value: "2:00 AM", label: "2:00 AM" },
              { value: "2:30 AM", label: "2:30 AM" },
              { value: "3:00 AM", label: "3:00 AM" },
              { value: "3:30 AM", label: "3:30 AM" },
              { value: "4:00 AM", label: "4:00 AM" },
              { value: "4:30 AM", label: "4:30 AM" },
              { value: "5:00 AM", label: "5:00 AM" },
              { value: "5:30 AM", label: "5:30 AM" },
              { value: "6:00 AM", label: "6:00 AM" },
              { value: "6:30 AM", label: "6:30 AM" },
              { value: "7:00 AM", label: "7:00 AM" },
              { value: "7:30 AM", label: "7:30 AM" },
              { value: "8:00 AM", label: "8:00 AM" },
              { value: "8:30 AM", label: "8:30 AM" },
              { value: "9:00 AM", label: "9:00 AM" },
              { value: "9:30 AM", label: "9:30 AM" },
              { value: "10:00 AM", label: "10:00 AM" },
              { value: "10:30 AM", label: "10:30 AM" },
              { value: "11:00 AM", label: "11:00 AM" },
              { value: "11:30 AM", label: "11:30 AM" },

              { value: "12:00 PM", label: "12:00 PM" },
              { value: "12:30 PM", label: "12:30 PM" },
              { value: "1:00 PM", label: "1:00 PM" },
              { value: "1:30 PM", label: "1:30 PM" },
              { value: "2:00 PM", label: "2:00 PM" },
              { value: "2:30 PM", label: "2:30 PM" },
              { value: "3:00 PM", label: "3:00 PM" },
              { value: "3:30 PM", label: "3:30 PM" },
              { value: "4:00 PM", label: "4:00 PM" },
              { value: "4:30 PM", label: "4:30 PM" },
              { value: "5:00 PM", label: "5:00 PM" },
              { value: "5:30 PM", label: "5:30 PM" },
              { value: "6:00 PM", label: "6:00 PM" },
              { value: "6:30 PM", label: "6:30 PM" },
              { value: "7:00 PM", label: "7:00 PM" },
              { value: "7:30 PM", label: "7:30 PM" },
              { value: "8:00 PM", label: "8:00 PM" },
              { value: "8:30 PM", label: "8:30 PM" },
              { value: "9:00 PM", label: "9:00 PM" },
              { value: "9:30 PM", label: "9:30 PM" },
              { value: "10:00 PM", label: "10:00 PM" },
              { value: "10:30 PM", label: "10:30 PM" },
              { value: "11:00 PM", label: "11:00 PM" },
              { value: "11:30 PM", label: "11:30 PM" },
            ]}
            // defaultValue={props.state.leadData.kids}
            placeholder="Select an Option"
            onChange={(selectedValue) => {
              console.log(selectedValue);
              props.dispatch({
                type: "leadFieldChanged",
                field: "departureTime",
                value: selectedValue.value,
              });
              return selectedValue.value;
            }}
          ></Select>
        </div>

        {props.state.data.tripDetails.tripType === 2 && (
          <div className="form-group">
            <label htmlFor="returnTime">Return Time</label>
            <Select
              className="base-select"
              classNamePrefix="select"
              options={[
                { value: "Flexible", label: "Flexible" },

                { value: "12:00 AM", label: "12:00 AM" },
                { value: "12:30 AM", label: "12:30 AM" },
                { value: "1:00 AM", label: "1:00 AM" },
                { value: "1:30 AM", label: "1:30 AM" },
                { value: "2:00 AM", label: "2:00 AM" },
                { value: "2:30 AM", label: "2:30 AM" },
                { value: "3:00 AM", label: "3:00 AM" },
                { value: "3:30 AM", label: "3:30 AM" },
                { value: "4:00 AM", label: "4:00 AM" },
                { value: "4:30 AM", label: "4:30 AM" },
                { value: "5:00 AM", label: "5:00 AM" },
                { value: "5:30 AM", label: "5:30 AM" },
                { value: "6:00 AM", label: "6:00 AM" },
                { value: "6:30 AM", label: "6:30 AM" },
                { value: "7:00 AM", label: "7:00 AM" },
                { value: "7:30 AM", label: "7:30 AM" },
                { value: "8:00 AM", label: "8:00 AM" },
                { value: "8:30 AM", label: "8:30 AM" },
                { value: "9:00 AM", label: "9:00 AM" },
                { value: "9:30 AM", label: "9:30 AM" },
                { value: "10:00 AM", label: "10:00 AM" },
                { value: "10:30 AM", label: "10:30 AM" },
                { value: "11:00 AM", label: "11:00 AM" },
                { value: "11:30 AM", label: "11:30 AM" },

                { value: "12:00 PM", label: "12:00 PM" },
                { value: "12:30 PM", label: "12:30 PM" },
                { value: "1:00 PM", label: "1:00 PM" },
                { value: "1:30 PM", label: "1:30 PM" },
                { value: "2:00 PM", label: "2:00 PM" },
                { value: "2:30 PM", label: "2:30 PM" },
                { value: "3:00 PM", label: "3:00 PM" },
                { value: "3:30 PM", label: "3:30 PM" },
                { value: "4:00 PM", label: "4:00 PM" },
                { value: "4:30 PM", label: "4:30 PM" },
                { value: "5:00 PM", label: "5:00 PM" },
                { value: "5:30 PM", label: "5:30 PM" },
                { value: "6:00 PM", label: "6:00 PM" },
                { value: "6:30 PM", label: "6:30 PM" },
                { value: "7:00 PM", label: "7:00 PM" },
                { value: "7:30 PM", label: "7:30 PM" },
                { value: "8:00 PM", label: "8:00 PM" },
                { value: "8:30 PM", label: "8:30 PM" },
                { value: "9:00 PM", label: "9:00 PM" },
                { value: "9:30 PM", label: "9:30 PM" },
                { value: "10:00 PM", label: "10:00 PM" },
                { value: "10:30 PM", label: "10:30 PM" },
                { value: "11:00 PM", label: "11:00 PM" },
                { value: "11:30 PM", label: "11:30 PM" },
              ]}
              // defaultValue={props.state.leadData.kids}
              placeholder="Select an Option"
              onChange={(selectedValue) => {
                console.log(selectedValue);
                props.dispatch({
                  type: "leadFieldChanged",
                  field: "returnTime",
                  value: selectedValue.value,
                });
                return selectedValue.value;
              }}
            ></Select>
          </div>
        )}

        <div className="form-group">
          <label htmlFor="infants">
            Traveling With Children Under 3 Years Old?
          </label>

          <Select
            className="base-select"
            classNamePrefix="select"
            options={[
              { value: true, label: "Yes" },
              { value: false, label: "No" },
            ]}
            // defaultValue={props.state.leadData.kids}
            placeholder="Select an Option"
            onChange={(selectedValue) => {
              console.log(selectedValue);
              props.dispatch({
                type: "leadFieldChanged",
                field: "infants",
                value: selectedValue.value,
              });
              return selectedValue.value;
            }}
          ></Select>
        </div>

        <div className="form-group">
          <label htmlFor="pets">Traveling With Pets?</label>
          <Select
            className="base-select"
            classNamePrefix="select"
            options={[
              { value: true, label: "Yes" },
              { value: false, label: "No" },
            ]}
            // defaultValue={props.initialTags}
            placeholder="Select an Option"
            onChange={(selectedValue) => {
              console.log(selectedValue);
              props.dispatch({
                type: "leadFieldChanged",
                field: "pets",
                value: selectedValue.value,
              });
              return selectedValue.value;
            }}
          ></Select>
        </div>

        <div className="form-group">
          <label htmlFor="aircraftSelection">
            Select Which Aircraft Type(s) You Are Interested In
          </label>
          <Select
            className="base-select"
            classNamePrefix="select"
            options={props.aircraftOptions}
            isMulti
            // defaultValue={props.initialTags}
            placeholder="Select All Options That Apply"
            onChange={(selectedValue) => {
              console.log(selectedValue);
              props.dispatch({
                type: "leadFieldChanged",
                field: "aircraftSelection",
                value: selectedValue.map((item) => item.value),
              });
              // return selectedValue.value;
            }}
          ></Select>
        </div>

        <div className="form-group">
          <label htmlFor="comments">Comments</label>
          <textarea
            id="comments"
            name="comments"
            autoComplete="new-password"
            value={props.state.leadData.comments}
            onChange={(e) =>
              props.dispatch({
                type: "leadFieldChanged",
                field: "comments",
                value: e.currentTarget.value,
              })
            }
          ></textarea>
        </div>

        <input type="submit" value="Submit"></input>
      </form>
    </Fragment>
  );
};

export default RFPForm;
