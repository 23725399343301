import React, { Fragment } from "react";

import Loader from "./loader";
import DataTable from "react-data-table-component";

const FlightTable = (props) => {
  const tableData = {
    columns: props.columns,
    data: props.state.flights.filteredFlights || props.state.flights.data,
  };

  return (
    <Fragment>
      {props.state.flights.loading ? (
        <Loader></Loader>
      ) : (
        <div className="flight-table-container standard-data-table-container">
          <DataTable
            data={tableData.data}
            columns={tableData.columns}
            selectableRows
            noHeader
            highlightOnHover
            pagination={true}
            paginationPerPage={50}
            paginationRowsPerPageOptions={[50, 100, 150, 200, 250]}
            onSelectedRowsChange={props.onSelection}
            clearSelectedRows={props.state.clearSelectedRows}
            responsive={true}
          ></DataTable>
        </div>
      )}
    </Fragment>
  );
};

export default FlightTable;
