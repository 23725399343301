import React from "react";
import LeadsContent from "../components/leads/leads-content";

const ClosedGhostLeads = () => {
  return (
    <LeadsContent
      prefilters={["closedGhost"]}
      defaultStageFilters={{ label: "Closed - Ghost", value: "closedGhost" }}
      pageTitle="Closed - Ghost Leads"
    />
  );
};

export default ClosedGhostLeads;
