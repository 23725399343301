import { Link } from "react-router-dom";

const columns = [
  {
    name: "Edit",
    sortable: false,
    cell: (row) => {
      return <Link to={`/organizations/${row._id}`}>Edit</Link>;
    },
    width: "70px",
  },
  {
    name: "Name",
    sortable: false,
    selector: "name",
    width: "200px",
  },
  {
    name: "Max Users",
    sortable: false,
    selector: "maxUsers",
    cell: (row) => (row.maxUsers ? row.maxUsers : "Unlimited"),
    width: "120px",
  },

  {
    name: "Phone",
    sortable: false,
    selector: "phone",
    width: "150px",
  },

  {
    name: "Permissions",
    sortable: false,
    selector: "permissions",
    width: "150px",
  },
];

export default columns;
