import React, { Fragment, useReducer, useEffect } from "react";
import DatePicker from "react-date-picker";
import { useAuth0 } from "@auth0/auth0-react";

import ai from "../../helpers/axios";
import AsyncSelect from "react-select/async";

import { ToastContainer, toast, Slide } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom";

// import Select from "react-select";
// import TextInput from "../text-input";
// import PhoneInput from "react-phone-number-input";

import "react-json-pretty/themes/monikai.css";
import JSONPretty from "react-json-pretty";

import "react-phone-number-input/style.css";

import * as Sentry from "@sentry/react";
import ProspectsAutocomplete from "../prospects/prospects-autocomplete";
import CreateProspectModal from "../prospects/create-prospect-modal";

let toastOptions = {
  position: "top-right",
  autoClose: 6000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

let initialState = {
  tripType: 1,
  data: {},
  tableData: [],
  leadData: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    companyName: "",
    comments: "",
    phoneCountry: "",
  },
  returnDate: null,

  legs: [
    {
      passengers: 0,
      departureDate: null,
      departureAirport: {
        airportName: null,
        id: null,
        code: null,
        city: null,
        localCode: null,
      },
      arrivalAirport: {
        airportName: null,
        id: null,
        code: null,
        city: null,
        localCode: null,
      },
    },
  ],
  outreachProspects: [],
  outreachProspectId: null,
  outreachOpportunityId: null,
  leadType: "phoneCall",
  prospectExists: false,
  createProspectModalIsOpen: false,
  prospectOptions: [],
  phoneCallData: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case "fieldChanged": {
      let value =
        action.fieldType === "number" ? parseInt(action.value) : action.value;

      return {
        ...state,
        [action.field]: value,
      };
    }

    case "prospectAutoSelected": {
      let prospectOptions = [action.value, ...state.prospectOptions];

      return {
        ...state,
        selectedProspect: action.value,
        prospectOptions,
      };
    }

    case "leadFieldChanged": {
      let value =
        action.fieldType === "number" ? parseInt(action.value) : action.value;

      return {
        ...state,
        leadData: {
          ...state.leadData,
          [action.field]: value,
        },
      };
    }
    case "airportChange": {
      return {
        ...state,
        [action.airportType]: action.payload,
      };
    }

    case "tripTypeChanged": {
      return {
        ...state,
        tripType: action.payload.tripType,
        returnDate: action.payload.tripType !== 2 ? null : state.returnDate,
      };
    }

    case "estimateReturned": {
      return {
        ...state,
        tableData: action.payload.results.tripOptions,
        data: action.payload,
      };
    }

    case "legAdded": {
      return {
        ...state,
        legs: [
          ...state.legs,
          {
            passengers: "",
            departureDate: "",
            departureAirport: {
              airportName: null,
              id: null,
              code: null,
              city: null,
              localCode: null,
            },
            arrivalAirport: {
              airportName: null,
              id: null,
              code: null,
              city: null,
              localCode: null,
            },
          },
        ],
      };
    }
    case "legDeleted": {
      let newLegsArray = [...state.legs];
      console.log(state.legs);
      newLegsArray.splice(action.index, 1);
      console.log(newLegsArray);

      return {
        ...state,
        legs: newLegsArray,
      };
    }
    case "legFieldChanged": {
      let newLegsArray = [...state.legs];
      newLegsArray[action.payload.legIndex][action.payload.field] =
        action.payload.value;
      return {
        ...state,
        legs: newLegsArray,
      };
    }

    case "resetState": {
      return {
        ...initialState,
      };
    }

    default:
      break;
  }
  return state;
};

const PhoneCallQuoteContent = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();

  console.log("render");

  const { getAccessTokenSilently, user, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (props.phoneCallId) {
      getPhoneCallData(props.phoneCallId);
    }

    document.title = "New Quote | Flight Deck";
  }, []);

  const handleAddLeg = (event) => {
    event.preventDefault();
    dispatch({ type: "legAdded" });
  };

  const handleDeleteLeg = (index) => {
    return dispatch({ type: "legDeleted", index: index });
  };

  const handleProspectOptionCreation = (inputValue) => {
    console.log("HANDLE PROSPECT OPTION CREATION", inputValue);
    dispatch({
      type: "fieldChanged",
      field: "createProspectModalIsOpen",
      value: true,
    });
  };

  const getPhoneCallData = async (phoneCallId) => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });
    let { data } = await ai.auth(token).get(`/api/phoneCalls/${phoneCallId}`);
    console.log(data);

    if (data.selectedProspect) {
      dispatch({
        type: "fieldChanged",
        field: "selectedProspect",
        value: data.selectedProspect,
      });
    }

    dispatch({
      type: "fieldChanged",
      field: "phoneCallData",
      value: data,
    });
    return data;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let estimateData = {
      departureAirport: state.legs[0].departureAirport,
      arrivalAirport: state.legs[0].arrivalAirport,
      passengers: state.legs[0].passengers,
      departureDate: state.legs[0].departureDate,
      tripType: state.tripType !== 3 ? state.tripType : 1,
      returnDate: state.returnDate,
      origin: "phoneCall",
      isMultiLeg: state.tripType === 3 ? true : false,
    };

    if (state.tripType === 3) {
      estimateData.rawLegData = state.legs;
    }

    console.log("ESTIMATE DATA", estimateData);
    let { data: estimateResponse } = await ai
      .auth(token)
      .post(`/api/estimates/create`, estimateData);

    console.log("Estimate DOC", estimateResponse.estimateDoc);

    console.log(
      "Estimate Doc Trip Type",
      estimateResponse.estimateDoc.tripType
    );

    if (!state.selectedProspect?.value) {
      toast.error("Please select a Prospect.", toastOptions);
      return;
    }

    let leadData = {
      firstName: state.selectedProspect.firstName,
      lastName: state.selectedProspect.lastName,
      email: state.selectedProspect.email,
      phone: state.selectedProspect.phone,
      companyName: state.selectedProspect.companyName,
      estimateId: estimateResponse.estimateDoc._id,
      tripType: estimateResponse.estimateDoc.tripType,
      isMultiLeg: estimateResponse.estimateDoc.isMultiLeg,
      sendNotifications: false,
      createProspect: false,
      createOpportunity: false,
      leadOrigin: "phoneCall",
      leadType: state.leadType,
      evoProspectId: state?.selectedProspect?.value,
      orProspectId: state.selectedProspect?.orProspectId,
      phoneCallId: props.phoneCallId,
    };

    let { data: leadResponse } = await ai
      .auth(token)
      .post(`/api/leads/create`, leadData);

    console.log(estimateResponse, leadResponse);

    setTimeout(() => {
      document
        .getElementById("nav-container")
        .scrollIntoView({ behavior: "smooth" });

      toast.success("Lead Created Successfully!", toastOptions);
    }, 50);
    setTimeout(() => {
      history.push({ pathname: `/leads/${leadResponse._id}/quotes/new` });
    }, 5000);
  };

  const loadAirportOptions = async (inputValue) => {
    let ip =
      Math.floor(Math.random() * 255) +
      1 +
      "." +
      (Math.floor(Math.random() * 255) + 0) +
      "." +
      (Math.floor(Math.random() * 255) + 0) +
      "." +
      (Math.floor(Math.random() * 255) + 0);
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });
    let { data } = await ai
      .auth(token)
      .get(`/api/airports/search?q=${inputValue}&ip_address=${ip}`);

    let options = [];
    if (data.length >= 1) {
      options = data.map((i) => ({
        value: i._id,
        label: i.airportName,
        code: i.code,
        city: i.city,
        localCode: i.localCode,
      }));
    }

    return options;
  };

  const handleProspectChange = (selectedProspect) => {
    if (selectedProspect) {
      dispatch({
        type: "fieldChanged",
        field: "selectedProspect",
        value: selectedProspect,
      });

      dispatch({
        type: "fieldChanged",
        field: "outreachProspectId",
        value: selectedProspect.orProspectId,
      });
    } else {
      dispatch({
        type: "fieldChanged",
        field: "outreachProspectId",
        value: null,
      });

      dispatch({
        type: "fieldChanged",
        field: "selectedProspect",
        value: selectedProspect,
      });
    }
  };

  return (
    <Fragment>
      {/* <ToastContainer transition={Slide} /> */}

      <CreateProspectModal
        modalIsOpen={state.createProspectModalIsOpen}
        parentDispatch={dispatch}
        toast={toast}
        toastOptions={toastOptions}
        defaultPhoneNumber={state.phoneCallData?.from || ""}
        stage={"inProgress"}
        source="phoneCall"
        tags={["phoneCall"]}
        key={`phoneCallData-${state.phoneCallData?.from}`}

        // onSubmit={handleProspectCreation}
      />

      <div className="text-center hero">
        <h1 className="mb-4" id="page-title">
          Phone Call Quote
        </h1>
      </div>

      <form className="calculatorForm" id="qfsForm" onSubmit={handleSubmit}>
        <ProspectsAutocomplete
          onCreateOption={handleProspectOptionCreation}
          handleProspectChange={handleProspectChange}
          prospectOptions={state.prospectOptions}
          parentDispatch={dispatch}
          selectedProspect={state.selectedProspect}
        />

        <div className="form-group">
          <label htmlFor="leadType">Lead Type</label>
          <select
            id="leadType"
            value={state.leadType}
            name="leadType"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
                fieldType: e.target.type,
              })
            }
            required
          >
            <option key="placeholder" value={0} hidden>
              Lead Type
            </option>

            <option key="repeatClient" value={"repeatClient"}>
              Repeat Client
            </option>
            <option key="referral" value={"referral"}>
              Referral
            </option>
            <option key="directEmail" value={"directEmail"}>
              Direct Email
            </option>
            <option key="phoneCall" value={"phoneCall"}>
              Phone Call
            </option>
            <option key="other" value={"other"}>
              Other
            </option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="tripType">Trip Type</label>
          <select
            id="tripType"
            value={state.tripType}
            onChange={(e) =>
              dispatch({
                type: "tripTypeChanged",
                payload: {
                  tripType: parseInt(e.currentTarget.value),
                },
              })
            }
            name="tripType"
            required
          >
            <option key="placeholder" value={0} hidden>
              Trip Type
            </option>

            <option key="oneWay" value={1}>
              One-Way
            </option>
            <option key="roundTrip" value={2}>
              Round Trip
            </option>
            <option key="multiLeg" value={3}>
              Multileg
            </option>
          </select>
        </div>
        <div id="leg-block-container">
          {state.legs.map((leg, index) => {
            return (
              <div className="leg-container" key={`leg-container-${index}`}>
                {state.tripType === 3 && (
                  <h3 className="legHeading">Leg {index + 1}</h3>
                )}
                <div className="form-group">
                  <label>Departure Airport</label>

                  <AsyncSelect
                    className="departure-airport-select"
                    classNamePrefix="select"
                    cacheOptions
                    loadOptions={loadAirportOptions}
                    placeholder="Search Airports"
                    // onChange={handleDepartureChange}
                    onChange={(selectedAirport) => {
                      dispatch({
                        type: "legFieldChanged",
                        payload: {
                          legIndex: index,
                          field: "departureAirport",
                          value: {
                            airportName: selectedAirport?.label || "",
                            id: selectedAirport?.value || "",
                            code: selectedAirport?.code || "",
                            city: selectedAirport?.city || "",
                            localCode: selectedAirport?.localCode || "",
                          },
                        },
                      });
                    }}
                  ></AsyncSelect>
                </div>

                <div className="form-group">
                  <label>Arrival Airport</label>

                  <AsyncSelect
                    className="arrival-airport-select"
                    classNamePrefix="select"
                    cacheOptions
                    loadOptions={loadAirportOptions}
                    placeholder="Search Airports"
                    onChange={(selectedAirport) => {
                      dispatch({
                        type: "legFieldChanged",
                        payload: {
                          legIndex: index,
                          field: "arrivalAirport",
                          value: {
                            airportName: selectedAirport?.label || "",
                            id: selectedAirport?.value || "",
                            code: selectedAirport?.code || "",
                            city: selectedAirport?.city || "",
                            localCode: selectedAirport?.localCode || "",
                          },
                        },
                      });
                    }}
                  ></AsyncSelect>
                </div>

                <div className="form-group">
                  <label htmlFor={`passengers[${index}]`}>Passengers</label>
                  <input
                    type="number"
                    name={`passengers[${index}]`}
                    placeholder="Passengers"
                    onChange={(e) =>
                      dispatch({
                        type: "legFieldChanged",
                        payload: {
                          field: "passengers",
                          legIndex: index,
                          value: parseInt(e.target.value),
                        },
                      })
                    }
                    value={
                      state.legs[index].passengers
                        ? state.legs[index].passengers
                        : ""
                    }
                  ></input>
                </div>

                <div className="form-group">
                  <label htmlFor={`departureDate[${index}]`}>
                    Departure Date
                  </label>

                  <DatePicker
                    name={`departureDate[${index}]`}
                    onChange={(date) =>
                      dispatch({
                        type: "legFieldChanged",
                        payload: {
                          legIndex: index,
                          field: "departureDate",
                          value: date,
                        },
                      })
                    }
                    value={state.legs[index].departureDate}
                  ></DatePicker>
                </div>

                {state.tripType === 3 && state.legs.length > 1 && index > 0 && (
                  <button
                    id="deleteLeg"
                    className="action-button base-button evo-gunmetal mb-20"
                    onClick={() => handleDeleteLeg(index)}
                    type="button"
                  >
                    Delete Leg {index + 1}
                  </button>
                )}
              </div>
            );
          })}

          {state.tripType === 3 && (
            <button
              id=""
              className="action-button base-button evo-blue mb-20"
              onClick={handleAddLeg}
              type="button"
            >
              Add Leg
            </button>
          )}
        </div>

        {state.tripType === 2 && (
          <div className="form-group">
            <label htmlFor="returnDate">Return Date</label>

            <DatePicker
              name="returnDate"
              onChange={(date) =>
                dispatch({
                  type: "fieldChanged",
                  field: "returnDate",
                  value: date,
                })
              }
              value={state.returnDate}
            ></DatePicker>
          </div>
        )}

        {/* <TextInput
          name="firstName"
          label="First Name"
          handleChange={handleLeadFieldChange}
          value={state.leadData.firstName}
        ></TextInput>

        <TextInput
          name="lastName"
          label="Last Name"
          handleChange={handleLeadFieldChange}
          value={state.leadData.lastName}
        ></TextInput>

        <TextInput
          name="email"
          label="Email"
          type="email"
          key={`key-${state.email}`}
          handleChange={handleLeadFieldChange}
          value={state.leadData.email}
          handleBlur={checkEmailForProspect}
        ></TextInput> */}

        {state.prospectExists.prospectExists &&
          state.prospectExists.conflictExists && (
            <p className="warning-text">
              <strong>WARNING:</strong> A prospect with this email exists, named{" "}
              <strong>
                {state.prospectExists.prospectName.firstName}{" "}
                {state.prospectExists.prospectName.lastName}{" "}
              </strong>
              and is{" "}
              {state.prospectExists.prospectOwner ? (
                <span>
                  owned by{" "}
                  <strong>
                    {state.prospectExists.prospectOwner.ownerName}
                  </strong>
                </span>
              ) : (
                <strong> unowned</strong>
              )}
              .
            </p>
          )}

        {/* <div className="form-group">
          <label className="phoneInputLabel" htmlFor="phone">
            Phone Number
          </label>
          <PhoneInput
            // key={`key-${state.leadData.phone}`}
            placeholder="Phone Number"
            value={state.leadData.phone}
            name="phone"
            onChange={(string) => {
              dispatch({
                type: "leadFieldChanged",
                field: "phone",
                value: string,
              });
            }}
            onCountryChange={(countryCode) => {
              dispatch({
                type: "leadFieldChanged",
                field: "phoneCountry",
                value: countryCode,
              });
            }}
          />
        </div> */}

        <input type="submit" value="Submit"></input>
      </form>

      {user &&
        user["https://app.evojets.com/roles"].includes("Super Admin") && (
          <>
            {/* <JSONPretty id="json-pretty" data={user}></JSONPretty> */}
            {/* <JSONPretty id="json-pretty" data={state}></JSONPretty> */}
          </>
        )}
    </Fragment>
  );
};

export default PhoneCallQuoteContent;
