import React, { useEffect } from "react";

import { Switch, Redirect } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { NavBar, Footer } from "./components";
import Loader from "./components/loader";
import {
  Home,
  EditFlight,
  AirportsAPI,
  Airports,
  SingleAirport,
  Aircrafts,
  SingleAircraft,
  AircraftTypes,
  SingleAircraftType,
  DistanceParams,
  Spreads,
  Calculator,
  Leads,
  SingleLead,
  NewQuote,
  SingleQuote,
  Quotes,
  QFS,
  PhoneCalls,
  PhoneCallQuote,
  Estimates,
  Plaid,
  MyLeads,
  Organizations,
  SingleOrganization,
  NewOrganization,
  NewUser,
  Users,
  SingleUser,
  MyAccount,
  TripRequests,
  InProgressLeads,
  ArchivedLeads,
  QFSLeads,
  UnqualifiedLeads,
  WarmProspectLeads,
  Export,
  MyTeamLeads,
  SingleTripRequest,
  OutsideOrgSingleQuote,
  OutsideOrgUsers,
  OutsideOrgNewUser,
  OutsideOrgSingleUser,
  FlightBoard,
  NewAirport,
  OPI,
  SingleOperator,
  UpdateOPIWeights,
  NewOperator,
  NewExclusionRule,
  NewCountrySetting,
  SingleCountrySetting,
  CountrySettings,
  ExclusionRules,
  SingleExclusionRule,
  FlightLegs,
  SingleFlightLeg,
  ClosedRepeatLeads,
  ClosedGhostLeads,
  ClosedLostToCompetitorLeads,
  InLimboLeads,
  SignedContractLeads,
  TripCompleteLeads,
  Prospects,
  CreateProspect,
  SingleProspect,
  NewLeads,
  Developers,
  RestrictedOperators,
  NewContract,
  SingleSpread,
  SourcingModule,
  SourcingList,
  LeadFromScratch,
  OFRBuilder,
  SingleTrip,
  ContractPreview,
  NewItinerary,
  ItineraryPreview,
  Trips,
} from "./views";

import ProtectedRoute from "./auth/protected-route";
import AdminRoute from "./auth/admin-route";
import AdminOrSalesRoute from "./auth/admin-or-sales-route";
import AdminOrADRoute from "./auth/admin-or-ad-route";

import AdminSalesOutsideOrgRoute from "./auth/admin-sales-outside-org.js";
import OutsideOrgRoute from "./auth/outside-org-route";
import OutsideOrgAdminRoute from "./auth/outside-org-admin-route";

import "./app.css";

import { ToastContainer, Slide } from "react-toastify";

import ScrollToTop from "./helpers/scroll-to-top";

//update
import AdminWidgets from "./components/admin-widgets/adminWidgets";

import NewInvoice from "./views/new-invoice";
import InvoicePreview from "./views/invoice-preview.js";

const App = () => {
  const {
    user,
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();

  const checkToken = async () => {
    // console.log("CHECK TOKEN RUNNING");

    if (!isLoading) {
      // console.log("CHECK TOKEN ACTUALLY RAN");

      await getAccessTokenSilently().catch((e) => {
        console.error(e);
        return loginWithRedirect({
          appState: { returnTo: window.location.pathname },
        });
      });
    }
  };

  useEffect(() => {
    checkToken();
  });

  // console.log("RENDER");

  if (!isAuthenticated && !isLoading) {
    return loginWithRedirect({
      appState: { returnTo: window.location.pathname },
    });
  }

  if (isLoading) {
    return <Loader />;
  }

  const windows = navigator.platform.includes("Win");

  return (
    <div
      id="app"
      className={`d-flex flex-column h-100  ${windows && "os-windows"}`}
    >
      {isAuthenticated && <NavBar />}
      <ToastContainer icon={false} transition={Slide} />

      <main id="page-wrap">
        <div className="container flex-grow-1">
          <Switch>
            {/* trips start */}

            <AdminOrSalesRoute
              path="/contracts/new"
              exact
              component={NewContract}
            />
            <AdminOrSalesRoute
              path="/trips/:tripId/contracts/:contractId"
              exact
              component={NewContract}
            />
            <AdminOrSalesRoute
              path="/:tripId/contracts/new"
              exact
              component={NewContract}
            />

            <AdminOrSalesRoute
              path="/contracts/:contractId/preview"
              exact
              component={ContractPreview}
            />
            <AdminOrSalesRoute
              path="/trips/:tripId/contracts/new"
              exact
              component={NewContract}
            />

            <AdminOrSalesRoute
              path="/trips/:tripId/invoices/new"
              exact
              component={NewInvoice}
            />
            <AdminOrSalesRoute
              path="/trips/:tripId/invoices/:invoiceId"
              exact
              component={NewInvoice}
            />
            <AdminOrSalesRoute
              path="/trips/:tripId/invoices/:invoiceId/preview"
              exact
              component={InvoicePreview}
            />

            <AdminOrSalesRoute path="/trips" exact component={Trips} />

            <AdminOrSalesRoute
              path="/trips/:tripId/contracts/:contractId/preview"
              exact
              component={ContractPreview}
            />
            <AdminOrSalesRoute
              path="/trips/:tripId/itineraries/new"
              exact
              component={NewItinerary}
            />
            <AdminOrSalesRoute
              path="/trips/:tripId/itineraries/:itineraryId"
              exact
              component={NewItinerary}
            />

            <AdminOrSalesRoute
              path="/trips/:tripId/itineraries/:itineraryId/preview"
              exact
              component={ItineraryPreview}
            />

            <AdminOrSalesRoute path="/trips/:id" exact component={SingleTrip} />

            <AdminOrSalesRoute
              path="/trips/:tripId/financials/new"
              exact
              component={OFRBuilder}
            />
            <AdminOrSalesRoute
              path="/trips/:tripId/financials/:id"
              exact
              component={OFRBuilder}
            />

            {/* trips end */}

            <AdminSalesOutsideOrgRoute Route path="/" exact component={Home} />
            <AdminSalesOutsideOrgRoute
              path="/my-account"
              component={MyAccount}
            />
            <AdminOrSalesRoute path="/atc/:id" component={EditFlight} />

            <AdminRoute path="/export" exact component={Export} />

            <AdminOrSalesRoute path="/leads" exact component={Leads} />
            <AdminOrSalesRoute path="/my-leads" exact component={MyLeads} />
            <AdminOrSalesRoute
              path="/my-team-leads"
              exact
              component={MyTeamLeads}
            />

            <AdminOrSalesRoute
              path="/flight-matches"
              exact
              component={FlightLegs}
            />

            <AdminOrSalesRoute
              path="/flight-matches/:id"
              exact
              component={SingleFlightLeg}
            />

            <AdminOrSalesRoute path="/opi" exact component={OPI} />
            <AdminOrSalesRoute
              path="/restricted-operators"
              exact
              component={RestrictedOperators}
            />

            <AdminOrSalesRoute path="/opi/new" exact component={NewOperator} />

            <AdminOrADRoute path="/opi/:id" exact component={SingleOperator} />

            <AdminRoute
              path="/exclusion-rules/new"
              exact
              component={NewExclusionRule}
            />

            <AdminOrSalesRoute path="/prospects" exact component={Prospects} />

            <AdminOrSalesRoute
              path="/prospects/create"
              exact
              component={CreateProspect}
            />
            <AdminOrSalesRoute
              path="/prospects/:id"
              exact
              component={SingleProspect}
            />

            <AdminRoute
              path="/exclusion-rules/:id"
              exact
              component={SingleExclusionRule}
            />

            <AdminRoute
              path="/country-settings/new"
              exact
              component={NewCountrySetting}
            />

            <AdminRoute
              path="/country-settings/:id"
              exact
              component={SingleCountrySetting}
            />

            <AdminRoute
              path="/country-settings/"
              exact
              component={CountrySettings}
            />

            <AdminRoute
              path="/exclusion-rules/"
              exact
              component={ExclusionRules}
            />

            <AdminRoute path="/widgets" exact component={AdminWidgets} />

            <AdminRoute
              path="/opi-weights"
              exact
              component={UpdateOPIWeights}
            />

            <AdminOrSalesRoute
              path="/flight-board"
              exact
              component={FlightBoard}
            />

            <AdminOrSalesRoute
              path="/leads/archived"
              exact
              component={ArchivedLeads}
            />
            <AdminOrSalesRoute path="/leads/new" exact component={NewLeads} />

            <AdminOrSalesRoute
              path="/leads/in-progress"
              exact
              component={InProgressLeads}
            />
            <AdminOrSalesRoute
              path="/leads/unqualified"
              exact
              component={UnqualifiedLeads}
            />
            <AdminOrSalesRoute
              path="/leads/warm-prospects"
              exact
              component={WarmProspectLeads}
            />
            <AdminOrSalesRoute
              path="/leads/closed-repeat"
              exact
              component={ClosedRepeatLeads}
            />

            <AdminOrSalesRoute
              path="/leads/closed-ghost"
              exact
              component={ClosedGhostLeads}
            />

            <AdminOrSalesRoute
              path="/leads/closed-lost"
              exact
              component={ClosedLostToCompetitorLeads}
            />

            <AdminOrSalesRoute
              path="/leads/in-limbo"
              exact
              component={InLimboLeads}
            />

            <AdminOrSalesRoute
              path="/leads/signed-contract"
              exact
              component={SignedContractLeads}
            />

            <AdminOrSalesRoute
              path="/leads/trip-complete"
              exact
              component={TripCompleteLeads}
            />

            <AdminOrSalesRoute path="/leads/qfs" exact component={QFSLeads} />

            <OutsideOrgRoute
              path="/trip-requests"
              exact
              component={TripRequests}
            />

            <OutsideOrgRoute path="/developers" exact component={Developers} />

            <OutsideOrgRoute
              path="/trip-requests/:id"
              exact
              component={SingleTripRequest}
            />
            <AdminOrSalesRoute path="/leads/:id" exact component={SingleLead} />
            <AdminOrSalesRoute
              path="/leads/:id/quotes/new"
              exact
              component={NewQuote}
            />

            <AdminRoute path="/plaid" exact component={Plaid} />
            <AdminRoute path="/users" exact component={Users} />

            <OutsideOrgAdminRoute
              path="/settings/users"
              exact
              component={OutsideOrgUsers}
            />
            <OutsideOrgAdminRoute
              path="/settings/users/new"
              exact
              component={OutsideOrgNewUser}
            />
            <OutsideOrgAdminRoute
              path="/settings/users/:id"
              exact
              component={OutsideOrgSingleUser}
            />

            <AdminRoute path="/users/new" exact component={NewUser} />
            <AdminRoute path="/users/:id" exact component={SingleUser} />
            <AdminRoute path="/estimates" exact component={Estimates} />
            <AdminRoute path="/organizations" exact component={Organizations} />
            <AdminOrSalesRoute path="/quotes" exact component={Quotes} />
            <AdminOrSalesRoute
              path="/quotes/:id"
              exact
              component={SingleQuote}
            />

            <OutsideOrgRoute
              path="/quote-preview/:id"
              exact
              component={OutsideOrgSingleQuote}
            />

            <AdminOrSalesRoute
              path="/quotes/:quoteId/edit"
              exact
              component={NewQuote}
            />

            <AdminOrSalesRoute path="/new-quote" exact component={QFS} />
            <AdminOrSalesRoute
              path="/new-lead"
              exact
              component={LeadFromScratch}
            />

            <AdminOrSalesRoute
              path="/phone-calls"
              exact
              component={PhoneCalls}
            />
            <AdminOrSalesRoute
              path="/phone-calls/:id/new-quote"
              exact
              component={PhoneCallQuote}
            />

            <AdminOrSalesRoute
              path="/sourcing/new"
              exact
              component={SourcingModule}
            />

            <AdminOrSalesRoute
              path="/sourcing/:id"
              exact
              component={SourcingModule}
            />

            <AdminOrSalesRoute
              path="/sourcing"
              exact
              component={SourcingList}
            />

            <AdminRoute
              path="/organizations/new"
              exact
              component={NewOrganization}
            />
            <AdminRoute
              path="/organizations/:id"
              exact
              component={SingleOrganization}
            />
            <AdminRoute path="/airports-api" component={AirportsAPI} />
            <AdminRoute path="/airports" exact component={Airports} />
            <AdminRoute path="/airports/new" component={NewAirport} />
            <AdminRoute path="/airports/:id" component={SingleAirport} />
            <AdminRoute path="/aircraft" exact component={Aircrafts} />
            <AdminRoute path="/aircraft/:id" component={SingleAircraft} />
            <AdminRoute
              path="/aircraft-types"
              exact
              component={AircraftTypes}
            />
            <AdminRoute
              path="/aircraft-types/:id"
              component={SingleAircraftType}
            />
            <AdminRoute
              path="/distance-params"
              exact
              component={DistanceParams}
            />
            <AdminRoute path="/spreads" exact component={Spreads} />

            <AdminRoute path="/spreads/:id" exact component={SingleSpread} />

            <AdminSalesOutsideOrgRoute
              path="/calculator"
              exact
              component={Calculator}
            />
            {/* <Redirect to="/" /> */}
          </Switch>
        </div>
        <Footer />
      </main>
    </div>
  );
  // } else {
  //   return (
  //     <div id="app" className="d-flex flex-column h-100">
  //       <Switch>
  //         <ProtectedRoute Route path="/" exact component={Home} />
  //         <ProtectedRoute path="/my-account" component={MyAccount} />
  //         <ProtectedRoute path="/edit/:id" component={EditFlight} />
  //         {/* <ProtectedRoute path="/plaid" exact component={Plaid} /> */}

  //         <Redirect to="/" />
  //       </Switch>
  //     </div>
  //   );
  // }
};

export default App;
