import axios from "axios";

const auth = (token) => {
  axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  return axios;
};

const ai = {
  auth,
};

export default ai;
