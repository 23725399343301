import React, { Fragment, useReducer, useEffect } from "react";
import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import ai from "../../helpers/axios";
import * as Sentry from "@sentry/react";

import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";

const reducer = (state, action) => {
  switch (action.type) {
    case "search": {
      return {
        ...state,
        query: action.query,
      };
    }

    case "fieldChanged": {
      return {
        ...state,
        [action.field]: action.value,
      };
    }

    default:
      break;
  }
  return state;
};

const OutsideOrgNewUserContent = (props) => {
  const history = useHistory();

  const initialState = {
    organizations: [],
    availableRoles: [],
    selectedOrganization: [],
    phone: "",
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    selectedRole: { id: "", value: "", label: "" },
    passwordVisibility: false,
  };

  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    document.title = "New User | Flight Deck by evoJets";
  }, []);

  const handlePasswordVisibilityToggle = (e) => {
    dispatch({
      type: "fieldChanged",
      field: "passwordVisibility",
      value: !state.passwordVisibility,
    });
  };

  // const loadOrganizations = async (inputValue) => {
  //   const token = await getAccessTokenSilently().catch((e) => {
  //   console.error(e);
  //   loginWithRedirect();
  // });
  //   let organizations = await ai
  //     .auth(token)
  //     .get(`/api/organizations/list`)
  //     .catch((error) => {
  //       console.error(error);
  //       Sentry.captureException(error);
  //     });

  //   let roles = await ai
  //     .auth(token)
  //     .get(`/api/users/availableRoles`)
  //     .catch((error) => {
  //       console.error(error);
  //       Sentry.captureException(error);
  //     });

  //   if (
  //     organizations &&
  //     organizations.data &&
  //     organizations.data.organizations &&
  //     organizations.data.organizations.length > 0
  //   ) {
  //     console.log(organizations.data);

  //     let organizationsFormatted = organizations.data.organizations.map(
  //       (organization) => {
  //         return {
  //           ...organization,
  //           label: organization.name,
  //           value: organization._id,
  //         };
  //       }
  //     );

  //     dispatch({
  //       type: "fieldChanged",
  //       field: "organizations",
  //       value: organizationsFormatted,
  //     });
  //   }

  //   if (
  //     roles &&
  //     roles.data &&
  //     roles.data.roles &&
  //     roles.data.roles.length > 0
  //   ) {
  //     dispatch({
  //       type: "fieldChanged",
  //       field: "availableRoles",
  //       value: roles.data.roles.map((role) => {
  //         return { ...role, label: role.name, value: role.id };
  //       }),
  //     });
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let formData = {
      firstName: state.firstName,
      lastName: state.lastName,
      name: `${state.firstName} ${state.lastName}`,
      phone: state.phone,
      email: state.email,
      // password: state.password,
    };
    console.log(formData);

    let response = await ai
      .auth(token)
      .post(`/api/users/myOrganization/create`, formData)
      .catch((error) => {
        Sentry.captureException(error);
        console.log("error");
        toast.error("You've hit your user limit!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return null;
      });

    if (response) {
      // history.push({ pathname: "/settings/users" });
      console.log(response.data);
      setTimeout(() => {
        toast.success("User Created Successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }, 50);
    }
  };

  return (
    <Fragment>
      <h2 id="addAirportHeading">Create a New User</h2>
      <form id="" className="base-form two-column" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="firstName">First Name</label>
          <input
            type="text"
            name="firstName"
            value={state.firstName}
            placeholder="First Name"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="lastName">Last Name</label>
          <input
            type="text"
            name="lastName"
            value={state.lastName}
            placeholder="Last Name"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="phone">Phone Number</label>
          <input
            type="text"
            name="phone"
            value={state.phone}
            placeholder="Phone Number"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="phone">Email Address</label>
          <input
            type="email"
            name="email"
            value={state.email}
            placeholder="Email Address"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        {/* <div className="form-group">
          <label htmlFor="notificationEmails">Organization</label>

          <Select
            key={`organization-options-key-${JSON.stringify(
              state.organizations
            )}`}
            className="organization-select"
            classNamePrefix="select"
            options={state.organizations}
            // defaultValue={props.initialTags}
            placeholder="Organization"
            onChange={(selectedOrganization) => {
              dispatch({
                type: "fieldChanged",
                field: "selectedOrganization",
                value: selectedOrganization,
              });
            }}
          ></Select>
        </div> */}
        {/* 
        <div className="form-group password-container">
          <label htmlFor="phone">Password</label>
          <input
            type={!state.passwordVisibility ? "password" : "text"}
            name="password"
            value={state.password}
            placeholder="Password"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
          <span
            onClick={handlePasswordVisibilityToggle}
            className="material-icons"
          >
            {state.passwordVisibility ? "visibility_off" : "visibility"}
          </span>
        </div> */}

        {/* <div className="form-group">
          <label htmlFor="userRoles">User Role</label>

          <Select
            key={`role-options-key-${JSON.stringify(state.availableRoles)}`}
            name="userRoles"
            className="organization-select"
            classNamePrefix="select"
            options={state.availableRoles}
            // defaultValue={state.userRoles}
            placeholder="User Role"
            onChange={(selectedRole) => {
              dispatch({
                type: "fieldChanged",
                field: "selectedRole",
                value: selectedRole,
              });
            }}
          ></Select>
        </div> */}

        <input type="submit" value="Save User"></input>
      </form>

      {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
    </Fragment>
  );
};

export default OutsideOrgNewUserContent;
