import React from "react";

const QuotePreview = (props) => {
  return (
    <div
      id="quoteEmailPreview"
      dangerouslySetInnerHTML={{ __html: props.emailPreview.body }}
      key={props.emailPreview.body}
      className={"outsideOrgPreview"}
    ></div>
  );
};

export default QuotePreview;
