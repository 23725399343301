import { Link } from "react-router-dom";
import moment from "moment";

const columns = [
  {
    name: "View Details",
    sortable: false,
    cell: (row) => <Link to={`/trip-requests/${row._id}`}>View</Link>,
    width: "130px",
  },

  {
    name: "Evo Quote",
    sortable: false,
    cell: (row) => {
      if (row.quotes.length > 0) {
        return <Link to={`/quote-preview/${row.quotes[0]}`}>View</Link>;
      }

      return "In Progress";
    },
    width: "170px",
  },
  {
    name: "Created At",
    selector: "createdAt",
    sortable: true,
    width: "250px",
    cell: (row) => {
      return moment(row.createdAt).format("llll");
    },
  },
  {
    name: "Created By",
    selector: "createdByName",
    sortable: true,
    width: "250px",
  },
  {
    name: "Stage",
    selector: "stage",
    sortable: true,
    width: "130px",
    cell: (row) => {
      if (row.stage === "newLead") {
        return "New Lead";
      } else if (row.stage === "inProgress") {
        return "In Progress";
      } else if (row.stage === "archived") {
        return "Archived";
      } else if (row.stage === "warmProspect") {
        return "Warm Prospect";
      } else if (row.stage === "unqualified") {
        return "Unqualified";
      }
    },
  },
  {
    name: "Evo Rep",
    selector: "assigneeName",
    cell: (row) => (row.assigneeName ? row.assigneeName : "Unassigned"),
    sortable: true,
    width: "150px",
  },
  {
    name: "First Name",
    selector: "firstName",
    sortable: true,
    width: "150px",
  },
  {
    name: "Last Name",
    selector: "lastName",
    sortable: true,
    width: "150px",
  },
  {
    name: "Phone",
    selector: "phone",
    sortable: true,
    width: "150px",
  },
  {
    name: "Email",
    selector: "email",
    sortable: true,
    width: "250px",
  },
  {
    name: "Comments",
    selector: "comments",
    sortable: true,
    width: "350px",
  },
  {
    name: "Company",
    selector: "companyName",
    sortable: true,
    width: "150px",
  },
  // {
  //   name: "Lead Origin",
  //   selector: "leadOrigin",
  //   sortable: true,
  //   width: "150px",
  //   cell: (row) => {
  //     let formatted = "";
  //     if (row.leadOrigin === "liveAPI") {
  //       formatted = "API Request";
  //     } else if (row.leadOrigin === "flightDeckExternal") {
  //       formatted = "Flight Deck Request";
  //     } else {
  //       formatted = "Unset";
  //     }
  //     return formatted;
  //   },
  // },
  {
    name: "Trip Type",
    selector: "tripType",
    sortable: true,
    width: "150px",
    cell: (row) => (row.tripType === 1 ? "One Way" : "Round Trip"),
  },
  {
    name: "Departure Date",
    selector: "estimateData[0].departureDate",
    sortable: true,
    width: "200px",
  },
  {
    name: "Return Date",
    selector: "estimateData[0].returnDate",
    sortable: true,
    width: "200px",
    cell: (row) =>
      row.estimateData[0].returnDate ? row.estimateData[0].returnDate : "-",
  },

  {
    name: "Departure Airport",
    selector: "estimateData[0].departureAirportName",
    sortable: true,
    width: "350px",
  },

  {
    name: "Arrival Airport",
    selector: "estimateData[0].arrivalAirportName",
    sortable: true,
    width: "350px",
  },

  {
    name: "Passengers",
    selector: "estimateData[0].passengerCount",
    sortable: true,
    width: "150px",
  },
];

export default columns;
