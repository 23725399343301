import React from "react";
import AsyncSelect from "react-select/async";
import Select from "react-select";

const LeadBulkActions = (props) => {
  const selectedRows = props.state.selectedRows;
  const superAdminOrAd = props.superAdminOrAd;

  return (
    <div id="lead-bulk-actions" key={props.disableAssignmentButtons}>
      {/* <AsyncSelect
        key={`selectedOwner-${props.state.selectedOwner}`}
        className="owner-select"
        classNamePrefix="select"
        cacheOptions
        loadOptions={props.loadOwnerOptions}
        defaultOptions
        placeholder="Assign Flight"
        onInputChange={props.handleOwnerInputChange}
        onChange={props.handleOwnerSelection}
        value={props.state.selectedOwner}
      ></AsyncSelect> */}
      {superAdminOrAd && (
        <Select
          key={`selectedOwner-${props.state.selectedOwner}`}
          className="owner-select"
          classNamePrefix="select"
          value={props.state.selectedOwner}
          options={props.ownerOptions}
          // defaultValue={props.initialTags}
          placeholder="Assign Lead"
          isDisabled={props.disableAssignmentSelect()}
          onChange={props.handleOwnerSelection}
        ></Select>
      )}

      <Select
        key={`outreach-stages`}
        className="stage-select"
        classNamePrefix="select"
        // value
        options={props.stageFilterOptions}
        // defaultValue={props.initialTags}
        placeholder="Change Lead Stage"
        isDisabled={props.disableStageAssignmentSelect()}
        onChange={props.handleLeadStageUpdate}
      ></Select>

      {/* <div id="outreach-status-updaters">
        <button
          className="lead-outreach-assignment"
          onClick={props.handleLeadStageUpdate}
          id="in-progress-button"
          disabled={props.disableAssignmentButtons()}
        >
          In Progress
        </button>
        <button
          className="lead-outreach-assignment"
          onClick={props.handleLeadStageUpdate}
          id="archive-button"
          disabled={props.disableAssignmentButtons()}
        >
          Archive
        </button>
        <button
          className="lead-outreach-assignment"
          onClick={props.handleLeadStageUpdate}
          id="warm-prospect-button"
          disabled={props.disableAssignmentButtons()}
        >
          Warm Prospect
        </button>
        <button
          className="lead-outreach-assignment"
          onClick={props.handleLeadStageUpdate}
          id="unqualified-button"
          disabled={props.disableAssignmentButtons()}
        >
          Unqualified
        </button>
      </div> */}

      <div id="filterByStage">
        <Select
          className="stage-select"
          classNamePrefix="select"
          placeholder="Stage"
          isMulti
          options={props.stageFilterOptions}
          onChange={props.handleStageFilterChange}
          defaultValue={props.defaultStageFilters}
          value={props.state.stageFilterInput}
        ></Select>
      </div>

      {!props.qfsOnly && (
        <div id="filterByOrigin">
          <Select
            className="stage-select"
            classNamePrefix="select"
            placeholder="Origin"
            isMulti
            options={props.originFilterOptions}
            onChange={props.handleOriginFilterChange}
            defaultValue={props.defaultOriginFilters}
            value={props.state.originFiltersInput}
            key={`ownerFilters${props.state.originFiltersInput}`}
          ></Select>
        </div>
      )}

      {props.showOnlyMine || props.showMyTeamLeads || !props.superAdminOrAd ? (
        ""
      ) : (
        <div id="filterByOwner">
          <Select
            className="stage-select"
            options={props.ownerFilterOptions}
            placeholder="Owner"
            onChange={props.handleOwnerFilterChange}
            defaultValue={props.defaultOwnerFilters}
            value={props.state.ownerFiltersInput}
            // key={`key-ownerFilters-${props.state.ownerFilters}`}
            isMulti
          ></Select>
        </div>
      )}
    </div>
  );
};

export default LeadBulkActions;
