import React from "react";
import LeadsContent from "../components/leads/leads-content";

const ClosedLostToCompetitorLeads = () => {
  return (
    <LeadsContent
      prefilters={["closedCompetitor"]}
      defaultStageFilters={{
        label: "Closed - Lost to Competitor",
        value: "closedCompetitor",
      }}
      pageTitle="Closed - Lost to Competitor Leads"
    />
  );
};

export default ClosedLostToCompetitorLeads;
