import React, { Fragment, useReducer, useEffect } from "react";
import DatePicker from "react-date-picker";
import { useAuth0 } from "@auth0/auth0-react";

import ai from "../../helpers/axios";

import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import "react-json-pretty/themes/monikai.css";
import JSONPretty from "react-json-pretty";
import * as Sentry from "@sentry/react";

const reducer = (state, action) => {
  switch (action.type) {
    case "dataBeganDownloading": {
      return {
        ...state,
        loading: true,
      };
    }
    case "dataDownloaded": {
      return {
        ...state,
        client: action.payload.data,
        loading: false,
      };
    }

    default:
      break;
  }
  return state;
};

const initialState = {
  client: {
    clientId: "",
    clientSecret: "",
    clientName: "",
  },
};

const DevelopersContent = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();

  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const getData = async () => {
    dispatch({ type: "dataBeganDownloading" });

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let response = await ai
      .auth(token)
      .get(`/api/organizations/developer-keys`)
      .catch((error) => {
        // history.push({ pathname: "/" });
        setTimeout(() => {
          toast.error("Unauthorized!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }, 50);

        return console.error(error.response);
      });

    if (response && response?.data) {
      console.log(response.data);

      dispatch({
        type: "dataDownloaded",
        payload: {
          data: response.data,
        },
      });
    }
  };

  useEffect(() => {
    getData();
    document.title = "Developers | Flight Deck by evoJets";
  }, []);

  return (
    <Fragment>
      <div className="text-center hero">
        <h1 className="mb-4">Developer Credentials</h1>
      </div>
      <div id="developerPage">
        <p>
          Flight Deck's API documentation can be found{" "}
          <a href="https://dev.evojets.com" target="_blank">
            here
          </a>
          .
        </p>

        <p>
          Below, you'll find your client credentials, which are necessary to
          authenticate with our API.
        </p>

        <form className="base-form">
          <div className="form-group">
            <label htmlFor="clientId">Client ID</label>
            <input
              type="text"
              name="clientId"
              placeholder="Client ID"
              value={state.client.clientId}
              disabled
            ></input>
          </div>

          <div className="form-group">
            <label htmlFor="clientId">Client Secret</label>
            <input
              type="text"
              name="clientSecret"
              placeholder="Client Secret"
              value={state.client.clientSecret}
              disabled
            ></input>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default DevelopersContent;
