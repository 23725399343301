import React, { Fragment, useEffect, useReducer } from "react";
import { ToastContainer, toast, Slide } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";

import { Link } from "react-router-dom";
import ai from "../../helpers/axios";
import columns from "./exclusionRules-columns";
// import TableBulkActions from "../table-bulk-actions";

// import DataTableServerPagination from "../data-table-server-pagination";
import PageHeaderRowSearch from "../shared/page-header-row-search";
import DataTable from "react-data-table-component";

const reducer = (state, action) => {
  switch (action.type) {
    case "search": {
      return {
        ...state,
        query: action.query,
      };
    }
    case "dataBeganDownloading": {
      return {
        ...state,
        loading: action.payload.loadingStatus,
      };
    }
    case "rowsDeleted": {
      return {
        ...state,
        clearSelectedRows: !state.clearSelectedRows,
        selectedRows: [],
      };
    }
    case "rowSelection": {
      return {
        ...state,
        selectedRows: action.payload.selectedRows,
        allSelected: action.payload.allSelected,
      };
    }
    case "dataDownloaded": {
      console.log(action.payload);
      return {
        ...state,
        exclusionRules: {
          data: action.payload.data.exclusionRules,
        },
        loading: false,
        totalLength: action.payload.data.countTotal,
      };
    }
    // case "pageChanged": {
    //   return {
    //     ...state,
    //     currentPage: action.payload.page,
    //   };
    // }
    // case "rowsPerPageChanged": {
    //   return {
    //     ...state,
    //     countPerPage: action.payload.currentRowsPerPage,
    //   };
    // }
    default:
      break;
  }
  return state;
};

const ExclusionRulesContent = () => {
  const initialState = {
    exclusionRules: {
      data: [],
    },
    loading: true,
    countPerPage: 25,
    currentPage: 1,
    query: "",
    selectedRows: [],
    allSelected: false,
    tagFilters: [],
    totalLength: null,
    clearSelectedRows: false,
  };

  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const [state, dispatch] = useReducer(reducer, initialState);

  console.log(state);

  const getData = async (query, loadSilently) => {
    let loadingStatus = true;
    if (loadSilently) {
      loadingStatus = false;
    }

    dispatch({ type: "dataBeganDownloading", payload: { loadingStatus } });

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });
    let { data } = await ai.auth(token).get(`/api/exclusionRules/list`, {
      params: {
        query: query,
      },
    });

    console.log(data);

    dispatch({
      type: "dataDownloaded",
      payload: {
        data,
      },
    });
  };

  useEffect(() => {
    getData("");
    document.title = "Exclusion Rules | Flight Deck by evoJets";
  }, []);

  const handlePageChange = (page, totalRows) => {
    dispatch({
      type: "pageChanged",
      payload: {
        page,
        totalRows,
      },
    });
  };

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    dispatch({
      type: "rowsPerPageChanged",
      payload: {
        currentRowsPerPage,
        currentPage,
      },
    });
  };

  const handleSelection = (rowData) => {
    dispatch({
      type: "rowSelection",
      payload: {
        selectedRows: rowData.selectedRows,
        allSelected: rowData.allSelected,
      },
    });
  };

  const handleDelete = async (e) => {
    e.preventDefault();

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let rowIds = state.selectedRows.map((item) => item._id);
    let { data } = await ai
      .auth(token)
      .delete("/api/exclusionRules/deleteMany", {
        data: { ids: rowIds },
      });

    if (data.deletedCount) {
      getData("", true);

      toast.success("Exclusion Rules Deleted Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      console.log("No Rows Deleted");
    }

    // state changes happen regardless if rows successfully deleted or not
    dispatch({
      type: "rowsDeleted",
    });
  };

  let deleteButtonText = "Delete";
  let deleteButtonStatus = "disabled";
  if (state.selectedRows.length) {
    if (state.selectedRows.length === 1) {
      deleteButtonText = `Delete ${state.selectedRows.length} Row`;
    } else {
      deleteButtonText = `Delete ${state.selectedRows.length} Rows`;
    }
    deleteButtonStatus = "";
  }

  return (
    <Fragment>
      {/* <ToastContainer transition={Slide} /> */}

      <PageHeaderRowSearch
        h1={`Exclusion Rules`}
        pretitle="Settings"
        breadcrumbs={[{ link: "/exclusion-rules", label: "Exclusion Rules" }]}
        rightColumn={
          <div className="header-actions-row mr-0">
            <input
              disabled={deleteButtonStatus}
              type="button"
              id="deleteButton"
              className={"align-right"}
              value={deleteButtonText}
              onClick={handleDelete}
            />

            <Link
              to="/exclusion-rules/new"
              className="evo-blue base-button ml-20"
            >
              Add Exclusion Rule
            </Link>
          </div>
        }
      ></PageHeaderRowSearch>
      <div
        className={`flight-table-container standard-data-table-container country-settings-table mt-25
        }`}
      >
        <DataTable
          data={state.exclusionRules.data}
          columns={columns}
          noHeader
          highlightOnHover
          pagination={true}
          paginationPerPage={50}
          paginationRowsPerPageOptions={[50, 100, 150, 200, 250]}
          defaultSortField="originCountryISOCode"
          defaultSortAsc={false}
          responsive={true}
          selectableRows={true}
          onSelectedRowsChange={handleSelection}
        ></DataTable>
      </div>

      {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
    </Fragment>
  );
};

export default ExclusionRulesContent;
