import React, { Fragment, useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import ai from "../helpers/axios";
import { useAuth0 } from "@auth0/auth0-react";

const NewAirportAutocomplete = (props) => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const SEARCH_URI = `${process.env.REACT_APP_SERVER_URL}/api/airports/search`;
  let ip =
    Math.floor(Math.random() * 255) +
    1 +
    "." +
    (Math.floor(Math.random() * 255) + 0) +
    "." +
    (Math.floor(Math.random() * 255) + 0) +
    "." +
    (Math.floor(Math.random() * 255) + 0);

  const handleSearch = async (query) => {
    setIsLoading(true);
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let response = await ai
      .auth(token)
      .get(`${SEARCH_URI}?q=${query}&ip_address=${ip}`);

    // console.log(response.data);

    const options = response.data.map((i) => ({
      id: i._id,
      airportName: i.airportName,
      code: i.code,
    }));

    setOptions(options);
    setIsLoading(false);
  };

  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;

  return (
    <div className="form-group airportAutocompleteContainer">
      <label className={"airportAutoCompleteLabel"}>{props.label}</label>
      <AsyncTypeahead
        key={props.defaultSelected}
        filterBy={filterBy}
        id={"newAirportAutocomplete"}
        isLoading={isLoading}
        labelKey="airportName"
        minLength={3}
        onSearch={handleSearch}
        options={options}
        placeholder={props.placeholder || `Search Airports`}
        defaultSelected={[props.defaultSelected] || null}
        onChange={props.handleChange}
        className={props.className}
        renderMenuItemChildren={(option, props) => (
          <Fragment>
            <span>{option.airportName}</span>
          </Fragment>
        )}
      />
    </div>
  );
};

export default NewAirportAutocomplete;
