import React, { Fragment, useReducer, useEffect } from "react";
import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import ai from "../../helpers/axios";
import CreatableSelect from "react-select/creatable";
// import ReactModal from "react-modal";

import { useHistory, useParams } from "react-router-dom";

const toastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "search": {
      return {
        ...state,
        query: action.query,
      };
    }
    case "fieldChanged": {
      return {
        ...state,
        organization: {
          ...state.organization,
          [action.field]: action.value,
        },
      };
    }
    case "rootFieldChanged": {
      return {
        ...state,
        [action.field]: action.value,
      };
    }
    case "dataBeganDownloading": {
      return {
        ...state,
        loading: true,
      };
    }
    case "dataDownloaded": {
      return {
        ...state,
        organization: {
          ...action.payload.data,
        },
        newEmails: action.payload.data.notificationEmails.map((item) => {
          return { label: item, value: item };
        }),
        evoCalculator:
          action.payload.data.permissions.includes("Evo Calculator"),
        rfpCalculator:
          action.payload.data.permissions.includes("RFP Calculator"),

        developerCredentials: action.payload.data.developerCredentials,
      };
    }
    default:
      break;
  }
  return state;
};

const SingleOrganizationContent = (props) => {
  const { id } = useParams();
  const history = useHistory();

  const initialState = {
    organization: {
      name: "",
      maxUsers: "",
      phone: "",
      notificationEmails: [],
      permissions: [],
    },
    loading: true,
    emailInputValue: "",
    newEmails: [],
    evoCalculator: true,
    rfpCalculator: false,
    developerCredentials: false,
  };

  const { getAccessTokenSilently, loginWithRedirect, user } = useAuth0();

  const [state, dispatch] = useReducer(reducer, initialState);

  const getData = async () => {
    dispatch({ type: "dataBeganDownloading" });

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let { data } = await ai.auth(token).get(`/api/organizations/${id}`);

    dispatch({
      type: "dataDownloaded",
      payload: {
        data,
      },
    });

    document.title = `${data.name} | Organizations | Flight Deck by evoJets`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let formData = {
      maxUsers: state.organization.maxUsers || null,
      name: state.organization.name,
      phone: state.organization.phone,
      notificationEmails: state.newEmails.map((item) => item.value),
      developerCredentials: state.developerCredentials,
    };

    if (state.evoCalculator) {
      formData.permissions = ["Evo Calculator"];
    }

    if (state.rfpCalculator) {
      formData.permissions = ["RFP Calculator"];
    }

    let { data } = await ai
      .auth(token)
      .put(`/api/organizations/${id}`, formData);
    console.log("resp", data);

    history.push({ pathname: "/organizations/" });

    setTimeout(() => {
      toast.success("Organization Edited Successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }, 50);
  };

  const emailComponents = {
    DropdownIndicator: null,
  };

  const createOption = (label) => ({
    label,
    value: label,
  });

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailChange = (newValue, actionMeta) => {
    console.group("Email Value Changed");
    console.log(newValue);
    console.log(`action: ${actionMeta.action}`);
    dispatch({
      type: "fieldChanged",
      field: "newEmails",
      value: newValue || [],
    });
    console.groupEnd();
  };

  const handleEmailInputChange = (inputValue, action) => {
    console.group("Email Input Changed");
    console.log(inputValue);
    console.log("action", action);

    dispatch({
      type: "rootFieldChanged",
      field: "emailInputValue",
      value: inputValue,
    });

    if (
      action.action === "menu-close" &&
      validateEmail(state.emailInputValue)
    ) {
      dispatch({
        type: "rootFieldChanged",
        field: "newEmails",
        value: [...state.newEmails, createOption(state.emailInputValue)],
      });
      dispatch({
        type: "rootFieldChanged",
        field: "emailInputValue",
        value: "",
      });
    }

    console.groupEnd();
  };

  const handleEmailKeyDown = (event) => {
    const { emailInputValue, newEmails } = state;
    if (!emailInputValue || validateEmail(emailInputValue)) return;

    // eslint-disable-next-line default-case
    switch (event.key) {
      case "Enter":
      case "Tab":
        console.group("Email Value Added");
        console.log(newEmails);
        console.groupEnd();

        if (state.email) {
          dispatch({
            type: "rootFieldChanged",
            field: "newEmails",
            value: [...newEmails, createOption(emailInputValue)],
          });
        } else {
          dispatch({
            type: "rootFieldChanged",
            field: "newEmails",
            value: [createOption(emailInputValue)],
          });
        }

        dispatch({
          type: "rootFieldChanged",
          field: "emailInputValue",
          value: "",
        });
        event.preventDefault();
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleDeleteOrganization = async (e) => {
    e.preventDefault();
    let confirmation = window.confirm(
      `Are you sure you'd like to delete ${state.organization.name}?`
    );

    if (!confirmation) {
      return;
    }

    let apiStatusToast = toast.loading("Loading...", {
      icon: true,
      // toastId: "apiStatus",
    });

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let response = await ai.auth(token).delete(`/api/organizations/${id}`);

    if (response && response.data) {
      console.log(response.data);

      toast.update(apiStatusToast, {
        render: `${state.organization.name} has been deleted.`,
        type: "success",
        isLoading: false,
        icon: false,
        ...toastOptions,
        // toastId: "apiStatus",
      });
      setTimeout(() => {
        history.push("/organizations");
      }, 1500);
    }
  };

  return (
    <Fragment>
      <h2 id="addAirportHeading">Edit Organization</h2>
      <form id="addAirport" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            name="name"
            value={state.organization.name}
            placeholder="Name"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="maxUsers">Max Users</label>
          <input
            type="number"
            name="maxUsers"
            value={state.organization.maxUsers || ""}
            placeholder="Max Users"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="phone">Phone Number</label>
          <input
            type="text"
            name="phone"
            value={state.organization.phone}
            placeholder="Phone"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="notificationEmails">Notification Emails</label>
          <CreatableSelect
            className="email-select"
            classNamePrefix="select"
            placeholder="Email"
            components={emailComponents}
            isClearable
            isMulti
            menuIsOpen={false}
            onChange={handleEmailChange}
            onInputChange={handleEmailInputChange}
            onKeyDown={handleEmailKeyDown}
            inputValue={state.emailInputValue}
            value={state.newEmails}
            name={"email"}
            inputId={"email"}
          ></CreatableSelect>
        </div>

        <div className="form-group">
          <h3>API Access</h3>

          <input
            name="developerCredentials"
            type="checkbox"
            value="developerCredentials"
            id="developerCredentials"
            checked={state.developerCredentials}
            onChange={(e) => {
              dispatch({
                type: "rootFieldChanged",
                field: e.target.name,
                value: e.target.checked ? true : false,
              });
            }}
          ></input>
          <label htmlFor="developerCredentials" className="ml-10">
            Allow API
          </label>
        </div>

        <div className="permissions-list">
          <h3>Calculator Permissions</h3>

          <div className="">
            <input
              name="evoCalculator"
              type="checkbox"
              value="evoCalculator"
              id="evoCalculator"
              checked={state.evoCalculator}
              onChange={(e) => {
                dispatch({
                  type: "rootFieldChanged",
                  field: e.target.name,
                  value: e.target.checked ? true : false,
                });
                dispatch({
                  type: "rootFieldChanged",
                  field: "rfpCalculator",
                  value: e.target.checked ? false : true,
                });
              }}
            ></input>
            <label htmlFor="evoCalculator">Evo Calculator</label>
          </div>

          <div className="">
            <input
              name="rfpCalculator"
              type="checkbox"
              value="rfpCalculator"
              id="rfpCalculator"
              checked={state.rfpCalculator}
              onChange={(e) => {
                dispatch({
                  type: "rootFieldChanged",
                  field: e.target.name,
                  value: e.target.checked ? true : false,
                });
                dispatch({
                  type: "rootFieldChanged",
                  field: "evoCalculator",
                  value: e.target.checked ? false : true,
                });
              }}
            ></input>
            <label htmlFor="rfpCalculator">RFP Calculator</label>
          </div>
        </div>

        <input type="submit" value="Update Organization"></input>
        <button
          className="evo-gunmetal base-button mt-20 centered w-100pct"
          onClick={handleDeleteOrganization}
        >
          Delete Organization
        </button>
      </form>

      <pre>{JSON.stringify(state, null, 2)}</pre>
    </Fragment>
  );
};

export default SingleOrganizationContent;
