import React, { Fragment } from "react";

import { Hero } from "../components";
import HomeContent from "../components/home/home-content";
import { useAuth0 } from "@auth0/auth0-react";
import TripRequestsWidget from "../components/leads/trip-requests-widget";

const Home = () => {
  const { isAuthenticated, user, isLoading } = useAuth0();

  const outsideOrg = user["https://app.evojets.com/outsideOrganization"];

  return (
    <Fragment>
      <Hero />
      {isAuthenticated && !outsideOrg && !isLoading && <HomeContent />}
      {isAuthenticated && !isLoading && outsideOrg && (
        <TripRequestsWidget></TripRequestsWidget>
      )}
    </Fragment>
  );
};

export default Home;
