import React from "react";
import { Link } from "react-router-dom";
const PageHeaderRow = (props) => {
  return (
    <div id="page-header">
      <div id="page-title-container">
        <p className="page-pretitle">{props.pretitle}</p>
        <h1 className="page-title">{props.h1}</h1>
      </div>

      <div id="page-breadcrumb">
        <ol className="breadcrumb">
          {props.breadcrumbs.map((item) => {
            return (
              <li className="breadcrumb-item" key={item.label}>
                <Link to={item.link}>{item.label}</Link>
              </li>
            );
          })}
          <span className="material-icons">chevron_right</span>
          <li className="breadcrumb-item active">
            {props.isMobile ? props.mobileCurrentPageLabel : props.h1}
          </li>
        </ol>
      </div>
    </div>
  );
};

export default PageHeaderRow;
