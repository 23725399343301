import React, { Fragment, useReducer, useEffect } from "react";
import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import ai from "../../helpers/axios";
import CreatableSelect from "react-select/creatable";
import * as Sentry from "@sentry/react";

import PhoneInput from "react-phone-number-input";

import { useHistory, useParams } from "react-router-dom";

const reducer = (state, action) => {
  switch (action.type) {
    case "search": {
      return {
        ...state,
        query: action.query,
      };
    }
    case "fieldChanged": {
      return {
        ...state,
        organization: {
          ...state.organization,
          [action.field]: action.value,
        },
      };
    }
    case "rootFieldChanged": {
      return {
        ...state,
        [action.field]: action.value,
      };
    }
    case "dataBeganDownloading": {
      return {
        ...state,
        loading: true,
      };
    }
    case "dataDownloaded": {
      return {
        ...state,
        organization: {
          ...action.payload.data,
        },
        newEmails: action.payload.data.notificationEmails.map((item) => {
          return { label: item, value: item };
        }),
        evoCalculator:
          action.payload.data.permissions.includes("Evo Calculator"),
        rfpCalculator:
          action.payload.data.permissions.includes("RFP Calculator"),
      };
    }
    default:
      break;
  }
  return state;
};

const NewOrganizationContent = (props) => {
  const history = useHistory();

  const initialState = {
    organization: {
      name: "",
      maxUsers: "",
      phone: "",
      notificationEmails: [],
      permissions: [],
    },
    loading: true,
    emailInputValue: "",
    newEmails: [],
    evoCalculator: false,
    rfpCalculator: true,
    developerCredentials: false,
  };

  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    document.title = "New Organization | Flight Deck by evoJets";
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let formData = {
      maxUsers: state.organization.maxUsers || null,
      name: state.organization.name,
      phone: state.organization.phone,
      notificationEmails: state.newEmails.map((item) => item.value),
      developerCredentials: state.developerCredentials,
    };

    if (state.evoCalculator) {
      formData.permissions = ["Evo Calculator"];
    }

    if (state.rfpCalculator) {
      formData.permissions = ["RFP Calculator"];
    }

    let response = await ai
      .auth(token)
      .post(`/api/organizations/create`, formData)
      .catch((error) => Sentry.captureException(error));

    if (response) {
      // history.push({ pathname: "/organizations/" });
      console.log(response);
      setTimeout(() => {
        toast.success("Organization Created Successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }, 50);
    }
  };

  const emailComponents = {
    DropdownIndicator: null,
  };

  const createOption = (label) => ({
    label,
    value: label,
  });

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailChange = (newValue, actionMeta) => {
    // console.group("Email Value Changed");
    console.log("newValue", newValue);
    // console.log(`action: ${actionMeta.action}`);

    dispatch({
      type: "rootFieldChanged",
      field: "newEmails",
      value: newValue || [],
    });

    if (newValue.length) {
      dispatch({
        type: "fieldChanged",
        field: "notificationEmails",
        value: newValue.map((item) => item.value),
      });
    }

    // console.groupEnd();
  };

  const handleEmailInputChange = (inputValue, action) => {
    // console.group("Email Input Changed");
    console.log("email input change", inputValue, action);
    // console.log("action", action);

    dispatch({
      type: "rootFieldChanged",
      field: "emailInputValue",
      value: inputValue,
    });

    if (
      action.action === "menu-close" &&
      validateEmail(state.emailInputValue)
    ) {
      console.log("MENU CLOSED AND EMAIL VALIDATED");
      dispatch({
        type: "rootFieldChanged",
        field: "newEmails",
        value: [...state.newEmails, createOption(state.emailInputValue)],
      });
      dispatch({
        type: "fieldChanged",
        field: "notificationEmails",
        value: [
          ...state.newEmails.map((item) => item.value),
          state.emailInputValue,
        ],
      });
      dispatch({
        type: "rootFieldChanged",
        field: "emailInputValue",
        value: "",
      });
    }

    // console.groupEnd();
  };

  const handleEmailKeyDown = (event) => {
    const { emailInputValue, newEmails } = state;
    if (!emailInputValue || validateEmail(emailInputValue)) return;

    // eslint-disable-next-line default-case
    switch (event.key) {
      case "Enter":
      case "Tab":
        console.group("Email Value Added");
        console.log(newEmails);
        console.groupEnd();

        if (state.email) {
          dispatch({
            type: "rootFieldChanged",
            field: "newEmails",
            value: [...newEmails, createOption(emailInputValue)],
          });
        } else {
          dispatch({
            type: "rootFieldChanged",
            field: "newEmails",
            value: [createOption(emailInputValue)],
          });
        }

        dispatch({
          type: "rootFieldChanged",
          field: "emailInputValue",
          value: "",
        });
        event.preventDefault();
    }
  };

  return (
    <Fragment>
      <h2 id="addAirportHeading">New Organization</h2>
      <form id="addAirport" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            name="name"
            value={state.organization.name}
            placeholder="Name"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="maxUsers">Max Users</label>
          <input
            type="number"
            name="maxUsers"
            value={state.organization.maxUsers || ""}
            placeholder="Max Users"
            onChange={(e) =>
              dispatch({
                type: "fieldChanged",
                field: e.target.name,
                value: e.target.value,
              })
            }
          ></input>
        </div>

        <div className="form-group">
          <label className="phoneInputLabel" htmlFor="phone">
            Phone Number
          </label>
          <PhoneInput
            placeholder="Phone Number"
            value={state.organization.phone}
            name="phone"
            onChange={(value) =>
              dispatch({
                type: "fieldChanged",
                field: "phone",
                value: value,
              })
            }
            onCountryChange={(value) =>
              dispatch({
                type: "fieldChanged",
                field: "phoneCountry",
                value: value,
              })
            }
            defaultCountry="US"
          />
        </div>

        <div className="form-group">
          <label htmlFor="notificationEmails">Notification Emails</label>
          <CreatableSelect
            className="email-select"
            classNamePrefix="select"
            placeholder="Email"
            components={emailComponents}
            isClearable
            isMulti
            menuIsOpen={false}
            onChange={handleEmailChange}
            onInputChange={handleEmailInputChange}
            onKeyDown={handleEmailKeyDown}
            inputValue={state.emailInputValue}
            value={state.newEmails}
            name={"email"}
            inputId={"email"}
          ></CreatableSelect>
        </div>

        <div className="form-group">
          <h3>API Access</h3>

          <input
            name="developerCredentials"
            type="checkbox"
            value="developerCredentials"
            id="developerCredentials"
            checked={state.developerCredentials}
            onChange={(e) => {
              dispatch({
                type: "rootFieldChanged",
                field: e.target.name,
                value: e.target.checked ? true : false,
              });
            }}
          ></input>
          <label htmlFor="developerCredentials" className="ml-10">
            Allow API
          </label>
        </div>

        <div className="permissions-list">
          <h3>Calculator Permissions</h3>

          <div className="">
            <input
              name="evoCalculator"
              type="checkbox"
              value="evoCalculator"
              id="evoCalculator"
              checked={state.evoCalculator}
              onChange={(e) => {
                dispatch({
                  type: "rootFieldChanged",
                  field: e.target.name,
                  value: e.target.checked ? true : false,
                });
                dispatch({
                  type: "rootFieldChanged",
                  field: "rfpCalculator",
                  value: e.target.checked ? false : true,
                });
              }}
            ></input>
            <label htmlFor="evoCalculator">Evo Calculator</label>
          </div>

          <div className="">
            <input
              name="rfpCalculator"
              type="checkbox"
              value="rfpCalculator"
              id="rfpCalculator"
              checked={state.rfpCalculator}
              onChange={(e) => {
                dispatch({
                  type: "rootFieldChanged",
                  field: e.target.name,
                  value: e.target.checked ? true : false,
                });
                dispatch({
                  type: "rootFieldChanged",
                  field: "evoCalculator",
                  value: e.target.checked ? false : true,
                });
              }}
            ></input>
            <label htmlFor="rfpCalculator">RFP Calculator</label>
          </div>
        </div>

        <input type="submit" value="Save New Organization"></input>
      </form>

      {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
    </Fragment>
  );
};

export default NewOrganizationContent;
