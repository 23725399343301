import { Link } from "react-router-dom";
import moment from "moment";

const columns = [
  {
    name: "View",
    sortable: false,
    cell: (row) => {
      return <Link to={`/leads/${row._id}`}>View</Link>;
    },
    width: "70px",
  },
  //   {
  //     name: "Send Quote",
  //     sortable: false,
  //     cell: (row) => {
  //       let newQuote = <Link to={`/leads/${row._id}/quotes/new`}>New Quote</Link>;

  //       if (row.assigneeId) {
  //         return newQuote;
  //       }

  //       return "Assign Lead First";
  //     },
  //     width: "130px",
  //   },

  {
    name: "Created At",
    selector: "createdAt",
    sortable: true,
    width: "200px",
    cell: (row) => {
      return moment(row.createdAt).format("llll");
    },
  },

  {
    name: "Assigned To",
    selector: "assigneeName",
    sortable: true,
    width: "150px",
    cell: (row) => row.assigneeName || "-",
  },
  {
    name: "Full Name",
    selector: "firstName",
    sortable: true,
    width: "150px",
    cell: (row) => `${row.firstName} ${row.lastName}`,
  },
];

export default columns;
